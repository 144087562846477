export enum DataLayoutDisplayMode {
    List = 'list',
    Grid = 'grid'
}

export type DataLayoutPrefs = {
    sorter?: { key: string; order: 'ASC' | 'DSC' };
    grouper?: string | null;
    displayMode?: DataLayoutDisplayMode;
    currentGroupsOpened?: string[];
    activeColumns?: string[];
    sort?: [columnKey: string, reversed: boolean];
};
