import React from 'react';
import type { SyntheticEvent, ReactNode } from 'react';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import type { Form, GenericModalFormProps } from '../../../types';
import { Theme } from '../../../enums';
import type { ButtonProps } from '../../buttons/Button/Button';
import cn from 'classnames';

type ModalFormProps = {
    cancel?: (e: SyntheticEvent) => void;
    closeModal: () => void;
    headerButtons?: ButtonProps[] | null;
    payload?: {
        data?: any;
    };
    FormComponent: React.ComponentType<Form>;
    submit: (e: SyntheticEvent, data: any, onError?: Function) => void;
    title: string;
    options?: ModalFormFactoryOptions;
    [key: string]: any;
};

export const ModalForm: React.FC<ModalFormProps> = ({
    cancel,
    closeModal,
    headerButtons,
    payload,
    FormComponent,
    submit,
    title,
    width,
    options = {},
    ...rest
}) => (
    <div className={cn('c-modal-form', options?.className)} style={{ width }}>
        <ModalHeader buttons={headerButtons} close={closeModal} title={title} />
        <FormComponent
            theme={Theme.Light}
            cancel={cancel || closeModal}
            submit={submit}
            {...(payload || {})}
            {...rest}
        />
        {options.after}
    </div>
);

type ModalFormFactoryOptions = {
    after?: ReactNode | ReactNode[];
    className?: string;
};

export const modalFormFactory =
    (
        FormComponent: any,
        payloadToTitle: (payload: any) => string,
        dataToHeaderButtons?: (payload: any, closeModal: Function) => ButtonProps[] | null,
        options?: ModalFormFactoryOptions
    ): React.FC<GenericModalFormProps> =>
    ({ cancel, closeModal, payload, submit, title, ...rest }) =>
        (
            <ModalForm
                cancel={cancel}
                closeModal={closeModal}
                headerButtons={
                    dataToHeaderButtons ? dataToHeaderButtons(payload, closeModal) : undefined
                }
                FormComponent={FormComponent}
                payload={payload}
                submit={submit}
                title={payloadToTitle(payload)}
                options={options}
                {...rest}
            />
        );
