import { connect } from 'react-redux';
import { SidePanelFolderSettings } from './SidePanelFolderSettings';
import { openModal } from '$redux/route/routeActions';
import { RECURSIVE_SETTINGS } from '@he-novation/paths/modals.constants';
import { updateUserPreferences } from '$redux/user/userActions';
import { branch, compose, renderNothing } from 'recompose';
import { folderObjectSelector } from '$redux/content/folder/folderSelectors';

export default compose(
    connect(folderObjectSelector, (dispatch) => ({
        openRecursiveSettingsModal: (folderUuid, settings, shared, clientName) =>
            dispatch(
                openModal(
                    RECURSIVE_SETTINGS,
                    { uuid: folderUuid, settings, shared, clientName },
                    null,
                    true
                )
            ),
        updatePreferences: (preferences) => dispatch(updateUserPreferences(preferences))
    })),
    branch(({ folder }) => !folder, renderNothing)
)(SidePanelFolderSettings);
