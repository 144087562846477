import React, { Suspense } from 'react';
import Modal from './Modal';
import modals from '../modals';
import withClickOutside from '../../HOC/withClickOutside';
import combineSelectors from '../../../helpers/combineSelectors';
import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { closeModal } from '$redux/route/routeActions';
import { modalDataSelector } from '$redux/route/routeSelectors';
import { isSmallScreenSelector } from '$redux/config/configSelectors';
import { toggleKeyboardListeners } from '$redux/ui/uiActions';
import { Loader } from '@he-novation/design-system/components/widgets/Loader/Loader';
import withKeyEventListeners from '$components/HOC/withKeyEventListeners';

export default compose(
    connect(
        (state) => combineSelectors(modalDataSelector, isSmallScreenSelector)(state),
        (dispatch) => ({
            close: () => dispatch(closeModal()),
            toggleKeyboardListeners: (active) => dispatch(toggleKeyboardListeners(active))
        })
    ),
    mapProps(
        ({
            modalData: { modal, modalPayload, extraParams },
            close,
            isSmallScreen,
            toggleKeyboardListeners
        }) => {
            let children = null;
            if (modal && modals[modal]) {
                const Comp = modals[modal];
                children = (
                    <Suspense fallback={<Loader />}>
                        <Comp
                            closeModal={close}
                            isSmallScreen={isSmallScreen}
                            payload={modalPayload || {}}
                            extraParams={extraParams}
                        />
                    </Suspense>
                );
            }

            return {
                children,
                close,
                disableClickOutside: modalPayload && modalPayload.disableClickOutside,
                className: modal,
                isSmallScreen,
                toggleKeyboardListeners
            };
        }
    ),
    withKeyEventListeners(['escape']),
)(Modal);
