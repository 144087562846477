import './StyledFormField.scss';
import React, { MutableRefObject, useEffect, useRef } from 'react';
import type { ReactNode } from 'react';
import GenericForm, { GenericFormField } from 'generic-form';
import { ExtraFormFieldTypes, Theme } from '../../../enums';
import classNames from 'classnames';
import Icon from '@he-novation/icons';
import { FormFieldTypes } from '../../../enums';
export { FormFieldTypes } from '../../../enums';
export { GenericForm, GenericFormField };

export type SelectOption = { label: string; value: any };

export type StyledFormFieldProps = {
    after?: ReactNode;
    autoFocus?: boolean;
    checked?: boolean;
    className?: string | null | false;
    disabled?: boolean;
    formId?: string;
    icon?: string;
    id?: string;
    label?: ReactNode;
    name?: string;
    onBlur?: (e: any) => void;
    onChange?: ((e: any, value: any) => void) | ((value: any) => void);
    options?: SelectOption[];
    size?: string;
    theme?: Theme;
    type: FormFieldTypes | ExtraFormFieldTypes;
    value?: any;
    [key: string]: any;
};

export const StyledFormField = React.forwardRef(
    (
        {
            autoFocus,
            className,
            theme = Theme.Dark,
            icon,
            after,
            size,
            ...props
        }: StyledFormFieldProps,
        ref: MutableRefObject<any>
    ) => {
        const inlineRef = useRef();
        const _ref = ref || inlineRef;
        useEffect(() => {
            if (autoFocus) {
                _ref?.current?.el?.current.focus?.();
            }
        }, [autoFocus, _ref]);

        return (
            <GenericFormField
                // @ts-ignore
                ref={_ref}
                className={classNames(
                    'c-styled-form-field',
                    theme,
                    className,
                    icon && 'has-icon',
                    size ? `${size}-input` : ''
                )}
                before={
                    props.type === FormFieldTypes.Color
                        ? (value: string) => <span>{value}</span>
                        : undefined
                }
                after={icon ? <Icon icon={icon} /> : after}
                {...props}
            />
        );
    }
);
