import './ActivityList.scss';
import React from 'react';
import ActivityItem from './ActivityItem/ActivityItemContainer';

export const ActivityList = ({ list, locale, detailed }) => {
    const renderedDates = [];

    return (
        <ul className="c-activity-list">
            {list.map((group, i) => {
                if (!group?.items?.length) return null;
                let date;

                const items = group.items.sort((a, b) => b.created - a.created);
                const d = items[0].created.toLocaleDateString(locale, {
                    month: 'long',
                    day: 'numeric',
                    weekday: 'long'
                });
                if (!renderedDates.includes(d)) {
                    date = <span className="activity-date">{d}</span>;
                    renderedDates.push(d);
                }

                return (
                    <li key={i}>
                        {date}
                        <ActivityItem group={group} detailed={detailed}>
                            <span className="activity-time">
                                {items[0].created.toLocaleTimeString(locale, {
                                    hour12: false,
                                    timeStyle: 'short'
                                })}
                            </span>
                        </ActivityItem>
                    </li>
                );
            })}
        </ul>
    );
};
