import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import SidePanelFilters from '../../SidePanel/SidePanelFilters';
import { __ } from '@he-novation/design-system/utils/i18n';
import { playslistFiltersSet } from '../../../redux/content/file/fileActions';

export default compose(
    connect(null, (dispatch) => ({
        onFilter: (playlistFilters) => dispatch(playslistFiltersSet(playlistFilters))
    })),
    withProps(({ files }) => {
        const types = [];
        if (files.find(({ final }) => final))
            types.push({ value: 'is_final', label: __('REACT_FINAL_VERSION') });
        return {
            allowSingle: true,
            values: {
                types
            },
            filters: [{ key: 'types', label: __('REACT_STATUS') }]
        };
    })
)(SidePanelFilters);
