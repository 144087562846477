import { compose, branch, renderNothing } from 'recompose';
import { connect } from 'react-redux';
import ProjectTeamsPicker from '$views/projects/Components/ProjectTeamsPicker/ProjectTeamsPicker';
import {
    selectedTeamSelector,
    teamsSelector
} from '../../../../redux/content/projects/projectsSelector';
import combineSelectors from '$helpers/combineSelectors';
import { setSelectedTeam } from '../../../../redux/content/projects/projectsActions';

export default compose(
    connect(
        combineSelectors(selectedTeamSelector, (s, { withCastTeam, withAdminTeam }) =>
            teamsSelector(withCastTeam, withAdminTeam)(s)
        ),
        (dispatch) => ({
            setSelectedTeam: (team) => dispatch(setSelectedTeam(team))
        })
    ),
    branch(({ teams }) => !teams || teams.length < 2, renderNothing)
)(ProjectTeamsPicker);
