import React from 'react';
import NoteOrTaskContentTitle from '../../Notes/NoteOrTaskContent/NoteOrTaskContentTitle';
import NoteThumbnail from '../../Notes/NoteThumbnail/NoteThumbnail';
import Link from '../../router/Link';

export type NoteOrTaskContentHeaderProps = {
    metadata?: any;
    type?: string;
    thumbnail?: string | null;
    href?: string | null;
    uuid: string;
    className?: string;
};
const NoteOrTaskContentHeader = ({
    metadata,
    type,
    thumbnail,
    href,
    uuid,
    className
}: NoteOrTaskContentHeaderProps) => {
    const content = (
        <>
            <NoteThumbnail metadata={metadata} thumbnail={thumbnail} zoomed={true} />
            <NoteOrTaskContentTitle type={type} metadata={metadata} />
        </>
    );

    return (
        <header className={className}>
            {href ? (
                <Link href={href} filters={{ selected_note: uuid }}>
                    {content}
                </Link>
            ) : (
                content
            )}
        </header>
    );
};

export default NoteOrTaskContentHeader;
