import React from 'react';
import './NoteTimeCode.scss';
import { __ } from '@he-novation/design-system/utils/i18n';

const NoteTimeCode = ({ timeIn, timeOut }) => (
    <div className="c-note-time-code">
        <p>
            {timeOut && <span>{__('REACT_FROM')}</span>}
            <span className="time">{timeIn}</span>
        </p>{' '}
        {timeOut && (
            <p>
                <span>{__('REACT_TO')}</span>
                <span className="time">{timeOut}</span>
            </p>
        )}
    </div>
);

/*NoteTimeCode.propTypes = {
    timeIn: PropTypes.string,
    timeOut: PropTypes.string,
    wording: PropTypes.shape({
        REACT_FROM: PropTypes.string,
        REACT_TO: PropTypes.string
    })
};*/

export default NoteTimeCode;
