import { compose } from 'recompose';
import { connect } from 'react-redux';
import combineSelectors from '../../../helpers/combineSelectors';
import { groupsSelector } from '$redux/content/contacts/contactsSelectors';
import { isSmallScreenSelector } from '../../../redux/config/configSelectors';
import { closeModal, openModal, set } from '../../../redux/route/routeActions';
import { ADD_GROUP, DELETE_CONFIRM, EDIT_GROUP } from '@he-novation/paths/modals.constants';
import { deleteGroup } from '$redux/content/contacts/contactsActions';
import SidePanelGroups from './SidePanelGroups';
import { __ } from '@he-novation/design-system/utils/i18n';
import { routeParamsSelector } from '$redux/route/routeSelectors';
import { contactsLink } from '@he-novation/paths/herawFrontUris';
import { hasClientRightSelector } from '$redux/user/userSelectors';

export default compose(
    connect(
        combineSelectors(
            groupsSelector,
            isSmallScreenSelector,
            routeParamsSelector,
            hasClientRightSelector('contactAdmin')
        ),
        (dispatch) => ({
            addGroup: () => dispatch(openModal(ADD_GROUP)),
            deleteGroup: (uuid, routeParams) =>
                dispatch(
                    openModal(DELETE_CONFIRM, {
                        onDelete: () => {
                            if (
                                routeParams.route === 'contact-group' &&
                                routeParams.subroute === uuid
                            )
                                dispatch(set(contactsLink()));

                            dispatch(deleteGroup(uuid));
                            dispatch(closeModal());
                        },
                        closeModal: () => dispatch(closeModal()),
                        message: __('POPIN_DELETE_TXT_CONFIRM_CONTENT'),
                        title: __('RCC_DELETE'),
                        cancel: __('POPIN_BTN_CANCEL')
                    })
                ),
            editGroup: (values) =>
                dispatch(
                    openModal(EDIT_GROUP, {
                        values
                    })
                )
        })
    )
)(SidePanelGroups);
