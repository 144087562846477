export const gangableByFolder = [
    'LOGS_FILE_DELETE_INFO',
    'LOGS_FILE_DELETE_SELF',
    'LOGS_FILE_DOWNLOAD_INFO',
    'LOGS_FILE_DOWNLOAD_SELF',
    'LOGS_FILE_INFO_INFO',
    'LOGS_FILE_INFO_SELF',
    'LOGS_FILE_MOVE_INFO',
    'LOGS_FILE_MOVE_SELF',
    'LOGS_FILE_NEW_INFO',
    'LOGS_FILE_NEW_SELF',
    'LOGS_FILE_PLAYER_INFO',
    'LOGS_FILE_PLAYER_SELF',
    'LOGS_FILE_READY_INFO',
    'LOGS_FILE_READY_SELF',
    'LOGS_FILE_VOTE_FINAL_INFO',
    'LOGS_FILE_VOTE_FINAL_SELF',
    'LOGS_FOLDER_ACCESS_INFO',
    'LOGS_FOLDER_ACCESS_SELF',
    'LOGS_FOLDER_ADD_INFO',
    'LOGS_FOLDER_ADD_SELF',
    'LOGS_FOLDER_DELETE_INFO',
    'LOGS_FOLDER_DELETE_SELF',
    'LOGS_FOLDER_ENCRYPTED_INFO',
    'LOGS_FOLDER_ENCRYPTED_SELF',
    'LOGS_FOLDER_GRANT_ACCESS_INFO',
    'LOGS_FOLDER_GRANT_ACCESS_MEMBER',
    'LOGS_FOLDER_GRANT_ACCESS_SELF',
    'LOGS_FOLDER_MOVE_INFO',
    'LOGS_FOLDER_MOVE_SELF',
    'LOGS_FOLDER_PROJECT_ADD_INFO',
    'LOGS_FOLDER_PROJECT_ADD_SELF',
    'LOGS_FOLDER_PROJECT_DEL_INFO',
    'LOGS_FOLDER_PROJECT_DEL_SELF',
    'LOGS_FOLDER_RENAME_INFO',
    'LOGS_FOLDER_RENAME_SELF',
    'LOGS_FOLDER_REVOKE_ACCESS_INFO',
    'LOGS_FOLDER_REVOKE_ACCESS_MEMBER',
    'LOGS_FOLDER_REVOKE_ACCESS_SELF',
    'LOGS_FOLDER_REVOKE_MY_ACCESS_INFO',
    'LOGS_FOLDER_REVOKE_MY_ACCESS_SELF'
];

export const gangableByFile = [
    'LOGS_COMMENTS_ADD_INFO',
    'LOGS_COMMENTS_ADD_SELF',
    'LOGS_COMMENTS_MODIFIED_INFO',
    'LOGS_COMMENTS_MODIFIED_SELF',
    'LOGS_COMMENTS_SET_DELETED_INFO',
    'LOGS_COMMENTS_SET_DELETED_SELF',
    'LOGS_COMMENTS_SET_TASK_INFO',
    'LOGS_COMMENTS_SET_TASK_SELF',
    'LOGS_FILE_ADD_SUBTITLE_INFO',
    'LOGS_FILE_ADD_SUBTITLE_SELF',
    'LOGS_FILE_DELETE_SUBTITLE_INFO',
    'LOGS_FILE_DELETE_SUBTITLE_SELF',
    'LOGS_FILE_DOWNLOAD_SUBTITLE_INFO',
    'LOGS_FILE_DOWNLOAD_SUBTITLE_SELF'
];
