import './SidePanelNotesHeader.scss';
import React from 'react';
import { __ } from '@he-novation/design-system/utils/i18n';
import NoteProjectTeamsPicker from '$views/projects/Components/ProjectTeamsPicker/NoteProjectTeamsPickerContainer';
import { SidePanelHeader } from '@he-novation/design-system/components/sidePanels/SidePanel/SidePanelHeader/SidePanelHeader';

const SidePanelNotesHeader = ({
    folderHasAvid,
    isFilterOpen,
    toggleFilter,
    fileType,
    fileName,
    openPanelNote,
    openPanelTask,
    openAvidLink,
    openPdfLink,
    isSmallScreen,
    closePanel,
    folderPlugins
}) => {
    const actions = [
        {
            children: __('REACT_ADD_COMMENT'),
            onClick: openPanelNote
        }
    ];
    if (folderPlugins && folderPlugins.indexOf('tasks') > -1)
        actions.unshift({
            children: __('REACT_CREATE_TASK'),
            onClick: openPanelTask
        });

    const downloadActions = [
        {
            children: __('REACT_EXPORT_COMMENT_PDF'),
            onClick: openPdfLink
        }
    ];

    if (folderHasAvid && fileType === 'video')
        downloadActions.push(
            {
                children: __('REACT_EXPORT_COMMENT_AVID'),
                onClick: () => openAvidLink(fileName)
            },
            {
                children: __('REACT_EXPORT_COMMENT_AVID_CRLF'),
                onClick: () => openAvidLink(fileName, 'crlf')
            }
        );

    const buttons = [
        {
            icon: 'menu-filter',
            onClick: toggleFilter,
            className: isFilterOpen ? 'is-active' : null
        },
        {
            icon: 'reply',
            actions,
            fireIfSingle: true
        }
    ];

    if (!isSmallScreen && folderPlugins.indexOf('summary') > -1)
        buttons.push({
            icon: 'download',
            actions: downloadActions
        });

    if (isSmallScreen)
        buttons.push({
            icon: 'cross',
            onClick: closePanel
        });

    return (
        <SidePanelHeader
            className="c-side-panel-notes-header"
            title={__('REACT_PLAYER_NOTES_TITLE')}
            buttons={buttons}
        >
            <NoteProjectTeamsPicker />
        </SidePanelHeader>
    );
};

export default SidePanelNotesHeader;
