import { AssetStatus } from '@he-novation/config/types/db/enums';
import { ContentFile } from '@he-novation/config/types/file.types';
import {
    FrontFolderContentFile,
    FrontFolderContentFileProcessingStatus,
    FrontFolderContentFileStatus
} from '../types/file.front-types';

export function apiFolderContentFileToFrontFolderContentFile(
    file: ContentFile & { uploadProgress?: number }
): FrontFolderContentFile {
    let thumbnail: string | undefined;
    let mimeType: string | undefined | null;
    let preview: string | undefined;
    let isPlayable = false;
    let size = 0;
    let purged = false;
    let isBundle = false;
    let processingStatus: FrontFolderContentFileProcessingStatus | undefined;

    for (const asset of file.assets) {
        if (asset.version !== file.version) continue;
        if (asset.type === 'thumbnail' && asset.url) thumbnail = asset.url;
        if (asset.type === 'preview' && asset.url) preview = asset.url;
        if (asset.quality === 'source') {
            if (asset.status === 'PURGED') {
                purged = true;
            }

            mimeType = asset.mimeType;
            size = asset.size || 0;

            if (asset.status === AssetStatus.PENDING) {
                if (file.uploadProgress !== undefined) {
                    processingStatus = {
                        status: FrontFolderContentFileStatus.UPLOADING,
                        uploadProgress: file.uploadProgress
                    };
                } else {
                    processingStatus = { status: FrontFolderContentFileStatus.WAITING_FOR_UPLOAD };
                }
            }
        }
        if (asset.type === 'player' && asset.url && asset.status === AssetStatus.READY) {
            isPlayable = true;
            if (asset.mimeType === 'text/html' && asset.metadata?.bundle) {
                isBundle = true;
            }
        }
    }

    const versions: { version: number; expired: boolean }[] = [];
    for (const asset of file.assets) {
        if (asset.quality === 'source' && !versions.find((v) => v.version === asset.version)) {
            versions.push({ version: asset.version, expired: !!asset.expires });
        }
    }

    const f: FrontFolderContentFile = {
        uuid: file.uuid,
        type: 'file',
        name: file.name,
        notes: file.notes,
        version: file.version,
        versions: versions,
        thumbnail,
        preview,
        mimeType: mimeType!,
        isPlayable,
        casts: file.castFiles.length,
        size,
        status: file.status,
        created: new Date(file.created!),
        updated: new Date(file.updated!),
        finals: file.finals.map((f) => f.user),
        processingStatus,
        purged,
        isBundle
    };

    if (file.duration) {
        f.duration = file.duration;
    }

    if (file.resolution) {
        f.resolution = file.resolution;
    }

    if (!file.assets.length) {
        f.noAssets = true;
    }

    if (file.metadata?.vantage?.file_path) {
        f.vantagePath = file.metadata.vantage.file_path;
    }

    return f;
}
