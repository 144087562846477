import { NONE } from '$constants/constants.sidePanels';
import { compose, pipe, property } from 'lodash/fp';
import { SIDEMENU } from './sideMenuActions';

export const stateSelector = property(SIDEMENU);

export const activeSidePanelSelector = compose(
    (activeSidePanel) => ({ activeSidePanel }),
    property('activePanel'),
    stateSelector
);

export const activePanelSelector = compose(
    (activePanel) => (activePanel === NONE ? null : activePanel),
    pipe(stateSelector, property('activePanel'))
);

export const panelPayloadSelector = compose(
    (panelPayload) => ({ panelPayload }),
    property('panelPayload'),
    stateSelector
);

export const isPanelOpenSelector = compose(
    (isPanelOpen) => ({ isPanelOpen: !!isPanelOpen }),
    property('activePanel'),
    stateSelector
);

export const currentPanelSelector = compose(
    (activePanel) => ({ currentPanel: activePanel }),
    property('activePanel'),
    stateSelector
);
