import './Tabs.scss';
import React, { ReactNode, SyntheticEvent, useState } from 'react';
import cn from 'classnames';
import { Button } from '../../buttons/Button/Button';
import { Theme } from '../../../enums';
import { v4 as uuid } from 'uuid';

export enum TabsMode {
    Replace,
    Visibility,
    Display,
    ButtonsOnly
}

export type Tab = {
    value?: any;
    label: ReactNode;
    icon?: string;
    onClick?: (e: SyntheticEvent, tab: Tab) => void;
};
export type TabsProps = {
    className?: string;
    tabs: Tab[];
    activeTab?: string;
    theme?: Theme;
    id?: string;
    mode?: TabsMode;
    children?: ReactNode | ReactNode[];
};

export const Tabs: React.FC<TabsProps> = ({
    id: _id,
    className,
    tabs,
    activeTab: _activeTab,
    theme = Theme.Light,
    mode = TabsMode.Replace,
    children
}) => {
    const [activeTab, setActiveTab] = useState<number>(
        _activeTab
            ? tabs.findIndex((t) =>
                  typeof t.value !== undefined ? t.value === _activeTab : t.label === _activeTab
              )
            : 0
    );
    const [id] = useState(_id || uuid());
    const content = React.Children.toArray(children);

    const contentId = `${id}-tab-content`;

    let tabContent;
    if (mode === TabsMode.Replace) {
        if (content[activeTab]) {
            tabContent = (
                <div
                    className="c-tab-content"
                    id={`${contentId}-${activeTab}`}
                    aria-labelledby={`${id}-tab-${activeTab}`}
                >
                    {content[activeTab]}
                </div>
            );
        }
    } else if (mode !== TabsMode.ButtonsOnly) {
        tabContent = tabs.map((t, i) => (
            <div
                key={t.label?.toString() || t.value || i}
                className={cn(
                    'c-tab-content',
                    activeTab !== i && (mode === TabsMode.Display ? 'not-displayed' : 'not-visible')
                )}
                id={`${contentId}-tab-${i}`}
                aria-labelledby={`${id}-${i}`}
            >
                {content[i]}
            </div>
        ));
    }

    return (
        <div className={cn('c-tabs', theme, className)} id={id}>
            <ul>
                {tabs.map((t, i) => {
                    const isActive = tabs[activeTab]?.value
                        ? tabs[activeTab].value === t.value
                        : tabs[activeTab]?.label === t.label;
                    return (
                        <li
                            key={t.label?.toString() || t.value || i}
                            className={isActive ? 'is-active' : undefined}
                        >
                            <Button
                                type="button"
                                id={`${id}-tab-${i}`}
                                role="tab"
                                icon={t.icon}
                                aria-controls={`${contentId}-${i}`}
                                onClick={(e: SyntheticEvent) => {
                                    setActiveTab(i);
                                    t.onClick?.(e, t);
                                }}
                                aria-selected={isActive}
                            >
                                {t.label}
                            </Button>
                        </li>
                    );
                })}
            </ul>
            {tabContent}
        </div>
    );
};
