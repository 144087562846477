import './Footer.scss';
import React from 'react';
import { useSelector } from 'react-redux';

import { activePanelSelector } from '$redux/sideMenu/sideMenuSelectors';

export const Footer: React.FC = () => {
    const activeSidePanel = useSelector(activePanelSelector);
    return <section id="footer" className={activeSidePanel ? 'has-open-panel' : ''} />;
};
