import { HerawEvent } from '@he-novation/config/types/event.types';
import { EventUpdateBody } from '@he-novation/config/types/payloads/event.payload';
import { apiFetch } from '@he-novation/front-shared/async/apiFetch';
import paths from '@he-novation/paths/herawApiPaths';
import { dateAsUTCDate, setLatestHour } from '@he-novation/utils/datetime';
import { isUndefined, omitBy } from 'lodash/fp';

import { isoDateToLocalLatest, isoDateToLocalMidnight } from '$helpers/datetime';

/**
 * If wholeDay is true, startDate and endDate will be converted from localized time to UTC
 * If GMT+1 is 23:59:59, it will be converted to  UTC 23:59:59 or GMT+1 22:59:59
 */
export async function eventCreate({
    title,
    description,
    startDate,
    endDate,
    projectUuid,
    teamUuids,
    itemUuids,
    userUuids,
    createChatRoom,
    notifyUsers,
    wholeDay,
    clientEventLabelUids
}): Promise<void> {
    if (wholeDay) {
        startDate = dateAsUTCDate(startDate);
        if (endDate) endDate = setLatestHour(dateAsUTCDate(endDate), true);
    }
    await apiFetch(paths.event.root, {
        method: 'POST',
        body: omitBy(isUndefined, {
            title,
            description,
            startDate,
            endDate,
            projectUuid,
            teamUuids,
            itemUuids,
            userUuids,
            createChatRoom,
            notifyUsers,
            wholeDay,
            clientEventLabelUids
        })
    });
}

export async function eventDelete(eventUuid: string): Promise<void> {
    await apiFetch(paths.event.specific, {
        method: 'DELETE',
        params: {
            eventUuid
        }
    });
}

/**
 * If wholeDay is true, startDate and endDate will be converted from localized time to UTC
 * If GMT+1 is 23:59:59, it will be converted to  UTC 23:59:59 or GMT+1 22:59:59
 */
export async function eventUpdate(eventUuid: string, data: EventUpdateBody): Promise<void> {
    let startDate = data.startDate;
    let endDate = data.endDate;

    if (data.wholeDay) {
        if (data.startDate) startDate = dateAsUTCDate(data.startDate);
        if (data.endDate) endDate = setLatestHour(data.endDate, true);
    }

    return await apiFetch(paths.event.specific, {
        method: 'PUT',
        body: omitBy(isUndefined, { ...data, startDate, endDate }),
        params: { eventUuid }
    });
}

export async function eventUserDelete(eventUuid: string): Promise<void> {
    await apiFetch(paths.event.user, {
        method: 'DELETE',
        params: { eventUuid }
    });
}

export async function eventSendInvitationEmail(eventUuid: string): Promise<void> {
    await apiFetch(paths.event.email, {
        method: 'POST',
        params: {
            eventUuid
        }
    });
}

export async function eventsFetch(
    start?: string,
    end?: string,
    projectUuid?: string
): Promise<HerawEvent[]> {
    return await apiFetch(paths.event.root, {
        query: {
            start,
            end,
            projectUuid
        }
    }).then((events) => events.map((e: HerawEvent) => mapEvent(e)));
}

export async function itemEventsFetch(
    start?: string | Date,
    end?: string | Date
): Promise<HerawEvent[]> {
    return await apiFetch(paths.event.items, {
        query: {
            start: start instanceof Date ? start.toISOString() : start,
            end: end instanceof Date ? end.toISOString() : end
        }
    }).then((events) => events.map((e: HerawEvent) => mapEvent(e)));
}

export async function projectEventsFetch(start?: string, end?: string): Promise<HerawEvent[]> {
    return await apiFetch(paths.event.projects, {
        query: {
            start,
            end
        }
    }).then((events) => events.map((e: HerawEvent) => mapEvent(e)));
}

export function mapEvent(event: HerawEvent): HerawEvent {
    const startDate = event.wholeDay
        ? isoDateToLocalMidnight(event.startDate)
        : new Date(event.startDate);

    const endDate = event.wholeDay ? isoDateToLocalLatest(event.endDate) : new Date(event.endDate);

    event.reminders = event.reminders?.map(({ datetime, uuid }) => ({
        uuid,
        datetime: new Date(datetime)
    }));

    return { ...event, startDate, endDate };
}

export async function eventReminderCreate(
    eventUuid: string,
    datetime: string
): Promise<{ insertUuid: string }> {
    return await apiFetch(paths.event.reminders, {
        method: 'POST',
        params: {
            eventUuid
        },
        body: JSON.stringify({
            datetime
        })
    });
}

export async function eventReminderDelete(eventUuid: string, reminderUuid: string): Promise<void> {
    await apiFetch(paths.event.reminderSpecific, {
        method: 'DELETE',
        params: {
            eventUuid,
            reminderUuid
        }
    });
}
