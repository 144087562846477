import React from 'react';
import { StyledFormField } from '../StyledFormField/StyledFormField';
import type { StyledFormFieldProps } from '../StyledFormField/StyledFormField';
import { Theme } from '../../../enums';
export { default as GenericForm, GenericFormField } from 'generic-form';
export { FormFieldTypes } from '../../../enums';

export type StyledFormFieldsProps = {
    fields: (StyledFormFieldProps | false | undefined)[];
    formId?: string;
    theme?: Theme;
};

export const StyledFormFields: React.FC<StyledFormFieldsProps> = ({ fields, formId, theme }) => (
    <>
        {fields
            .filter((v) => v)
            .map((f: StyledFormFieldProps, i) => (
                <StyledFormField key={i} {...f} formId={formId} theme={theme} />
            ))}
    </>
);
