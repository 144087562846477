import './ViewTitle.scss';
import React, { SyntheticEvent } from 'react';
import { TitleContent } from '../TitleContent/TitleContent';

interface Props {
    actions?: any[];
    backLink?: { href: string };
    children: React.ReactNode;
    tag?: keyof JSX.IntrinsicElements;
    tags: React.ReactNode[];
    title: string;
    extra?: React.ReactNode | React.ReactNode[];
    breadcrumb?: { label: string; href?: string }[];
    search?: {
        search?: string;
        setSearch: (s: string) => void;
        onSearchInput: (e: SyntheticEvent) => void;
    };
}

export function ViewTitle({
    actions,
    backLink,
    children,
    tag,
    title,
    extra,
    breadcrumb,
    search
}: Props) {
    return (
        <div id="c-view-title">
            <TitleContent
                extra={extra}
                actions={actions}
                backLink={backLink}
                tag={tag}
                title={title}
                breadcrumb={breadcrumb}
                search={search}
            >
                {children}
            </TitleContent>
        </div>
    );
}
