import './MultiActionButton.scss';
import React, { CSSProperties, ReactNode } from 'react';
import { Button, ButtonProps, ButtonTone } from '../Button/Button';
import { AbsoluteContentButton } from '../AbsoluteContentButton/AbsoluteContentButton';
import { Direction, DirectionX, DirectionY } from '../../../utils/getAbsolutePosition';
import classNames from 'classnames';
import { MultiActionButtonList, MultiActionsList } from './MultiActionButtonList';
import { Theme } from '../../../enums';

export type ActionType =
    | (ButtonProps & { separator?: boolean })
    | (MultiActionButtonProps & { separator?: boolean });

export type MultiActionButtonProps = {
    actions: MultiActionsList;
    className?: string | false | null;
    closeOnClickOutside?: boolean;
    closeOnContentClick?: boolean;
    contentClassName?: string;
    contentSizedByTrigger?: boolean;
    delay?: number;
    direction?: Direction;
    disabled?: boolean;
    icon?: string | SVGElement;
    tone?: ButtonTone;
    fireIfSingle?: boolean;
    hasArrow?: boolean;
    iconAfter?: boolean;
    hoverable?: boolean;
    theme?: Theme;
    onClickableClick?: (e: MouseEvent) => any;
    onMouseOver?: (e: MouseEvent) => any;
    children?: ReactNode | ReactNode[];
    onClick?: (e: React.MouseEvent) => void;
    style?: CSSProperties;
    id?: string;
};

export const MultiActionButton: React.FC<MultiActionButtonProps> = ({
    actions,
    delay,
    children,
    className,
    closeOnContentClick = true,
    closeOnClickOutside = true,
    contentClassName,
    contentSizedByTrigger,
    direction = [DirectionX.Center, DirectionY.Top],
    disabled,
    fireIfSingle,
    icon,
    tone,
    theme,
    hasArrow = true,
    hoverable = false,
    onClickableClick,
    onClick,
    onMouseOver,
    style,
    iconAfter
}) =>
    fireIfSingle && actions.length === 1 && actions[0] ? (
        <Button
            theme={theme}
            tone={tone}
            className={classNames('c-multi-action-button', className)}
            disabled={disabled}
            icon={icon}
            iconAfter={iconAfter}
            onMouseOver={onMouseOver}
            style={style}
            {...actions[0]}
            onClick={(e) => {
                onClick?.(e);
                if (actions[0]) actions[0].onClick?.(e);
            }}
        >
            {children}
        </Button>
    ) : (
        <AbsoluteContentButton
            delay={delay}
            theme={theme}
            className={classNames('c-multi-action-button', className)}
            direction={direction}
            disabled={disabled}
            tagName={Button}
            triggersOnHover={hoverable}
            triggersOnClick={true}
            closeOnClickOutside={closeOnClickOutside}
            closeOnContentClick={closeOnContentClick}
            icon={icon}
            iconAfter={iconAfter}
            tone={tone}
            contentClassName={classNames(contentClassName, hasArrow && 'has-arrow')}
            contentSizedByTrigger={contentSizedByTrigger}
            onMouseOver={onMouseOver}
            onClick={onClick}
            style={style}
            content={
                <MultiActionButtonList onClickableClick={onClickableClick} actions={actions} />
            }
        >
            {children}
        </AbsoluteContentButton>
    );
