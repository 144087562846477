const offset = (el, getBoundingClientRect) => {
    if (getBoundingClientRect) {
        const rect = el.getBoundingClientRect();
        return {
            top: rect.top + document.body.scrollTop,
            left: rect.left + document.body.scrollLeft
        };
    }
    // getBoundingClientRect : weird bugs in scrollable elements
    const width = el?.offsetWidth || 0;
    const height = el?.offsetHeight || 0;
    let x = 0;
    let y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        x += el.offsetLeft - el.scrollLeft;
        y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return { top: y, left: x, width, height };
};

export const offsetTop = (el, getBoundingClientRect) => offset(el, getBoundingClientRect).top;
export const offsetLeft = (el, getBoundingClientRect) => offset(el, getBoundingClientRect).left;

export default offset;

window.offset = offset;
