import { asyncActionSuccess } from '$helpers/asyncAction';
import { fetchPage as _fetchPage } from './activityAsync';

export const ACTIVITY = 'ACTIVITY';
export const FETCH_PAGE = `${ACTIVITY}/FETCH_PAGE`;
export const FIRST_FETCH_PAGE = `${ACTIVITY}/FIRST_FETCH_PAGE`;
export const ADD = `${ACTIVITY}/ADD`;

export const fetchPage =
    (page = 0, filters) =>
    async (dispatch) => {
        const type = page === 0 ? FIRST_FETCH_PAGE : FETCH_PAGE;
        dispatch({ type });
        const r = await _fetchPage({
            page,
            count: 30,
            filters
        });

        dispatch({ type: asyncActionSuccess(type), response: r });
    };

export const add = (activities) => ({
    type: ADD,
    activities
});

export const activitySocketActions = {
    sioActivityAdd: add
};
