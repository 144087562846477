import { pathWithParams } from './herawApiPaths';

export const REGISTER = `/:locale/register/:type/:plan`;
export const LOGIN = `/:locale/login`;
export const LOGIN_CLIENT = `/:locale/login-client`;
export const PAGE = `/:locale/*`;
export const BLOG_INDEX = `/:locale/blog`;
export const TERMS_LINK = `/:locale/legal`;
export const RESET_PASSWORD = `/:locale/password-reset`;

export const ADMITTANCE = `/:locale/admittance`;

export const registerLink = (locale: string, type: string, plan?: string) =>
    pathWithParams(REGISTER, { locale, type, plan });
export const loginLink = (locale = 'fr', email?: string, redirect?: string) =>
    pathWithParams(LOGIN, { locale }, { email, redirect });
export const loginClientLink = (locale = 'fr') => pathWithParams(LOGIN_CLIENT, { locale });
export const blogIndexLink = (locale = 'fr') => pathWithParams(BLOG_INDEX, { locale });
export const termsLink = (locale = 'fr') => pathWithParams(TERMS_LINK, { locale });
export const resetPasswordLink = (locale = 'fr') => pathWithParams(RESET_PASSWORD, { locale });

export const admittanceLink = (locale = 'fr', clientName: string, folderUuid: string) =>
    pathWithParams(ADMITTANCE, { locale }, { clientName, folderUuid });
