import paths, { pathWithParams } from '@he-novation/paths/herawApiPaths';
import { apiFetch } from './apiFetch';

export const asyncWordingFetch = () =>
    apiFetch(paths.wording.single, {
        method: 'GET'
    });

export const asyncWordingListsFetch = () =>
    apiFetch(paths.wordingList.multiple, {
        method: 'GET'
    });
export const asyncWordingListFetch = (listName) =>
    apiFetch(paths.wordingList.specific, {
        params: { listName },
        method: 'GET'
    });

export const asyncWordingListCreate = (name: string) =>
    apiFetch(paths.wordingList.single, {
        method: 'POST',
        body: {
            name
        }
    });
export const asyncWordingListDelete = (listName: string) =>
    apiFetch(pathWithParams(paths.wordingList.specific, { listName }), {
        method: 'DELETE'
    });

export const asyncWordingAddToList = (listName: string, identifier: string) =>
    apiFetch(pathWithParams(paths.wordingListEntry.single, { listName }), {
        method: 'POST',
        body: {
            identifier
        }
    });

export const asyncWordingDeleteFromList = (listName: string, identifier: string) =>
    apiFetch(pathWithParams(paths.wordingListEntry.single, { listName }), {
        method: 'DELETE',
        body: {
            identifier
        }
    });

export const asyncWordingCreate = (locale: string, identifier: string, value: string) =>
    apiFetch(paths.wording.single, {
        method: 'POST',
        body: { locale, identifier, value }
    });

export const asyncWordingUpdate = (locale: string, identifier: string, value: string) =>
    apiFetch(pathWithParams(paths.wording.specific, { identifier }), {
        method: 'PUT',
        body: { locale, value }
    });

export const asyncWordingDelete = (identifier: string) =>
    apiFetch(pathWithParams(paths.wording.specific, { identifier }), {
        method: 'DELETE'
    });

export const asyncWordingSync = (host: string, deleteKeys: boolean) =>
    apiFetch(paths.wording.single, {
        method: 'PUT',
        body: {
            host,
            deleteKeys: deleteKeys
        }
    });
