import './DataLayoutGridItem.scss';
import React, { useEffect } from 'react';
import { useAbsoluteMenu } from '@he-novation/design-system/components/widgets/AbsoluteMenu/AbsoluteMenu';
import cn from 'classnames';

import { DataLayoutSelectionProps, ItemComponent } from '$components/DataLayout/DataLayout.types';

type DataLayoutItemGridProps<T, U extends boolean | undefined, V> = {
    item: T;
    ItemGridComponent: ItemComponent<T, U, V>;
    selection: U extends true ? undefined : DataLayoutSelectionProps<T>;
    notSelectable?: U;
    extra: V;
    className: string;
};
export function DataLayoutGridItem<T, U extends boolean | undefined, V>({
    item,
    ItemGridComponent,
    selection,
    extra,
    className
}: DataLayoutItemGridProps<T, U, V>) {
    return (
        <div
            id={selection?.id}
            className={cn(
                'data-layout-grid-item',
                selection?.isSelected && 'is-selected',
                className
            )}
            ref={(ref) => {
                if (selection?.selectableRef) selection.selectableRef.current = ref;
            }}
        >
            <ItemGridComponent item={item} selection={selection} extra={extra} />
        </div>
    );
}
