import './SidePanelActivity.scss';
import React, { useEffect, useState } from 'react';
import { SidePanelHeader } from '@he-novation/design-system/components/sidePanels/SidePanel/SidePanelHeader/SidePanelHeader';
import { fetchPage } from '$redux/content/activity/activityAsync';
import { InfiniteScroll } from '@he-novation/design-system/components/widgets/InfiniteScroll/InfiniteScroll';
import { ActivityList } from '../../ActivityList/ActivityList';
import { __ } from '@he-novation/design-system/utils/i18n';
import { sortActivityEvent } from '$helpers/sortActivityEvent';

const fetchActivityPage = (page, folderUuid, activity) =>
    fetchPage({
        page,
        count: 20,
        order: 'desc',
        log: { folder_uuid: folderUuid }
    });

export const SidePanelActivity = ({ payload: { folderUuid }, locale }) => {
    const [page, setPage] = useState(0);
    const [activity, setActivity] = useState([]);

    useEffect(() => {
        fetchPage({
            page,
            count: 20,
            order: 'desc',
            log: { folder_uuid: folderUuid }
        }).then((r) => setActivity([...activity, ...sortActivityEvent(r)]));
    }, [page]);

    return (
        <div id="c-side-panel-activity">
            <InfiniteScroll page={page} fetchPage={() => setPage(page + 1)}>
                <SidePanelHeader title={__('ACTIVITY')} />
                <ActivityList list={activity} locale={locale} />
            </InfiniteScroll>
        </div>
    );
};
