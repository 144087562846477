import styles from './DataLayoutClientGuests.module.css';
import React from 'react';
import { ClientGuest } from '@he-novation/config/types/client.types';
import { AvatarUser } from '@he-novation/design-system/components/avatars/Avatar/AvatarUser';
import { __ } from '@he-novation/design-system/utils/i18n';

import { DataLayout } from '$components/DataLayout/DataLayout';
import {
    DataLayoutDisplayMode,
    DataLayoutSelectionProps
} from '$components/DataLayout/DataLayout.types';
import {
    DataLayoutClientGuestsActionsMenu,
    filterSearch,
    highlight
} from '$components/DataLayout/DataLayoutClientGuests/DataLayoutClientGuests.utils';
import { sorterFunctionFactory } from '$components/DataLayout/sorterFunctions';

type DataLayoutClientGuestsProps = { items: ClientGuest[]; search?: string };

export function DataLayoutClientGuests({ items, search }: DataLayoutClientGuestsProps) {
    return (
        <DataLayout
            id="data-layout-client-guests"
            displayMode={DataLayoutDisplayMode.List}
            notSelectable
            extra={{ search }}
            itemToId={(item: ClientGuest) => item.uuid}
            ActionsMenuComponent={DataLayoutClientGuestsActionsMenu}
            columns={[
                {
                    key: 'name',
                    header: __('NAME'),
                    width: 300,
                    sort: sorterFunctionFactory('alphanumeric', 'lastname'),
                    render: (
                        user: ClientGuest,
                        _selection: DataLayoutSelectionProps<ClientGuest>,
                        { search }
                    ) => {
                        const highlighted = highlight(user, search);
                        return (
                            <div className={styles.name}>
                                <AvatarUser {...user} />
                                <span>
                                    {highlighted.firstname || ''} {highlighted.lastname || ''}
                                </span>
                            </div>
                        );
                    }
                },
                {
                    key: 'email',
                    header: __('EMAIL'),
                    grow: true,
                    width: 300,
                    sort: sorterFunctionFactory('alphanumeric', 'email'),
                    render: (user: ClientGuest, _selection, { search }) => {
                        const highlighted = highlight(user, search);
                        return highlighted.email;
                    }
                },
                {
                    key: 'created',
                    width: 200,
                    header: __('CREATED'),
                    sort: sorterFunctionFactory('date', 'created')
                },
                {
                    key: 'firm',
                    width: 200,
                    header: __('FIRM'),
                    sort: sorterFunctionFactory('alphanumeric', 'firm')
                },
                {
                    key: 'phone',
                    width: 200,
                    header: __('PHONE'),
                    sort: sorterFunctionFactory('alphanumeric', 'phone')
                },
                {
                    key: 'folders',
                    header: __('FOLDERS'),
                    width: 120,
                    sort: sorterFunctionFactory('alphanumeric', 'folders'),
                    icon: 'folder-full'
                },
                {
                    key: 'projects',
                    header: __('PROJECTS'),
                    width: 120,
                    sort: sorterFunctionFactory('alphanumeric', 'projects'),
                    icon: 'project'
                }
            ]}
            items={filterSearch(items, search)}
        />
    );
}
