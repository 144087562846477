import './NoteThumbnail.scss';
import React from 'react';
import SvgFreeHand from '../../Thumbnail/SvgFreeHand';

type NoteThumbnailProps = {
    metadata: {
        freehand: { x: number; y: number }[];
    };
    thumbnail?: string | null;
    zoomed: boolean;
};
const NoteThumbnail = ({ metadata, thumbnail, zoomed }: NoteThumbnailProps) => (
    <div className="c-note-thumbnail">
        {thumbnail && <img src={thumbnail} alt="" />}
        {metadata.freehand &&
            metadata.freehand.map((freehandItem, i) => (
                <SvgFreeHand key={i} freehandItem={freehandItem} {...metadata} zoomed={zoomed} />
            ))}
    </div>
);

export default NoteThumbnail;
