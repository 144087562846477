import './ModalHeader.scss';
import React from 'react';
import { Button, ButtonProps } from '../../buttons/Button/Button';

type ModalHeaderProps = {
    title: string;
    buttons?: ButtonProps[] | null;
    close?: Function;
};

export const ModalHeader: React.FC<ModalHeaderProps> = ({ title, buttons, close }) => (
    <div className="c-modal-header">
        <h1>{title}</h1>
        <ul className="buttons">
            {buttons &&
                buttons.map((b, i) => (
                    <li key={i}>
                        <Button {...b} />
                    </li>
                ))}
            <li>
                <Button onClick={close as (e?: any) => void} icon="cross" />
            </li>
        </ul>
    </div>
);
