import { PROJECT_STATUS } from '@he-novation/config/constants/projects.constants';
import { TEAM_STATUS } from '@he-novation/config/constants/teams.constants';
import { OAProject } from '@he-novation/config/types/open-api/project.open-api.types';
import { ProjectSpecificSchema } from '@he-novation/config/types/payloads/project.payload';
import { Member } from '@he-novation/config/types/payloads/team.payload';
import { Project, ProjectStats } from '@he-novation/config/types/project.types';
import { Task, TaskChange, TaskStatus } from '@he-novation/config/types/task.types';
import paths, {
    pathWithParams,
    projectPaths,
    publicApiV1Paths
} from '@he-novation/paths/herawApiPaths';
import {
    dateAsUTCDate,
    isoStringToUTCDate,
    objectIsoStringsToDates,
    setLatestHour
} from '@he-novation/utils/datetime';
import { isNull, omitBy } from 'lodash/fp';
import { apiFetch, fetchAsFormData } from './apiFetch';

const API_TASKS = 'hpi/tasks';
const API_TEAMS = 'hpi/teams';

export const createProject = ({ name, startDate, endDate, companyName }): Promise<Project> =>
    apiFetch(projectPaths.single, {
        method: 'POST',
        body: {
            name,
            startDate,
            endDate,
            companyName
        }
    });
export const asyncProjectDelete = (projectUuid: string) =>
    apiFetch(paths.projects.single, {
        method: 'DELETE',
        params: { projectUuid } satisfies ProjectSpecificSchema
    });

export const asyncProjectStatusUpdate = (projectUuid: string, status: PROJECT_STATUS) =>
    apiFetch(paths.projects.updateProjectStatus, {
        method: 'PUT',
        params: { projectUuid } satisfies ProjectSpecificSchema,
        body: {
            status
        }
    });

/**
 * estimatedEndDate will be converted from localized time to UTC
 * If GMT+1 is 23:59:59, it will be converted to  UTC 23:59:59 or GMT+1 22:59:59
 */
export const createTask = ({
    assignees,
    attachments,
    projectUuid,
    teamUuid,
    status,
    description,
    estimatedEndDate
}: {
    assignees: string[];
    attachments: any;
    projectUuid: string;
    teamUuid: string;
    status?: TaskStatus;
    description: string;
    estimatedEndDate?: Date | null;
}) => {
    if (estimatedEndDate) estimatedEndDate = setLatestHour(dateAsUTCDate(estimatedEndDate), true);
    return fetchAsFormData(paths.tasks.root, {
        // should use fetchAsFormData to send attachments
        method: 'POST',
        body: omitBy(isNull, {
            assignees: assignees,
            attachments,
            projectUuid,
            teamUuid,
            status,
            description,
            estimatedEndDate: estimatedEndDate?.toISOString()
        })
    });
};

export const deleteMember = (projectUuid: string, userUuid: string) => {
    return apiFetch(paths.projects.member, {
        method: 'DELETE',
        params: { projectUuid, userUuid }
    });
};

export const deleteTask = (taskUuid: string) =>
    apiFetch(`${API_TASKS}/${taskUuid}`, {
        method: 'DELETE'
    });

export const fetchProjects = (): Promise<Project[]> =>
    apiFetch(projectPaths.multiple, {
        method: 'GET'
    }).then((projects) =>
        projects.map(objectIsoStringsToDates(['created', 'updated', 'startDate', 'endDate']))
    );

export const fetchProject = (projectUuid: string): Promise<Project> =>
    apiFetch(paths.projects.single, {
        method: 'GET',
        params: { projectUuid } satisfies ProjectSpecificSchema
    }).then((p) => {
        p = objectIsoStringsToDates(['created', 'updated', 'startDate', 'endDate'])(p);
        p.tasks = p.tasks.map((t) => ({
            ...t,
            created: new Date(t.created),
            updated: new Date(t.updated || t.created),
            estimatedEndDate: t.estimatedEndDate && isoStringToUTCDate(t.estimatedEndDate)
        }));
        return p;
    });

export const fetchProjectTasks = (projectUuid: string): Promise<{ tasks: Task[] }> =>
    apiFetch(paths.projects.tasks, {
        params: { projectUuid } satisfies ProjectSpecificSchema,
        method: 'GET'
    }).then((tasks) =>
        tasks.map((t) => ({
            ...t,
            created: new Date(t.created),
            updated: new Date(t.updated || t.created),
            estimatedEndDate: t.estimatedEndDate && isoStringToUTCDate(t.estimatedEndDate)
        }))
    );

export const fetchProjectTeams = (projectUuid: string) =>
    apiFetch(paths.projects.getTeamsByProject, {
        method: 'GET',
        params: { projectUuid } satisfies ProjectSpecificSchema
    });

export const fetchProjectCompanies = () =>
    apiFetch(paths.projects.getAvailableCompanies, {
        method: 'GET'
    });

export const inviteMembersToTeam = (
    teamName: string,
    castTeamAccess: boolean,
    projectUuid: string,
    members: Member[],
    message: string
) =>
    apiFetch(paths.teams.inviteToTeam, {
        params: { teamName },
        method: 'POST',
        body: {
            members: members.map(({ uuid, projectRole, email, canDownload, canExport }) => ({
                uuid,
                projectRole,
                email,
                canDownload,
                canExport
            })),
            castTeamAccess,
            message,
            projectUuid
        }
    });

export const updateMember = (
    projectUuid: string,
    userUuid: string,
    {
        teamUuid,
        role,
        download,
        export: canExport
    }: { teamUuid: string; role: string; download: boolean; export: boolean }
) =>
    apiFetch(paths.projects.member, {
        method: 'PUT',
        params: {
            projectUuid,
            userUuid
        },
        body: {
            teamUuid,
            role,
            download,
            export: canExport
        }
    });

export const updateProject = ({ uuid, name, startDate, endDate, companyName }) =>
    apiFetch(paths.projects.single, {
        method: 'PUT',
        params: { projectUuid: uuid } satisfies ProjectSpecificSchema,
        body: {
            name,
            startDate,
            endDate,
            companyName
        }
    });

/**
 * estimatedEndDate will be converted from localized time to UTC
 * If GMT+1 is 23:59:59, it will be converted to  UTC 23:59:59 or GMT+1 22:59:59
 */
export const updateTask = ({
    taskUuid,
    assignees,
    attachments,
    teamUuid,
    status,
    description,
    estimatedEndDate
}: {
    taskUuid: string;
    assignees?: string[];
    attachments?: string[];
    teamUuid?: string;
    status?: string;
    description?: string;
    estimatedEndDate?: Date;
}) => {
    if (estimatedEndDate) estimatedEndDate = setLatestHour(dateAsUTCDate(estimatedEndDate), true);
    return fetchAsFormData(pathWithParams(paths.tasks.single, { taskUuid }), {
        method: 'PUT',
        body: {
            assignees: Array.isArray(assignees) ? assignees.join(',') : assignees,
            attachments,
            teamUuid,
            status,
            description,
            estimatedEndDate:
                estimatedEndDate instanceof Date ? estimatedEndDate.toISOString() : estimatedEndDate
        }
    });
};

export const deleteTeam = (teamUuid: string, projectUuid: string) =>
    apiFetch(`${API_TEAMS}/${teamUuid}/status`, {
        method: 'PUT',
        body: JSON.stringify({
            status: TEAM_STATUS.HIDDEN,
            projectUuid
        })
    });

export const updateTeam = ({ teamUuid, name, projectUuid, castTeamAccess }) =>
    apiFetch(`${API_TEAMS}/${teamUuid}`, {
        method: 'PUT',
        body: JSON.stringify({
            name,
            projectUuid,
            castTeamAccess
        })
    });

export async function updateMultipleTaskStatusAndOrdering(
    changes: TaskChange
): Promise<{ [taskUuid: string]: { orderingDelta: number; status?: string } }> {
    return await apiFetch(paths.tasks.multipleStatus, {
        method: 'PUT',
        body: JSON.stringify({
            changes
        })
    });
}

export const updateUserProjectMetadata = ({ projectUuid, metadata }) =>
    apiFetch(paths.projects.updateCurrentUserMetadata, {
        method: 'PUT',
        params: { projectUuid } satisfies ProjectSpecificSchema,
        body: JSON.stringify({ metadata })
    });

export const asyncTasksFetch = ({
    startDate,
    endDate
}: { startDate?: Date; endDate?: Date } = {}): Promise<Task[]> =>
    apiFetch(paths.tasks.root, {
        query: {
            startDate: startDate?.toISOString(),
            endDate: endDate?.toISOString()
        }
    }).then((r) =>
        r.map((t) => ({
            ...t,
            created: new Date(t.created),
            updated: new Date(t.updated || t.created),
            estimatedEndDate: t.estimatedEndDate && isoStringToUTCDate(t.estimatedEndDate)
        }))
    );

export const asyncFetchProjectClientEventLabels = (projectUuid: string) =>
    apiFetch(paths.projects.clientEventLabels, {
        params: { projectUuid } satisfies ProjectSpecificSchema
    });

export const asyncProjectSizeFetch = (
    projectUuid: string
): Promise<{ size: number; files: number }> =>
    apiFetch(paths.projects.size, {
        params: { projectUuid } satisfies ProjectSpecificSchema
    });

export const asyncProjectStatsFetch = (projectUuid: string): Promise<ProjectStats> =>
    apiFetch(paths.projects.stats, {
        params: { projectUuid } satisfies ProjectSpecificSchema
    }).then(objectIsoStringsToDates(['updated']));

export const asyncTaskFetch = (taskUuid: string): Promise<Task> =>
    apiFetch(paths.tasks.single, { params: { taskUuid } }).then((t) => ({
        ...t,
        created: new Date(t.created),
        updated: new Date(t.updated || t.created),
        estimatedEndDate: t.estimatedEndDate && isoStringToUTCDate(t.estimatedEndDate)
    }));

export const asyncOpenApiProjectFetch = (apiKey: string): Promise<OAProject[]> =>
    apiFetch(publicApiV1Paths.projects.multiple, {
        headers: {
            Authorization: 'Bearer ' + apiKey
        }
    });
