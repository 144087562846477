import { connect } from 'react-redux';
import MainMenu from '../../layout/MainMenu/MainMenu';

import combineSelectors from '$helpers/combineSelectors';
import { clientNameSelector, isSuperAdminSelector } from '$redux/client/clientSelectors';
import { isSmallScreenSelector } from '$redux/config/configSelectors';
import { fileProjectUuidSelector } from '$redux/content/file/fileSelectors';
import { set } from '$redux/route/routeActions';
import { routeSelector } from '$redux/route/routeSelectors';
import { activityCountSelector } from '$redux/ui/uiSelectors';
import {
    hasPluginSelector,
    preferencesSelector,
    storageSelector,
    userEventsSelector,
    userInfosSelector,
    userProjectsSelector
} from '$redux/user/userSelectors';

export default connect(
    combineSelectors(
        hasPluginSelector('planning'),
        hasPluginSelector('projects'),
        hasPluginSelector('videochat'),
        hasPluginSelector('contacts'),
        hasPluginSelector('cast'),
        storageSelector,
        userInfosSelector,
        routeSelector,
        clientNameSelector,
        isSmallScreenSelector,
        isSuperAdminSelector,
        fileProjectUuidSelector,
        userProjectsSelector,
        activityCountSelector,
        userEventsSelector
    ),
    (dispatch) => ({
        setRoute: (route, silent, replace) => dispatch(set(route, silent, replace))
    })
)(MainMenu);
