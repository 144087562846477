import React from 'react';
import { AvatarUser } from '@he-novation/design-system/components/avatars/Avatar/AvatarUser';
import { StyledFormField } from '@he-novation/design-system/components/form/StyledFormField/StyledFormField';

const highlight = (search, label) =>
    label.replace(new RegExp(search, 'gi'), '<span class="is-highlighted">$&</span>');

const FilterContactCheckbox = ({
    contact: { picture, firstname, lastname, email },
    search,
    ...checkboxProps
}) => (
    <StyledFormField
        {...checkboxProps}
        label={
            <div className="wrapper-label-avatar">
                <AvatarUser {...{ picture, firstname, lastname, email }} />
                <span
                    dangerouslySetInnerHTML={{
                        __html: highlight(
                            search,
                            firstname && lastname ? `${firstname} ${lastname}` : email
                        )
                    }}
                />
            </div>
        }
    />
);

export default FilterContactCheckbox;
