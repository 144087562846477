import React from 'react';
import Icon from '@he-novation/icons';
import {
    folderLink,
    planningLink,
    playerLink,
    projectTasksLink
} from '@he-novation/paths/herawFrontUris';

import NoteThumbnail from '$components/Notes/NoteThumbnail/NoteThumbnail';
import Link from '$components/router/Link';

export function ActivityItemV1({
    isUser,
    index,
    item: {
        file_thumbnail_url,
        file_uuid,
        folder_uuid,
        log_metadata,
        note_metadata,
        note_thumbnail_url,
        note_uuid,
        project_uuid,
        note_type,
        tag,
        values,
        version
    }
}: any) {
    return (
        <React.Fragment key={index}>
            {note_thumbnail_url || file_thumbnail_url ? (
                <Link
                    className="activity-button"
                    disabled={!isUser}
                    filters={note_uuid ? { selected_note: note_uuid } : null}
                    href={playerLink(file_uuid, version)}
                >
                    {note_type === 'areaselection' ? (
                        <NoteThumbnail
                            metadata={note_metadata}
                            thumbnail={note_thumbnail_url || file_thumbnail_url}
                            zoomed={true}
                        />
                    ) : (
                        <img src={note_thumbnail_url || file_thumbnail_url} alt="" />
                    )}

                    <span>
                        {values.name}
                        {note_metadata?.tcIn && (
                            <>
                                <br />
                                {note_metadata.tcIn}
                            </>
                        )}
                    </span>
                </Link>
            ) : file_uuid ? (
                <div className="activity-button">
                    <Icon icon="file-full" />
                    <span>{values.name}</span>
                </div>
            ) : null}

            {values.task_uuid && (
                <Link
                    className="activity-button"
                    disabled={!isUser}
                    href={projectTasksLink(project_uuid, values.task_uuid)}
                >
                    <Icon icon="briefcase" />
                    <span>{values.project_name}</span>
                </Link>
            )}

            {(tag.startsWith('folder') || (tag === 'access_modify' && folder_uuid)) && (
                <Link className="activity-button" disabled={!isUser} href={folderLink(folder_uuid)}>
                    <Icon icon="folder" />
                    <span>{values.name || values.folder_name}</span>
                </Link>
            )}

            {log_metadata?.eventUuid && (
                <Link
                    className="activity-button"
                    disabled={!isUser}
                    href={planningLink(new Date(log_metadata.startDate), log_metadata.eventUuid)}
                >
                    <Icon icon="date" />
                    <span className="date">{new Date(log_metadata.startDate).getDate()}</span>
                    <span>{values.name}</span>
                </Link>
            )}
        </React.Fragment>
    );
}
