import { ContactsState } from '@he-novation/config/types/contact.types';
import { compose, filter, find, pick, pipe, property } from 'lodash/fp';
import combineSelectors from '../../../helpers/combineSelectors';
import { stateSelector as contentSelector } from '../contentSelectors';
import { CONTACTS } from './contactsActions';

import { ReduxState } from '$redux/store';

const stateSelector: (state: ReduxState) => ContactsState = pipe(
    contentSelector,
    property(CONTACTS)
);

export const contactsSelector = compose(({ contacts }) => ({ contacts }), stateSelector);

export const contactsAsArraySelector = pipe(stateSelector, ({ contacts }) => ({
    contacts: contacts.list
}));

export const groupsSelector = compose(({ groups }) => ({ groups }), stateSelector);

export const groupSelector = (groupUuid) =>
    compose(
        (group) => ({ group }),
        find(({ uuid }) => uuid === groupUuid),
        property('groups.list'),
        groupsSelector
    );

export const activeGroupSelector = compose(({ activeGroup }) => ({ activeGroup }), stateSelector);

export const selectedGroupSelector = (uuid) =>
    compose(
        ({ activeGroup, group }) => ({
            selectedGroup: {
                ...activeGroup,
                ...group
            }
        }),
        combineSelectors(groupSelector(uuid), activeGroupSelector)
    );

export const errorSelector = pipe(stateSelector, pick(['error']));

export const clientContactsSelector = pipe(
    contactsAsArraySelector,
    property('contacts'),
    filter((c) => c.isClientContact),
    (clientContacts) => ({ clientContacts })
);
