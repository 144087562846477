import { connect } from 'react-redux';
import { downloadLink } from '@he-novation/front-shared/utils/downloadLink';
import { notePaths } from '@he-novation/paths/herawApiPaths';
import { pathWithParams, pdfSummaryLink } from '@he-novation/paths/herawFrontUris';
import SidePanelNotesHeader from '../../../SidePanel/SidePanelNotes/SidePanelNotesHeader/SidePanelNotesHeader';

import { CREATE_NOTE_PANEL, CREATE_TASK_PANEL } from '$constants/constants.sidePanels';
import combineSelectors from '$helpers/combineSelectors';
import { isSmallScreenSelector } from '$redux/config/configSelectors';
import { fileNameSelector, fileTypeSelector } from '$redux/content/file/fileSelectors';
import {
    folderHasAvidSelector,
    folderPluginsSelector
} from '$redux/content/folder/folderSelectors';
import { selectedTeamSelector } from '$redux/content/projects/projectsSelector';
import { routeParamsSelector } from '$redux/route/routeSelectors';
import { closePanel, openPanel } from '$redux/sideMenu/sideMenuActions';

export default connect(
    combineSelectors(
        fileTypeSelector,
        fileNameSelector,
        routeParamsSelector,
        folderHasAvidSelector,
        isSmallScreenSelector,
        folderPluginsSelector,
        selectedTeamSelector
    ),
    (dispatch) => ({ dispatch }),
    (
        {
            routeParams: { fileUuid, fileVersion },
            fileType,
            fileName,
            folderHasAvid,
            isSmallScreen,
            folderPlugins,
            selectedTeam
        },
        { dispatch },
        { isPublicFile, ...ownProps }
    ) => ({
        openPanelNote: () => dispatch(openPanel(CREATE_NOTE_PANEL, { isPublicFile })),
        openPanelTask: () => dispatch(openPanel(CREATE_TASK_PANEL)),
        openAvidLink: (fileName, lineBreak) => {
            const filters = ownProps.filters || {};
            if (selectedTeam) {
                ownProps.filters.team_uuid = selectedTeam;
            }
            downloadLink(
                process.env.API_URL +
                    pathWithParams(
                        notePaths.avidSummary,
                        { fileUuid, fileVersion },
                        { filters, lineBreak }
                    ),
                fileName + '.txt',
                true
            );
        },
        openPdfLink: () => {
            const filters = ownProps.filters || {};
            if (selectedTeam) {
                ownProps.filters.team_uuid = selectedTeam;
            }
            if (Object.keys(filters).length) {
                const url = `?filters=${encodeURIComponent(JSON.stringify(filters))}`;
                window.open(pdfSummaryLink(fileUuid, fileVersion) + url);
            } else {
                window.open(pdfSummaryLink(fileUuid, fileVersion));
            }
        },
        closePanel: () => dispatch(closePanel()),
        fileType,
        fileName,
        folderHasAvid,
        isSmallScreen,
        folderPlugins,
        ...ownProps
    })
)(SidePanelNotesHeader);
