import './AutoComplete.scss';
import { Button } from '../../buttons/Button/Button';
import { DirectionX, DirectionY, getAbsolutePosition } from '../../../utils/getAbsolutePosition';
import { GenericFormFieldTypes } from 'generic-form';
import { Key } from '../../../enums';
import classNames from 'classnames';
import cn from 'classnames';
import debounce from 'lodash/fp/debounce';
import React from 'react';
import ReactDOM from 'react-dom';
import type { ReactNode, RefObject, SyntheticEvent } from 'react';
import withKeyEventListeners from '../../../hoc/withKeyEventListeners';

type WithKeyEventListeners = {
    onWindowKeyUp: (cb: Function) => void;
    onWindowKeyDown: (cb: Function) => void;
    onWindowKeyEnter: (cb: Function, elementGetter: () => HTMLElement) => void;
    onWindowKeyEscape: (cb: Function) => void;
};

type AutoCompleteProps = WithKeyEventListeners & {
    exactMatch?: boolean;
    defaultList?: string[];
    disabled?: boolean;
    fieldClassName?: string;
    id?: string;
    inputRef: RefObject<any>;
    list: string[];
    listClassName?: string;
    minLength?: number;
    name?: string;
    noResult?: Function | ReactNode;
    onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void;
    onChange?: (label: string, value: any, filteredList: any) => void;
    onSelect?: (label: string, value: any) => void;
    onEnter?: (
        e: SyntheticEvent<HTMLInputElement>,
        fieldValue: string | undefined,
        cb: Function
    ) => void;
    onFocus?: (e: SyntheticEvent<HTMLInputElement>) => void;
    renderOption?: (label: string, value: any, index: number) => ReactNode;
    resetOnEnter?: boolean;
    resetOnSelect?: boolean;
    setMatchError?: (matchError: boolean) => void;
    value?: string;
    valueToInputValue?: (value: any) => string;
    hasArrow?: boolean;
    noFilter?: boolean;
    readOnly?: boolean;
    onKeyDown?: any;
    onKeyUp?: any;
};

type option = { label: string; value: any };

type AutoCompleteState = {
    filteredList: option[];
    focused: boolean;
    listVisible: boolean;
    matchError: boolean;
    reachedEnd: boolean;
    selectedIndex: number;
    style: object | null;
    value?: string;
    values: option[];
};

class _AutoComplete extends React.Component<AutoCompleteProps, AutoCompleteState> {
    absoluteRef: RefObject<HTMLDivElement>;
    triggerRef: RefObject<HTMLDivElement>;
    blurTimeout: any;
    options: RefObject<HTMLElement>;

    constructor(props: AutoCompleteProps) {
        super(props);
        const values = this.listToValues(props.list);
        this.state = {
            filteredList: this.filterValues(values, props.value),
            focused: false,
            listVisible: false,
            matchError: false,
            reachedEnd: false,
            selectedIndex: -1,
            style: null,
            value: props.value,
            values
        };
        this.absoluteRef = React.createRef();
        this.triggerRef = React.createRef();
        this.options = React.createRef();
    }

    listToValues(list: string[]) {
        return list
            .map((value) => ({
                label: this.valueToInputValue(value),
                value
            }))
            .filter(({ label }) => label);
    }

    componentDidMount() {
        this.props.onWindowKeyUp(this.onUp);
        this.props.onWindowKeyDown(this.onDown);
        this.props.onWindowKeyEnter(this.onEnter, this.getInputRef);
        this.props.onWindowKeyEscape(this.onEscape);
    }

    componentDidUpdate(prevProps: AutoCompleteProps) {
        if (prevProps.value !== this.props.value) this.setState({ value: this.props.value });
        if (prevProps.list !== this.props.list) {
            const values = this.listToValues(this.props.list);
            this.setState({
                values,
                filteredList: this.filterValues(values, this.state.value)
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.blurTimeout);
    }

    render() {
        return (
            <div
                className={cn('c-autocomplete', this.state.listVisible && 'is-toggled')}
                ref={this.triggerRef}
            >
                <input
                    disabled={this.props.disabled}
                    ref={this.props.inputRef}
                    readOnly={this.props.readOnly}
                    autoComplete="off"
                    className={this.props.fieldClassName}
                    type={GenericFormFieldTypes.TEXT}
                    name={this.props.name}
                    id={this.props.id}
                    value={this.state.value}
                    onChange={this.onSearchChange}
                    onFocus={this.onSearchFocus}
                    onBlur={this.onSearchBlur}
                    onKeyDown={this.props.onKeyDown}
                    onKeyUp={this.props.onKeyUp}
                />

                {this.props.hasArrow && (
                    <Button
                        type="button"
                        icon={'arrow-left'}
                        className="arrow"
                        onClick={() => {
                            if (!this.state.listVisible) {
                                this.setState(
                                    { listVisible: true, focused: true },
                                    this.updateStyle
                                );
                            } else {
                                this.setState({ listVisible: false });
                            }
                        }}
                    />
                )}

                {this.renderOptions()}
            </div>
        );
    }

    getInputRef = (): HTMLElement => this.props.inputRef.current;

    renderOptions() {
        if (
            this.state.listVisible &&
            (this.state.filteredList.length > 0 || this.props.defaultList || this.props.noResult)
        ) {
            const options = this.getOptionsContent();
            if (!options) return null;

            return ReactDOM.createPortal(
                <div
                    className={classNames('c-autocomplete-options-list', this.props.listClassName)}
                    ref={this.absoluteRef}
                    style={this.state.style || {}}
                >
                    <div
                        className="c-autocomplete-options-container"
                        onScroll={(e) =>
                            this.onScroll({
                                scrollTop: e.currentTarget.scrollTop,
                                offsetHeight: e.currentTarget.offsetHeight,
                                scrollHeight: e.currentTarget.scrollHeight
                            })
                        }
                    >
                        <ul>{options}</ul>
                    </div>
                    <div
                        className={!this.state.reachedEnd ? 'gradient' : 'gradient gradient-offset'}
                    />
                </div>,
                document.body
            );
        }
    }

    updateStyle = () => {
        if (this.absoluteRef.current && this.triggerRef.current)
            this.setState({
                style: getAbsolutePosition(
                    this.triggerRef,
                    this.absoluteRef,
                    [DirectionX.LeftInner, DirectionY.Bottom],
                    true
                )
            });
    };

    filterValues = (values: option[], search = '') => {
        return search || this.props.minLength === 0
            ? !this.props.noFilter
                ? values.filter(({ label }) =>
                      new RegExp(search.toUpperCase()).test(label.toUpperCase())
                  )
                : values
            : [];
    };

    valueToInputValue(v: any) {
        return this.props.valueToInputValue ? this.props.valueToInputValue(v) : v;
    }

    onSearchChange = (e: SyntheticEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        const filteredList =
            value.length >= (typeof this.props.minLength !== 'undefined' ? this.props.minLength : 1)
                ? this.filterValues(this.state.values, value)
                : [];
        this.setState(
            {
                value,
                listVisible: true,
                filteredList,
                selectedIndex:
                    filteredList.length && this.state.selectedIndex < filteredList.length
                        ? this.state.selectedIndex
                        : -1
            },
            () => {
                this.updateStyle();
                if (this.state.matchError) this.setMatchError();
            }
        );
        if (typeof this.props.onChange === 'function') this.props.onChange(value, e, filteredList);
    };

    setMatchError() {
        if (this.props.exactMatch) {
            this.setState(
                {
                    matchError: !this.state.values.find(({ label }) => label === this.state.value)
                },
                () => {
                    if (typeof this.props.setMatchError === 'function')
                        this.props.setMatchError(this.state.matchError);
                }
            );
        }
    }

    onSearchBlur = (e: SyntheticEvent<HTMLInputElement>) => {
        e.persist();
        this.blurTimeout = setTimeout(() => {
            this.setMatchError();

            this.setState({
                listVisible: false,
                selectedIndex: -1,
                focused: false
            });

            if (typeof this.props.onBlur === 'function') this.props.onBlur(e);
        }, 200);
    };

    onSearchFocus = (e: SyntheticEvent<HTMLInputElement>) => {
        this.setState({ listVisible: true, focused: true }, this.updateStyle);

        if (typeof this.props.onFocus === 'function') this.props.onFocus(e);
    };

    onSelectOption = (e: SyntheticEvent<HTMLElement>, value: any, label: string) => {
        const filteredList = this.props.resetOnSelect
            ? []
            : this.filterValues(this.state.values, label);
        this.setState(
            {
                value: this.props.resetOnSelect ? '' : label,
                filteredList,
                listVisible: false
            },
            this.setMatchError
        );
        if (typeof this.props.onChange === 'function')
            this.props.onChange(label, value, filteredList);
        if (typeof this.props.onSelect === 'function') this.props.onSelect(label, value);
    };

    onScroll = debounce(10, (scrollInfo: any) => {
        let toBottom = scrollInfo.scrollHeight - (scrollInfo.offsetHeight + scrollInfo.scrollTop);

        let reachedEnd = false;
        if (toBottom < 10) {
            reachedEnd = true;
        }

        this.setState({ reachedEnd });
    });

    getOptionsContent() {
        if (this.state.filteredList.length === 0 && !this.props.defaultList) {
            const content =
                typeof this.props.noResult === 'function'
                    ? this.props.noResult(this.props, this.state)
                    : this.props.noResult;
            return content ? <li className="option">{content}</li> : null;
        }

        return (
            this.state.filteredList.length
                ? this.state.filteredList
                : this.listToValues(this.props.defaultList || [])
        ).map(({ value, label }: { value: any; label: string }, index: number) => (
            <li
                className={`option ${this.state.selectedIndex === index ? 'focused' : ''}`}
                key={index}
            >
                <button type="button" onClick={(e) => this.onSelectOption(e, value, label)}>
                    {typeof this.props.renderOption === 'function'
                        ? this.props.renderOption(label, value, index)
                        : label}
                </button>
            </li>
        ));
    }

    onUp = () => {
        if (this.state.listVisible) {
            this.setState({ selectedIndex: Math.max(-1, this.state.selectedIndex - 1) });
        }
    };

    onDown = () => {
        if (this.state.focused)
            this.setState({
                listVisible: true,
                selectedIndex: Math.min(
                    this.state.filteredList.length - 1,
                    this.state.selectedIndex + 1
                )
            });
    };

    onEnter = (e: SyntheticEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (this.state.selectedIndex > -1) {
            const selected = this.state.filteredList[this.state.selectedIndex];
            this.onSelectOption(e, selected.value, selected.label);
        } else {
            const match = this.state.values.find(
                ({ label }) => label.trim() === this.state.value?.trim()
            );
            if (match) {
                this.onSelectOption(e, match.value, match.label);
            } else if (typeof this.props.onEnter === 'function') {
                this.props.onEnter(e, this.state.value, () =>
                    this.setState({ value: '', listVisible: false })
                );
                if (this.props.resetOnEnter) this.setState({ value: '', listVisible: false });
            }
        }
    };

    onEscape = () => {
        if (this.state.listVisible) {
            this.setState({
                listVisible: false,
                selectedIndex: -1
            });
        }
    };
}

export const AutoComplete = withKeyEventListeners([Key.Up, Key.Down, Key.Enter, Key.Escape])(
    _AutoComplete
);
