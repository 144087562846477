import React from 'react';
import { connect } from 'react-redux';
import { set, setFilters } from '../../redux/route/routeActions';
import { routeSelector } from '../../redux/route/routeSelectors';

export const isActive = (activeClassName, href, route, isRoot) =>
    activeClassName && (route === href || (isRoot && route === '/')) ? activeClassName : '';

const Link = ({
    className = '',
    activeClassName,
    children,
    disabled,
    download,
    href,
    isLegacy,
    isRoot,
    onClick,
    route,
    target,
    external,
    configRoute,
    queryParams,
    params,
    forwardedRef,
    ...rest
}) => (
    <a
        download={download}
        className={`${className} ${isActive(activeClassName, href, route, isRoot)}`}
        href={disabled ? null : href}
        onClick={disabled || isLegacy || external ? null : onClick}
        ref={forwardedRef}
        target={target}
        {...rest}
    >
        {children}
    </a>
);

export default connect(routeSelector, (dispatch, { href, onClick, filters, onTouchStart }) => ({
    onClick: (e) => {
        if (typeof onClick === 'function') onClick(e);

        if (!e.defaultPrevented) {
            e.preventDefault();
            dispatch(set(href));
            if (filters) dispatch(setFilters(filters));
        }
    },
    onTouchStart: (e) => {
        if (!onTouchStart) return;
        if (typeof onTouchStart === 'function') onTouchStart(e);
        e.preventDefault();
        dispatch(set(href));
        if (filters) dispatch(setFilters(filters));
    }
}))(Link);
