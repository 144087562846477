import fscreen from 'fscreen';
import isSmallScreen from '$helpers/isSmallScreen';

export const APP_ID = 'app';
export const FULLSCREEN_CLASS = 'is-fullscreen';
export const HIDE_TIMEOUT = isSmallScreen ? 2000 : 10000;

export const CONTROLS_FADE_IN_CLASS = 'controls-fade-in';
export const CONTROLS_FADE_OUT_CLASS = 'controls-fade-out';

let interval;

export const hideControls = (targetClass) => {
    const appNode = document.getElementById(APP_ID);
    if (appNode.classList.contains(targetClass)) {
        appNode.classList.remove(CONTROLS_FADE_IN_CLASS);
        appNode.classList.add(CONTROLS_FADE_OUT_CLASS);
    }
};

export const controlsHideTimeout = (targetClass) => {
    const appNode = document.getElementById(APP_ID);
    clearTimeout(interval);
    const isActive = appNode.classList.contains(targetClass);
    const classlist = appNode.classList;
    if (isActive) {
        classlist.remove(CONTROLS_FADE_OUT_CLASS);
        classlist.add(CONTROLS_FADE_IN_CLASS);

        interval = setTimeout(() => hideControls(targetClass), HIDE_TIMEOUT);
    }
};

export const enterFullscreen = () => {
    const appNode = document.getElementById(APP_ID);
    appNode.classList.add(FULLSCREEN_CLASS);
    try {
        fscreen.requestFullscreen(appNode);
    } catch (e) {
        /* eslint-disable */
        console.warn('Fullwidth API is not available on this device', e);
        /* eslint-enable */
    }
    document.addEventListener('mousemove', () => controlsHideTimeout(FULLSCREEN_CLASS));
    if (isSmallScreen(window.innerWidth, window.innerHeight)) hideControls(FULLSCREEN_CLASS);
    else interval = setTimeout(() => hideControls(FULLSCREEN_CLASS), HIDE_TIMEOUT);
};

export const exitFullscreen = () => {
    const appNode = document.getElementById(APP_ID);
    if (fscreen.fullscreenElement === appNode) {
        try {
            fscreen.exitFullscreen();
        } catch (e) {
            /* eslint-disable */
            console.warn('Fullwidth API is not available on this device', e);
            /* eslint-enable */
        }

        document.removeEventListener('mousemove', () => controlsHideTimeout(FULLSCREEN_CLASS));
        appNode.classList.remove(FULLSCREEN_CLASS, CONTROLS_FADE_OUT_CLASS, CONTROLS_FADE_IN_CLASS);
    } else {
        document.removeEventListener('mousemove', () => controlsHideTimeout(FULLSCREEN_CLASS));
        appNode.classList.remove(FULLSCREEN_CLASS, CONTROLS_FADE_OUT_CLASS, CONTROLS_FADE_IN_CLASS);
    }
};

export const onChangeFullscreen = () => {
    const appNode = document.getElementById(APP_ID);
    if (appNode.classList.contains(FULLSCREEN_CLASS) && !fscreen.fullscreenElement)
        exitFullscreen();
};

export const toggleFullscreen = () =>
    fscreen.fullscreenElement === document.getElementById(APP_ID)
        ? exitFullscreen()
        : enterFullscreen();
