import './ItemPlaylist.scss';
import React, { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { FileStatus } from '@he-novation/config/types/db/enums';
import { FolderMember, FolderRole } from '@he-novation/config/types/folder.types';
import { FrontFolderContentFile } from '@he-novation/front-shared/types/file.front-types';
import cn from 'classnames';
import PlaylistThumbnail from '../PlaylistThumbnail/PlaylistThumbnail';

import LockVersion from '$components/base/LockVersion/LockVersion';
import { folderUuidSelector } from '$redux/content/file/fileSelectors';
import { folderRoleSelector } from '$redux/content/folder/folderSelectors';

type ItemPlaylistProps = {
    fileUuid: string;
    file: FrontFolderContentFile;
    time?: number | null;
    onClick: (e: MouseEvent, fileUuid: string) => void;
    folderMembers: FolderMember[];
    currentUserUuid: string;
};
const ItemPlaylist = ({
    file,
    fileUuid,
    onClick,
    folderMembers,
    currentUserUuid
}: ItemPlaylistProps) => {
    const { folderRole } = useSelector(folderRoleSelector);
    return (
        <div
            className={cn('c-item-playlist', {
                isSelected: fileUuid === file.uuid,
                disabled: !file.isPlayable
            })}
            id={file.uuid}
            onClick={(e) => onClick(e, file.uuid)}
        >
            <PlaylistThumbnail
                isActive={fileUuid === file.uuid}
                thumbnail={file.thumbnail}
                mimeType={file.mimeType}
                isPlayable={file.isPlayable}
                isBundle={file.isBundle}
            />
            <div className="wrapper-content">
                <h3>{file.name}</h3>
                <div className="wrapper-state-file">
                    <div className="state-file">
                        <LockVersion
                            classname={'-playlist'}
                            folderRole={folderRole}
                            final_vote_user={file.finals.find((f) => f.uuid === currentUserUuid)}
                            versionLocked={file.status === FileStatus.final}
                            final_votes_total={
                                folderMembers.filter((m) => m.role === FolderRole.ROLE_REPORTER)
                                    .length
                            }
                            final_voted={file.finals.length}
                            fileUuid={file.uuid}
                            fileVersion={file.version}
                        />
                    </div>
                    {file.version !== 0 && <span>version {file.version + 1}</span>}
                </div>
            </div>
        </div>
    );
};

export default ItemPlaylist;
