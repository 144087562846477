import update from 'immutability-helper';
import { OPEN_PANEL, CLOSE_PANEL, TOGGLE_PANEL } from './sideMenuActions';
import { SET, SET_FILTER } from '$redux/route/routeActions';
import { NOTES_PANEL, SUBTITLES } from '$constants/constants.sidePanels';
import { FILE, FILE_COMPARE, SHARED_FILE } from '@he-novation/paths/herawFrontUris';

export const initialState = {
    activePanel: null
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET:
            if (
                state.activePanel === SUBTITLES &&
                ![FILE, SHARED_FILE, FILE_COMPARE].includes(action.route)
            )
                return update(state, { activePanel: { $set: null } });
            return state;

        case SET_FILTER:
            if (
                action.filterName === 'selected_note' &&
                state.activePanel === 'note' &&
                action.filterValue === null
            )
                return update(state, { activePanel: { $set: NOTES_PANEL } });
            return state;
        case OPEN_PANEL:
            return update(state, {
                activePanel: {
                    $set: action.panel
                },
                panelPayload: {
                    $set: action.payload
                }
            });

        case CLOSE_PANEL:
            return update(state, {
                activePanel: {
                    $set: null
                },
                panelPayload: {
                    $set: null
                }
            });

        case TOGGLE_PANEL:
            return update(state, {
                activePanel: {
                    $set: state.activePanel === action.panel ? null : action.panel
                }
            });

        default:
            return state;
    }
};
