import {
    AreaSelectionData,
    AreaSelectionOutputData,
    AreaSelectionType
} from '@he-novation/config/types/area-selection.types';
import { v4 as uuidV4 } from 'uuid';

export const REDUX_AREA_SELECTION = 'AREA_SELECTION';
export const AREA_SELECTION_START_SHAPE = `${REDUX_AREA_SELECTION}/START_SHAPE`;
export const AREA_SELECTION_PUSH_SHAPE = `${REDUX_AREA_SELECTION}/PUSH_SHAPE`;
export const AREA_SELECTION_SPLICE_SHAPE = `${REDUX_AREA_SELECTION}/SPLICE_SHAPE`;
export const AREA_SELECTION_RESET = `${REDUX_AREA_SELECTION}/RESET`;
export const AREA_SELECTION_TOGGLE = `${REDUX_AREA_SELECTION}/TOGGLE`;
export const AREA_SELECTION_DISPLAY = `${REDUX_AREA_SELECTION}/DISPLAY`;

export const areaSelectionStartShape = (shape: AreaSelectionType) => ({
    type: AREA_SELECTION_START_SHAPE,
    shape,
    uuid: uuidV4()
});

export const areaSelectionPushShape = (data: AreaSelectionData) => ({
    type: AREA_SELECTION_PUSH_SHAPE,
    data
});

export const areaSelectionSpliceShape = (index: number) => ({
    type: AREA_SELECTION_SPLICE_SHAPE,
    index
});

export const areaSelectionReset = () => ({
    type: AREA_SELECTION_RESET
});

export const areaSelectionToggle = (toggle?: boolean) => ({
    type: AREA_SELECTION_TOGGLE,
    toggle
});

export const areaSelectionDisplay = (data: AreaSelectionOutputData[]) => ({
    type: AREA_SELECTION_DISPLAY,
    data
});
