import React from 'react';
import { capitalizeFirstLetter } from '$helpers/capitalizeFirstLetter';

class WithKeyEventListeners extends React.Component {
    constructor(props) {
        super(props);
        this.removeEventListeners = [];
    }
    static shortcutToFunctionName(s) {
        return `onKey${capitalizeFirstLetter(typeof s === 'string' ? s : s.toString())}`;
    }

    static shortcutToKeycode(s) {
        switch (s) {
            case 1:
                return 49;
            case 2:
                return 50;
            case 3:
                return 51;
            case 'backspace':
                return 8;
            case 'down':
                return 40;
            case 'c':
                return 67;
            case 'enter':
                return 13;
            case 'escape':
                return 27;
            case 'left':
                return 37;
            case 'right':
                return 39;
            case 'shift':
                return 16;
            case 'space':
                return 32;
            case 'tab':
                return 9;
            case 'up':
                return 38;
            case 'd':
                return 68;
            case 'del':
                return 46;
            case 'e':
                return 69;
            case 'i':
                return 73;
            case 'm':
                return 77;
            case 'n':
                return 78;
            case 'o':
                return 79;
            case 'pageUp':
                return 33;
            case 'pageDown':
                return 34;
            case 's':
                return 83;
            case 'v':
                return 86;
            case 'x':
                return 88;
        }
    }

    shortcutToFunction(s) {
        return (callback, elementGetter, remove) => {
            const func = (e) => {
                if (e.which === WithKeyEventListeners.shortcutToKeycode(s)) callback(e);
            };
            const el = elementGetter ? elementGetter() : window;
            el.addEventListener('keydown', func);
            const removeEventListener = () =>
                (elementGetter ? elementGetter() : window).removeEventListener('keydown', func);
            this.removeEventListeners.push(removeEventListener);

            return () => {
                removeEventListener();
                this.removeEventListeners.splice(
                    this.removeEventListeners.indexOf(removeEventListener),
                    1
                );
            };
        };
    }

    listenersToRegistrationFunctions(listeners) {
        return listeners.reduce((acc, s) => {
            acc[WithKeyEventListeners.shortcutToFunctionName(s)] = this.shortcutToFunction(s);
            return acc;
        }, {});
    }
    componentWillUnmount() {
        this.removeEventListeners.forEach((removeEventListener) => removeEventListener());
    }

    render() {
        const { Component, componentProps, listeners } = this.props;
        return (
            <Component
                {...componentProps}
                {...this.listenersToRegistrationFunctions(listeners)}
                ref={this.props.componentRef}
            />
        );
    }
}

/*WithKeyEventListeners.propTypes = {
    Component: PropTypes.any,
    componentProps: PropTypes.object,
    listeners: PropTypes.arrayOf(
        PropTypes.oneOf([
            1,
            2,
            3,
            'backspace',
            'enter',
            'escape',
            'del',
            'down',
            'left',
            'right',
            'shift',
            'tab',
            'up',
            'c',
            'e',
            'd',
            'i',
            'm',
            'n',
            'o',
            's',
            'v',
            'x',
            'pageUp',
            'pageDown',
            'space'
        ])
    )
};*/

export default (listeners) => (Component) =>
    React.forwardRef((props, ref) => (
        <WithKeyEventListeners
            Component={Component}
            componentProps={props}
            listeners={listeners}
            componentRef={ref}
        />
    ));
