import React from 'react';
import { useSelector } from 'react-redux';
import MobileFooterButton from '../../Buttons/MobileFooterButton/MobileFooterButton';
import { ViewTitle } from './ViewTitle/ViewTitle';

import { isSmallScreenSelector } from '$redux/config/configSelectors';

export function ResponsiveViewTitle({
    actions,
    children,
    mobileActions,
    mobileIcon,
    ...rest
}: any) {
    const { isSmallScreen } = useSelector(isSmallScreenSelector);

    if (isSmallScreen) {
        return (
            <MobileFooterButton
                actions={mobileActions || actions}
                icon={mobileIcon || actions?.[0]?.icon}
                {...rest}
            />
        );
    }
    return (
        <ViewTitle actions={actions} tag="h2" {...rest}>
            {children}
        </ViewTitle>
    );
}
