import React from 'react';
import { __ } from '@he-novation/design-system/utils/i18n';
import { isEmpty } from 'lodash/fp';
import { noteTypeToWording } from '@he-novation/utils/note';

const NoteOrTaskContentTitle = ({ Tag = 'h4', type, metadata }) => (
    <Tag>
        {__(noteTypeToWording(type))}
        {!isEmpty(metadata) && (
            <span>
                {metadata.tcIn} {metadata.tcOut ? `> ${metadata.tcOut}` : null}
            </span>
        )}
    </Tag>
);

export default NoteOrTaskContentTitle;
