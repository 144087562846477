import './EditCommentForm.scss';
import React, { MouseEventHandler, SyntheticEvent } from 'react';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import {
    FormField,
    ModularForm
} from '@he-novation/design-system/components/form/FormField/FormField';
import { Theme } from '@he-novation/design-system/enums';
import { __ } from '@he-novation/design-system/utils/i18n';
import Icon from '@he-novation/icons';

type EditCommentFormProps = {
    openAttachmentsModal: (setAttachments: (attachments: File[]) => void, attachments: any) => void;
    attachments: any;
    setAttachments: (attachments: File[]) => void;
    onClick: MouseEventHandler<HTMLButtonElement>;
    openKeywordsModal: (setKeywords: any, keywords: any) => void;
    keywords: any;
    setKeywords: any;
    disableKeyboardListeners: () => void;
    enableKeyboardListeners: () => void;
    formCommentRef: any;
    onSubmit: (e: SyntheticEvent, data: { content: string }) => void;
    content: any;
    isDraft: any;
};
export default function EditCommentForm({
    openAttachmentsModal,
    attachments,
    setAttachments,
    onClick,
    openKeywordsModal,
    keywords,
    setKeywords,
    disableKeyboardListeners,
    enableKeyboardListeners,
    formCommentRef,
    onSubmit,
    content,
    isDraft
}: EditCommentFormProps) {
    const formId = 'c-form-comment-edit';
    return (
        <ModularForm id={formId} ref={formCommentRef} onSubmit={onSubmit}>
            <FormField
                theme={Theme.Dark}
                type="textarea"
                name="content"
                value={content}
                onFocus={disableKeyboardListeners}
                onBlur={enableKeyboardListeners}
                formId={formId}
            />
            <div className="row-buttons">
                <button
                    type="button"
                    onClick={() => openAttachmentsModal(setAttachments, attachments)}
                >
                    {attachments.length > 0 && <span className="number">{attachments.length}</span>}
                    <Icon icon="attachment" />
                </button>
                <button type="button" onClick={() => openKeywordsModal(setKeywords, keywords)}>
                    {keywords.length > 0 && <span className="number">{keywords.length}</span>}
                    <Icon icon="tag" />
                </button>
            </div>
            <div className="form-comment-buttons">
                {isDraft && (
                    <Button tone={ButtonTone.Outlined} onClick={onClick} id="comment-draft-submit">
                        {__('REACT_SAVE_DRAFT_SHORT')}
                    </Button>
                )}
                <Button tone={ButtonTone.Primary} onClick={onClick} type="submit">
                    {__('REACT_PUBLISH')}{' '}
                </Button>
            </div>
        </ModularForm>
    );
}
