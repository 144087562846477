import Filters from './Filters';
import { connect } from 'react-redux';
import { filtersLengthSelector, filtersSelector } from '../../redux/route/routeSelectors';
import { setFilter, setFilters } from '../../redux/route/routeActions';
import { compose } from 'recompose';
import combineSelectors from '../../helpers/combineSelectors';
import { localeSelector } from '../../redux/config/configSelectors';

export default compose(
    connect(
        combineSelectors(filtersSelector, filtersLengthSelector, localeSelector),
        (dispatch) => ({
            setCheckboxFilter(filters, filterName, e) {
                let filter = [];
                if (filters[filterName]) filter = filter.concat(filters[filterName]);

                const index = filter.indexOf(e.target.value);
                if (index === -1) {
                    filter.push(e.target.value);
                } else {
                    filter.splice(index, 1);
                }

                dispatch(setFilter(filterName, filter.length ? filter : undefined));
            },

            setRadioFilter(filters, filterName, e) {
                dispatch(
                    setFilter(filterName, e.target && e.target.value ? e.target.value : undefined)
                );
            },

            setDateFilter(filterName, e) {
                dispatch(
                    setFilter(filterName, e && e instanceof Date ? e.toISOString() : undefined)
                );
            },

            resetFilters() {
                dispatch(setFilters());
            }
        }),
        (
            { filters, ...state },
            { setCheckboxFilter, setRadioFilter, ...dispatchProps },
            props
        ) => ({
            setCheckboxFilter: (filterName, e) => setCheckboxFilter(filters, filterName, e),
            setRadioFilter: (filterName, e) => setRadioFilter(filters, filterName, e),
            filters,
            id: props.id,
            ...dispatchProps,
            ...props,
            ...state
        })
    )
)(Filters);
