import { connect } from 'react-redux';
import { Note } from '@he-novation/config/types/note.types';
import { __ } from '@he-novation/design-system/utils/i18n';
import { getDisplayName } from '@he-novation/utils/user';
import { compose, withProps } from 'recompose';
import SidePanelFilters from '../../SidePanel/SidePanelFilters';

import { hasFolderPluginSelector } from '$redux/content/folder/folderSelectors';

type FilterOption = { value: string; label: string };
export default compose(
    connect(hasFolderPluginSelector('notes_all_versions')),
    withProps(
        ({
            notes,
            isPublicFile,
            displayCastNotes
        }: {
            notes: Note[];
            isPublicFile?: boolean;
            displayCastNotes?: boolean;
        }) => {
            const members: FilterOption[] = [];
            const _tags: FilterOption[] = [];
            let hasImportant = false;
            let hasTask = false;
            let hasAttachments = false;
            const casts: FilterOption[] = [];

            notes.forEach(({ comments, task, cast }) => {
                if (!displayCastNotes && cast && !casts.find((c) => c.value === cast.uuid))
                    casts.push({ value: cast.uuid, label: cast.name });
                if (task) hasTask = true;
                comments.forEach(({ user, tags, assets, isImportant }) => {
                    if (user?.uuid && !members.find(({ value }) => value === user.uuid))
                        members.push({
                            value: user.uuid,
                            label: getDisplayName(user)
                        });
                    if (tags && tags.length) {
                        _tags.push(
                            ...tags
                                .filter((tag) => !_tags.find((t) => t.value === tag))
                                .map((tag) => ({ value: tag, label: tag }))
                        );
                    }

                    if (isImportant) hasImportant = true;
                    if (assets.length) hasAttachments = true;
                });
            });

            const types: FilterOption[] = [];
            if (hasImportant) types.push({ value: 'tasks_legacy', label: 'Important' });
            if (hasTask) types.push({ value: 'task', label: 'Tâche' });
            if (hasAttachments) types.push({ value: 'attachments', label: 'Pièces jointes' });

            const values = {
                members,
                tags: _tags,
                types,
                casts: undefined as FilterOption[] | undefined
            };

            const filters = [
                { key: 'members', label: __('REACT_MEMBERS') },
                { key: 'tags', label: __('REACT_KEYWORDS'), allowSingle: true },
                { key: 'types', label: __('REACT_TYPES'), allowSingle: true }
            ];

            if (!isPublicFile && !displayCastNotes) {
                values.casts = casts;
                if (casts.length)
                    filters.push({ key: 'casts', label: __('CASTS'), allowSingle: true });
            }

            return {
                values,
                filters
            };
        }
    )
)(SidePanelFilters);
