import './SidePanelFolderSettingsCheckbox.scss';
import React, { ReactNode, useEffect, useState } from 'react';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { Theme } from '@he-novation/design-system/enums';
import cn from 'classnames';

type SidePanelFolderSettingsCheckboxProps = {
    label: string;
    children?: ReactNode | ReactNode[];
    checked?: boolean;
    name: string;
    id: string;
    formId: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
};
export const SidePanelFolderSettingsCheckbox = ({
    label,
    children,
    checked,
    name,
    id,
    formId,
    onChange,
    disabled
}: SidePanelFolderSettingsCheckboxProps) => {
    const [isOpen, toggle] = useState(false);
    useEffect(() => {
        if (!checked) toggle(false);
    }, [checked]);
    return (
        <div className="c-sp-folder-settings-cb">
            <FormField
                theme={Theme.Dark}
                type="checkbox"
                className="is-switch "
                checked={checked}
                name={name}
                onChange={onChange}
                id={id}
                formId={formId}
                label={label}
                disabled={disabled}
            />

            {children && checked ? (
                <Button
                    type="button"
                    tone={ButtonTone.Hoverable}
                    icon="three-dots"
                    onClick={() => toggle(!isOpen)}
                />
            ) : null}

            <div className={cn('c-sp-folder-settings-submenu', isOpen && 'is-open')}>
                {children}
            </div>
        </div>
    );
};
