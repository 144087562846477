import React from 'react';

export const useForwardedRef = <T,>(ref?: React.ForwardedRef<T>, initialValue?: T) => {
    const targetRef = React.useRef<T | null>(initialValue || null);

    React.useEffect(() => {
        if (!ref) return;

        if (typeof ref === 'function') {
            ref(targetRef.current);
        } else {
            ref.current = targetRef.current;
        }
    }, [ref]);

    return targetRef;
};
