import './SidePanelNoteHeader.scss';
import React from 'react';
import { Note } from '@he-novation/config/types/note.types';
import { __ } from '@he-novation/design-system/utils/i18n';
import Icon from '@he-novation/icons';
import NoteThumbnail from '../../../Notes/NoteThumbnail/NoteThumbnail';
import NoteTimeCode from '../../../Notes/NoteTimeCode/NoteTimeCode';

import { TYPE_NOTE } from '$constants/constants.note';

const noteTypeToTitle = (type) => {
    switch (type) {
        case TYPE_NOTE.SEQUENCE:
            return 'NOTE_TYPE_SEQUENCE';
        case TYPE_NOTE.DRAW:
        case TYPE_NOTE.RECTANGLE:
            return 'NOTE_VIEW_TC_AREASELECTION';
        case TYPE_NOTE.GLOBAL:
            return 'NOTE_TYPE_GLOBAL';
        default:
            return 'NOTE_TYPE_TIMECODE';
    }
};

type SidePanelNoteHeaderProps = {
    goBackToNotes: () => void;
    fileThumbnail: string;
    selectedNote: Note;
};
export default function SidePanelNoteHeader({
    goBackToNotes,
    fileThumbnail,
    selectedNote
}: SidePanelNoteHeaderProps) {
    return (
        <header className="c-side-panel-note-header">
            <button type="button" className="btn-back" onClick={() => goBackToNotes()}>
                <Icon icon="arrow-left-thick" />
            </button>
            <div className="title-wrapper">
                <h2>{__(noteTypeToTitle(selectedNote.type))}</h2>
                {selectedNote.type !== TYPE_NOTE.GLOBAL && selectedNote.metadata ? (
                    <NoteTimeCode
                        timeIn={selectedNote.metadata.tcIn}
                        timeOut={selectedNote.metadata.tcOut}
                    />
                ) : null}
            </div>
            <NoteThumbnail
                metadata={selectedNote.metadata}
                thumbnail={
                    selectedNote.assets.find(
                        (a) => a.type === 'note' && a.url && a.quality === 'min'
                    )?.url || (selectedNote.type === 'global' ? fileThumbnail : undefined)
                }
                zoomed={true}
            />
        </header>
    );
}
