import './NoteOrTaskContent.scss';
import React from 'react';
import { Asset } from '@he-novation/config/types/asset.types';
import { Task } from '@he-novation/config/types/task.types';
import { ContentUser } from '@he-novation/config/types/user.types';
import { AvatarSize } from '@he-novation/design-system/components/avatars/Avatar/Avatar';
import { MultipleAvatars } from '@he-novation/design-system/components/avatars/MultipleAvatars/MultipleAvatars';
import { ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { MultiActionButton } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { MultiActionsList } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButtonList';
import { Theme } from '@he-novation/design-system/enums';
import { __ } from '@he-novation/design-system/utils/i18n';
import { projectTasksLink } from '@he-novation/paths/herawFrontUris';
import { getDisplayName } from '@he-novation/utils/user';
import LineBreakString from '../../base/LineBreakString/LineBreakString';
import NoteButtons from '../../Notes/NoteButtons/NoteButtons';
import TaskStatusPicker from '../../Notes/NoteOrTaskContent/TaskStatusPicker/TaskStatusPickerContainer';
import TaskRemaining from '../../Notes/TaskRemaining/TaskRemaining';
import Link from '../../router/Link';
import NoteOrTaskContentHeader, { NoteOrTaskContentHeaderProps } from './NoteOrTaskContentHeader';

const renderNames = (assignees) => {
    switch (assignees.length) {
        case 1:
            return <p> {getDisplayName(assignees[0])}</p>;
        case 2:
            return (
                <p>
                    {`${getDisplayName(assignees[0])} ${__('AND')} ${getDisplayName(assignees[1])}`}
                </p>
            );
        default:
            return (
                <p>
                    {`${getDisplayName(assignees[0])} ${__('AND')}  ${__('N_USERS', {
                        n: assignees.length - 1
                    })}`}
                </p>
            );
    }
};

type NoteOrTaskContentProps = {
    actions: MultiActionsList | null;
    attachments: Asset[];
    user: ContentUser | { email: string; uuid?: undefined };
    description: string;
    header?: NoteOrTaskContentHeaderProps;
    projectUuid?: string;
    tags: string[];
    task?: Omit<Task, 'note' | 'assets' | 'client'> | null;
    taskStatusPicker?: boolean;
};

export default function NoteOrTaskContent({
    actions,
    attachments,
    user,
    description,
    header,
    projectUuid,
    tags,
    task,
    taskStatusPicker = false
}: NoteOrTaskContentProps) {
    return (
        <article className="c-note-or-task-content">
            {header && <NoteOrTaskContentHeader {...header} />}
            <main>
                {description && (
                    <div className="description">
                        <LineBreakString string={description} parseLinks />
                    </div>
                )}

                <NoteButtons theme={Theme.Dark} attachments={attachments} keywords={tags} />

                {task && task.assignedUsers && task.assignedUsers.length ? (
                    <MultipleAvatars
                        theme={Theme.Dark}
                        avatars={task.assignedUsers}
                        className={task.assignedUsers.length > 3 ? 'show-total' : ''}
                        renderMoreContent={() => renderNames(task.assignedUsers)}
                        overlap
                        size={AvatarSize.Small}
                    >
                        {task.assignedUsers.length < 3 && renderNames(task.assignedUsers)}
                    </MultipleAvatars>
                ) : null}
            </main>

            <footer>
                <div className="creator-and-actions">
                    <div className="creator">
                        {task && projectUuid && (
                            <Link href={projectTasksLink(projectUuid, task.uuid)}>
                                #{task.number}
                            </Link>
                        )}
                        &bull; {__('CREATED_BY', { user: user ? getDisplayName(user) : '-' })}
                    </div>

                    {task && taskStatusPicker && (
                        <TaskStatusPicker uuid={task.uuid} status={task.status} />
                    )}

                    {actions && (
                        <MultiActionButton
                            tone={ButtonTone.Hoverable}
                            theme={Theme.Light}
                            className="actions"
                            icon="three-dots"
                            actions={actions}
                        />
                    )}
                </div>
                {task && <TaskRemaining task={task} />}
            </footer>
        </article>
    );
}
