import React from 'react';
import ActivityFilters from '../../../views/activity/ActivityFiltersContainer';

const SidePanelActivityFilter = () => (
    <div className="c-side-panel-activity-filter">
        <ActivityFilters />
    </div>
);

export default SidePanelActivityFilter;
