import React from 'react';
import { setConfiguration } from 'react-calendars';
import Icon from '@he-novation/icons';
import { Locale } from '@he-novation/config/types/enums';

const translate = {
    daysTitles: {
        fr: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
        en: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
        de: ['L', 'M', 'M', 'J', 'V', 'S', 'D']
    },
    monthTitles: {
        fr: [
            'Janvier',
            'Février',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Août',
            'Septembre',
            'Octobre',
            'Novembre',
            'Decembre'
        ],
        en: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ],
        de: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ]
    }
};

export const setDatePickerConfiguration = (locale?: Locale) => {
    setConfiguration({
        daysTitles: translate.daysTitles[locale || Locale.FR],
        firstDay: 1, // set to 0 to start week sunday
        libClassName: 'react-calendar',
        monthsTitles: translate.monthTitles[locale || Locale.FR],
        previousButton: <Icon icon="arrow-left" />,
        nextButton: <Icon icon="arrow-left" />
    });
};
