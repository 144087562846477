import { eventsFetch, eventUpdate, projectEventsFetch } from '../../content/events/eventsAsync';

import { WS_PREFIX_IN } from '$constants/webSocket.constants';
import { asyncActionSuccess } from '$helpers/asyncAction';

export const EVENTS = 'EVENTS';
export const FETCH = `${EVENTS}/FETCH`;
export const UPDATE = `${EVENTS}/UPDATE`;
export const WS_EVENT_UPSERT = `${WS_PREFIX_IN}${EVENTS}/UPSERT`;
export const WS_EVENT_DELETE = `${WS_PREFIX_IN}${EVENTS}/DELETE`;
export const WS_EVENT_DELETE_USER = `${WS_PREFIX_IN}${EVENTS}/DELETE_USER`;

export const fetchEvents =
    ({ start, end, projectUuid }: { start?: string; end?: string; projectUuid?: string } = {}) =>
    async (dispatch) => {
        dispatch({ type: FETCH, query: 'events' });
        const events = await eventsFetch(start, end, projectUuid);
        dispatch({ type: asyncActionSuccess(FETCH), events });
    };

export const fetchGlobalEvents =
    ({ start, end }: { start: string; end: string }) =>
    async (dispatch) => {
        dispatch({ type: FETCH, query: 'global' });
        const events = await eventsFetch(start, end);
        const projectEvents = await projectEventsFetch(start, end);

        dispatch({
            type: asyncActionSuccess(FETCH),
            events: [
                ...events,
                ...projectEvents.filter((pe) => !events.find((e) => e.uuid === pe.uuid))
            ]
        });
    };

export const updateEvent =
    ({ uuid, ..._event }) =>
    async (dispatch) => {
        dispatch({ type: UPDATE });
        await eventUpdate(uuid, _event);
        dispatch({ type: asyncActionSuccess(UPDATE), event: { uuid, ..._event } });
    };

export const eventSocketActions = {
    sioEventUpsert: (event) => ({
        type: WS_EVENT_UPSERT,
        event
    }),
    sioEventDelete: (eventUuid) => ({
        type: WS_EVENT_DELETE,
        eventUuid
    }),
    sioEventDeleteUser: ({ eventUuid, userUuid }) => ({
        type: WS_EVENT_DELETE_USER,
        eventUuid,
        userUuid
    })
};
