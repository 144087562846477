import './MultiActionButtonList.scss';
import React, { useEffect, useRef } from 'react';
import { Button, ButtonProps, ButtonTone } from '../Button/Button';
import classNames from 'classnames';
import { DirectionX } from '../../../utils/getAbsolutePosition';
import { ActionType, MultiActionButton, MultiActionButtonProps } from './MultiActionButton';
import cn from 'classnames';

export type MultiActionsList = (ActionType | false | null | undefined)[];
export type MultiActionButtonListProps = {
    actions: MultiActionsList;
    onClickableClick?: (e: MouseEvent) => any;
    className?: string;
};

export const MultiActionButtonList: React.FC<MultiActionButtonListProps> = ({
    actions,
    onClickableClick,
    className
}) => {
    const ref = useRef<HTMLUListElement>(null);

    useEffect(() => {
        if (!ref.current) return;
        if (onClickableClick) {
            Array.from(ref.current.querySelectorAll('a, button')).forEach((c) =>
                c.addEventListener('click', onClickableClick)
            );
        }
        return () => {
            if (ref.current && onClickableClick) {
                Array.from(ref.current.querySelectorAll('a, button')).forEach((c) =>
                    c.removeEventListener('click', onClickableClick)
                );
            }
        };
    }, []);
    return (
        <ul className={cn('c-multi-action-button-list', className)} ref={ref}>
            {actions
                ?.filter((a) => a)
                .map((a: ActionType, i) => {
                    if (a.separator) return <li key={i} className="separator" aria-hidden={true} />;
                    return (
                        <li key={i}>
                            {a.actions ? (
                                <MultiActionButton
                                    onClickableClick={onClickableClick}
                                    icon="arrow-left"
                                    tone={ButtonTone.Plain}
                                    hoverable={true}
                                    {...(a as MultiActionButtonProps)}
                                    contentClassName={classNames(a.contentClassName, 'is-sub-menu')}
                                    className={classNames(
                                        a.className,
                                        'sub-menu',
                                        (a.direction?.[0] === DirectionX.Right ||
                                            a.direction?.[0] === DirectionX.RightInner) &&
                                            'rotate-icon-180'
                                    )}
                                />
                            ) : (
                                <Button tone={ButtonTone.Plain} {...a} />
                            )}
                        </li>
                    );
                })}
        </ul>
    );
};
