import { PluginConfigurationSchema } from '@he-novation/config/types/payloads/plugin.payload';
import { PluginConfigurationType } from '@he-novation/config/types/plugin.types';
import { pluginPaths } from '@he-novation/paths/herawApiPaths';
import { apiFetch } from './apiFetch';

export const asyncPluginConfigurationUpdate = (
    pluginUuid: string,
    body: PluginConfigurationSchema
): Promise<PluginConfigurationType> =>
    apiFetch(pluginPaths.specific, {
        params: {
            pluginUuid
        },
        method: 'PATCH',
        body
    });
