export const SIDEMENU = 'SIDEMENU';
export const OPEN_PANEL = `${SIDEMENU}/OPEN_PANEL`;
export const CLOSE_PANEL = `${SIDEMENU}/CLOSE_PANEL`;
export const TOGGLE_PANEL = `${SIDEMENU}/TOGGLE_PANEL`;

export const openPanel = (panel, payload) => ({
    type: OPEN_PANEL,
    panel,
    payload
});

export const closePanel = () => ({
    type: CLOSE_PANEL
});

export const togglePanel = (panel) => ({
    type: TOGGLE_PANEL,
    panel
});
