export const isFirefox = (userAgent: string) => userAgent.toLowerCase().indexOf('firefox') > -1;

export const isOpera = (userAgent: string) =>
    !isFirefox(userAgent) && userAgent.toLowerCase().indexOf('opera') > -1;

export const isIE = (userAgent: string) =>
    !isOpera(userAgent) && userAgent.toLowerCase().indexOf('trident') > -1;

export const isEdge = (userAgent: string) =>
    (!isIE(userAgent) && userAgent.toLowerCase().indexOf('edge') > -1) ||
    userAgent.toLowerCase().indexOf('edg/') > -1;

export const isChrome = (userAgent: string) =>
    !isEdge(userAgent) && userAgent.toLowerCase().indexOf('chrome') > -1;

export const isSafari = (userAgent: string) =>
    !isChrome(userAgent) && userAgent.toLowerCase().indexOf('safari') > -1;

export const FIREFOX = 'firefox';
export const OPERA = 'opera';
export const IE = 'ie';
export const EDGE = 'edge';
export const CHROME = 'chrome';
export const SAFARI = 'safari';

export const getBrowserString = (userAgent: string) => {
    if (isFirefox(userAgent)) return FIREFOX;
    if (isOpera(userAgent)) return OPERA;
    if (isIE(userAgent)) return IE;
    if (isEdge(userAgent)) return EDGE;
    if (isChrome(userAgent)) return CHROME;
    if (isSafari(userAgent)) return SAFARI;
    return 'unknown';
};
