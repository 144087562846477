import styles from './UploadTracker.module.css';
import React, { useState } from 'react';
import { ModalHeader } from '@he-novation/design-system/components/modals/ModalHeader/ModalHeader';
import { ProgressCircle } from '@he-novation/design-system/components/widgets/ProgressCircle/ProgressCircle';
import { __ } from '@he-novation/design-system/utils/i18n';
import Icon from '@he-novation/icons';
import { useUploader } from '../../../hooks/useUploader';
import { UploadTrackerList } from './UploadTrackerList/UploadTrackerList';

import { ModalWrapper } from '$components/modal/ModalWrapper/ModalWrapper';
import type { FinishedUpload, PendingUpload, Upload } from '$helpers/Uploader.types';

const computeProgress = (
    uploads: Upload[],
    pending: PendingUpload[],
    finished: FinishedUpload[]
) => {
    let uploaded = finished.reduce((n, { file }) => n + file.size, 0);
    let total = uploaded + pending.reduce((n, { file }) => n + file.size, 0);
    for (const { progression } of uploads) {
        total += progression.total;
        uploaded += progression.loaded;
    }

    return { total, uploaded };
};

export function UploadTracker() {
    const [displayModal, setDisplayModal] = useState(false);
    const { uploads, pending, finished, errors } = useUploader(1000);
    const { total, uploaded } = computeProgress(uploads, pending, finished);

    if (!total && !finished.length && !errors.length) return null;
    return (
        <>
            <ProgressCircle
                className={styles.tracker}
                total={total}
                value={uploaded}
                color="white"
                backgroundColor="#2e333d"
                ringBackgroundColor="black"
                onClick={() => setDisplayModal(true)}
            >
                <Icon icon="export" />
            </ProgressCircle>
            {displayModal && (
                <ModalWrapper
                    isVisible
                    isModalVisible
                    modalClassName={styles.modal}
                    onClickOutside={() => setDisplayModal(false)}
                >
                    <ModalHeader
                        title={__('UPLOADS_IN_PROGRESS')}
                        close={() => setDisplayModal(false)}
                    />
                    <UploadTrackerList
                        uploads={uploads}
                        pending={pending}
                        finished={finished}
                        errors={errors}
                        onLinkClick={() => setDisplayModal(false)}
                    />
                </ModalWrapper>
            )}
        </>
    );
}
