import './Button.scss';
import { JSX, SyntheticEvent } from 'react';
import React, { ComponentType, ReactNode, RefObject } from 'react';
import classNames from 'classnames';
import { SizedIcon } from '@he-novation/icons';
import { RouterLink } from '../../../init';
import type { IconSize } from '../../../enums';
import { Theme, WidgetTone } from '../../../enums';
import { Loader, LoaderSize } from '../../widgets/Loader/Loader';
import { Tooltip } from '../../widgets/Tooltip/Tooltip';
import { Direction } from '../../../utils/getAbsolutePosition';

export enum ButtonTone {
    Primary = 'primary',
    Outlined = 'outlined',
    Light = 'light',
    Medium = 'medium',
    Alert = 'alert',
    Hoverable = 'hoverable',
    Plain = 'plain',
    Neutral = 'neutral',
    FormField = 'form-field-b',
    Dark = 'dark'
}

export type ButtonProps = {
    className?: string | false | null;
    icon?: string | SVGElement;
    img?: string;
    iconSize?: IconSize;
    onClick?: (e: SyntheticEvent) => void;
    number?: number;
    numberStyle?: { [k: string]: any };
    style?: { [p: string]: string };
    tagName?: ComponentType | keyof JSX.IntrinsicElements;
    tone?: ButtonTone;
    external?: boolean;
    theme?: Theme;
    isLoading?: boolean;
    [a: string]: any;
    tooltip?: string;
    tooltipOptions?: {
        direction?: Direction;
        hasArrow?: boolean;
        confirm?: () => void;
        tone?: WidgetTone;
    };
    children?: ReactNode | ReactNode[];
    iconAfter?: boolean;
    disabled?: boolean;
};

export const Button = React.forwardRef(
    (
        {
            children,
            tagName: Component,
            className,
            icon,
            iconSize,
            img,
            number,
            numberStyle,
            onClick,
            style,
            theme = Theme.Dark,
            tone,
            isLoading,
            external,
            tooltip,
            tooltipOptions,
            iconAfter,
            disabled,
            ...rest
        }: ButtonProps,
        ref: RefObject<any>
    ) => {
        if (!Component) {
            // @ts-ignore
            Component = rest.href
                ? external || rest.target === '_blank'
                    ? 'a'
                    : RouterLink
                : 'button';
        }

        const buttonProps = {
            ref,
            className: classNames(
                'c-btn',
                tone,
                theme,
                className,
                isLoading && 'is-loading',
                iconAfter && 'icon-after',
                disabled && 'disabled'
            ),
            onClick,
            style,
            disabled: Component === 'button' ? disabled : undefined,
            ...rest
        };

        const content = (
            <>
                {isLoading && <Loader size={LoaderSize.Small} theme={theme} />}
                {img && <img src={img} alt="" />}
                {icon && typeof icon === 'string' ? (
                    <SizedIcon icon={icon} size={iconSize} />
                ) : (
                    icon
                )}
                {children && <span>{children}</span>}
                {typeof number !== 'undefined' && (
                    <span className="number" style={numberStyle}>
                        {number}
                    </span>
                )}
            </>
        );

        return tooltip ? (
            <Tooltip tagName={Component} content={tooltip} {...buttonProps} {...tooltipOptions}>
                {content}
            </Tooltip>
        ) : (
            // @ts-ignore
            <Component {...buttonProps}>{content}</Component>
        );
    }
);
