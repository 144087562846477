import { BaseCommentLogPayload } from './log.payload';

export enum CommentLogKey {
    CommentAdd = 'comment_add',
    CommentDelete = 'comment_delete',
    CommentEdit = 'comment_edit'
}
export interface LogCommentAddPayload extends BaseCommentLogPayload {
    key: CommentLogKey.CommentAdd;
}
export interface LogCommentDeletePayload extends BaseCommentLogPayload {
    key: CommentLogKey.CommentDelete;
}
export interface LogCommentEditPayload extends BaseCommentLogPayload {
    key: CommentLogKey.CommentEdit;
}

export type LogCommentPayload =
    | LogCommentAddPayload
    | LogCommentDeletePayload
    | LogCommentEditPayload;
