import React, { SyntheticEvent, useState } from 'react';
import { ModularForm } from 'react-modular-forms';
import { Client } from '@he-novation/config/types/client.types';
import { ClientBody } from '@he-novation/config/types/payloads/client.payload';
import { plugins } from '@he-novation/config/utils/plugins';
import { BottomButtons } from '@he-novation/design-system/components/buttons/BottomButtons/BottomButtons';
import { ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { FormFields } from '@he-novation/design-system/components/form/FormField/FormField';
import { Error } from '@he-novation/design-system/components/text/Error/Error';
import { Theme } from '@he-novation/design-system/enums';
import { __ } from '@he-novation/design-system/utils/i18n';

type FormClientProps = {
    cancel: () => void;
    data?: Client;
    plugins: string[];
    theme: Theme;
    submit: (e: SyntheticEvent, data: ClientBody) => void;
};

export const FormClient = ({ cancel, data, submit, theme }: FormClientProps) => {
    const [error, setError] = useState<string | null>(null);

    const id = 'form-client';

    return (
        <ModularForm
            id={id}
            onSubmit={(e, formData: ClientBody & { password_new_repeat?: string }) => {
                if (formData.password !== formData.password_new_repeat) {
                    e.preventDefault();
                    setError(__('PASSWORDS_NOT_MATCHING'));
                    return;
                }

                submit(e, formData);
            }}
        >
            <FormFields
                formId={id}
                theme={theme}
                fields={[
                    {
                        type: 'text',
                        name: 'name',
                        label: __('NAME'),
                        id: `${id}-name`,
                        disabled: data?.name,
                        value: data?.name
                    },
                    {
                        type: 'email',
                        name: 'email',
                        label: __('EMAIL'),
                        id: `${id}-email`,
                        value: data?.profile?.email
                    },
                    {
                        type: 'text',
                        name: 'firstname',
                        label: __('FIRSTNAME'),
                        id: `${id}-firstname`,
                        value: data?.profile?.firstname
                    },
                    {
                        type: 'text',
                        name: 'lastname',
                        label: __('LASTNAME'),
                        id: `${id}-lastname`,
                        value: data?.profile?.lastname
                    },
                    {
                        type: 'number',
                        name: 'storage',
                        coerceType: 'int',
                        id: `${id}-storage`,
                        label: __('STORAGE'),
                        value: data?.storage.total
                    },
                    {
                        type: 'number',
                        name: 'licenses',
                        label: __('LICENSES'),
                        coerceType: 'int',
                        id: `${id}-licenses`,
                        value: data?.licenses
                    },
                    {
                        type: 'password',
                        name: 'password',
                        label: __('PASSWORD'),
                        id: `${id}-password_new`
                    },
                    {
                        type: 'password',
                        name: 'password_new_repeat',
                        label: __('PASSWORD_REPEAT'),
                        id: `${id}-password_new_repeat`
                    },
                    {
                        type: 'checkbox',
                        className: 'is-switch',
                        name: 'stripPayment',
                        label: __('STRIPE_PAYMENT'),
                        id: `${id}-enable_stripe_payment`,
                        checked: data?.enable_stripe_payment
                    },
                    {
                        type: 'react-select',
                        isMulti: true,
                        name: 'plugins',
                        label: __('PLUGINS'),
                        coerceType: 'array',
                        options: plugins.map((plugin) => ({ label: plugin, value: plugin })),
                        value: data?.plugins
                    }
                ]}
            />
            {error && <Error error={error} />}

            <BottomButtons
                buttons={[
                    {
                        children: __('CANCEL'),
                        tone: ButtonTone.Outlined,
                        theme: Theme.Light,
                        onClick: cancel
                    },
                    {
                        children: __('CONFIRM'),
                        tone: ButtonTone.Primary
                    }
                ]}
            />
        </ModularForm>
    );
};
