import {
    CSSProperties,
    ElementType,
    MutableRefObject,
    ReactNode,
    useEffect,
    useRef,
    useState
} from 'react';
import React from 'react';
import {
    Direction,
    DirectionX,
    DirectionY,
    getAbsolutePosition
} from '../../../utils/getAbsolutePosition';
import { Theme } from '../../../enums';
import { AbsoluteContent } from './AbsoluteContent';
import cn from 'classnames';
import useWindowSize from '../../../hooks/useWindowSize';

export type AbsoluteContentButtonProps = {
    autoClose?: number;
    className?: string;
    closeOnClickOutside?: boolean;
    closeOnTriggerClick?: boolean;
    closeOnContentClick?: boolean;
    content: ReactNode | ((hide: Function) => ReactNode);
    contentClassName?: string;
    contentSizedByTrigger?: boolean;
    delay?: number;
    direction?: Direction;
    tagName?: ElementType;
    triggersOnHover?: boolean;
    triggersOnClick?: boolean;
    controlRef?: MutableRefObject<{ setIsVisible: (isVisible: boolean) => void } | undefined>;
    capture?: boolean;
    isVisible?: boolean;
    style?: any;
    [p: string]: any;
    children?: ReactNode | ReactNode[];
};

export const AbsoluteContentButton: React.FC<AbsoluteContentButtonProps> = ({
    autoClose,
    className,
    children,
    closeOnClickOutside,
    closeOnTriggerClick = true,
    closeOnContentClick,
    content,
    contentClassName,
    contentSizedByTrigger,
    delay = 0,
    direction = [DirectionX.LeftInner, DirectionY.Top],
    tagName: Component = 'div',
    theme = Theme.Dark,
    triggersOnHover = true,
    triggersOnClick = false,
    style: _style,
    capture = true,
    controlRef,
    onClick,
    ...rest
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const [style, setStyle] = useState<CSSProperties>({});
    const triggerRef = useRef<HTMLElement>();
    const absoluteRef = useRef<HTMLElement>();
    const isVisibleRef = useRef<boolean>(false);
    const timeout = useRef<any>();
    const size = useWindowSize();

    useEffect(() => {
        isVisibleRef.current = isVisible;
        if (isVisible) {
            setStyle(
                getAbsolutePosition(triggerRef, absoluteRef, direction, contentSizedByTrigger)
            );
        }
    }, [isVisible, size]);

    useEffect(() => {
        if (controlRef) {
            controlRef.current = { setIsVisible };
        }

        const unmountActions = [() => clearTimeout(timeout.current)];

        if (triggerRef.current) {
            if (triggersOnHover) {
                const onMouseOver = () => {
                    clearTimeout(timeout.current);
                    setIsVisible(true);
                };
                triggerRef.current.addEventListener('mouseover', onMouseOver);
                unmountActions.push(() =>
                    triggerRef.current?.removeEventListener('mouseover', onMouseOver)
                );
                const onMouseOut = () => {
                    timeout.current = setTimeout(() => setIsVisible(false), delay);
                };
                triggerRef.current.addEventListener('mouseout', onMouseOut);
                unmountActions.push(() =>
                    triggerRef.current?.removeEventListener('mouseout', onMouseOut)
                );
            }
            if (triggersOnClick || onClick) {
                const onTriggerClick = (e) => {
                    onClick?.(e);
                    if ((triggersOnClick && !isVisibleRef.current) || closeOnTriggerClick)
                        setIsVisible(!isVisibleRef.current);
                };

                triggerRef.current.addEventListener('click', onTriggerClick);
                unmountActions.push(() =>
                    triggerRef.current?.removeEventListener('click', onTriggerClick)
                );
            }
        }

        return () => {
            unmountActions.forEach((a) => a());
        };
    }, []);
    return (
        <>
            <Component
                ref={triggerRef}
                className={cn(className, isVisible && 'is-visible')}
                style={_style}
                {...rest}
            >
                {children}
            </Component>
            {isVisible && (
                <AbsoluteContent
                    capture={capture}
                    autoClose={autoClose}
                    absoluteRef={absoluteRef}
                    triggerRef={triggerRef}
                    closeOnContentClick={closeOnContentClick}
                    closeOnClickOutside={closeOnClickOutside}
                    content={content}
                    contentClassName={contentClassName}
                    direction={direction}
                    style={style}
                    triggersOnHover={triggersOnHover}
                    hide={() => {
                        timeout.current = setTimeout(() => setIsVisible(false), delay);
                    }}
                    show={() => {
                        clearTimeout(timeout.current);
                        setIsVisible(true);
                    }}
                />
            )}
        </>
    );
};
