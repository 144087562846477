import { FileState } from '@he-novation/config/types/file.types';
import { Subtitle } from '@he-novation/config/types/subtitle.types';
import { PUBLIC_FILE } from '@he-novation/paths/modals.constants';
import update from 'immutability-helper';
import { OPEN_MODAL, SET } from '../../route/routeActions';
import { CLOSE_PANEL, OPEN_PANEL } from '../../sideMenu/sideMenuActions';
import { FETCH_FILE_VIEW, PUBLIC_FILE_VIEW_FETCH } from '../contentActions';
import {
    FILE_SET_ACTIVE_ASSET,
    HIGHLIGHT_VERSION,
    IFRAME_CAPTURE_SET,
    PAUSE,
    PLAY,
    PLAYLIST_FILTERS_SET,
    SET_ACTIVE_AUDIO_TRACK,
    SET_ACTIVE_SUBTITLES,
    SET_FINAL,
    SET_PAGE,
    SET_WIDTH_HEIGHT,
    SUBTITLES_FETCH,
    SUBTITLES_GENERATE,
    SUBTITLES_SET_TIMESTAMP,
    SUBTITLES_TOGGLE,
    TOGGLE_PLAYLIST,
    WS_SUBTITLES_CREATE,
    WS_SUBTITLES_READY,
    WS_SUBTITLES_UPDATE
} from './fileActions';

import { CREATE_NOTE_PANEL, CREATE_TASK_PANEL, NOTE_PANEL } from '$constants/constants.sidePanels';
import { asyncActionError, asyncActionSuccess } from '$helpers/asyncAction';
import {
    WS_FILE_VERSION_DELETE,
    WS_FILE_VERSION_RESTORE,
    WS_SET_FOLDER_FILE
} from '$redux/content/folder/folderActions';

export const fileInitialState = {
    activeSubtitles: [],
    isLoaded: false,
    comparison: null,
    isPlaying: true,
    playlistFilters: {
        types: []
    },
    subtitlesTimeStamp: new Date().getTime(),
    error: null,
    folder: null,
    isPlayingPlaylist: false,
    subtitles: [],
    assets: undefined
};

export default (state: FileState = fileInitialState, action: any = {}): FileState => {
    switch (action.type) {
        case asyncActionSuccess(FETCH_FILE_VIEW):
        case asyncActionSuccess(PUBLIC_FILE_VIEW_FETCH):
            return update(state, {
                isLoaded: { $set: true },
                $merge: {
                    ...action.fileState,
                    activeAsset: action.fileState.assets.find(
                        (a) => a.type === 'player' && a.version === action.fileState.version
                    )
                }
            });

        case asyncActionError(FETCH_FILE_VIEW):
            return update(state, { isLoaded: { $set: true }, error: { $set: action.error } });

        case FILE_SET_ACTIVE_ASSET:
            return update(state, {
                activeAsset: { $set: action.asset }
            });

        case IFRAME_CAPTURE_SET:
            return update(state, {
                iframeCapture: { $set: action.iframeCapture }
            });

        case OPEN_PANEL:
        case CLOSE_PANEL:
            if (
                action.type === CLOSE_PANEL ||
                [CREATE_NOTE_PANEL, NOTE_PANEL, CREATE_TASK_PANEL].indexOf(action.panel) === -1
            )
                return update(state, {
                    iframeCapture: { $set: null }
                });
            break;

        case SET:
        case OPEN_MODAL:
            if (action.type === OPEN_MODAL && action.modal !== PUBLIC_FILE) return state;
            return update(state, {
                $set: {
                    ...fileInitialState,
                    timecodestart: undefined,
                    isPlayingPlaylist:
                        action.route &&
                        /^\/?(player|sharedplayer)\/|^\/cast\/[a-zA-Z\d]*\/file\//.test(
                            action.route
                        )
                            ? state.isPlayingPlaylist
                            : false
                }
            });

        case TOGGLE_PLAYLIST:
            return update(state, {
                isPlayingPlaylist: {
                    $set:
                        typeof action.isPlayingPlaylist === 'undefined'
                            ? !state.isPlayingPlaylist
                            : action.isPlayingPlaylist
                }
            });
        case SET_PAGE:
            return update(state, {
                page: { $set: action.page }
            });
        case HIGHLIGHT_VERSION:
            return update(state, {
                highlightedVersion: { $set: action.highlightedVersion }
            });

        case PLAY:
            return update(state, {
                isPlaying: { $set: true }
            });
        case PLAYLIST_FILTERS_SET:
            return update(state, {
                playlistFilters: { $set: action.playlistFilters }
            });

        case PAUSE:
            return update(state, {
                isPlaying: { $set: false }
            });

        case SET_ACTIVE_AUDIO_TRACK:
            return update(state, {
                activeAudioTrack: { $set: action.activeAudioTrack }
            });

        case SET_ACTIVE_SUBTITLES:
            return update(state, {
                activeSubtitles: { $set: action.activeSubtitles }
            });

        case asyncActionSuccess(SUBTITLES_FETCH):
            return update(state, {
                subtitles: {
                    $set: action.subtitles
                }
            });

        case asyncActionSuccess(SET_FINAL):
            if (state.uuid !== action.uuid) return state;
            return update(state, {
                versionLocked: { $set: action.final }
            });

        case SUBTITLES_SET_TIMESTAMP:
            return update(state, {
                subtitlesTimeStamp: {
                    $set: action.subtitlesTimeStamp
                }
            });

        case SET_WIDTH_HEIGHT:
            return update(state, {
                width: { $set: action.width },
                height: { $set: action.height }
            });

        case SUBTITLES_TOGGLE:
            return update(state, {
                activeSubtitles: {
                    $apply: (activeSubtitles) =>
                        activeSubtitles.indexOf(action.uuid) > -1
                            ? activeSubtitles.filter((l) => l !== action.uuid)
                            : [...activeSubtitles, action.uuid]
                }
            });

        case asyncActionSuccess(SUBTITLES_GENERATE):
            return update(state, {
                subtitles: {
                    $push: action.subtitles
                }
            });

        case WS_FILE_VERSION_DELETE:
            if (!state.assets) return state;
            return update(state, {
                assets: {
                    $apply: (assets) =>
                        assets.map((a) =>
                            a.version === action.deletedVersion ? { ...a, expires: new Date() } : a
                        )
                },
                status: {
                    $set: action.status
                }
            });
        case WS_FILE_VERSION_RESTORE:
            if (!state.assets) return state;
            return update(state, {
                assets: {
                    $apply: (assets) =>
                        assets.map((a) =>
                            a.version === action.restoredVersion ? { ...a, expires: null } : a
                        )
                }
            });
        case WS_SET_FOLDER_FILE:
            return update(state, {
                status: { $set: action.file.status },
                finals: { $set: action.file.finals }
            });
        case WS_SUBTITLES_CREATE: {
            const subtitle: Subtitle = action.subtitle;
            if (
                state.uuid !== subtitle.fileUuid ||
                state.subtitles.find((s) => s.uuid === subtitle.uuid)
            ) {
                return state;
            }
            return update(state, {
                subtitles: {
                    $push: [action.subtitle]
                }
            });
        }

        case WS_SUBTITLES_UPDATE: {
            return update(state, {
                subtitles: {
                    [state.subtitles.findIndex(({ uuid }) => uuid === action.subtitle.uuid)]: {
                        $set: action.subtitle
                    }
                },
                assets: {
                    [state.assets!.findIndex(({ uuid }) => uuid === action.subtitle.assetUuid)]: {
                        $apply: (asset) => {
                            return { ...asset, updated: new Date() };
                        }
                    }
                }
            });
        }

        case WS_SUBTITLES_READY:
            if (action.fileUuid === state.uuid && action.fileVersion === state.highlightedVersion) {
                const i = state.subtitles.findIndex((s) => s.uuid === action.subtitle.uuid);
                return update(
                    state,
                    i > -1
                        ? {
                              subtitles: { [i]: { $merge: action.subtitle } }
                          }
                        : {
                              subtitles: {
                                  $push: [action.subtitle]
                              }
                          }
                );
            }
            break;
    }

    return state;
};
