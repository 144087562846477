import React from 'react';
import { Subtitle } from '@he-novation/config/types/subtitle.types';
import { Tooltip } from '@he-novation/design-system/components/widgets/Tooltip/Tooltip';
import { __ } from '@he-novation/design-system/utils/i18n';
import Icon from '@he-novation/icons';

import { subtitleTypesToData } from '$constants/constants.subtitles';
import { LanguagesAndCountryCode } from '$types/localeTypes';

type SubtitleLabelProps = {
    language: string;
    subtitles?: Subtitle[];
    languagesAndCountryCodes: LanguagesAndCountryCode[];
};

export function SubtitleLabel({
    language,
    subtitles = [],
    languagesAndCountryCodes
}: SubtitleLabelProps) {
    let languagesAndCountryCode = languagesAndCountryCodes.find((l) => l.value === language);
    if (!languagesAndCountryCode) {
        const lang = language.replace(/-[a-z]{2}$/, '');
        languagesAndCountryCode = languagesAndCountryCodes.find((l) => l.languageCode === lang);
    }
    let matches = subtitles.filter((s) => s.locale === language);
    matches = matches.filter((f, i) => i === matches.findIndex((_f) => f.locale === _f.locale));
    return (
        <>
            <span className="locale-label">{languagesAndCountryCode?.text || language}</span>
            {matches.map((m) => (
                <Tooltip key={m.uuid} content={__(subtitleTypesToData[m.type].tooltip)}>
                    <Icon icon={subtitleTypesToData[m.type].icon} />
                </Tooltip>
            ))}
        </>
    );
}
