import { BaseProjectLogPayload } from './log.payload';

export enum ProjectLogKey {
    ProjectAdd = 'project_add',
    ProjectDelete = 'project_delete',
    ProjectEdit = 'project_edit'
}

export interface LogProjectAddPayload extends BaseProjectLogPayload {
    key: ProjectLogKey.ProjectAdd;
}
export interface LogProjectDeletePayload extends BaseProjectLogPayload {
    key: ProjectLogKey.ProjectDelete;
}

export type ProjectChanges = {
    name?: [string, string];
    startDate?: [string | null, string | null];
    endDate?: [string | null, string | null];
    status?: [string, string];
};
export interface LogProjectEditPayload extends BaseProjectLogPayload {
    key: ProjectLogKey.ProjectEdit;
    changes: ProjectChanges;
}

export type LogProjectPayload =
    | LogProjectAddPayload
    | LogProjectDeletePayload
    | LogProjectEditPayload;
