import { pathWithParams, subtitlesPaths } from './herawApiPaths';
export { pathWithParams, subtitlesPaths } from './herawApiPaths';
export const doubleDigit = (number: number) => (number < 10 ? '0' + number : number);
export const PUBLIC_FILE = '/public-file';

export const ACTIVITY = '/activity';

export const ADMIN = '/admin';
export const ADMIN_CLIENTS = '/admin/clients';
export const ADMIN_WORDING = '/admin/wording';
export const CAST_FOLDER = '/cast/:castUid/?castFolderUuid';
export const CAST_FILE = '/cast/:castUid/file/:castFileUuid/:version';
export const CAST = '/casts/:castUid';
export const CASTS = '/casts';
export const CHAT_ROOM = '/chat/room/:roomUuid';
export const CLIENT_DASHBOARD = '/client';
export const CLIENT_GUESTS = '/client/guests';
export const CLIENT_PROJECTS = '/client/projects';
export const CLIENT_SETTINGS = '/client/settings/?route';
export const CLIENT_USERS = '/client/users';
export const CONTACTS = '/directory/contacts';
export const CONTACT_GROUP = '/directory/contact-group/:uuid';
export const DIRECTORY = '/directory/?route/?subroute';
export const DIRECTORY_COMPANIES = '/directory/companies';
export const DIRECTORY_CONTACTS = '/directory/contacts';
export const DIRECTORY_ITEMS = '/directory/items';
export const DIRECTORY_ITEM_KIT = '/directory/item-kit/:uuid';
export const FILE = '/player/:fileUuid/v/:fileVersion/?extra';
export const FILE_COMPARE = '/player/:fileUuid/v/:fileVersion/compare/:comparisonVersion';
export const HOME = '';
export const SHARED_FILE = '/sharedplayer/:fileUuid/v/:fileVersion/?extra';
export const SHARED_FILE_COMPARE =
    '/sharedplayer/:fileUuid/v/:fileVersion/compare/:comparisonVersion';
export const FOLDER = '/folders/?folderUuid';
export const FOLDER_SHARED = '/shared/?folderUuid';
export const FOLDER_PUBLIC = '/public/:folderUuid/:userUuid';
export const SHARED_FOLDER = '/shared/:folderUuid';
export const PLAYER = '/player/:fileUuid/v/:fileVersion';
export const SHARED_PLAYER = '/sharedplayer/:fileUuid/v/:fileVersion';
export const PDF_SUMMARY = '/summary/:fileUuid/v/:fileVersion';
export const AVID_SUMMARY = '/summary_avid/:fileUuid/v/:fileVersion';
export const PLANNING = '/planning/?route';
export const PROJECTS = '/projects/?route';
export const PROJECT = '/project/:uuid/?route';
export const PROJECT_CASTS = '/project/:uuid/casts';
export const PROJECT_CAST = '/project/:uuid/cast/:castUid';
export const PROJECT_FOLDERS = '/project/:uuid/folders/?folderUuid';
export const PROJECT_STATS = '/project/:uuid/stats';
export const PROJECT_ACTIVITY = '/project/:uuid/activity';
export const PROJECT_PLANNING = '/project/:uuid/planning';
export const PROJECT_TASKS = '/project/:uuid/tasks';
export const PUBLIC_FOLDER = '/public/:folderUuid/:userUuid';
export const RIGHTS = '/:locale/rights';
export const SETTINGS = '/settings/?route';
export const OFFICE = 'https://view.officeapps.live.com/op/embed.aspx?';
export const OFFICE_FILE =
    'src=:baseUrl/proxy/office/:accessToken/:requestToken/:fileUuid/:fileVersion/:fileName';
export const SEARCH = '/search/?query';
export const SIGNUP = '/sign-up';
export const TRASH = '/trash/?folderUuid';

const uriWithParams = (
    uri: string,
    params: Record<string, string | number | undefined>,
    queryParams?: Record<string, string | number | undefined>
) =>
    uri
        .replace(/\/\?([a-zA-Z]+)/g, (full, g1) => (params[g1] ? '/' + params[g1] : '') as string)
        .replace(/:([a-zA-Z]+)/g, (full, g1) => params[g1]?.toString() as string) +
    (queryParams && Object.keys(queryParams).length
        ? '?' +
          Object.keys(queryParams)
              .map((key) =>
                  typeof queryParams[key] !== 'undefined' ? `${key}=${queryParams[key]}` : ''
              )
              .join('&')
        : '');

const dateToStartQueryParams = (date?: Date, event?: string) => {
    const queryParams: any = {};
    if (date) {
        queryParams.start = `${date.getFullYear()}-${doubleDigit(
            date.getMonth() + 1
        )}-${doubleDigit(date.getDate())}`;
    }
    if (event) queryParams.event = event;
    return queryParams;
};

export const heraw = () => 'https://heraw.com';
export const homeLink = () => '/';

export const activityLink = () => ACTIVITY;

export const adminLink = () => ADMIN;
export const adminClientsLink = () => ADMIN_CLIENTS;
export const adminWordingLink = () => ADMIN_WORDING;

export const castsLink = () => CASTS;

export const castLink = (castUid: string) => uriWithParams(CAST, { castUid });

export const chatRoom = (roomUuid: string) => uriWithParams(CHAT_ROOM, { roomUuid });

export const clientDashboardLink = () => CLIENT_DASHBOARD;
export const clientGuestsLink = () => CLIENT_GUESTS;

export const clientProjectslink = () => CLIENT_PROJECTS;

export const clientSettingsLink = (route?: string) => uriWithParams(CLIENT_SETTINGS, { route });

export const clientUsersLink = () => CLIENT_USERS;

export const contactsLink = () => DIRECTORY_CONTACTS;

export const contactGroupLink = (uuid: string) => uriWithParams(CONTACT_GROUP, { uuid });

export const demoLink = () => '/demo';

export const directoryLink = () => '/directory';

export const directoryCompaniesLink = () => '/directory/companies';

export const directoryContactsLink = () => '/directory/contacts';

export const directoryItemsLink = () => '/directory/items';

export const directoryItemKitLink = (uuid: string) => uriWithParams(DIRECTORY_ITEM_KIT, { uuid });

export const folderLink = (folderUuid?: string) => uriWithParams(FOLDER, { folderUuid });

export const folderPublicLink = (folderUuid: string, userUuid: string) =>
    uriWithParams(FOLDER_PUBLIC, { folderUuid, userUuid });

export const castFileLink = (castUid: string, castFileUuid: string, version: string | number) =>
    uriWithParams(CAST_FILE, { castUid, castFileUuid, version });

export const castFolderLink = (castUid: string, castFolderUuid?: string) =>
    uriWithParams(CAST_FOLDER, { castUid, castFolderUuid });

export const indexLink = () => '/';

/**
 *
 * @param {Object} p
 * @param {string} [p.locale]
 * @param {string} [p.email]
 * @returns {string}
 */
export const loginLink = (p: { locale?: string; email?: string } = {}) =>
    `${process.env.MARKETING_URL}${p.locale ? `/${p.locale}` : ''}/login${
        p.email ? `?email=${p.email}` : ''
    }`;

export const passwordResetLink = () => '/password-reset';

export const planningLink = (date?: Date, eventUuid?: string) =>
    uriWithParams(PLANNING, {}, dateToStartQueryParams(date, eventUuid));
export const planningItemsLink = (date?: Date) =>
    uriWithParams(PLANNING, { route: 'items' }, dateToStartQueryParams(date));
export const planningGlobalLink = (date?: Date) =>
    uriWithParams(PLANNING, { route: 'global' }, dateToStartQueryParams(date));

export const playerLink = (
    fileUuid: string,
    fileVersion: number | string,
    { noteUuid, sidePanel }: { noteUuid?: string; sidePanel?: string } = {}
) =>
    uriWithParams(
        FILE,
        { fileUuid, fileVersion },
        {
            filters: noteUuid ? encodeURIComponent(JSON.stringify({ noteUuid })) : undefined,
            sidePanel
        }
    );

export const playerPublicLink = (
    folderUuid: string,
    userUuid: string,
    fileUuid: string,
    fileVersion: number
) =>
    uriWithParams(
        FOLDER_PUBLIC,
        { folderUuid, userUuid },
        { modal: PUBLIC_FILE, uuid: fileUuid, v: fileVersion }
    );

export const playerCompareLink = (
    comparisonVersion: number,
    fileUuid: string,
    fileVersion: number,
    isShared?: boolean
) =>
    isShared
        ? uriWithParams(SHARED_FILE_COMPARE, {
              fileUuid,
              fileVersion,
              comparisonVersion
          })
        : uriWithParams(FILE_COMPARE, { fileUuid, fileVersion, comparisonVersion });

export const pdfSummaryLink = (fileUuid: string, fileVersion: number) =>
    uriWithParams(PDF_SUMMARY, { fileUuid, fileVersion });

export const avidSummaryLink = (fileUuid: string, fileVersion: number) =>
    uriWithParams(AVID_SUMMARY, { fileUuid, fileVersion });
export const officeLink = (assetSignedUrl: string) =>
    OFFICE + 'src=' + encodeURIComponent(assetSignedUrl);

export const projectsLink = () => PROJECTS.replace('/?route', '');
export const projectsTasksLink = () => PROJECTS.replace('/?route', '/tasks');

export const projectsDashboardLink = () => PROJECTS.replace('/?route', '/dashboard');

export const projectStatsLink = (uuid: string) => uriWithParams(PROJECT_STATS, { uuid });
export const projectCastLink = (uuid: string, castUid: string) =>
    uriWithParams(PROJECT_CAST, { uuid, castUid });
export const projectActivityLink = (uuid: string) => uriWithParams(PROJECT_ACTIVITY, { uuid });
export const projectPlanningLink = (uuid: string, date?: string) =>
    uriWithParams(PROJECT_PLANNING, { uuid }, { start: date });
export const projectCastsLink = (uuid: string) => uriWithParams(PROJECT_CASTS, { uuid });
export const projectFoldersLink = (uuid: string, folderUuid?: string) =>
    uriWithParams(PROJECT_FOLDERS, { uuid, folderUuid });
export const projectTasksLink = (uuid: string, task?: string) =>
    uriWithParams(PROJECT_TASKS, { uuid }, { task });
export const rightsLink = (locale: string) =>
    process.env.MARKETING_URL + uriWithParams(RIGHTS, { locale });
export const settingsLink = (route?: string) => uriWithParams(SETTINGS, { route });

export const sharedRootLink = () => '/shared';
export const signupLink = () => SIGNUP;
export const subtitlesFileLink = (
    subtitleUuid: string,
    format: string,
    frameRate: number | string
) =>
    process.env.API_URL +
    pathWithParams(
        subtitlesPaths.specific,
        { subtitleUuid },
        { format, frameRate: encodeURIComponent(frameRate) }
    );
export const trashLink = (folderUuid?: string) => uriWithParams(TRASH, { folderUuid });
