import { Asset } from '@he-novation/config/types/asset.types';
import { compose, find, map, pipe, property } from 'lodash/fp';

const findSD: (assets: Asset[]) => Asset | undefined = find(
    ({ quality, type }) => type === 'player' && quality === 'sd'
);
const getSD: (asset: Asset[]) => string | undefined = pipe(findSD, property('url'));

const getSizeSD = pipe(
    find(({ quality, type }) => type === 'player' && quality === 'sd'),
    property('exif_result.ImageSize'),
    (s = '') => s.replace(' ', 'x')
);

const getSizeHD = pipe(
    find(({ quality, type }) => type === 'player' && quality === 'hd'),
    property('exif_result.ImageSize'),
    (s = '') => s.replace(' ', 'x')
);
const getBitRateHD = pipe(
    find(({ quality, type }) => type === 'player' && quality === 'hd'),
    property('ffprobe_result.BitRate'),
    (bitRate) => Math.round(parseInt(bitRate) / 1000) + 'Mbits'
);

const getBitRateSD = pipe(
    find(({ quality, type }) => type === 'player' && quality === 'sd'),
    property('ffprobe_result.BitRate'),
    (bitRate) => Math.round(parseInt(bitRate) / 1000) + 'Mbits'
);

const findHD: (assets: Asset[]) => Asset | undefined = find(
    ({ quality, type }) => type === 'player' && quality === 'hd'
);

const findThumbnail: (assets: Asset[]) => string | undefined = pipe(
    find(({ type }) => type === 'thumbnail'),
    property('url')
);

const getHD = pipe(findHD, property('url'));

const getPreviewUrl = compose(
    property('url'),
    find((asset) => property('quality')(asset) === 'preview')
);

const getAudioTracks = pipe(findSD, property('ffprobe_result.AudioStreams'), map(property('Tags')));

const videoResolutions = {
    '720x480': '480p',
    '1280x720': '720p',
    '1920x1080': '1080p',
    '3840x2160': '4K',
    '7680x4320': '8K'
};
const mapFetchAssetsResponseToPDF = (assets) => ({
    sourceSD: null,
    sourceHD: null,
    sizeHD: getSizeHD(assets),
    sizeSD: getSizeSD(assets),
    pages: assets
        .filter(({ type, quality }) => type === 'player' && quality === 'sd')
        .map(({ collection_id: id, url }) => ({
            id,
            sourceSD: url,
            sourceHD: (
                assets.find(
                    ({ collection_id, quality }) => collection_id === id && quality === 'hd'
                ) || {}
            ).url,
            sizeHD: getSizeHD(assets),
            sizeSD: getSizeSD(assets)
        })),
    version: assets[0].version
});

const parseFfprobeDuration = (tc) => {
    const [h, m, s, ms] = tc.split(/[:.]/).map((v) => parseInt(v));
    return s + m * 60 + h * 60 * 60 + parseFloat(`0.${ms}`);
};

const mapFetchAssetsResponseToVideo = (assets: Asset[], encrypted: boolean) => {
    const sizeHD = getSizeHD(assets);
    const sizeSD = getSizeSD(assets);
    const bitRateHD = getBitRateHD(assets);
    const bitRateSD = getBitRateSD(assets);

    let videoSize = [1920, 1080];
    let vs;
    let duration = null;

    try {
        const sd = findSD(assets);
        if (sd?.ffprobeResult) {
            vs = encrypted
                ? encrypted
                : sd.ffprobeResult.VideoSize?.split('x').map((v) => parseInt(v));
            duration = parseFfprobeDuration(sd.ffprobeResult.Duration);
        }
    } catch (e) {
        // SD not ready

        const hd = findHD(assets);
        if (hd?.ffprobeResult) {
            vs = encrypted
                ? encrypted
                : hd.ffprobeResult.VideoSize?.split('x').map((v) => parseInt(v));
            duration = parseFfprobeDuration(hd.ffprobeResult.Duration);
        }
    }

    if (vs) {
        videoSize = vs;
    }

    return {
        activeAudioTrack: 0,
        audioTracks: getAudioTracks(assets),
        sourceSD: getSD(assets),
        sourceHD: getHD(assets),
        sizeHD: videoResolutions[sizeHD] || sizeHD,
        sizeSD: videoResolutions[sizeSD] || sizeSD,
        bitRateHD,
        bitRateSD,
        duration,
        version: assets[0].version,
        previewUrl: getPreviewUrl(assets),
        width: videoSize[0],
        height: videoSize[1]
    };
};

const mapFetchAssetsResponseToImage = (assets) => {
    const sourceSD = getSD(assets);
    const sourceHD = getHD(assets);
    const sizeHD = getSizeHD(assets);
    const sizeSD = getSizeSD(assets);
    return {
        sourceSD,
        sourceHD,
        sizeHD,
        sizeSD,
        thumbnail: findThumbnail(assets),
        bitRateHD: null,
        bitRateSD: null,
        pages: [
            {
                id: 0,
                sourceSD,
                sourceHD,
                sizeHD,
                sizeSD
            }
        ],
        version: assets[0].version
    };
};

const mapFetchAssetsResponseToHTML = (assets) => ({
    source: assets.find(({ type }) => type === 'player').url,
    version: assets[0].version
});

export const mapFetchAssetsToUpdate = (type, response, encrypted) => {
    try {
        switch (type) {
            case 'image':
                return mapFetchAssetsResponseToImage(response);
            case 'html':
                return mapFetchAssetsResponseToHTML(response);
            case 'video':
                return mapFetchAssetsResponseToVideo(response, encrypted);
            case 'pdf':
                return mapFetchAssetsResponseToPDF(response);
            case 'office':
                return {};
        }
        return {};
    } catch (e) {
        console.log(e);
        return {
            error: 'Mapping error'
        };
    }
};
