import './TitleContent.scss';
import type { SyntheticEvent } from 'react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { MultiActionButton } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { Tags } from '@he-novation/design-system/components/text/Tags/Tags';
import { Tooltip } from '@he-novation/design-system/components/widgets/Tooltip/Tooltip';
import { Theme, WidgetTone } from '@he-novation/design-system/enums';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import Icon from '@he-novation/icons';
import Link from '../../../router/Link';

import Breadcrumb from '$components/Breadcrumb/Breadcrumb';
import SearchField from '$components/SearchField/SearchField';
import { closePanel } from '$redux/sideMenu/sideMenuActions';

interface Props {
    breadcrumb?: { label: string; href?: string }[];
    backLink?: {
        href: string;
    };
    tag?: keyof JSX.IntrinsicElements;
    actions?: any[];
    children?: React.ReactNode;
    tags?: React.ReactNode[];
    title: string;
    subtitle?: string;
    extra?: React.ReactNode | React.ReactNode[];
    search?: {
        search?: string;
        setSearch: (s: string) => void;
        onSearchInput: (e: SyntheticEvent) => void;
    };
}

export function TitleContent({
    breadcrumb,
    backLink,
    tag,
    actions = [],
    children,
    tags,
    title,
    subtitle,
    extra,
    search
}: Props) {
    const TitleTag = tag || 'h1';
    const dispatch = useDispatch();
    return (
        <div className="c-title-content">
            {backLink && (
                <Link href={backLink.href} className="back-link">
                    <Icon icon="arrow-left-thick" />
                </Link>
            )}

            {breadcrumb && breadcrumb.length > 1 && (
                <Tooltip
                    hasArrow
                    className="breadcrumb-back"
                    onClick={() => dispatch(closePanel())}
                    tone={WidgetTone.Light}
                    content={<Breadcrumb links={breadcrumb} />}
                    direction={[DirectionX.LeftInner, DirectionY.Bottom]}
                >
                    <Link href={breadcrumb[breadcrumb.length - 2]?.href}>
                        <Icon icon="arrow-left" />
                    </Link>
                </Tooltip>
            )}

            <div className="title-wrapper">
                <TitleTag className="title">{title}</TitleTag>
                {(tags || subtitle || extra) && (
                    <div className="title-extra">
                        {extra}
                        {tags && <Tags tags={tags} />}
                        {subtitle &&
                            (Array.isArray(subtitle) ? (
                                subtitle.map((s) => <span key={s}>{s}</span>)
                            ) : (
                                <span>{subtitle}</span>
                            ))}
                    </div>
                )}
            </div>

            {children}

            {search && (
                <SearchField
                    theme={Theme.Dark}
                    value={search.search}
                    onInput={search.onSearchInput}
                    onReset={() => search.setSearch('')}
                />
            )}

            {actions?.length > 0 && (
                <ul className="actions-container">
                    {actions.map((el, i) => {
                        if (!el) return null;

                        let content;
                        if (el.type === 'react') content = el.content;
                        else if (typeof el.onClick === 'function' || el.href)
                            content = (
                                <Button
                                    tone={el.tone ? el.tone : ButtonTone.Primary}
                                    icon={el.icon}
                                    href={el.href}
                                    download={el.download}
                                    iconSize={el.iconSize}
                                    onClick={el.onClick}
                                    className={el.className}
                                    external={el.external}
                                    target={el.target}
                                >
                                    {el.children}
                                </Button>
                            );
                        else if (el.actions && el.actions.length > 0) {
                            content = (
                                <MultiActionButton
                                    tone={el.tone ? el.tone : ButtonTone.Primary}
                                    icon={el.icon}
                                    fireIfSingle={true}
                                    direction={[DirectionX.Center, DirectionY.Bottom]}
                                    actions={el.actions.filter((a) => a)}
                                    className={el.className}
                                    closeOnClickOutside={true}
                                    closeOnContentClick={true}
                                >
                                    {el.children}
                                </MultiActionButton>
                            );
                        }

                        return <li key={i}>{content}</li>;
                    })}
                </ul>
            )}
        </div>
    );
}

export default TitleContent;
