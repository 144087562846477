import './ProgressCircle.scss';
import React, { ReactNode, SyntheticEvent } from 'react';
import { Colors } from '../../../enums';
import cn from 'classnames';

const getCircleStyle = (ring: number, size: number, bgcolor: string) => {
    let circleSize = '100%';
    let doubleRingSize = '8px';
    const style: any = {};
    if (ring) {
        style.top = style.left = `${ring}px`;
        doubleRingSize = ring * 2 + 'px';
    }
    if (size) {
        circleSize = size + 'px';
    }
    style.width = style.height = `calc(${circleSize} - ${doubleRingSize})`;

    if (bgcolor) style.backgroundColor = bgcolor;

    return style;
};

const getActiveBorderStyle = (
    size: number,
    color: string,
    value: number,
    total: number,
    bgcolor: string
) => {
    const style: any = {};
    if (size) style.width = style.height = `${size}px`;
    if (color) style.backgroundColor = color;

    const isnan = isNaN((value * 100) / total);
    const percent =
        isnan || value === 0
            ? 0
            : !isnan && (value * 100) / total < 1
            ? 0.5
            : (value * 100) / total;

    const i = (percent * 360) / 100;
    style.backgroundImage =
        i <= 180
            ? `linear-gradient(${
                  90 + i
              }deg, transparent 50%, ${bgcolor} 50%),linear-gradient(90deg, ${bgcolor} 50%, transparent 50%)`
            : `linear-gradient(${
                  i - 90
              }deg, transparent 50%, ${color} 50%),linear-gradient(90deg, ${bgcolor} 50%, transparent 50%)`;

    return style;
};

type ProgressCircleProps = {
    total: number;
    value: number;
    ring?: number;
    size?: number;
    color?: string;
    backgroundColor?: string;
    ringBackgroundColor?: string;
    textColor?: string;
    className?: string;
    children?: ReactNode | ReactNode[];
    onClick?: (e: SyntheticEvent) => void;
};
export const ProgressCircle: React.FC<ProgressCircleProps> = ({
    total,
    value,
    ring = 0,
    size = 0,
    color = Colors.Alert,
    textColor,
    backgroundColor = Colors.Darkest,
    ringBackgroundColor,
    className,
    children,
    onClick
}) => (
    <div className={cn('c-progress-circle', className)} onClick={onClick}>
        <div
            className="active-border"
            style={getActiveBorderStyle(
                size,
                color,
                value,
                total,
                ringBackgroundColor || backgroundColor
            )}
        >
            <div className="circle" style={getCircleStyle(ring, size, backgroundColor)}>
                <div className="prec" style={{ color: textColor || color }}>
                    {children
                        ? children
                        : (isNaN((value * 100) / total) ? 0 : Math.round((value * 100) / total)) +
                          '%'}
                </div>
            </div>
        </div>
    </div>
);
