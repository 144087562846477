import styles from '@he-novation/design-system/components/widgets/AbsoluteMenu/AbsoluteMenu.module.css';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ClientGuest } from '@he-novation/config/types/client.types';
import { MultiActionButtonList } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButtonList';
import { AbsoluteMenuHandler } from '@he-novation/design-system/components/widgets/AbsoluteMenu/AbsoluteMenu';
import { __ } from '@he-novation/design-system/utils/i18n';
import { getHighlightObject } from '@he-novation/front-shared/utils/propsToHighlightedSearchNode';
import { CONFIRM } from '@he-novation/paths/modals.constants';
import objectSearchFactory from '@he-novation/utils/objectSearchFactory';
import { pick } from 'lodash/fp';

import { clientUserRemove } from '$redux/client/clientActions';
import { closeModal, openModal } from '$redux/route/routeActions';

type DataLayoutClientGuestsActionsMenuProps = {
    item: ClientGuest;
    absoluteMenuHandler: AbsoluteMenuHandler<ClientGuest>;
};
export function DataLayoutClientGuestsActionsMenu({
    item,
    absoluteMenuHandler
}: DataLayoutClientGuestsActionsMenuProps) {
    const dispatch = useDispatch();
    return (
        <MultiActionButtonList
            className={styles.menu}
            onClickableClick={() => {
                setTimeout(() => absoluteMenuHandler.close(), 0);
            }}
            actions={[
                {
                    children: __('DELETE'),
                    onClick: () =>
                        dispatch(
                            openModal(CONFIRM, {
                                message: __('CLIENT_LICENSE_CANCEL_QUESTION_TXT'),
                                alert: true,
                                onSubmit: () => {
                                    dispatch(
                                        clientUserRemove(item.uuid, () => dispatch(closeModal()))
                                    );
                                }
                            })
                        )
                }
            ]}
        />
    );
}

export const searchProperties = ['email', 'firstname', 'lastname', 'role', 'phone', 'firm'];
export const filterSearch = objectSearchFactory(searchProperties);

export const highlight = (item: ClientGuest, search?: string) =>
    getHighlightObject(pick(searchProperties)(item), search);
