export const TYPE_NOTE = {
    GLOBAL: 'global',
    SEQUENCE: 'sequence',
    DRAW: 'draw',
    RECTANGLE: 'rectangle',
    TIMECODE: 'timecode'
};

export const PARAM_NOTE = {
    SELECTED_TYPE: 'selectedType',
    KEYWORDS: 'keywords',
    PROJECTS: 'projects',
    NOTIFY: 'isNotify',
    IMPORTANT: 'isImportant'
};
