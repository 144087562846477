import {
    CompanyCreateBody,
    CompanyUpdateBody
} from '@he-novation/config/types/payloads/company.payload';
import { companyPaths, pathWithParams } from '@he-novation/paths/herawApiPaths';
import { apiFetch } from './apiFetch';
import { asyncAssetStatusUpdate } from './asset.async';

export const companiesFetch = () => apiFetch(companyPaths.root);

export const companyCreate = (body: CompanyCreateBody) =>
    apiFetch(companyPaths.root, {
        method: 'POST',
        body
    });

export const companyDelete = (companyUuid: string) =>
    apiFetch(pathWithParams(companyPaths.specific, { companyUuid }), {
        method: 'DELETE'
    });

export const companyUpdate = (companyUuid: string, body: CompanyUpdateBody) =>
    apiFetch(pathWithParams(companyPaths.specific, { companyUuid }), {
        method: 'PUT',
        body
    });
export const asyncCompanyPictureUploadLink = async (companyUuid: string, blob: Blob) => {
    const { link, headers, assetUuid } = await apiFetch(
        pathWithParams(companyPaths.specificPicture, { companyUuid }),
        {
            method: 'POST',
            body: {
                size: blob.size,
                contentType: blob.type
            }
        }
    );
    await fetch(link, {
        method: 'PUT',
        headers: {
            'Content-Type': blob.type,
            ...headers
        },
        body: blob
    });
    return await asyncAssetStatusUpdate(assetUuid, { status: 'READY', signUrl: true });
};
