import './ModalHeader.scss';
import React from 'react';
import Icon from '@he-novation/icons';
import classNames from 'classnames';

type ModalHeaderProps = {
    title: string;
    close?: Function;
    isError?: boolean;
};
const ModalHeader = ({ title, close, isError }: ModalHeaderProps) => (
    <div className={classNames('c-modal-header', isError && 'is-error')}>
        <h1>{title}</h1>
        {close && (
            <button className={'close'} onClick={() => close()}>
                <Icon icon="cross" />
            </button>
        )}
    </div>
);

export default ModalHeader;
