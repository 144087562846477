import './SidePanelList.scss';
import React from 'react';
import Link from '../../router/Link';
import Icon from '@he-novation/icons';
import { withContextMenu } from '@he-novation/design-system/components/widgets/ContextMenu/ContextMenu';

const SidePanelListItem = ({ closePanel, isSmallScreen, editItem, deleteItem, item, canEdit }) => {
    return (
        <>
            <Link href={item.href} onClick={isSmallScreen ? closePanel : null}>
                <div className="count">{item.count}</div>
                <h3>{item.name}</h3>
            </Link>
            {editItem && (!canEdit || canEdit(item)) && (
                <button className="edit" onClick={() => editItem(item)}>
                    <Icon icon="pencil" />
                </button>
            )}
            {deleteItem && (!canEdit || canEdit(item)) && (
                <button className="delete" onClick={() => deleteItem(item)}>
                    <Icon icon="cross2" />
                </button>
            )}
        </>
    );
};

const SidePanelList = ({
    editItem,
    deleteItem,
    isSmallScreen,
    items,
    closePanel,
    contextMenuActions,
    canEdit
}) => {
    const Tag = contextMenuActions
        ? withContextMenu(contextMenuActions)(SidePanelListItem)
        : SidePanelListItem;

    return (
        <ul className="c-side-panel-list">
            {items.map((item, i) => (
                <li key={i} className={item.className}>
                    <Tag
                        item={item}
                        canDelete={canEdit}
                        closePanel={closePanel}
                        isSmallScreen={isSmallScreen}
                        editItem={editItem}
                        deleteItem={deleteItem}
                    />
                </li>
            ))}
        </ul>
    );
};

export default SidePanelList;
