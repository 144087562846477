import styles from './ModalWrapper.module.scss';
import React, { ReactNode, useEffect, useRef } from 'react';
import { onClickOutside } from '@he-novation/design-system/utils/onClickOutside';
import cn from 'classnames';

type ModalWrapperProps = {
    className?: string;
    modalClassName?: string;
    isVisible?: boolean;
    isModalVisible?: boolean;
    isModalLeaving?: boolean;
    isTop?: boolean;
    disableOverlay?: boolean;
    children?: ReactNode | ReactNode[];
    onClickOutside?: () => void;
};

export function ModalWrapper({
    className,
    modalClassName,
    isVisible,
    isModalVisible,
    isModalLeaving,
    isTop,
    onClickOutside: _onClickOutside,
    disableOverlay,
    children
}: ModalWrapperProps) {
    const clickOutsideRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (_onClickOutside) {
            const unregister = onClickOutside(clickOutsideRef, _onClickOutside, {
                excludedClasses: [
                    'c-autocomplete-options-list',
                    'react-calendar',
                    'c-color-picker-absolute',
                    'c-checkbox-dropdown-list',
                    'c-player-volume',
                    'react-select-menu',
                    'checkbox-dropdown-list',
                    'rich-number-menu',
                    'css-g56vrd-indicatorContainer'
                ]
            });

            return () => {
                unregister();
            };
        }
    }, []);
    return (
        <div
            className={cn(
                styles.wrapper,
                disableOverlay && styles.disableOverlay,
                isVisible && styles.isVisible,
                isModalVisible && styles.isModalVisible,
                isModalLeaving && styles.isModalLeaving,
                isTop && styles.isTop,
                className
            )}
        >
            <div className={styles.overlay} onClick={_onClickOutside} />
            <div className={cn(styles.modal, modalClassName)} ref={clickOutsideRef}>
                {children}
            </div>
        </div>
    );
}
