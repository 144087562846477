import {
    isAdminTeam,
    TEAM_ADMIN,
    TEAM_CAST
} from '@he-novation/config/constants/projects.constants';
import { ProjectsState, ProjectWithTasksAndTeams } from '@he-novation/config/types/project.types';
import { compose, pick, pipe, property } from 'lodash/fp';
import { CONTENT } from '../contentActions';
import { PROJECTS } from './projectsActions';

import { ReduxState } from '$redux/store';

const stateSelector: (state: ReduxState) => ProjectsState = pipe(
    property(CONTENT),
    property(PROJECTS)
);

export const companiesSelector = pipe(stateSelector, pick('companies'));

export const currentProjectSelector = (withCastTeam?: boolean, withAdminTeam = true) =>
    pipe(stateSelector, property('currentProject'), (currentProject) => {
        if (!currentProject) return { currentProject };
        let teams = currentProject?.teams || [];
        if (!withAdminTeam) teams = teams.filter((t) => t.name !== TEAM_ADMIN);
        if (!withCastTeam) teams = teams.filter((t) => t.name !== TEAM_CAST);

        return {
            currentProject: {
                ...currentProject,
                teams
            } as ProjectWithTasksAndTeams
        };
    });

export const currentProjectUuidSelector = pipe(
    stateSelector,
    property('currentProject.uuid'),
    (currentProjectUuid) => ({ currentProjectUuid })
);

export const projectOwnerSelector = pipe(
    currentProjectSelector(),
    property('currentProject.user'),
    (projectOwner) => ({ projectOwner })
);

export const teamsSelector = (withCastTeam?: boolean, withAdminTeam?: boolean) =>
    pipe(currentProjectSelector(withCastTeam, withAdminTeam), ({ currentProject }) => ({
        teams: currentProject && currentProject.teams ? currentProject.teams : []
    }));
export const projectsSelector = compose(({ projects }) => ({ projects }), stateSelector);

export const projectsInProgressSelector = compose(
    ({ projects }) => ({
        projectsInProgress: projects.filter((el) => el.status === 'IN_PROGRESS')
    }),
    stateSelector
);

export const projectsArchivedSelector = compose(
    ({ projects }) => ({ projectsArchived: projects.filter((el) => el.status === 'ARCHIVED') }),
    stateSelector
);

export const projectByUuidSelector = (_uuid: string) =>
    pipe(
        stateSelector,
        ({ currentProject, projects }) =>
            currentProject && currentProject.uuid === _uuid
                ? currentProject
                : projects.find(({ uuid }) => uuid === _uuid),
        (project) => ({ project })
    );

export const isProjectManagerSelector = pipe(
    stateSelector,
    property('currentProject.team_name'),
    (teamName) => isAdminTeam(teamName),
    (isProjectManager) => ({ isProjectManager })
);
export const castTeamAccessSelector = pipe(
    stateSelector,
    property('currentProject'),
    pick(['castTeamAccess'])
);

export const selectedTeamSelector = pipe(
    stateSelector,
    property('currentProject'),
    pick('selectedTeam')
);

export const userTeamSelector = pipe(
    stateSelector,
    property('currentProject.team_uuid'),
    (team_uuid) => ({ userTeam: team_uuid })
);
export const defaultCastStyleSelector = pipe(
    stateSelector,
    property('currentProject.defaultCastStyle'),
    (defaultCastStyle) => ({ defaultCastStyle })
);

export const planningTasksSelector = pipe(stateSelector, pick('planningTasks'));
