import './SelectableGroup.scss';
import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { parentHasClass } from '@he-novation/design-system/utils/dom/parentHasClass';
import offset from '@he-novation/front-shared/utils/offset';
import cn from 'classnames';

import {
    SelectableItemComponentProps,
    SelectionHandler,
    SelectionOptions
} from '$components/Selectable/SelectionHandler';

type SelectableGroupProps = SelectionOptions & {
    id: string;
    Tag?: React.ElementType;
    className?: string;
    children?: ReactNode | ReactNode[];
    onScroll?: React.UIEventHandler;
    wrapperRef: React.RefObject<HTMLElement>;
    role?: string;
    style?: React.CSSProperties;
    noAreaSelection?: boolean;
};

type SelectionGroupState = {
    selecting: boolean;
    draggedItems?: SelectableItemComponentProps[];
};

function getScrollParent(node: Element | Document | null): Element | null {
    if (node === null || node instanceof Document) return null;
    const overflowY = node && window.getComputedStyle(node).overflowY;
    const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';
    if (isScrollable && node.scrollHeight > node.clientHeight) {
        return node;
    } else {
        return getScrollParent(node.parentNode as Element | Document | null);
    }
}

const selectables = {};

class SelectableGroup<T> extends React.Component<SelectableGroupProps, SelectionGroupState> {
    ref: React.RefObject<HTMLElement>;
    areaSelectionRef: React.RefObject<HTMLDivElement>;
    group: SelectionHandler<T>;
    scrollParent?: Element | null;
    fixedOffsetY: number;
    fixedOffsetX: number;
    delayOverlapCheck: boolean;
    draggedRef: React.RefObject<HTMLDivElement>;
    bubble = true;
    selection: {
        start?: [number, number];
        end?: [number, number];
    };
    static getGroup = (id) => {
        return selectables[id];
    };

    constructor(props) {
        super(props);
        this.state = {
            selecting: false,
            draggedItems: []
        };
        this.ref = props.wrapperRef || React.createRef();
        this.areaSelectionRef = React.createRef();
        this.draggedRef = React.createRef();
        this.selection = {};
        selectables[this.props.id] = this.group = new SelectionHandler(this.props.id, {
            onSetDragging: (draggedItems) => {
                this.setState({ draggedItems });
            },
            onSetSelecting: (selecting) => {
                this.setState({ selecting });
            }
        });
    }

    componentDidMount() {
        window.addEventListener('mousemove', this.onWindowMouseMove);
        window.addEventListener('mouseup', this.onWindowMouseUp);
        this.computeValues();
        if (this.props.keyboardNavigation) {
            window.addEventListener('keydown', this.onKeyDown);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.onWindowMouseMove);
        window.removeEventListener('mouseup', this.onMouseUp);
        //delete selectables[this.props.selectableGroupId];
    }

    render() {
        const DraggedComponent = this.props.DraggedComponent;
        const Tag = this.props.Tag || 'div';
        return (
            <>
                <Tag
                    id={this.props.id}
                    role={this.props.role}
                    onDrop={this.props.onDrop}
                    ref={this.ref}
                    className={cn(
                        'c-selectable-group',
                        { 'is-selecting': this.state.selecting },
                        this.props.className
                    )}
                    style={{ position: 'relative', ...this.props.style }}
                    onMouseDown={this.onMouseDown}
                    onMouseUp={this.onMouseUp}
                    onScroll={this.props.onScroll}
                >
                    {this.state.selecting && this.renderAreaSelection()}
                    {this.props.children}
                </Tag>

                {this.state.draggedItems &&
                    DraggedComponent &&
                    ReactDOM.createPortal(
                        <DraggedComponent items={this.state.draggedItems} ref={this.draggedRef} />,
                        document.body
                    )}
            </>
        );
    }

    renderAreaSelection() {
        return (
            <div
                ref={this.areaSelectionRef}
                style={{
                    position: 'absolute',
                    zIndex: '2',
                    border: '1px solid rgba(255,255,255, .5)',
                    background: 'rgba(255,255,255,.1)',
                    display: 'none',
                    borderRadius: '4px'
                }}
            />
        );
    }

    computeValues() {
        this.fixedOffsetY = this.ref.current!.scrollTop + offset(this.ref.current).top;
        this.fixedOffsetX = this.ref.current!.scrollTop + offset(this.ref.current).left;
    }

    getOrderedAreaSelectionCoordinates(start, end): [number, number, number, number] {
        const smallestX = Math.min(start[0], end[0]);
        const largestX = Math.max(start[0], end[0]);
        const smallestY = Math.min(start[1], end[1]);
        const largestY = Math.max(start[1], end[1]);
        return [smallestX, smallestY, largestX, largestY];
    }

    getOffsetCoordinates(_x, _y): [number, number] {
        const o = offset(this.ref.current);
        const x = _x - o.left;
        const y = _y - o.top;
        return [x, y];
    }

    onMouseDown = (e) => {
        if (
            e.button > 0 ||
            parentHasClass(e.target, 'ignore-mouseup') ||
            parentHasClass(e.target, 'display-mode')
        )
            return;
        const selectableItem = e.target.closest('.selectable-item');

        if (selectableItem) {
            const child = this.group.children.find((c) => c.props.id === selectableItem.id);
            if (!child || child.props.noClickHandler) return;

            this.group.mouseDown = true;
            e.preventDefault();
            if (e.shiftKey) {
                this.group.shiftSelectTo(child);
                return;
            }
            this.group.setShouldDrag(true, child);
            this.group.setMouseCoords([e.clientX, e.clientY]);
        }

        if (this.props.noAreaSelection) return;

        this.group.mouseDown = true;
        this.scrollParent = getScrollParent(this.ref.current);
        this.selection.start = this.getOffsetCoordinates(e.clientX, e.clientY);
        this.selection.end = this.selection.start;
        this.group.setMouseCoords([e.clientX, e.clientY]);
    };

    onMouseUp = (e) => {
        this.bubble = true;
        const selectableItem = e.target.closest('.selectable-item');
        if (!selectableItem) return;
        const child = this.group.children.find((c) => c.props.id === selectableItem.id);
        if (!child) return;
        this.bubble = false;
        if (child.props.noClickHandler || parentHasClass(e.target, 'ignore-mouseup')) return;
        if (e.button > 0) {
            if (this.group.selected.length < 2) this.group.deselectAll();
            this.group.toggle(child, true);
            return;
        }
        this.group.mouseDown = false;
        if (child.props.onDrop && this.group.dragging) {
            e.preventDefault();
            child.props.onDrop(
                e,
                this.group.getSelectedComponentProps(),
                child.props.componentProps
            );
        } else if (!this.group.selecting) {
            if (
                !e.ctrlKey &&
                !e.shiftKey &&
                !e.metaKey && //metaKey === command key on MAC
                (!this.group.isSelected(child) || this.group.selected.length) &&
                !this.group.dragging
            )
                this.group.deselectAll();
            this.group.toggle(child, e.ctrlKey || e.metaKey ? undefined : true);
        }
        this.group.setDragging(false);
        this.group.setSelecting(false);
        this.group.setShouldDrag(false);
    };

    onWindowMouseMove = (e) => {
        if (this.props.noAreaSelection || e.button > 0) return;
        if (!this.group.mouseDown) return;

        if (
            !this.state.selecting &&
            !this.group.dragging &&
            (Math.abs(e.clientX - this.group.mouseCoords[0]) > 5 ||
                Math.abs(e.clientY - this.group.mouseCoords[1]) > 5)
        ) {
            if (this.group.shouldDrag) {
                this.group.setSelecting(false);
                this.group.setDragging(true);
            } else {
                this.group.setSelecting(true);
            }
        }

        if (this.state.selecting || this.group.dragging)
            this.group.setMouseCoords([e.clientX, e.clientY]);

        if (!this.state.selecting) {
            if (!this.group.dragging) return;
            this.updateDraggedRefPosition(e.clientX, e.clientY);
            return;
        }

        this.selection.end = this.getOffsetCoordinates(e.clientX, e.clientY);
        const areaSelection = this.getOrderedAreaSelectionCoordinates(
            this.selection.start,
            this.selection.end
        );
        const w = areaSelection[2] - areaSelection[0];
        const h = areaSelection[3] - areaSelection[1];
        const areaSelectionEl = this.areaSelectionRef.current!;
        areaSelectionEl.style.display = w > 1 || h > 1 ? 'block' : 'none';

        areaSelectionEl.style.left = `${areaSelection[0]}px`;
        areaSelectionEl.style.top = `${areaSelection[1]}px`;
        areaSelectionEl.style.width = `${w}px`;
        areaSelectionEl.style.height = `${h}px`;

        const scrolledAreaSelectionRect = areaSelection.map(
            (n, i) => n - this.ref.current![i % 2 ? 'scrollTop' : 'scrollLeft']
        ) as [number, number, number, number];

        if (!this.delayOverlapCheck) {
            this.delayOverlapCheck = true;
            this.group.children.forEach((item, i) => {
                this.group.toggle(
                    item,
                    this.areaSelectionOverlapsRect(item.getElement(), scrolledAreaSelectionRect)
                );
            });
            setTimeout(() => (this.delayOverlapCheck = false), 20);
        }

        if (!this.scrollParent) return;

        const scrollParentRect = this.scrollParent.getBoundingClientRect();
        if (
            typeof this.selection?.start !== 'undefined' &&
            this.selection.start[1] < this.selection.end[1]
        ) {
            if (e.clientY > scrollParentRect.top + scrollParentRect.height - 100)
                this.scrollParent.scrollTop = this.scrollParent.scrollTop + 4;
        } else {
            if (e.clientY < scrollParentRect.top + 100)
                this.scrollParent.scrollTop = this.scrollParent.scrollTop - 4;
        }
    };

    areaSelectionOverlapsRect(
        item: HTMLElement,
        [left, top, right, bottom]: [number, number, number, number]
    ) {
        const itemRect = offset(item);
        const offsetRect = [itemRect.left - this.fixedOffsetX, itemRect.top - this.fixedOffsetY];
        const selectionPaddingX = this.props.selectionPaddingX || 0;
        return (
            offsetRect[0] - selectionPaddingX < right &&
            offsetRect[0] + itemRect.width + selectionPaddingX > left &&
            offsetRect[1] < bottom &&
            offsetRect[1] + itemRect.height > top
        );
    }

    onWindowMouseUp = (e) => {
        if (this.props.noAreaSelection || e.button > 0 || !this.bubble) return;
        if (
            e.target.classList.contains('ignore-mouseup') ||
            parentHasClass(e.target, 'display-mode')
        )
            return;
        this.group.mouseDown = false;
        const wasDragging = this.group.dragging;
        this.group.setDragging(false);
        this.group.setShouldDrag(false);
        if (!this.state.selecting) {
            if (this.group.selected.length) {
                if (!wasDragging && !e.ctrlKey && !e.shiftKey) {
                    this.group.deselectAll();
                }
                setTimeout(() => this.group.setDragging(false), 0);
            }
            return;
        }

        setTimeout(() => this.group.setSelecting(false), 0);
    };

    updateDraggedRefPosition = (x, y) => {
        if (this.draggedRef.current) {
            this.draggedRef.current.style.left = `${x}px`;
            this.draggedRef.current.style.top = `${y}px`;
        }
    };

    scrollTo = (level) => {
        this.scrollParent = getScrollParent(this.ref.current);
        if (this.scrollParent) this.scrollParent.scrollTop = level;
    };

    onKeyDown = (e) => {
        const selectionHandler = SelectableGroup.getGroup(this.props.id);
        const selection = selectionHandler.getSelectedComponentProps();
        if (!selection.length) return;
        if (e.keyCode === 32) return this.onKeySpace(selectionHandler);
        selectionHandler.deselectAll();
        if (e.keyCode === 37) this.moveSelectionLeft(selectionHandler, selection);
        if (e.keyCode === 38) this.moveSelectionRight(selectionHandler, selection);
    };

    onKeySpace = (selectionHandler: SelectionHandler<T>) => {
        const selected = selectionHandler.getSelectedComponents();
        if (!selected.length) return;
        selected[0].getFocusElement()?.click();
    };

    moveSelectionLeft = (
        selectionHandler: SelectionHandler<T>,
        selection: SelectableItemComponentProps[]
    ) => {
        if (selection[0].index <= 0) return;
        const newIndex = selection[0].index - 1;
        selectionHandler.selectIndex(newIndex);
        this.focusTargetLinkOrButton(selectionHandler.children[newIndex].props.id);
    };

    moveSelectionRight = (
        selectionHandler: SelectionHandler<T>,
        selection: SelectableItemComponentProps[]
    ) => {
        if (selection[0].index === selectionHandler.children.length - 1) return;
        const newIndex = selection[0].index + 1;
        selectionHandler.selectIndex(newIndex);
        this.focusTargetLinkOrButton(selectionHandler.children[newIndex].props.id);
    };

    focusTargetLinkOrButton = (targetId) => {
        const el = document.getElementById(targetId);
        const buttons = el?.querySelectorAll('a, button:not(.c-file-version-button)');
        if (!buttons?.length) return;
        (buttons[0] as HTMLElement).focus();
        return buttons[0];
    };
}
export default SelectableGroup;
