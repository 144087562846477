import ProjectTeamsPicker from '$views/projects/Components/ProjectTeamsPicker/ProjectTeamsPickerContainer';
import { compose, branch, renderNothing, withProps } from 'recompose';
import { connect } from 'react-redux';
import { setSelectedTeam } from '../../../../redux/content/projects/projectsActions';
import combineSelectors from '$helpers/combineSelectors';
import {
    castTeamAccessSelector,
    isProjectManagerSelector,
    selectedTeamSelector,
    teamsSelector
} from '../../../../redux/content/projects/projectsSelector';

export default compose(
    connect(
        combineSelectors(
            selectedTeamSelector,
            teamsSelector(true),
            isProjectManagerSelector,
            castTeamAccessSelector
        ),
        (dispatch) => ({
            onTeamChange: (e) => dispatch(setSelectedTeam(e.target.value))
        })
    ),
    withProps(({ isProjectManager, castTeamAccess }) => ({
        withCastTeam: castTeamAccess,
        withAdminTeam: isProjectManager
    })),
    branch(
        ({ isProjectManager, castTeamAccess }) => !isProjectManager && !castTeamAccess,
        renderNothing
    )
)(ProjectTeamsPicker);
