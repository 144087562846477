import './global.scss';
import '$redux/store.ts';

import React from 'react';

import { createRoot } from 'react-dom/client';
import App from './views/App/AppContainer';

document.addEventListener('DOMContentLoaded', () => {
    const root = createRoot(document.getElementById('app'));
    root.render(<App />);
});
