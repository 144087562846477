import './Main.scss';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { activePanelSelector } from '$redux/sideMenu/sideMenuSelectors';

export const Main = ({ children }: { children?: ReactNode | ReactNode[] }) => {
    const activeSidePanel = useSelector(activePanelSelector);
    return (
        <div id="main" className={activeSidePanel ? 'has-open-panel' : undefined}>
            {children}
        </div>
    );
};
