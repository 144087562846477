import React from 'react';
import upperFirst from 'lodash/fp/upperFirst';
import { Key } from '../enums';

type WithKeyEventListenersProps = {
    listeners: Key[];
    Component: React.ComponentType;
    componentProps: any;
};

class WithKeyEventListeners extends React.Component<WithKeyEventListenersProps> {
    removeEventListeners: Function[];

    constructor(props: WithKeyEventListenersProps) {
        super(props);
        this.removeEventListeners = [];
    }
    static shortcutToFunctionName(s: string) {
        return `onWindowKey${upperFirst(s)}`;
    }

    static shortcutToKeycode(s: string) {
        switch (s) {
            case Key.Down:
                return 40;
            case Key.Enter:
                return 13;
            case Key.Escape:
                return 27;
            case Key.Left:
                return 37;
            case Key.Right:
                return 39;
            case Key.Shift:
                return 16;
            case Key.Tab:
                return 9;
            case Key.Up:
                return 38;
            case Key.D:
                return 68;
            case Key.M:
                return 77;
        }
    }

    shortcutToFunction(s: string) {
        return (callback: Function, elementGetter?: () => HTMLElement) => {
            const func = (e: KeyboardEvent) => {
                if (e.which === WithKeyEventListeners.shortcutToKeycode(s)) callback(e);
            };
            (elementGetter ? elementGetter() : window).addEventListener('keydown', func);
            this.removeEventListeners.push(() =>
                (elementGetter ? elementGetter() : window).removeEventListener('keydown', func)
            );
        };
    }

    listenersToRegistrationFunctions(listeners: Key[]) {
        return listeners.reduce((acc, s) => {
            let key = WithKeyEventListeners.shortcutToFunctionName(s);
            acc[key] = this.shortcutToFunction(s);
            return acc;
        }, {} as { [key: string]: Function });
    }
    componentWillUnmount() {
        this.removeEventListeners.forEach((removeEventListener) => removeEventListener());
    }

    render() {
        const { Component, componentProps, listeners } = this.props;
        return (
            <Component {...componentProps} {...this.listenersToRegistrationFunctions(listeners)} />
        );
    }
}

export default (listeners: Key[]) => (Component: React.ComponentType) => (props: any) =>
    <WithKeyEventListeners Component={Component} componentProps={props} listeners={listeners} />;
