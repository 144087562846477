import {
    asyncProjectSizeFetch,
    asyncTasksFetch,
    createProject as _createProject,
    createTask as _createTask,
    deleteMember as _deleteMember,
    deleteTask as _deleteTask,
    deleteTeam as _deleteTeam,
    fetchProject as _fetchProject,
    fetchProjectCompanies as _fetchProjectCompanies,
    fetchProjects as _fetchProjects,
    fetchProjectTasks as _fetchProjectTasks,
    fetchProjectTeams as _fetchProjectTeams,
    inviteMembersToTeam as _inviteMembersToTeam,
    updateMember as _updateMember,
    updateMultipleTaskStatusAndOrdering as _updateMultipleTaskStatusAndOrdering,
    updateProject as _updateProject,
    updateTask as _updateTask,
    updateTeam as _updateTeam,
    updateUserProjectMetadata
} from '@he-novation/front-shared/async/project.async';
import { projectsLink } from '@he-novation/paths/herawFrontUris';
import { fetchPage } from '../../content/activity/activityAsync';
import mapFetchProjectActivityToUpdate from '../../content/projects/maps/mapFetchProjectActivityToUpdate';
import { set as setRoute } from '../../route/routeActions';
import mapFetchTeamsToUpdate from './maps/mapFetchTeamsToUpdate';

import { WS_PREFIX_IN } from '$constants/webSocket.constants';
import { asyncActionSuccess } from '$helpers/asyncAction';
import { mapUserAndProfileToUser } from '$redux/helpers';

export const PROJECTS = 'PROJECTS';
export const CREATE_PROJECT = `${PROJECTS}/CREATE_PROJECT`;
export const ARCHIVE_PROJECT = `${PROJECTS}/ARCHIVE_PROJECT`;
export const DELETE_PROJECT = `${PROJECTS}/DELETE_PROJECT`;
export const RESTORE_PROJECT = `${PROJECTS}/RESTORE_PROJECT`;
export const CREATE_TASK = `${PROJECTS}/CREATE_TASK`;
export const DELETE_MEMBER = `${PROJECTS}/DELETE_MEMBER`;
export const DELETE_TASK = `${PROJECTS}/DELETE_TASK`;
export const DELETE_TEAM = `${PROJECTS}/DELETE_TEAM`;
export const UPDATE_TEAM = `${PROJECTS}/UPDATE_TEAM`;
export const SET_PROJECTS = `${PROJECTS}/SET_PROJECTS`;
export const SET_SELECTED_TEAM = `${PROJECTS}/SET_SELECTED_TEAM`;
export const SET_LAST_PROJECT_UUID = `${PROJECTS}/SET_LAST_PROJECT_UUID`;
export const FETCH_PROJECT = `${PROJECTS}/FETCH_PROJECT`;
export const FETCH_PROJECT_TASKS = `${PROJECTS}/FETCH_PROJECT_TASKS`;
export const FETCH_PLANNING_TASKS = `${PROJECTS}/FETCH_PLANNING_TASKS`;
export const FETCH_PROJECTS = `${PROJECTS}/FETCH_PROJECTS`;
export const FETCH_PROJECT_SIZE = `${PROJECTS}/FETCH_PROJECT_SIZE`;
export const FETCH_PROJECT_ACTIVITY = `${PROJECTS}/FETCH_PROJECT_ACTIVITY`;
export const FETCH_PROJECT_LAST_ACTIVITY = `${PROJECTS}/FETCH_PROJECT_LAST_ACTIVITY`;
export const FETCH_PROJECT_COMPANIES = `${PROJECTS}/FETCH_PROJECT_COMPANIES`;
export const FETCH_PROJECT_TEAMS = `${PROJECTS}/FETCH_PROJECT_TEAMS`;
export const FETCH_TASK_ASSETS = `${PROJECTS}/FETCH_TASK_ASSETS`;
export const TEAM_CREATE = `${PROJECTS}/TEAM_CREATE`;
export const TEAM_INVITE_MEMBERS = `${PROJECTS}/TEAM_INVITE_MEMBERS`;
export const FETCH_CURRENT_USER_PROJECTS = `${PROJECTS}/FETCH_CURRENT_USER_PROJECTS`;
export const TOGGLE_FAVORITE = `${PROJECTS}/TOGGLE_FAVORITE`;
export const UPDATE_MEMBER = `${PROJECTS}/UPDATE_MEMBER`;
export const UPDATE_PROJECT = `${PROJECTS}/UPDATE_PROJECT`;
export const UPDATE_CURRENT_PROJECT = `${PROJECTS}/UPDATE_CURRENT_PROJECT`;
export const UPDATE_TASK = `${PROJECTS}/UPDATE_TASK`;
export const UPDATE_TASK_STATUS_ORDER = `${PROJECTS}/UPDATE_TASK_STATUS_ORDER`;

export const WS_CREATE_PROJECT = `${WS_PREFIX_IN}${CREATE_PROJECT}`;
export const WS_ARCHIVE_PROJECT = `${WS_PREFIX_IN}${ARCHIVE_PROJECT}`;
export const WS_DELETE_PROJECT = `${WS_PREFIX_IN}${DELETE_PROJECT}`;
export const WS_RESTORE_PROJECT = `${WS_PREFIX_IN}${RESTORE_PROJECT}`;
export const WS_DELETE_MEMBER = `${WS_PREFIX_IN}${DELETE_MEMBER}`;
export const WS_TEAM_CREATE = `${WS_PREFIX_IN}${TEAM_CREATE}`;
export const WS_TEAM_INVITE = `${WS_PREFIX_IN}${TEAM_INVITE_MEMBERS}`;
export const WS_UPDATE_MEMBER = `${WS_PREFIX_IN}${UPDATE_MEMBER}`;
export const WS_UPDATE_PROJECT = `${WS_PREFIX_IN}${UPDATE_PROJECT}`;
export const WS_UPDATE_CURRENT_PROJECT = `${WS_PREFIX_IN}${UPDATE_CURRENT_PROJECT}`;
export const WS_TASK_CREATE = `${WS_PREFIX_IN}${CREATE_TASK}`;
export const WS_TASK_UPDATE = `${WS_PREFIX_IN}${UPDATE_TASK}`;
export const WS_TASK_DELETE = `${WS_PREFIX_IN}${DELETE_TASK}`;
export const WS_TEAM_DELETE = `${WS_PREFIX_IN}${DELETE_TEAM}`;
export const WS_TEAM_UPDATE = `${WS_PREFIX_IN}${UPDATE_TEAM}`;

export const createProject = (body, cb) => async (dispatch) => {
    dispatch({ type: CREATE_PROJECT });
    try {
        const r = await _createProject(body);
        if (typeof cb === 'function') cb(null, r);
    } catch (e) {
        if (typeof cb === 'function') cb(e);
    }
};

export const createTask = (body, cb) => async (dispatch) => {
    dispatch({ type: CREATE_TASK });
    const r = await _createTask(body);
    if (typeof cb === 'function') cb(r);
};

export const deleteMember = (projectUuid: string, userUuid: string) => async (dispatch) => {
    dispatch({ type: WS_DELETE_MEMBER, uuid: userUuid });
    await _deleteMember(projectUuid, userUuid);
};

export const deleteTask = (taskUuid) => async (dispatch) => {
    dispatch({ type: DELETE_TASK });
    await _deleteTask(taskUuid);
};

export const deleteTeam = (teamUuid, projectUuid, cb) => async (dispatch) => {
    dispatch({ type: DELETE_TEAM });
    await _deleteTeam(teamUuid, projectUuid);
    if (typeof cb === 'function') cb();
};

export const updateTeam = (teamUuid, name, projectUuid, castTeamAccess, cb) => async (dispatch) => {
    dispatch({ type: UPDATE_TEAM });
    const res = await _updateTeam({ teamUuid, name, projectUuid, castTeamAccess });
    if (typeof cb === 'function') cb(null, res);
};

export const fetchProjects = (fetchSizes?: boolean) => async (dispatch) => {
    dispatch({ type: FETCH_PROJECTS });
    const projects = await _fetchProjects();
    dispatch({
        type: asyncActionSuccess(FETCH_PROJECTS),
        projects
    });

    if (fetchSizes) {
        for (const { uuid } of projects) {
            dispatch({ type: FETCH_PROJECT_SIZE });
            const { size, files } = await asyncProjectSizeFetch(uuid);
            dispatch({ type: asyncActionSuccess(FETCH_PROJECT_SIZE), uuid, size, files });
        }
    }
};

export const fetchProject = (uuid) => async (dispatch) => {
    dispatch({ type: FETCH_PROJECT });
    try {
        const [response, teams] = await Promise.all([
            _fetchProject(uuid),
            _fetchProjectTeams(uuid)
        ]);
        dispatch({
            type: asyncActionSuccess(FETCH_PROJECT),
            project: {
                ...response,
                ...mapFetchTeamsToUpdate(teams)
            }
        });
    } catch (e) {
        if (e.code === 404) dispatch(setRoute(projectsLink(), false, true));
    }
};

export const fetchProjectTasks = (projectUuid) => async (dispatch) => {
    dispatch({ type: FETCH_PROJECT_TASKS });
    const tasks = await _fetchProjectTasks(projectUuid);
    dispatch({ type: asyncActionSuccess(FETCH_PROJECT_TASKS), tasks });
};

export const fetchPlanningTasks = (startDate, endDate) => async (dispatch) => {
    dispatch({ type: FETCH_PLANNING_TASKS });
    const planningTasks = await asyncTasksFetch({ startDate, endDate });

    dispatch({ type: asyncActionSuccess(FETCH_PLANNING_TASKS), planningTasks });
};

export const fetchProjectTeams = (uuid) => async (dispatch) => {
    dispatch({ type: FETCH_PROJECT_TEAMS });
    const teams = await _fetchProjectTeams(uuid);
    dispatch({
        type: asyncActionSuccess(FETCH_PROJECT_TEAMS),
        teams: teams.map((team) => ({
            ...team,
            members: team.members.map((member) => mapUserAndProfileToUser(member))
        }))
    });
};

export const fetchProjectLastActivity = (uuid) => async (dispatch) => {
    dispatch({ type: FETCH_PROJECT_LAST_ACTIVITY });
    const response = await fetchPage({
        page: 0,
        log: { project_uuid: uuid },
        filters: [
            { type: 'tag', id: 'file_new' },
            { type: 'tag', id: 'folder_add' }
        ]
    });
    dispatch({
        type: asyncActionSuccess(FETCH_PROJECT_LAST_ACTIVITY),
        activity: response ? mapFetchProjectActivityToUpdate(response) : []
    });
};

export const fetchProjectActivity = (uuid, page) => async (dispatch) => {
    dispatch({ type: FETCH_PROJECT_ACTIVITY });
    const response = await fetchPage({
        page,
        log: { project_uuid: uuid },
        filters: []
    });
    dispatch({
        type: asyncActionSuccess(FETCH_PROJECT_ACTIVITY),
        response: response ? mapFetchProjectActivityToUpdate(response) : [],
        page
    });
};

export const fetchProjectCompanies = () => async (dispatch) => {
    dispatch({ type: FETCH_PROJECT_COMPANIES });
    const companies = await _fetchProjectCompanies();
    dispatch({ type: asyncActionSuccess(FETCH_PROJECT_COMPANIES), companies });
};

export const inviteMembersToTeam =
    (teamName, castTeamAccess, projectUuid, members, message, cb) => async (dispatch) => {
        dispatch({ type: TEAM_INVITE_MEMBERS });
        try {
            await _inviteMembersToTeam(teamName, castTeamAccess, projectUuid, members, message);
            dispatch({ type: asyncActionSuccess(TEAM_INVITE_MEMBERS) });
            if (typeof cb === 'function') cb(null);
        } catch (e) {
            if (typeof cb === 'function') cb(e);
        }
    };

export const setSelectedTeam = (selectedTeam) => ({
    type: SET_SELECTED_TEAM,
    selectedTeam
});

export const updateMember = (projectUuid, userUuid, data) => async (dispatch) => {
    dispatch({ type: UPDATE_MEMBER });
    await _updateMember(projectUuid, userUuid, data);
    dispatch({ type: asyncActionSuccess(UPDATE_MEMBER), projectUuid, userUuid, data });
};

export const updateTask = (taskUuid, data, cb) => async (dispatch) => {
    dispatch({ type: UPDATE_TASK });
    await _updateTask({ taskUuid, ...data });
    if (typeof cb === 'function') cb();
};

export const updateMultipleTaskStatusAndOrdering = (changes) => async (dispatch) => {
    dispatch({ type: UPDATE_TASK_STATUS_ORDER });
    await _updateMultipleTaskStatusAndOrdering(changes);
};

export const toggleFavorite = (projectUuid, favorite) => async (dispatch) => {
    dispatch({ type: TOGGLE_FAVORITE, favorite });
    await updateUserProjectMetadata({
        projectUuid,
        metadata: { favorite }
    });
    dispatch({ type: asyncActionSuccess(TOGGLE_FAVORITE), projectUuid, isFavorite: favorite });
};

export const updateProject = (body, cb) => async (dispatch) => {
    dispatch({ type: UPDATE_PROJECT });
    try {
        await _updateProject(body);
        if (typeof cb === 'function') cb(null);
    } catch (e) {
        if (typeof cb === 'function') cb(e);
    }
};

export const sioProjectTasksSocketAction = {
    sioTaskCreate: (task) => ({
        type: WS_TASK_CREATE,
        task
    }),
    sioTaskUpdate: ({ task, orderChangesByUuid }) => ({
        type: WS_TASK_UPDATE,
        task,
        orderChangesByUuid
    }),
    sioTaskDelete: (taskUuid) => ({
        type: WS_TASK_DELETE,
        taskUuid
    })
};

export const sioProjectSocketAction = {
    sioProjectsUpdate: (project) => ({
        type: WS_UPDATE_PROJECT,
        project
    }),
    sioProjectUpdate: (project) => ({
        type: WS_UPDATE_CURRENT_PROJECT,
        project
    }),
    sioProjectArchive: (project) => ({
        type: WS_ARCHIVE_PROJECT,
        project
    }),
    sioProjectDelete: ({ uuid }) => ({
        type: WS_DELETE_PROJECT,
        uuid
    }),
    sioProjectRestore: (project) => ({
        type: WS_RESTORE_PROJECT,
        project
    }),
    sioProjectCreate: (project) => ({
        type: WS_CREATE_PROJECT,
        project
    }),
    sioProjectDeleteMember: (data) => ({
        type: WS_DELETE_MEMBER,
        ...data
    }),
    sioProjectDeleteTeam: (data) => ({
        type: WS_TEAM_DELETE,
        ...data
    }),
    sioProjectUpdateTeam: (data) => ({
        type: WS_TEAM_UPDATE,
        ...data
    }),
    sioProjectUpdateMember: (data) => ({
        type: WS_UPDATE_MEMBER,
        ...data
    }),
    sioProjectTeamCreate: (data) => ({
        type: WS_TEAM_CREATE,
        ...data
    }),
    sioProjectTeamInvite: (data) => ({
        type: WS_TEAM_INVITE,
        ...data
    })
};
