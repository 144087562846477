import { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

const resizeCallbacks: (() => void)[] = [];
const handleResize = () => {
    resizeCallbacks.forEach((cb) => cb());
};
const useWindowSize = (throttleAmount?: number) => {
    const [size, setSize] = useState(
        typeof window === 'undefined' ? [500, 500] : [window.innerWidth, window.innerHeight]
    );

    useEffect(() => {
        let cb = () => setSize([window.innerWidth, window.innerHeight]);
        if (throttleAmount) cb = throttle(cb, throttleAmount);
        resizeCallbacks.push(cb);
        if (resizeCallbacks.length === 1) {
            window.addEventListener('resize', handleResize);
        }
        return () => {
            resizeCallbacks.splice(resizeCallbacks.indexOf(cb), 1);
            if (resizeCallbacks.length === 0) window.removeEventListener('resize', handleResize);
        };
    });

    return size;
};

export default useWindowSize;
