export const ELEMENT_ID_IMAGE = 'file-viewer';
export const ELEMENT_ID_IMAGE_COMPARISON = `${ELEMENT_ID_IMAGE}-comparison`;

export const ELEMENT_ID_VIDEO = 'file-player';
export const ELEMENT_ID_VIDEO_COMPARISON = `${ELEMENT_ID_VIDEO}-comparison`;
export const ELEMENT_ID_AUDIO = 'file-audio';
export const ELEMENT_ID_AUDIO_VISUALIZER = `${ELEMENT_ID_AUDIO}-visualizer`;
export const ELEMENT_ID_AUDIO_COMPARISON = `${ELEMENT_ID_AUDIO}-comparison`;
export const ELEMENT_ID_AUDIO_COMPARISON_VISUALIZER = `${ELEMENT_ID_AUDIO_COMPARISON}-visualizer`;

export const ELEMENT_ID_PUBLIC_IMAGE = 'public-file-viewer';
export const ELEMENT_ID_PUBLIC_VIDEO = 'public-file-player';
export const ELEMENT_ID_PUBLIC_AUDIO = 'public-file-audio';
export const ELEMENT_ID_PUBLIC_AUDIO_VISUALIZER = 'public-file-audio-visualizer';

export const TYPE_IMAGE = 'image';
export const TYPE_OFFICE = 'office';
export const TYPE_PDF = 'pdf';
export const TYPE_VIDEO = 'video';
export const TYPE_HTML = 'html';
