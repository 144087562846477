import { gangableByFile, gangableByFolder } from '$constants/constants.activityAction';
import { __ } from '@he-novation/design-system/utils/i18n';

// returns last group if matches type and uuid, or creates group and returns it
export const findMatchingGroup = (groups, key, parentUuid, userUuid, date = {}) => {
    const dateString = date.toLocaleDateString();
    const g = groups.find(
        (g) =>
            g.key === key &&
            g.parentUuid === parentUuid &&
            g.userUuid === userUuid &&
            g.date === dateString
    );
    if (g) return g;
    groups.push({
        key,
        items: [],
        parentUuid,
        userUuid,
        date: dateString,
        rawDate: date
    });
    return groups[groups.length - 1];
};

export const sortActivityEvent = (list) => {
    const groups = [];

    list.forEach((event, i) => {
        if (typeof event.created?.getDate !== 'function') {
            event.created = new Date(event.created);
        }

        if (gangableByFolder.includes(event.key)) {
            const group = findMatchingGroup(
                groups,
                event.key,
                event.folder_uuid,
                event.request_user_uuid,
                event.created
            );
            group.items.push(event);
        } else if (gangableByFile.includes(event.key)) {
            const group = findMatchingGroup(
                groups,
                event.key,
                event.file_uuid,
                event.request_user_uuid,
                event.created
            );
            group.items.push(event);
        } else {
            groups.push({
                key: event.key,
                items: [event],
                date: event.created.toLocaleDateString(),
                rawDate: event.created
            });
        }
    });

    groups.forEach((g) => {
        g.i18n = __(
            g.items.length > 1
                ? __(g.key + '_GROUPED', { n: g.items.length, ...g.items[0].values })
                : __(g.key, { ...(g.items[0].values?.i18n || {}), ...g.items[0].values })
        );
        g.index = process.env.OPEN_SEARCH_LEGACY_INDEX;
    });
    return groups;
};
