const strToQueryParams = (str) => {
    const queryParams = {};
    if (str.indexOf('?') > -1) {
        let query = str.split('?').pop();
        query = query.split('&');
        query.forEach((q) => {
            q = q.split('=');
            queryParams[q[0]] = q[1] || null;
        });
    }
    return queryParams;
};

export default strToQueryParams;
