import './SidePanelNotes.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FolderRole } from '@he-novation/config/types/folder.types';
import { Note } from '@he-novation/config/types/note.types';
import { ROLE_FOLLOWER, ROLE_SPECTATOR } from '@he-novation/config/utils/acl';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { MultiActionButton } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { MultiActionsList } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButtonList';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import { __ } from '@he-novation/design-system/utils/i18n';
import update from 'immutability-helper';
import NotesWithFilters from '../../Notes/NotesWithFilters';
import SidePanelNoteHeader from '../../SidePanel/SidePanelNotes/SidePanelNotesHeader/SidePanelNotesHeaderContainer';

import { CREATE_NOTE_PANEL, CREATE_TASK_PANEL } from '$constants/constants.sidePanels';
import { openPanel } from '$redux/sideMenu/sideMenuActions';
import { updateUserPreferences } from '$redux/user/userActions';

type SidePanelNotesProps = {
    currentProjectUuid: string;
    folderRole: FolderRole;
    sorters: string[];
    notes: Note[];
    payload: {
        isPublicFile?: boolean;
        password?: string;
    };
    castNotes: Note[];
    preferences: { ui: { videoSorter?: string } };
    fileType: string;
};

const SidePanelNotes = ({
    currentProjectUuid,
    folderRole,
    sorters,
    notes,
    payload: { isPublicFile, password } = {},
    castNotes,
    preferences,
    fileType
}: SidePanelNotesProps) => {
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [filters, setFilters] = useState({});
    const [sorter, setSorter] = useState(
        (fileType === 'video' && preferences?.ui?.videoSorter) || sorters[0] || 'date'
    );
    const dispatch = useDispatch();

    const actions: MultiActionsList = [];
    if ([ROLE_FOLLOWER, ROLE_SPECTATOR].indexOf(folderRole) === -1) {
        actions.push({
            children: __('REACT_ADD_COMMENT'),
            onClick: () => dispatch(openPanel(CREATE_NOTE_PANEL, { isPublicFile, password }))
        });

        if (currentProjectUuid)
            actions.unshift({
                children: __('REACT_CREATE_TASK'),
                onClick: () => dispatch(openPanel(CREATE_TASK_PANEL))
            });
    }

    useEffect(() => {
        if (
            fileType === 'video' &&
            preferences &&
            sorters.length &&
            sorter !== preferences?.ui?.videoSorter
        ) {
            if (!isPublicFile) {
                dispatch(
                    updateUserPreferences(
                        update(preferences, {
                            ui: {
                                videoSorter: { $set: sorter }
                            }
                        })
                    )
                );
            }
        }
    }, [sorter]);

    return (
        <div className="c-side-panel-notes">
            <SidePanelNoteHeader
                isFilterOpen={isFilterOpen}
                toggleFilter={() => setIsFilterOpen(!isFilterOpen)}
                filters={filters}
                isPublicFile={isPublicFile}
                displayCastNotes={castNotes.length}
            />

            <NotesWithFilters
                notes={notes}
                displayFilters={isFilterOpen}
                filters={filters}
                setFilters={setFilters}
                sorters={sorters}
                sorter={sorter}
                setSorter={setSorter}
                isPublicFile={isPublicFile}
                password={password}
                displayCastNotes={!!castNotes.length}
            />

            {actions && actions.length ? (
                actions.length > 1 ? (
                    <MultiActionButton
                        direction={[DirectionX.Center, DirectionY.Top]}
                        icon="reply"
                        tone={ButtonTone.Primary}
                        actions={actions}
                    >
                        {__('REACT_ANNOTATE')}
                    </MultiActionButton>
                ) : (
                    <Button
                        icon={'reply'}
                        tone={ButtonTone.Primary}
                        onClick={actions[0] ? actions[0].onClick : undefined}
                    >
                        {__('REACT_ANNOTATE')}
                    </Button>
                )
            ) : null}
        </div>
    );
};

export default SidePanelNotes;
