import Filters from '../../components/Filters/FiltersContainer';
import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { contactsSelector } from '$redux/content/contacts/contactsSelectors';
import FilterContactCheckbox from '../../components/Filters/FilterContactCheckbox';
import { FormFieldTypes } from '@he-novation/design-system/enums';
import combineSelectors from '../../helpers/combineSelectors';
import { __ } from '@he-novation/design-system/utils/i18n';
import { userInfosSelector } from '$redux/user/userSelectors';

export const mapContactsToCheckboxFilters = (list, isUserRoute) =>
    list.map(({ picture, firstname, lastname, email, user_uuid, uuid }) => ({
        label: firstname && lastname ? `${firstname} ${lastname}` : email,
        contact: {
            picture,
            firstname,
            lastname,
            email
        },
        value: isUserRoute ? user_uuid : uuid,
        type: FormFieldTypes.Checkbox
    }));

export const mapActivityTypesToCheckboxFilters = (types) =>
    types
        .map((type) => ({
            label: __(activityTypeToWordingKey(type)),
            value: type,
            type: FormFieldTypes.Checkbox
        }))
        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));

const ACTIVITY_TYPES = [
    'access_admittance',
    'access_grant',
    'access_modify',
    'access_revoke_my',
    'access_revoke',
    'client_user_delete',
    'client_user_disable',
    'client_user_enable',
    'client_user_transfer',
    'comment_add',
    'comment_modified',
    'comment_set_deleted',
    'comment_set_task',
    'file_add_subtitle',
    'file_delete_subtitle',
    'file_delete',
    'file_download_subtitle',
    'file_download',
    'file_export',
    'file_move',
    'file_new_version',
    'file_new',
    'file_rename',
    'file_set_final',
    'file_set_subtitle_locale',
    'file_set_subtitle_name',
    'file_vote_final',
    'folder_add',
    'folder_admittance',
    'folder_copy_tree',
    'folder_default_presets',
    'folder_delete',
    'folder_download',
    'folder_move',
    'folder_project_add',
    'folder_project_del',
    'folder_public_password_changed',
    'folder_public_password_disabled',
    'folder_public_password_enabled',
    'folder_public',
    'folder_rename',
    'folder_set_recursive',
    'folder_watermark',
    'project_add',
    'project_update',
    'task_add',
    'task_update'
];

export const activityTypeToWordingKey = (type) => `REACT_ACTIVITY_FILTER_${type.toUpperCase()}`;

export default compose(
    connect(combineSelectors(contactsSelector, userInfosSelector)),
    mapProps(({ contacts, userInfos, isUserRoute }) => ({
        id: 'activity-filters',
        checkboxLists: [
            {
                title: __('REACT_ACTIVITY_FILTER_SECTION_TYPES'),
                name: 'tags',
                hasSearch: true,
                onlyChecked: false,
                list: mapActivityTypesToCheckboxFilters(ACTIVITY_TYPES)
            },
            {
                title: __('REACT_ACTIVITY_FILTER_SECTION_USERS'),
                name: 'user_uuids',
                hasSearch: true,
                onlyChecked: false,
                list: mapContactsToCheckboxFilters([userInfos, ...contacts.list], isUserRoute),
                Component: FilterContactCheckbox
            }
        ],
        dates: [
            {
                title: __('REACT_ACTIVITY_FILTER_SECTION_DATE'),
                name: 'date_start',
                label: __('REACT_ACTIVITY_FILTER_SECTION_DATE_START')
            },
            {
                name: 'date_end',
                label: __('REACT_ACTIVITY_FILTER_SECTION_DATE_END')
            }
        ],
        wording: {
            filtersListLess: __('REACT_ACTIVITY_FILTER_LIST_LESS'),
            filtersListMore: __('REACT_ACTIVITY_FILTER_LIST_MORE'),
            filtersAll: __('FILTERS_ALL')
        }
    }))
)(Filters);
