import React from 'react';
import SidePanelList from '../../SidePanel/SidePanelList/SidePanelListContainer';
import { directoryItemKitLink } from '@he-novation/paths/herawFrontUris';
import { SidePanelHeader } from '@he-novation/design-system/components/sidePanels/SidePanel/SidePanelHeader/SidePanelHeader';
import { __ } from '@he-novation/design-system/utils/i18n';

const SidePanelItemKits = ({ canCreateItem, deleteItemKit, kits, openItemKitModal }) => (
    <>
        <SidePanelHeader
            title={__('ITEM_KITS')}
            buttons={[
                canCreateItem && {
                    icon: 'shapes-add',
                    onClick: openItemKitModal
                }
            ]}
        />

        <div className="c-side-item-kits">
            <SidePanelList
                deleteItem={({ uuid }) => deleteItemKit(uuid)}
                items={kits.list.map(({ name, items, uuid }) => ({
                    name,
                    count: items.length,
                    href: directoryItemKitLink(uuid),
                    uuid
                }))}
            />
        </div>
    </>
);

export default SidePanelItemKits;
