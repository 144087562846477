import './FiltersRadioList.scss';
import React from 'react';
import {
    StyledFormField,
    FormFieldTypes
} from '@he-novation/design-system/components/form/StyledFormField/StyledFormField';
import Icon from '@he-novation/icons';
import { withProps } from 'recompose';
import { Button } from '@he-novation/design-system/components/buttons/Button/Button';

class FiltersRadioList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            seeAll: false,
            search: null,
            searchActive: false
        };
        this.toggleSeeAll = this.toggleSeeAll.bind(this);
        this.toggleSearch = this.toggleSearch.bind(this);
        this.setSearch = this.setSearch.bind(this);
        this.resetSearch = this.resetSearch.bind(this);
    }

    render() {
        const { title, hasSearch, seeAllList } = this.props;
        const list = this.filter();

        return (
            <div className="c-filters-radio-list">
                {title && <h3>{title}</h3>}
                {hasSearch && <Button icon={'magnifier'} onClick={this.toggleSearch} />}
                {this.renderSearchField()}
                <div className="c-filters-radio-list-container">{this.renderList(list)}</div>

                {list.length > 5 && !hasSearch && (
                    <button className="see-more" type="button" onClick={this.toggleSeeAll}>
                        {!seeAllList
                            ? this.state.seeAll
                                ? __('filterListLess')
                                : __('filterListMore')
                            : null}
                    </button>
                )}
            </div>
        );
    }

    filter() {
        if (!this.state.search) return this.props.list;
        return this.props.list.filter(
            ({ label }) => label.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1
        );
    }

    isChecked(filters, filterName, checkboxValue) {
        return (
            (filters[filterName] === undefined && checkboxValue === '' && this.props.setAllInput) ||
            (filters[filterName] && filters[filterName].indexOf(checkboxValue) > -1)
        );
    }

    renderList(list) {
        const { setAllInput, filters, onlyChecked, filterName, formId, onChange, wording } =
            this.props;
        const { search, seeAll } = this.state;

        const _list = list;
        if (
            setAllInput &&
            !_list.find((el) => el.value === '' && el.label === __('FILTERS_ALL').toUpperCase())
        )
            _list.unshift({
                label: __('filtersAll').toUpperCase(),
                value: '',
                type: FormFieldTypes.Radio
            });

        const Component = this.props.Component || StyledFormField;

        list =
            !search && onlyChecked && filters[filterName] && filters[filterName].length
                ? _list.filter(({ value }) => filters[filterName].includes(value))
                : _list.slice(0, this.props.seeAllList ? list.length : seeAll ? list.length : 5);

        return list.map((checkbox, i) => (
            <Component
                formId={formId}
                id={`filter-radio-${filterName}-${i}`}
                type={Component === StyledFormField ? FormFieldTypes.Radio : null}
                key={checkbox.value}
                {...checkbox}
                search={search}
                checked={this.isChecked(filters, filterName, checkbox.value)}
                onChange={(e) => onChange(filterName, e)}
            />
        ));
    }

    renderSearchField() {
        if (this.props.hasSearch && this.state.searchActive) {
            return (
                <div className="c-filters-checkbox-list-search">
                    <StyledFormField
                        autoFocus
                        className="search-field"
                        formId={this.props.formId}
                        onChange={this.setSearch}
                        value={this.state.search || ''}
                        type={FormFieldTypes.Text}
                    />
                    {this.state.search && (
                        <button type="button" onClick={this.resetSearch}>
                            <Icon icon="cross2" />
                        </button>
                    )}
                </div>
            );
        }
    }

    toggleSeeAll() {
        this.setState({
            seeAll: !this.state.seeAll
        });
    }

    toggleSearch() {
        this.setState({
            searchActive: !this.state.searchActive
        });
    }

    setSearch(e) {
        this.setState({
            search: e.target.value
        });
    }

    resetSearch() {
        this.setState({
            search: null
        });

        document.querySelector('.search-field input').focus();
    }
}

/*
FiltersRadioList.propTypes = {
    hasSearch: PropTypes.bool,
    title: PropTypes.string,
    formId: PropTypes.string,
    list: PropTypes.arrayOf(
        PropTypes.shape({
            formId: PropTypes.string,
            label: PropTypes.node,
            type: PropTypes.string
        })
    ),
    filters: PropTypes.object,
    setAllInput: PropTypes.bool,
    onChange: PropTypes.func,
    locale: PropTypes.string,
    filterName: PropTypes.string,
    onlyChecked: PropTypes.bool,
    seeAllList: PropTypes.bool,
    Component: PropTypes.func,
    wording: PropTypes.shape({
        filterListLess: PropTypes.string,
        filterListMore: PropTypes.string,
        filtersAll: PropTypes.string
    })
};
*/

export default withProps(({ filters }) => ({
    filters: Object.keys(filters).reduce(
        (acc, curr) =>
            Array.isArray(filters[curr])
                ? { ...acc, [curr]: filters[curr] }
                : { ...acc, [curr]: [filters[curr]] },
        {}
    )
}))(FiltersRadioList);
