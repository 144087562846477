import './HourPicker.scss';
import React from 'react';
import { StyledFormFields } from '../StyledFormFields/StyledFormFields';
import { doubleDigit } from '../../../utils/doubleDigit';
import { Theme, FormFieldTypes } from '../../../enums';
import { __ } from '../../../utils/i18n';

type HourPickerProps = {
    id: string;
    onChange?: (e: any, value: string, timezoneOffset: number) => void;
    setTime: (value: string, timezoneOffset: number, callback: Function) => void;
    theme: Theme;
    time: string;
    timezoneOffset: number;
};

export class HourPicker extends React.Component<HourPickerProps> {
    render() {
        let minutes = 0;
        const options: { label: string; value: string }[] = [];
        while (minutes < 1440) {
            const hours = Math.floor(minutes / 60);
            const _minutes = minutes - hours * 60;
            const v = `${doubleDigit(hours)}:${doubleDigit(_minutes)}`;
            options.push({ label: v, value: v });
            minutes += 15;
        }

        return (
            <div className="c-hour-picker">
                <StyledFormFields
                    fields={[
                        {
                            label: __('HOUR'),
                            type: FormFieldTypes.Select,
                            options,
                            id: this.props.id,
                            value: this.props.time,
                            size: 'half',
                            onChange: this.onTimeChange,
                            className: ' inline margin-right with-padding-top'
                        },
                        {
                            label: __('TIMEZONE'),
                            type: FormFieldTypes.Select,
                            options: this.getTimeZones(),
                            value: this.props.timezoneOffset,
                            size: 'half',
                            className: ' inline margin-left with-padding-top',
                            onChange: this.onTimeZoneChange
                        }
                    ]}
                    theme={this.props.theme}
                />
            </div>
        );
    }

    getTimeZones() {
        const options: { label: string; value: string | number }[] = [];
        for (let i = -12; i < 14; i++) {
            options.push({
                label: `GMT${i < 0 ? ` ${i}` : i > 0 ? ` +${i}` : ''}`,
                value: i
            });
        }
        return options;
    }

    onTimeChange = (e: any, value: string) => {
        this.props.setTime(value, this.props.timezoneOffset, () => {
            if (typeof this.props.onChange === 'function')
                this.props.onChange(e, value, this.props.timezoneOffset);
        });
    };

    onTimeZoneChange = (e: any) => {
        this.props.setTime(this.props.time, parseInt(e.target.value), () => {
            if (typeof this.props.onChange === 'function')
                this.props.onChange(e, this.props.time, this.props.timezoneOffset);
        });
    };
}
