import {
    CSSProperties,
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import useWindowSize from '@he-novation/design-system/hooks/useWindowSize';

export type WindowingOptions = {
    itemHeight: number;
    itemWidth: number;
    itemsPerRow: number;
    minRows?: number;
    offset?: [number, number];
};

export type Windowing = {
    scrollContainerStyle?: CSSProperties;
    itemWrapperStyle?: CSSProperties;
    computeItemStyle: (index: number) => CSSProperties;
    visibleRange: [number, number];
};
const SCROLLBAR_HEIGHT = 30;
export function useWindowing(
    scrollContainer: MutableRefObject<HTMLElement | null>,
    w: WindowingOptions | undefined | null,
    items: number
): Windowing | null {
    const [_windowWidth, windowHeight] = useWindowSize();
    const [visibleRange, setVisibleRange] = useState<[number, number]>([0, 0]);
    const totalRows = useMemo(() => (w ? Math.ceil(items / w.itemsPerRow) : 0), [items, w]);
    useEffect(() => {
        if (!w || !scrollContainer.current) return;

        const visibleRows = Math.ceil(scrollContainer.current.offsetHeight / w.itemHeight);

        const onScroll = () => {
            if (!scrollContainer.current) return;
            let scrollTop = scrollContainer.current.scrollTop;

            scrollTop -= w.itemHeight * visibleRows;
            scrollTop = Math.max(
                0,
                Math.min(scrollTop, (totalRows - visibleRows * 2) * w.itemHeight)
            );

            const firstVisibleRow = Math.floor(scrollTop / w.itemHeight);
            const firstVisible = firstVisibleRow * w.itemsPerRow;
            const lastVisibleRow = Math.min(firstVisibleRow + visibleRows * 3, totalRows - 1);
            const lastVisible = lastVisibleRow * w.itemsPerRow + w.itemsPerRow - 1;
            setVisibleRange([firstVisible, lastVisible]);
        };
        onScroll();
        scrollContainer.current.addEventListener('scroll', onScroll);
        return () => {
            scrollContainer.current?.removeEventListener('scroll', onScroll);
        };
    }, [scrollContainer, w, totalRows, items, windowHeight]);

    const computeItemStyle = useCallback(
        (index: number): CSSProperties => {
            if (!w) return {};
            const itemRow = Math.floor(index / w.itemsPerRow);

            return {
                position: 'absolute',
                height: w.itemHeight,
                top: itemRow * w.itemHeight + (w.offset?.[1] || 0),
                left: (index % w.itemsPerRow) * w.itemWidth + (w.offset?.[0] || 0)
            };
        },
        [w]
    );
    if (!w || (typeof w.minRows !== 'undefined' && w.minRows > totalRows)) return null;

    return {
        scrollContainerStyle: {
            overflowY: 'auto'
        },
        itemWrapperStyle: {
            position: 'relative',
            minHeight: totalRows * w.itemHeight + SCROLLBAR_HEIGHT
        },
        computeItemStyle,
        visibleRange
    };
}
