import { UserPrefs, UserState } from '@he-novation/config/types/user.types';
import plugins from '@he-novation/config/utils/plugins';
import { compose, find, includes, pick, pipe, property } from 'lodash/fp';
import { camelCase, upperFirst } from 'lodash/fp';
import { CLIENT_PRIVATE_NAME } from '../config/constants';
import { USER } from './userActions';

import combineSelectors from '$helpers/combineSelectors';
import { ReduxState } from '$redux/store';

const stateSelector: (state: ReduxState) => UserState = property(USER);

export const fetchedAccountSelector = compose(
    ({ fetchedAccount }) => ({
        fetchedAccount: fetchedAccount
    }),
    stateSelector
);

export const storageSelector = compose(
    (storage = {}) => ({
        storage
    }),
    property('storage'),
    stateSelector
);

export const userLicensesSelector = pipe(stateSelector, ({ licenses }) => licenses);

export const customerSelector = pipe(stateSelector, pick('customer'));

export const licensesSelector = pipe(stateSelector, pick('licenses'));

export const avatarSelector = compose(
    ({ email, firstname, lastname, picture }) => ({
        avatar: {
            email,
            firstname,
            lastname,
            picture
        }
    }),
    stateSelector
);

export const clientNameSelector = pipe(stateSelector, ({ clientName }) => ({ clientName }));
export const clientUuidSelector = pipe(stateSelector, ({ clientUuid }) => ({ clientUuid }));

export const currentUserSelector = pipe(stateSelector, (user) => ({ currentUser: user }));

export const currentUserUuidSelector = pipe(stateSelector, ({ uuid }) => ({
    currentUserUuid: uuid
}));
export const currentUserClientNameSelector = pipe(clientNameSelector, ({ clientName }) => ({
    currentUserClientName: clientName
}));
export const userUuidSelector = pipe(stateSelector, property('uuid'), (userUuid: string) => ({
    userUuid
}));

export const pluginsSelector = pipe(stateSelector, pick('plugins'));
export const pluginsEnabledSelector = pipe(stateSelector, pick('pluginsEnabled'));

export const hasPluginSelector = (plugin: string) =>
    pipe(stateSelector, property('plugins'), includes(plugin), (hasPlugin: boolean) => ({
        [`hasPlugin${upperFirst(camelCase(plugin))}`]: hasPlugin
    }));

export const hasAnExportPluginSelector = pipe(
    stateSelector,
    property('plugins'),
    (_plugins: string[]) => {
        const exportPlugins = plugins.getTypes.export();
        for (let i = 0, iLength = exportPlugins.length; i < iLength; i++) {
            if (_plugins.includes(exportPlugins[i])) {
                return true;
            }
        }
        return false;
    },
    (hasAnExportPlugin) => ({ hasAnExportPlugin })
);

export const isPrivateSelector = compose(
    ({ clientName }) => ({
        isPrivate: clientName === CLIENT_PRIVATE_NAME
    }),
    stateSelector
);

export const pluginEnabledSelectorFromProperties = (
    type: string,
    propertyAccessor: string,
    propertyValue: string | number
) =>
    pipe(
        stateSelector,
        property('pluginsEnabled'),
        find(
            (p: Record<string, string | number>) =>
                p.type === type && property(propertyAccessor)(p) === propertyValue
        )
    );

export const preferencesSelector: (state: ReduxState) => { preferences: UserPrefs } = pipe(
    stateSelector,
    property('preferences'),
    (preferences?: UserPrefs) => {
        if (!preferences) preferences = { ui: {} };
        if (!preferences.ui) preferences.ui = {};
        return { preferences };
    }
);

export const preferencesSortersAndGroupersSelector = (preferencesGrouperAndSorterName: string) =>
    pipe(
        stateSelector,
        property(`preferences.ui.sortersAndGroupers.${preferencesGrouperAndSorterName}`),
        (preferencesSortersAndGroupers) => ({
            preferencesSortersAndGroupers: preferencesSortersAndGroupers || {}
        })
    );

export const rightsPreferencesSelector = pipe(
    stateSelector,
    property('preferences.rights'),
    (rightsPreferences = {}) => ({ rightsPreferences })
);

export const uiPreferencesSelector = pipe(
    stateSelector,
    property('preferences.ui'),
    (uiPreferences) => ({ uiPreferences })
);

export const totpEnabledSelector = pipe(stateSelector, property('totp_enabled'), (totpEnabled) => ({
    totpEnabled: !!totpEnabled
}));

export const requestAndAccessTokenSelector = pipe(
    stateSelector,
    pick(['accessToken', 'requestToken'])
);

export const clientRightsSelector = pipe(stateSelector, pick('clientRights'));

export const canCreateProjectsSelector = pipe(
    combineSelectors(clientRightsSelector, pluginsSelector, stateSelector),
    ({ storage, storage_expires, clientRights, clientName, plugins: _plugins }) => {
        if (clientName === 'private') {
            return {
                canCreateProjects:
                    storage.total > 0 &&
                    (!storage_expires ||
                        storage_expires === '0000-00-00 00:00:00' ||
                        new Date().getTime() < new Date(storage_expires).getTime())
            };
        } else {
            return {
                canCreateProjects:
                    clientRights && clientRights.projectAdd && _plugins.indexOf('projects') > -1
            };
        }
    }
);

export const hasClientRightSelector = (right: string) =>
    pipe(stateSelector, property('clientRights'), property(right), (hasClientRight) => ({
        [`hasClientRight${upperFirst(right)}`]: hasClientRight
    }));

export const canCreateCompanySelector = pipe(
    hasClientRightSelector('companyAdd'),
    ({ hasClientRightCompanyAdd }) => ({
        canCreateCompany: hasClientRightCompanyAdd
    })
);

export const canCreateItemSelector = pipe(
    combineSelectors(hasPluginSelector('items'), hasClientRightSelector('itemAdd')),
    ({ hasPluginItems, hasClientRightItemAdd }) => ({
        canCreateItem: hasPluginItems && hasClientRightItemAdd
    })
);

export const canDisplayCompaniesSelector = pipe(
    clientNameSelector,
    ({ clientName }) => clientName !== 'private',
    (canDisplayCompanies: boolean) => ({ canDisplayCompanies })
);

export type UserInfos = {
    city?: string;
    clientName: string;
    clientUuid: string;
    country?: string;
    created: Date;
    email: string;
    firm?: string;
    firstname?: string;
    lastname?: string;
    password_reset: boolean;
    phone?: string;
    picture?: string;
    role?: string;
    uuid: string;
};

export const userInfosSelector = pipe(
    stateSelector,
    pick([
        'city',
        'clientName',
        'clientUuid',
        'country',
        'created',
        'email',
        'firm',
        'firstname',
        'lastname',
        'password_reset',
        'phone',
        'picture',
        'role',
        'uuid'
    ]),
    (userInfos: UserInfos) => ({ userInfos })
);

export const emailSelector = pipe(stateSelector, pick('email'));

export const userInfosAsInfosSelector = pipe(userInfosSelector, ({ userInfos }) => ({
    infos: userInfos
}));

export const userProjectsSelector = pipe(stateSelector, property('projects'), (userProjects) => ({
    userProjects
}));

export const userEventsSelector = pipe(stateSelector, property('events'), (userEvents) => ({
    userEvents
}));

export const isClientSelector = pipe(stateSelector, property('isClient'));

export const rootFolderUuidSelector = pipe(stateSelector, pick('rootFolderUuid'));
