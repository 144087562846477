import './Datepicker.scss';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { FieldComponentProps } from 'react-modular-forms/dist/declarations/types';
import { DatePicker as DatePickerLib } from 'react-calendars';
import { FormField } from '../../FormField';
import { doubleDigit } from '../../../../../utils/doubleDigit';
import { Option } from '../ReactSelect/ReactSelect';
import { renderToString } from 'react-dom/server';

type DatePickerProps = FieldComponentProps & {
    withTime?: boolean;
    direction?: 'TOP' | 'BOTTOM';
    value?: Date;
};

let minutes = 0;
const timeOptions: Option[] = [];
while (minutes < 1440) {
    const hours = Math.floor(minutes / 60);
    const _minutes = minutes - hours * 60;
    const v = `${doubleDigit(hours)}:${doubleDigit(_minutes)}`;
    timeOptions.push({ label: v, value: minutes * 60 * 1000 });
    minutes += 15;
}

const timezoneOptions: Option[] = [];
for (let i = -12; i < 14; i++) {
    timezoneOptions.push({
        label: `GMT${i < 0 ? ` ${i}` : i > 0 ? ` +${i}` : ''}`,
        value: i
    });
}

const getBrowserTimezone = () => -new Date().getTimezoneOffset() / 60;

const applyTime = (input: Date | null, msTime: number, timezone: number) => {
    if (!input) return null;
    const deltaTimezone = getBrowserTimezone() - timezone;

    const date = new Date(input);

    const timeDate = new Date(msTime);
    date.setHours(timeDate.getUTCHours() + deltaTimezone);
    date.setMinutes(timeDate.getUTCMinutes());
    date.setSeconds(timeDate.getUTCSeconds());
    date.setMilliseconds(timeDate.getUTCMilliseconds());
    return date;
};

export function DatePicker({
    type,
    disabled,
    id,
    name,
    direction,
    withTime,
    theme,
    utcDate,
    value: _value,
    onBlur,
    onFocus,
    onChange,
    errors,
    validation,
    componentRef,
    setComponentRef,
    children,
    formId,
    ...intrinsic
}: DatePickerProps) {
    const [value, setValue] = useState<Date | null>(_value || null);
    const currentMs = value ? (value.getHours() * 60 + value.getMinutes()) * 60 * 1000 : 0;
    const [time, setTime] = useState(
        timeOptions.find((curr) => curr.value >= currentMs)?.value || timeOptions[0].value
    );
    const [timezone, setTimezone] = useState(getBrowserTimezone());

    useEffect(() => {
    componentRef.current = _value || null;
       }, []);

    const updateDate = (e: SyntheticEvent, date: Date, time?: number) => {
        if (withTime && time) {
            date = applyTime(date, time, timezone) as Date;
        } else {
            if (utcDate) {
                date = applyTime(date, 0, 0) as Date;
            }
        }
        setValue(date);
        componentRef.current = date;
        onChange?.(e, date);
    };

    return (
        <>
            <div className="date-wrapper">
                <DatePickerLib
                    displayYearPicker={false}
                    renderMonthTitle={(month: number, year: number) => (
                        <>
                            {month} {year}
                        </>
                    )}
                    selectedDay={value}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    className={withTime && 'withTime'}
                    direction={direction}
                    onChange={(e: SyntheticEvent, date: Date) => updateDate(e, date, time)}
                    onSelect={(e: SyntheticEvent, date: Date) => updateDate(e, date, time)}
                />
                {withTime && (
                    <>
                        <FormField
                            placeholder="00:00"
                            menuClassName="date-picker-time-menu"
                            value={time}
                            isSearchable
                            onChange={(e, t) => {
                                setTime(t);
                                if (value) {
                                    updateDate(e, value, t);
                                }
                            }}
                            theme={theme}
                            id={`${formId}-${id | name}-time`}
                            type="react-select"
                            options={timeOptions}
                            filterOption={(option: Option, input: string) => {
                                const filteredOptionLabel = renderToString(
                                    <> {option.label}</>
                                ).replace(/^\s*<!-- -->\s*/g, '');
                                const refiltered = filteredOptionLabel.replace(/^0/, '');
                                const filteredInput = input;
                                return (
                                    filteredOptionLabel.startsWith(filteredInput) ||
                                    refiltered.startsWith(filteredInput)
                                );
                            }}
                        />
                        <FormField
                            placeholder="GMT"
                            menuClassName="date-picker-time-menu"
                            value={timezone}
                            onChange={(_, t) => setTimezone(t)}
                            theme={theme}
                            id={`${formId}-${id | name}-timezone`}
                            type="react-select"
                            options={timezoneOptions}
                        />
                    </>
                )}
            </div>
        </>
    );
}
