import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Subtitle } from '@he-novation/config/types/subtitle.types';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { SidePanelHeader } from '@he-novation/design-system/components/sidePanels/SidePanel/SidePanelHeader/SidePanelHeader';
import { Tooltip } from '@he-novation/design-system/components/widgets/Tooltip/Tooltip';
import { Theme } from '@he-novation/design-system/enums';
import { __ } from '@he-novation/design-system/utils/i18n';
import { downloadLink } from '@he-novation/front-shared/utils/downloadLink';
import Icon from '@he-novation/icons';
import { subtitlesFileLink } from '@he-novation/paths/herawFrontUris';
import {
    SUBTITLES,
    SUBTITLES_CREATE,
    SUBTITLES_GENERATE
} from '@he-novation/paths/modals.constants';

import { SubtitleLabel } from '$components/text/SubtitleLabel';
import { subtitleTypesToData } from '$constants/constants.subtitles';
import { setActiveSubtitles, subtitlesFetch } from '$redux/content/file/fileActions';
import { i18nLanguageAndCountryCodesSelector } from '$redux/i18n/i18nSelectors';
import { openModal as openModalAction } from '$redux/route/routeActions';
import { hasPluginSelector } from '$redux/user/userSelectors';

type SidePanelSubtitlesHeaderProps = {
    fileUuid: string;
    fileVersion: number;
    activeSubtitle?: Subtitle;
    subtitles: Subtitle[];
    frameRate: number;
    addEntry: () => void;
};

const openModalFactory =
    (dispatch: (payload: Record<string, unknown>) => void) =>
    (
        modal: string,
        payload: Record<string, unknown>,
        extra?: Record<string, unknown>,
        silent?: boolean
    ) =>
        dispatch(openModalAction(modal, payload, extra, silent));
export function SidePanelSubtitlesHeader({
    fileUuid,
    fileVersion,
    activeSubtitle,
    subtitles,
    frameRate,
    addEntry
}: SidePanelSubtitlesHeaderProps) {
    const dispatch = useDispatch();
    const openModal = openModalFactory(dispatch);
    const { hasPluginSpotl } = useSelector(hasPluginSelector('spotl'));
    const languagesAndCountryCodes = useSelector(i18nLanguageAndCountryCodesSelector);

    return (
        <SidePanelHeader
            title={__('SUBTITLES')}
            buttons={[
                {
                    icon: 'subtitles-add',
                    actions: [
                        {
                            children: __('SUBTITLES_UPLOAD'),
                            onClick: () =>
                                openModal(
                                    SUBTITLES,
                                    {
                                        onClose: () =>
                                            dispatch(subtitlesFetch(fileUuid, fileVersion))
                                    },
                                    {
                                        uuid: fileUuid,
                                        v: fileVersion
                                    },
                                    false
                                )
                        },
                        {
                            children: __('SUBTITLES_CREATE'),
                            onClick: () =>
                                openModal(
                                    SUBTITLES_CREATE,
                                    { onClose: () => {} },
                                    {
                                        uuid: fileUuid,
                                        v: fileVersion
                                    }
                                )
                        },
                        hasPluginSpotl && {
                            children: __('SUBTITLES_GENERATE'),
                            onClick: () => openModal(SUBTITLES_GENERATE, { fileUuid, fileVersion })
                        }
                    ]
                },
                activeSubtitle
                    ? {
                          icon: 'dashed-add',
                          actions: [{ onClick: addEntry }]
                      }
                    : null,
                activeSubtitle
                    ? {
                          icon: 'download',
                          actions: [
                              activeSubtitle.assetUrl
                                  ? {
                                        children: __('DOWNLOAD_SOURCE'),
                                        href: activeSubtitle.assetUrl,
                                        external: true,
                                        download: activeSubtitle.name
                                    }
                                  : null,
                              {
                                  children: __('EXPORT_SRT'),
                                  onClick: () =>
                                      downloadLink(
                                          subtitlesFileLink(activeSubtitle.uuid, 'srt', frameRate),
                                          activeSubtitle.name.replace(/\.[a-zA-Z0-9]*$/, '.srt'),
                                          true
                                      )
                              },
                              {
                                  children: __('EXPORT_STL'),
                                  onClick: () =>
                                      downloadLink(
                                          subtitlesFileLink(
                                              activeSubtitle.uuid,
                                              'stl-ebu',
                                              frameRate
                                          ),
                                          activeSubtitle.name.replace(/\.[a-zA-Z0-9]*$/, '.stl'),
                                          true
                                      )
                              },
                              {
                                  children: __('EXPORT_VTT'),
                                  onClick: () =>
                                      downloadLink(
                                          subtitlesFileLink(activeSubtitle.uuid, 'vtt', frameRate),
                                          activeSubtitle.name.replace(/\.[a-zA-Z0-9]*$/, '.vtt'),
                                          true
                                      )
                              }
                          ]
                      }
                    : null
            ]}
        >
            <FormField
                type={'react-select'}
                id="subtitles-panel-select"
                theme={Theme.Dark}
                onChange={(_e, value) => {
                    dispatch(setActiveSubtitles(value !== 'null' ? [value] : []));
                }}
                value={activeSubtitle ? activeSubtitle.uuid : 'null'}
                isOptionDisabled={(option: { disabled?: boolean }) => option.disabled}
                menuClassName="subtitles-select-menu"
                options={[
                    { label: __('SELECT'), value: 'null' },
                    ...subtitles.map(({ type, name, uuid, spotlTranslation, locale }) => ({
                        disabled: spotlTranslation && spotlTranslation.status !== 'done',
                        label: (
                            <span>
                                {spotlTranslation && spotlTranslation.status !== 'done' ? (
                                    <Tooltip
                                        content={
                                            spotlTranslation.verified
                                                ? __('TRANSLATION_AI_VERIFIED_IN_PROGRESS')
                                                : __('TRANSLATION_AI_IN_PROGRESS')
                                        }
                                    >
                                        <Icon icon="clock" />
                                    </Tooltip>
                                ) : (
                                    <Tooltip content={__(subtitleTypesToData[type].tooltip)}>
                                        <Icon icon={subtitleTypesToData[type].icon} />
                                    </Tooltip>
                                )}
                                <SubtitleLabel
                                    language={locale}
                                    languagesAndCountryCodes={languagesAndCountryCodes!}
                                />
                                <span className="name">&nbsp;- {name}</span>
                            </span>
                        ),
                        value: uuid
                    }))
                ]}
            />
        </SidePanelHeader>
    );
}
