import SidePanel from './SidePanel/SidePanelContainer';
import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import routes from '../routes';
import { setRoutes } from '$redux/route/routeActions';
import { queryParamAsPropSelector } from '$redux/route/routeSelectors';
import { openPanel } from '$redux/sideMenu/sideMenuActions';

const SidePanelsContainer = compose(
    connect(queryParamAsPropSelector('sidePanel'), (dispatch) => ({
        setRoutes() {
            const _routes = [];
            for (let key in routes) {
                _routes.push(key);
            }
            dispatch(setRoutes(_routes));
        },
        openPanel: (panel, payload) => dispatch(openPanel(panel, payload))
    })),
    lifecycle({
        componentDidMount() {
            this.props.setRoutes();
            if (this.props.sidePanel) {
                this.props.openPanel(this.props.sidePanel);
            }
        },

        componentDidUpdate(prevProps) {
            if (this.props.sidePanel && this.props.sidePanel !== prevProps.sidePanel) {
                this.props.openPanel(this.props.sidePanel);
            }
        }
    })
)(SidePanel);

export default SidePanelsContainer;
