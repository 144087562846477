import { __ } from '@he-novation/design-system/utils/i18n';
import { fetchAsFormData } from '@he-novation/front-shared/async/apiFetch';

import {
    FinishedUpload,
    PendingUpload,
    ProgressCallback,
    Upload,
    UploadCallback,
    UploadError,
    UploaderState,
    UploadFolder,
    UploadProgress
} from '$helpers/Uploader.types';
import { openFeedbackModal } from '$redux/route/routeActions';
import store from '$redux/store';

// exponential backoff
function exponentialBackoff(retries: number) {
    return 2 * retries * 2000 + 1000 * Math.random();
}
const MAX_CONCURRENT_UPLOADS = 5;

export class Uploader {
    public static uploads: Upload[] = [];

    private static setStateFunctions: ((state: UploaderState) => void)[] = [];
    private static pending: PendingUpload[] = [];
    private static finished: FinishedUpload[] = [];
    private static errors: UploadError[] = [];

    public static register(setState: (state: UploaderState) => void) {
        Uploader.setStateFunctions.push(setState);
        setState(Uploader.getData());
    }

    public static unregister(setState: (state: UploaderState) => void) {
        Uploader.setStateFunctions.splice(Uploader.setStateFunctions.indexOf(setState), 1);
    }

    public static resetInvalidFiles() {}

    public static async upload(
        {
            uploadGroup,
            file,
            folder,
            parentFileUuid,
            uploadIndex,
            uploadsTotal
        }: {
            uploadGroup: string;
            file: File;
            folder: UploadFolder;
            parentFileUuid?: string;
            uploadIndex: number;
            uploadsTotal: number;
        },
        {
            onPending,
            onPendingStart,
            onError,
            debounceProgressMs
        }: {
            onPending?: UploadCallback;
            onPendingStart?: UploadCallback;
            onError?: (uploadGroup: string, file: File, folder: UploadFolder, e: Error) => void;
            debounceProgressMs?: number;
        } = {}
    ) {
        const now = Date.now();

        if (Uploader.uploads.length >= MAX_CONCURRENT_UPLOADS) {
            Uploader.pending.push({
                uploadGroup,
                uploadIndex,
                uploadsTotal,
                file,
                folder
            });
            if (typeof onPending === 'function') onPending(uploadGroup, file, folder);
            return;
        }
        Uploader.uploads.push({
            uploadGroup,
            file,
            folder,
            uploadIndex,
            uploadsTotal,
            progression: {
                bitrate: 0,
                lastTick: now,
                loaded: 0,
                progress: 0,
                startTime: now,
                total: 1,
                remainingMs: null
            }
        });
        try {
            await Uploader.asyncUpload(
                { uploadGroup, file, folder, parentFileUuid },
                { uploadIndex, uploadsTotal },
                {
                    debounceProgressMs
                }
            );

            const i = Uploader.uploads.findIndex(({ file: _file }) => file === _file);
            const [finished] = Uploader.uploads.splice(i, 1);
            clearTimeout(finished.timeout);
            Uploader.finished.push({
                uploadGroup,
                uploadIndex,
                uploadsTotal,
                file,
                folder,
                startedAt: finished.progression.startTime,
                finishedAt: Date.now()
            });
        } catch (e) {
            console.error('static upload', uploadGroup, file, folder, e);
            onError?.(uploadGroup, file, folder, e);
            const i = Uploader.uploads.findIndex(({ file: _file }) => file === _file);
            const [{ timeout }] = Uploader.uploads.splice(i, 1);
            clearTimeout(timeout);
            Uploader.errors.push({
                uploadGroup,
                uploadIndex,
                uploadsTotal,
                file,
                folder,
                error: e
            });
            if (e?.error?.message) {
                store.dispatch(openFeedbackModal(__(e.error.message)));
            }
        }
        Uploader.setStateFunctions.forEach((setState) => setState(Uploader.getData()));

        if (!Uploader.pending.length) return;
        if (!Uploader.pending.length && Uploader.uploads.length < MAX_CONCURRENT_UPLOADS) return;

        const pending = Uploader.pending.shift()!;
        if (typeof onPendingStart === 'function') onPendingStart(uploadGroup, file, folder);
        await Uploader.upload(pending);
    }

    private static async asyncUpload(
        {
            uploadGroup,
            file,
            folder,
            parentFileUuid
        }: {
            uploadGroup: string;
            file: File;
            parentFileUuid?: string;
            folder: UploadFolder;
        },
        {
            uploadIndex,
            uploadsTotal,
            retries
        }: {
            uploadIndex: number;
            uploadsTotal: number;
            retries?: number;
        },
        {
            onUploadProgress,
            debounceProgressMs
        }: {
            onUploadProgress?: ProgressCallback;
            debounceProgressMs?: number;
        } = {}
    ) {
        let cancel: () => void;

        try {
            return await fetchAsFormData('/upload', {
                method: 'POST',
                body: {
                    parent: folder.uuid, // order is important, parent should be sent first
                    upload_group: uploadGroup,
                    upload_group_id: uploadIndex,
                    upload_group_count: uploadsTotal,
                    parent_file: parentFileUuid,
                    file_name: file.name,
                    file_upload_message: '',
                    files: [file]
                },
                onUploadProgress: (e: UploadProgress) => {
                    Uploader.onUploadProgress(
                        file,
                        e,
                        onUploadProgress,
                        cancel,
                        debounceProgressMs
                    );
                }
            });
        } catch (e) {
            if (e.name === 'ERR_CONCURRENT_UPLOADS') {
                return new Promise((resolve, reject) => {
                    setTimeout(async () => {
                        try {
                            await Uploader.asyncUpload(
                                { uploadGroup, file, folder, parentFileUuid },
                                { uploadIndex, uploadsTotal, retries: (retries || 0) + 1 }
                            );
                            resolve(null);
                        } catch (e) {
                            reject(e);
                        }
                    }, exponentialBackoff(retries || 0));
                });
            }
            throw e;
        }
    }

    private static onUploadProgress = (
        file: File,
        e: UploadProgress,
        onUploadProgress?: ProgressCallback,
        cancel?: () => void,
        debounceProgressMs = 200
    ) => {
        const upload = Uploader.uploads.find(({ file: _file }) => file === _file);
        if (!upload) return;
        if (!upload.progression.debounce) {
            upload.progression.debounce = true;
            const now = Date.now();
            const elapsed = now - upload.progression.lastTick;
            upload.progression.bitrate = e.bytes / elapsed; // bytes per MS
            upload.progression.remainingMs = (e.total - e.loaded) / upload.progression.bitrate; //ms
            upload.progression.loaded = e.loaded;
            upload.progression.total = e.total;
            upload.progression.progress = (e.loaded / e.total) * 100;
            upload.progression.lastTick = now;
            const data = Uploader.getData();
            Uploader.setStateFunctions.forEach((setState) => setState(data));
            if (onUploadProgress) onUploadProgress(e, upload, cancel);
            upload.timeout = setTimeout(() => {
                if (upload) upload.progression.debounce = false;
            }, debounceProgressMs);
        }
    };

    private static getData(): UploaderState {
        return {
            uploads: Uploader.uploads,
            pending: Uploader.pending,
            finished: Uploader.finished,
            errors: Uploader.errors
        };
    }
}
