import React from 'react';
import type { ReactNode } from 'react';
import './MultipleAvatars.scss';
import { Tooltip } from '../../widgets/Tooltip/Tooltip';
import classNames from 'classnames';
import { AvatarSize, AvatarUser } from '../Avatar/AvatarUser';
import { IAvatar } from '../Avatar/Avatar';
import { Theme } from '../../../enums';

const renderList = (Component: IAvatar, avatars: any[], maxAvatars: number) =>
    avatars.slice(maxAvatars).map((props) => (
        <>
            <div>{Component.propsToTooltip(props)}</div>
        </>
    ));

export type MultipleAvatarsProps = {
    avatars: any[];
    className?: string;
    Component?: IAvatar;
    maxAvatars?: number;
    onClick?: (avatar: any) => void;
    renderAvatarChildren?: (avatar: any) => ReactNode;
    square?: boolean;
    theme?: Theme;
    overlap?: boolean;
    renderMoreContent?: (avatars: any[], maxAvatars?: number) => ReactNode;
    children?: ReactNode | ReactNode[];
    size?: AvatarSize;
};

export const MultipleAvatars: React.FC<MultipleAvatarsProps> = ({
    avatars,
    children,
    className,
    Component = AvatarUser,
    maxAvatars = 3,
    onClick,
    renderAvatarChildren,
    square,
    theme = Theme.Light,
    overlap = false,
    renderMoreContent,
    size = AvatarSize.Medium
}) => {
    let _avatars = [...avatars];
    const displayMore = maxAvatars > -1 && avatars.length - maxAvatars > 0;
    if (displayMore) _avatars = _avatars.slice(0, maxAvatars);
    return (
        <div
            className={classNames(
                'c-multiple-avatars',
                className,
                theme,
                overlap && 'has-overlap',
                size && `is-${size}`
            )}
        >
            <div className="avatars">
                {_avatars.map((avatar) => (
                    <Component
                        key={JSON.stringify(avatar)}
                        onClick={onClick}
                        square={square}
                        tooltip={true}
                        size={size || 'small'}
                        {...avatar}
                    >
                        {typeof renderAvatarChildren === 'function'
                            ? renderAvatarChildren(avatar)
                            : null}
                    </Component>
                ))}
            </div>
            {displayMore ? (
                <div className="more-avatars">
                    {renderMoreContent ? (
                        renderMoreContent(avatars, maxAvatars)
                    ) : (
                        <Tooltip content={() => renderList(AvatarUser, avatars, maxAvatars)}>
                            <>+{avatars.length - maxAvatars}</>
                        </Tooltip>
                    )}
                </div>
            ) : null}

            {children && <div className="children">{children}</div>}
        </div>
    );
};
