import { Item, ItemKit, ItemWithEvents } from '@he-novation/config/types/item.types';
import { ItemCreateBody, ItemUpdateBody } from '@he-novation/config/types/payloads/item.payload';
import paths, { pathWithParams } from '@he-novation/paths/herawApiPaths';
import { isNull, omitBy } from 'lodash/fp';
import { apiFetch } from './apiFetch';
import { asyncAssetStatusUpdate } from './asset.async';

export const itemsFetch = (clientUuid?: string): Promise<Item[]> =>
    apiFetch(pathWithParams(paths.item.multiple, { clientUuid }));

export const itemsFetchWithEvents = (clientUuid) => {
    return apiFetch(pathWithParams(paths.item.multipleEvents, { clientUuid })).then((items) =>
        items.map(({ events, ...item }) => ({
            ...item,
            events: events.map(({ startDate, endDate, ...event }) => ({
                startDate: new Date(startDate),
                endDate: new Date(endDate),
                ...event
            }))
        }))
    );
};

export const itemKitsFetch = (): Promise<ItemKit[]> => apiFetch(paths.item.kitMultiple);

export const itemsWithProjectItemsFetch = () => apiFetch(paths.item.all);

export const itemCreate = ({
    name,
    serialNumber,
    category,
    description,
    clientItemLabelUids
}: ItemCreateBody): Promise<Item> =>
    apiFetch(paths.item.single, {
        method: 'POST',
        body: JSON.stringify(
            omitBy(isNull, {
                name,
                serialNumber,
                category,
                description,
                clientItemLabelUids
            })
        )
    });
export const itemKitCreate = ({ name }: { name: string }): Promise<ItemKit> =>
    apiFetch(paths.item.kitSingle, {
        method: 'POST',
        body: JSON.stringify({
            name
        })
    });

export const itemDelete = (itemUuid: string) =>
    apiFetch(pathWithParams(paths.item.specific, { itemUuid }), {
        method: 'DELETE'
    });

export const itemKitDelete = (itemKitUuid: string) =>
    apiFetch(pathWithParams(paths.item.kitSpecific, { itemKitUuid }), {
        method: 'DELETE'
    });

export const itemUpdate = (
    uuid: string,
    { name, serialNumber, category, description, clientItemLabelUids }: ItemUpdateBody
): Promise<Item> =>
    apiFetch(pathWithParams(paths.item.specific, { itemUuid: uuid }), {
        method: 'PUT',
        body: JSON.stringify(
            omitBy(isNull, {
                name,
                serialNumber,
                category,
                description,
                clientItemLabelUids
            })
        )
    });

export const itemKitEdit = ({ uuid, name }: { uuid: string; name: string }): Promise<ItemKit> =>
    apiFetch(pathWithParams(paths.item.kitSpecific, { itemKitUuid: uuid }), {
        method: 'PUT',
        body: JSON.stringify({
            name
        })
    });

export const itemKitAddItem = (itemKitUuid: string, itemUuid: string) =>
    apiFetch(pathWithParams(paths.item.kitSpecific, { itemKitUuid }), {
        method: 'POST',
        body: JSON.stringify({
            itemUuid
        })
    });

export const itemKitDeleteItem = (itemKitUuid: string, itemUuid: string) =>
    apiFetch(pathWithParams(paths.item.kitItem, { itemKitUuid, itemUuid }), {
        method: 'DELETE'
    });

export const asyncItemEventsFetch = (
    itemUuid: string,
    startDate?: Date,
    endDate?: Date
): Promise<ItemWithEvents> =>
    apiFetch(paths.item.specificEvents, {
        method: 'GET',
        params: { itemUuid },
        query: {
            start: startDate?.getTime().toString(),
            end: endDate?.getTime().toString()
        }
    }).then((r: ItemWithEvents) => {
        r.events = r.events.map((e) => ({
            ...e,
            startDate: new Date(e.startDate),
            endDate: new Date(e.endDate)
        }));
        return r;
    });

export const asyncProjectItemsFetch = (projectUuid: string) =>
    apiFetch(pathWithParams(paths.item.multipleProject, { projectUuid }));

export const asyncProjectItemKitsFetch = (projectUuid: string) =>
    apiFetch(pathWithParams(paths.item.kitMultipleProject, { projectUuid }));

export const asyncItemPictureUploadLink = async (itemUuid: string, blob: Blob) => {
    const { link, headers, assetUuid } = await apiFetch(
        pathWithParams(paths.item.specificPicture, { itemUuid }),
        {
            method: 'POST',
            body: {
                size: blob.size,
                contentType: blob.type
            }
        }
    );
    await fetch(link, {
        method: 'PUT',
        headers: {
            'Content-Type': blob.type,
            ...headers
        },
        body: blob
    });
    return await asyncAssetStatusUpdate(assetUuid, { status: 'READY', signUrl: true });
};
