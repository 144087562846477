import './View.scss';
import React, { ReactNode } from 'react';
import cn from 'classnames';

import PageTitle from '$components/base/titles/PageTitle/PageTitle';
import { ResponsiveViewTitle } from '$components/base/titles/ResponsiveViewTitle';

export type ViewProps = {
    className?: string;
    id: string;
    pageTitle?: any;
    viewTitle?: any;
    topContent?: ReactNode;
    children?: ReactNode | ReactNode[];
};

export const View = ({ children, className, id, pageTitle, viewTitle, topContent }: ViewProps) => {
    return (
        <div
            id={`view-${id}`}
            className={cn(
                className,
                viewTitle && 'has-view-title',
                topContent && 'has-top-content'
            )}
        >
            {pageTitle && <PageTitle {...pageTitle} />}
            {viewTitle && <ResponsiveViewTitle {...viewTitle} />}
            {topContent && <div className="top-content">{topContent}</div>}
            {children}
        </div>
    );
};
