import { CastContentFile, CastsState } from '@he-novation/config/types/cast.types';
import update from 'immutability-helper';
import type { Action } from 'redux';

import { asyncActionSuccess } from '$helpers/asyncAction';
import {
    CASTS_CREATE,
    CASTS_DELETE,
    CASTS_EDIT,
    CASTS_FETCH,
    CASTS_FILE_DELETE,
    CASTS_FOLDER_CREATE,
    CASTS_FOLDER_DELETE,
    CASTS_FOLDER_FETCH,
    CASTS_FOLDER_RESET,
    CASTS_FOLDER_UPDATE
} from '$redux/content/casts/castsActions';
import { LoadedStatus } from '$types/enums';

export const castsInitialState: CastsState = {
    status: LoadedStatus.NotLoaded,
    list: [],
    projectList: [],
    folders: {}
};

export const castsReducer = (state: CastsState = castsInitialState, action: Action & any) => {
    switch (action.type) {
        case CASTS_FETCH:
            return update(state, {
                status: { $set: LoadedStatus.Pending }
            });

        case asyncActionSuccess(CASTS_FETCH):
            return update(state, {
                projectUuid: { $set: action.projectUuid },
                status: { $set: LoadedStatus.Loaded },
                list: { $set: action.casts },
                projectList: { $set: action.projectCasts || [] }
            });

        case asyncActionSuccess(CASTS_FOLDER_FETCH): {
            return update(state, {
                folders: {
                    [action.folder.uuid || action.castUid]: { $set: action.folder }
                }
            });
        }

        case asyncActionSuccess(CASTS_CREATE):
            return update(state, {
                list: {
                    $push: [
                        {
                            ...action.cast,
                            created: new Date(),
                            views: 0,
                            expired: false,
                            folders: 0
                        }
                    ]
                }
            });

        case asyncActionSuccess(CASTS_EDIT): {
            const now = new Date().getTime();
            const expired = action.cast.expires
                ? now > new Date(action.cast.expires).getTime()
                : false;
            const key = action.projectUuid ? 'projectList' : 'list';
            return update(state, {
                [key]: {
                    [state[key].findIndex((c) => c.uid === action.cast.uid)]: {
                        $merge: {
                            ...action.cast,
                            created: new Date(action.cast.created),
                            expired
                        }
                    }
                }
            });
        }

        case asyncActionSuccess(CASTS_DELETE): {
            const key = action.projectUuid ? 'projectList' : 'list';
            const castIndex = state[key].findIndex((c) => c.uid === action.castUid);

            return update(state, {
                [key]: {
                    $splice: [[castIndex, 1]]
                }
            });
        }

        case asyncActionSuccess(CASTS_FILE_DELETE): {
            const identifier = action.castFolderUuid || action.castUid;
            if (!state.folders[identifier]) return state;
            return update(state, {
                folders: {
                    [identifier]: {
                        content: {
                            $set: state.folders[identifier].content.filter(
                                ({ castFileUuid }: CastContentFile) =>
                                    castFileUuid !== action.castFileUuid
                            )
                        }
                    }
                }
            });
        }

        case asyncActionSuccess(CASTS_FOLDER_CREATE): {
            const identifier = action.castParentFolderUuid || action.castUid;
            if (!state.folders[identifier]) return state;
            return update(state, {
                folders: {
                    [identifier]: {
                        content: {
                            $push: [action.folder]
                        }
                    }
                }
            });
        }

        case asyncActionSuccess(CASTS_FOLDER_UPDATE): {
            const identifier = action.castParentFolderUuid || action.castUid;
            if (!state.folders[identifier]) return state;
            const itemIndex = state.folders[identifier].content.findIndex(
                (i) => i.uuid === action.uuid
            );
            if (itemIndex < 0) return state;
            return update(state, {
                folders: {
                    [identifier]: {
                        content: {
                            [itemIndex]: { name: { $set: action.name } }
                        }
                    }
                }
            });
        }

        case asyncActionSuccess(CASTS_FOLDER_DELETE): {
            const identifier = action.castParentFolderUuid || action.castUid;
            if (!state.folders[identifier]) return state;
            return update(state, {
                folders: {
                    [identifier]: {
                        content: {
                            $set: state.folders[identifier].content.filter(
                                ({ uuid }) => uuid !== action.castFolderUuid
                            )
                        }
                    }
                }
            });
        }

        case CASTS_FOLDER_RESET:
            return update(state, {
                folders: { $set: {} }
            });
    }

    return state;
};
