import './SidePanelHeader.scss';
import React, { ReactNode } from 'react';
import {
    ActionType,
    MultiActionButton,
    MultiActionButtonProps
} from '../../../buttons/MultiActionButton/MultiActionButton';
import { Button, ButtonTone } from '../../../buttons/Button/Button';
import classNames from 'classnames';
import { DirectionX, DirectionY } from '../../../../utils/getAbsolutePosition';

type SidePanelHeaderProps = {
    className?: string;
    buttons?: (ActionType | null | false)[];
    title: string;
    children?: ReactNode | ReactNode[];
};

export const SidePanelHeader: React.FC<SidePanelHeaderProps> = ({
    children,
    className,
    buttons,
    title
}) => (
    <header className={classNames('c-side-panel-header', className)}>
        <div className="title">
            <h2>{title}</h2>
            {buttons && (
                <ul>
                    {buttons
                        .filter((v) => v)
                        .map((props: MultiActionButtonProps, i) => (
                            <li key={props.id || i}>
                                {props.actions ? (
                                    <MultiActionButton
                                        direction={[DirectionX.Center, DirectionY.Bottom]}
                                        tone={ButtonTone.Hoverable}
                                        fireIfSingle
                                        {...props}
                                    />
                                ) : (
                                    <Button tone={ButtonTone.Hoverable} {...props} />
                                )}
                            </li>
                        ))}
                </ul>
            )}
        </div>
        {children}
    </header>
);
