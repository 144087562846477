export const noteTypeToWording = (type: string) => {
    switch (type) {
        case 'areaselection':
            return 'ZONE_NOTE';
        case 'sequence':
            return 'SEQUENCE_NOTE';
        case 'timecode':
            return 'IMAGE_NOTE';
        default:
            return 'GLOBAL_NOTE';
    }
};
