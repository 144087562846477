export const colorSuccess = '#49F281';
export const colorBgDarkest = '#000000';

export const mobileThreshold = 768;
export const mobileHeightThreshold = 400;

/* -------------------- */

export const DISPLAY = {
    LIST: 'list',
    MOSAIC: 'mosaic'
};

export enum DISPLAY_TYPE {
    LIST = 'list',
    MOSAIC = 'mosaic'
}
