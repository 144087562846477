import './TimeCodes.scss';
import React from 'react';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { Theme } from '@he-novation/design-system/enums';
import Icon from '@he-novation/icons';
import { useOnKeyDown } from '../../../../hooks/useOnKeyDown';

type TimeCodesProps = {
    timecode: string;
    tcIn?: string;
    tcOut?: string;
    setTcIn: (value: string) => void;
    setTcOut: (value: string) => void;
    theme?: Theme;
    separator?: React.ReactNode;
    formId: string;
    mask: string;
    timeCodeToSeconds: (timeCode: string) => number;
};
export default function TimeCodes({
    timecode,
    tcIn,
    tcOut,
    setTcIn,
    setTcOut,
    theme = Theme.Dark,
    separator,
    mask,
    formId,
    timeCodeToSeconds
}: TimeCodesProps) {
    useOnKeyDown('i', () => {
        setTcIn(timecode);
    });
    useOnKeyDown('o', () => {
        setTcOut(timecode);
    });
    const replacement = { _: /\d/ };
    return (
        <div className={`c-time-codes ${theme}`}>
            <div className="sequence-timecode sequence-timecode-in">
                <button type="button" onClick={() => setTcIn(timecode)}>
                    <Icon icon="pin-in" />
                </button>
                <FormField
                    theme={theme}
                    formId={formId}
                    name="tcIn"
                    value={tcIn}
                    onChange={(e) => {
                        setTcIn(e.target.value);
                    }}
                    type={'masked-field'}
                    mask={mask}
                    replacement={replacement}
                />
            </div>
            {separator}
            <div className="sequence-timecode sequence-timecode-out">
                <FormField
                    theme={theme}
                    formId={formId}
                    name="tcOut"
                    value={tcOut}
                    onChange={(e) => {
                        setTcOut(e.target.value);

                        const secondsOut = timeCodeToSeconds(e.target.value);
                        const secondsIn = timeCodeToSeconds(tcIn!);

                        if (secondsOut < secondsIn) {
                            setTcIn(e.target.value);
                        }
                    }}
                    type={'masked-field'}
                    mask={mask}
                    replacement={replacement}
                />
                <button type="button" onClick={() => setTcOut(timecode)}>
                    <Icon icon="pin-out" />
                </button>
            </div>
        </div>
    );
}
