import { connect } from 'react-redux';
import { modalFormFactory } from '@he-novation/design-system/components/modals/ModalForm/ModalForm';
import { __ } from '@he-novation/design-system/utils/i18n';

import { FormCastFolder } from '$components/form/FormCastFolder';
import { castFolderCreate, castFolderUpdate } from '$redux/content/casts/castsActions';
import { closeModal } from '$redux/route/routeActions';

const ModalFormCastFolder = connect(
    undefined,
    (dispatch, { payload: { castUid, castFolderUuid, cb, data } }) => ({
        submit: async (e, payload) => {
            e.preventDefault();
            if (data?.uuid) {
                dispatch(
                    castFolderUpdate(
                        castUid,
                        data.uuid,
                        payload.name,
                        data.castParentFolderUuid,
                        () => {
                            cb?.({ ...data, name: payload.name, type: 'folder' });
                            dispatch(closeModal());
                        }
                    )
                );
            } else {
                dispatch(
                    castFolderCreate(castUid, castFolderUuid, payload.name, ({ uuid }) => {
                        cb?.({ uuid, name: payload.name, type: 'folder' });
                        dispatch(closeModal());
                    })
                );
            }
        }
    })
)(
    modalFormFactory(FormCastFolder, (payload) =>
        __(payload.data?.uuid ? 'CAST_FOLDER_UPDATE' : 'CAST_FOLDER_CREATE')
    )
);

export default ModalFormCastFolder;
