import './TimeCodesNotesList.scss';
import React from 'react';
import { timeCodeToSeconds } from '@he-novation/lib-timecodes';

import Note from '$components/Notes/Note/NoteContainer';

export const TimeCodesNotesList = ({ notes: _notes, isPublicFile }: any) => {
    const notes = Object.keys(_notes)
        .map((n) => _notes[n])
        .sort(
            (a, b) =>
                timeCodeToSeconds(a.metadata?.tcIn, 25) - timeCodeToSeconds(b.metadata?.tcIn, 25)
        );
    return (
        <ul className="c-timecodes-notes-list">
            {notes.map((n) => (
                <li key={n.uuid}>
                    <Note note={n} key={n.uuid} isPublicFile={isPublicFile} />
                </li>
            ))}
        </ul>
    );
};
