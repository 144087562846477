import './SidePanelSubtitles.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FixedSizeList as List } from 'react-window';
import {
    MappedSubtitle,
    MappedSubtitleEntry,
    Subtitle
} from '@he-novation/config/types/subtitle.types';
import {
    subtitleEntryCreate,
    subtitleEntryDelete,
    subtitleEntryUpdate
} from '@he-novation/front-shared/async/subtitle.async';
import { timeCodeToSeconds } from '@he-novation/lib-timecodes';
import { DELETE_CONFIRM } from '@he-novation/paths/modals.constants';
import update from 'immutability-helper';
import { useAtomValue } from 'jotai';
import omit from 'lodash/omit';
import { videoAtom } from '../../../atoms/file-atoms/video-atom';
import { useVideoControls } from '../../../hooks/useVideoControls';

import { SidePanelSubtitlesHeader } from '$components/SidePanel/SidePanelSubtitles/SidePanelSubtitlesHeader';
import Row from '$components/SidePanel/SidePanelSubtitles/SidePanelSubtitlesRow';
import { setSubtitlesTimeStamp } from '$redux/content/file/fileActions';
import {
    activeSubtitlesSelector,
    fileUuidSelector,
    fileVersionSelector,
    subtitlesSelector
} from '$redux/content/file/fileSelectors';
import { closeModal, openModal } from '$redux/route/routeActions';
import { toggleKeyboardListeners } from '$redux/ui/uiActions';

const mapEntry = (e, frameRate: number, secondsOffset = 0) => ({
    ...e,
    timeIn: timeCodeToSeconds(e.timecodeIn, frameRate) - secondsOffset,
    timeOut: timeCodeToSeconds(e.timecodeOut, frameRate) - secondsOffset
});

const mapSubs = (subtitle: Subtitle, frameRate: number, secondsOffset?: number) => {
    return {
        ...subtitle,
        entries: subtitle.entries.map((e) => mapEntry(e, frameRate, secondsOffset))
    };
};
const getSubtitlesEntriesInPlayer = (activeSubtitle: MappedSubtitle, currentTime: number) =>
    activeSubtitle
        ? activeSubtitle.entries
              .filter((e) => e.timeIn <= currentTime && e.timeOut >= currentTime)
              .map(({ uuid }) => uuid)
        : [];

const subtitleItemHeight = 180;
export function SidePanelSubtitles() {
    const dispatch = useDispatch();
    const [activeSubtitle, setActiveSubtitle] = useState<MappedSubtitle>();
    const { subtitles } = useSelector(subtitlesSelector);
    const { activeSubtitles } = useSelector(activeSubtitlesSelector);
    const [editing, setEditing] = useState<string[]>([]);
    const listRef = useRef<List>();
    const itemRefs = useRef({});
    const [subtitleEntriesInPlayer, setSubtitleEntriesInPlayer] = useState<string[]>([]);
    const [focused, setFocused] = useState<string | null>(null);
    const { fileUuid } = useSelector(fileUuidSelector);
    const { fileVersion } = useSelector(fileVersionSelector);
    const videoState = useAtomValue(videoAtom);
    const { setCurrentTime } = useVideoControls();

    useEffect(() => {
        let active;
        for (const activeSubtitleUuid of activeSubtitles) {
            active = subtitles.find(
                (s) => s.uuid === activeSubtitleUuid && Array.isArray(s.entries)
            );
            if (active) break;
        }
        setActiveSubtitle(
            active ? mapSubs(active, videoState.frameRate, videoState.secondsOffset) : undefined
        );
        setEditing([]);
    }, [activeSubtitles, subtitles]);

    useEffect(() => {
        setSubtitleEntriesInPlayer(
            activeSubtitle
                ? getSubtitlesEntriesInPlayer(activeSubtitle, videoState.currentTime)
                : []
        );
    }, [videoState.currentTime, activeSubtitle, videoState.secondsOffset]);

    useEffect(() => {
        if (subtitleEntriesInPlayer?.[0] && !editing?.length) {
            document.getElementById(subtitleEntriesInPlayer[0])?.scrollIntoView();
        }
    }, [subtitleEntriesInPlayer]);

    const scrollTo = (y: number) => {
        /** windowed list so elements do not exist scroll to scrollHeight for element to be loaded */
        listRef.current._outerRef.scrollTop = Math.min(y, listRef.current._outerRef.scrollHeight);
    };

    const updateEntry = (activeSubtitle, entryUuid, _update, noScroll = false) => {
        const updated = update(activeSubtitle, {
            entries: {
                [activeSubtitle.entries.findIndex((e) => e.uuid === entryUuid)]: _update
            }
        });
        updated.entries = updated.entries.sort((a, b) => a.timeIn - b.timeIn);
        setActiveSubtitle(updated);
        if (noScroll) return;
        const newIndex = updated.entries.findIndex((e) => e.uuid === entryUuid);
        scrollTo(newIndex * subtitleItemHeight);
    };

    return (
        <div className="c-side-panel-subtitles">
            <SidePanelSubtitlesHeader
                fileUuid={fileUuid}
                fileVersion={fileVersion}
                activeSubtitle={activeSubtitle}
                subtitles={subtitles}
                frameRate={videoState.frameRate}
                addEntry={() => {
                    const uuid = `new-${new Date().getTime()}`;
                    const entry: MappedSubtitleEntry = {
                        uuid,
                        start: videoState.currentTime,
                        end: videoState.currentTime,
                        timeIn: videoState.currentTime,
                        timeOut: videoState.currentTime,
                        timecodeIn: videoState.timecodeWithTimecodeStart,
                        timecodeOut: videoState.timecodeWithTimecodeStart,
                        content: '',
                        isNew: true
                    };
                    const updated = update(activeSubtitle, {
                        entries: {
                            $push: [entry]
                        }
                    })!;
                    updated.entries = updated.entries.sort((a, b) => a.timeIn - b.timeIn);
                    const newIndex = updated.entries.findIndex((e) => e.uuid === uuid);
                    setActiveSubtitle(updated);
                    setTimeout(() => {
                        setEditing([...editing, entry.uuid]);
                        /** windowed list so elements do not exist scroll to Infinity => scrollHeight for element to be loaded */
                        scrollTo(newIndex * subtitleItemHeight);
                        setTimeout(() => {
                            itemRefs.current[entry.uuid]?.current?.el.current?.quill?.focus();
                        }, 100);
                    }, 100);
                }}
            />

            {activeSubtitle && (
                <List
                    ref={listRef}
                    height={window.innerHeight - 150 - 80 - 40}
                    width={380}
                    itemCount={activeSubtitle.entries.length}
                    itemSize={subtitleItemHeight}
                    itemKey={(index) => activeSubtitle.entries[index].uuid}
                    itemData={{
                        focused: focused,
                        itemRefs: itemRefs.current,
                        frameRate: videoState.frameRate,
                        subtitleEntries:
                            activeSubtitle?.entries.sort((a, b) => a.timeIn - b.timeIn) || [],
                        subtitleEntriesInPlayer,
                        editing: editing,
                        onBlur: () => {
                            dispatch(toggleKeyboardListeners(true));
                            setFocused(null);
                        },
                        onFocus: (e, uuid) => {
                            dispatch(toggleKeyboardListeners(false));
                            setFocused(uuid);
                        },
                        onChange: (e, uuid, v) => {
                            v = v.replace(/<br\s*\/>/g, '\n\n');
                            updateEntry(
                                activeSubtitle,
                                uuid,
                                {
                                    content: { $set: v }
                                },
                                true
                            );
                        },
                        setTcIn: (entryUuid: string, timecodeIn) => {
                            const activeEntry = activeSubtitle.entries.find(
                                ({ uuid }) => uuid === entryUuid
                            )!;
                            const timeIn = timeCodeToSeconds(timecodeIn, videoState.frameRate);
                            const changes: any = { timecodeIn, timeIn };
                            const timeOut = timeCodeToSeconds(
                                activeEntry.timecodeOut,
                                videoState.frameRate
                            );
                            if (timeIn > timeOut) {
                                changes.timecodeOut = timecodeIn;
                                changes.timeOut = timeIn;
                            }
                            updateEntry(activeSubtitle, entryUuid, { $merge: changes });
                        },
                        setTcOut: (entryUuid, timecodeOut) => {
                            const activeEntry = activeSubtitle.entries.find(
                                ({ uuid }) => uuid === entryUuid
                            )!;
                            const timeOut = timeCodeToSeconds(timecodeOut, videoState.frameRate);
                            const changes: any = { timecodeOut, timeOut };
                            const timeIn = timeCodeToSeconds(
                                activeEntry.timecodeIn,
                                videoState.frameRate
                            );
                            if (timeIn > timeOut) {
                                changes.timecodeIn = timecodeOut;
                                changes.timeIn = timeIn;
                            }
                            updateEntry(activeSubtitle, entryUuid, { $merge: changes });
                        },
                        onClickDelete: (entryUuid: string) => {
                            dispatch(
                                openModal(DELETE_CONFIRM, {
                                    onDelete: async (e) => {
                                        await subtitleEntryDelete(activeSubtitle.uuid, entryUuid);
                                        dispatch(closeModal());
                                        setActiveSubtitle(
                                            update(activeSubtitle, {
                                                entries: {
                                                    $splice: [
                                                        [
                                                            activeSubtitle.entries.findIndex(
                                                                (e) => e.uuid === entryUuid
                                                            ),
                                                            1
                                                        ]
                                                    ]
                                                }
                                            })
                                        );
                                        dispatch(setSubtitlesTimeStamp());
                                    }
                                })
                            );
                        },
                        onClickEdit: (entryUuid: string) =>
                            setEditing(
                                editing.includes(entryUuid)
                                    ? editing.filter((e) => e !== entryUuid)
                                    : [...editing, entryUuid]
                            ),
                        saveChanges: async (entryUuid: string) => {
                            const entry = activeSubtitle.entries.find(
                                ({ uuid }) => uuid === entryUuid
                            )!;
                            entry.delta =
                                itemRefs.current[
                                    entryUuid
                                ].current.el.current.quill.getContents().ops;

                            if (entry.isNew) {
                                const { uuid: createdSubtitleEntryUUID } =
                                    await subtitleEntryCreate(
                                        activeSubtitle.uuid,
                                        omit(entry, ['uuid', 'timeIn', 'timeOut', 'isNew'])
                                    );

                                const updated = update(activeSubtitle, {
                                    entries: {
                                        [activeSubtitle.entries.findIndex(
                                            ({ uuid }) => uuid === entryUuid
                                        )]: {
                                            uuid: { $set: createdSubtitleEntryUUID },
                                            isNew: { $set: entry.isNew }
                                        }
                                    }
                                });
                                updated.entries = updated.entries.sort(
                                    (a, b) => a.timeIn - b.timeIn
                                );
                                setActiveSubtitle(updated);
                                dispatch(toggleKeyboardListeners(true));
                                setFocused(null);
                                setEditing(editing.filter((uuid) => uuid !== entryUuid));
                                dispatch(setSubtitlesTimeStamp());
                            } else {
                                await subtitleEntryUpdate(activeSubtitle.uuid, entryUuid, {
                                    delta: entry.delta,
                                    timecodeIn: entry.timecodeIn,
                                    timecodeOut: entry.timecodeOut,
                                    metadata: entry.metadata
                                });
                                dispatch(toggleKeyboardListeners(true));
                                setFocused(null);
                                setEditing(editing.filter((uuid) => uuid !== entryUuid));
                                dispatch(setSubtitlesTimeStamp());
                            }
                        },
                        setSubtitleMetadata: (entryUuid, metadata) => {
                            updateEntry(activeSubtitle, entryUuid, {
                                metadata: { $set: metadata }
                            });
                        },
                        timecode: videoState.timecodeWithTimecodeStart,
                        goToTime: (time: number) => {
                            setCurrentTime(time);
                        }
                    }}
                >
                    {Row}
                </List>
            )}
        </div>
    );
}
