import './SidePanelProjectInfo.scss';
import React from 'react';
import { Project } from '@he-novation/config/types/project.types';
import { AvatarUser } from '@he-novation/design-system/components/avatars/Avatar/AvatarUser';
import { SidePanelHeader } from '@he-novation/design-system/components/sidePanels/SidePanel/SidePanelHeader/SidePanelHeader';
import { __ } from '@he-novation/design-system/utils/i18n';
import Icon from '@he-novation/icons';
import { getDisplayName } from '@he-novation/utils/user';

import { getLocalizedDate } from '$helpers/datetime';
import ProjectProgress from '$views/projects/Components/ProjectProgress/ProjectProgress';

type SidePanelProjectInfoProps = {
    isProjectManager: boolean;
    currentProject: Project;
    openEditProjectModal: (uuid: string) => void;
    locale: string;
};
function SidePanelProjectInfo({
    isProjectManager,
    currentProject,
    openEditProjectModal,
    locale
}: SidePanelProjectInfoProps) {
    return (
        <div id="c-side-panel-project-info">
            <SidePanelHeader
                title={__('PROJECT')}
                buttons={
                    isProjectManager
                        ? [
                              {
                                  icon: 'pencil',
                                  onClick: () => openEditProjectModal(currentProject.uuid)
                              }
                          ]
                        : undefined
                }
            />
            <div className="owner-info">
                <AvatarUser {...currentProject.user} />
                <div className="infos">
                    <h3> {getDisplayName(currentProject.user)}</h3>

                    <span>
                        {__('FOLDER_INFORMATIONS_CREATED')}{' '}
                        {getLocalizedDate(currentProject.created, locale)}
                    </span>
                    {currentProject.updated && (
                        <span>
                            {__('FOLDER_INFORMATIONS_MODIFIED')}{' '}
                            {getLocalizedDate(currentProject.updated, locale)}
                        </span>
                    )}
                </div>
            </div>

            {currentProject.company && (
                <>
                    <hr />
                    <div className="company-info">
                        <Icon icon="company" />
                        <h3>{currentProject.company.name}</h3>
                    </div>
                </>
            )}
            <hr />
            <ProjectProgress
                startDate={currentProject.startDate}
                endDate={currentProject.endDate}
                taskCounts={currentProject?.taskCounts}
                small
            />
        </div>
    );
}

export default SidePanelProjectInfo;
