import { asyncActionSuccess } from '$helpers/asyncAction';
import {
    FETCH_NOTE,
    SET_SELECTED_NOTE,
    ADD_NOTE_COMMENT_WS,
    CREATE_COMMENT,
    CREATE_NOTE,
    SET_NOTE_ASSET,
    UPDATE_ATTACHMENT,
    SET_COMMENT_ASSET,
    SET_DISPLAY_ALL_VERSIONS,
    FETCH_CAST_NOTE,
    EDIT_COMMENT
} from './noteActions';
import update from 'immutability-helper';
import isJsonString from '../../../helpers/isJsonString';
import { FETCH_FILE_VIEW } from '../contentActions';
import mapFetchNoteToUpdate from '../../content/note/map/mapFetchNoteToUpdate';
import {
    WS_CREATE_COMMENT,
    WS_CREATE_NOTE,
    WS_DELETE_COMMENT,
    WS_DELETE_NOTE,
    WS_SET_NOTE_ATTACHMENT,
    WS_UPDATE_NOTE
} from './noteActions';
import sqlDatesToJsDates from '../../content/projects/maps/sqlDatesToJsDates';

export const noteInitialState = {
    list: []
};

const reducer = (state = noteInitialState, action = {}) => {
    switch (action.type) {
        case asyncActionSuccess(FETCH_NOTE):
        case asyncActionSuccess(FETCH_CAST_NOTE):
            return update(state, {
                $merge: mapFetchNoteToUpdate(action.response)
            });
        case SET_SELECTED_NOTE:
            return update(state, {
                selectedNoteUuid: {
                    $set: action.uuidNote
                }
            });

        case CREATE_NOTE: {
            const note = {
                uuid: 'new',
                ...action.note,
                assets: [],
                file: {
                    uuid: action.file.uuid,
                    name: action.file.name,
                    version: action.file.version
                },
                comments: action.note.comments.map((c) => ({
                    ...c,
                    assets: []
                }))
            };

            const noteIndex = state.list.findIndex(
                (n) => n.type === 'global' && note.type === 'global'
            );

            if (noteIndex > -1) {
                return update(state, {
                    list: {
                        [noteIndex]: {
                            $merge: note
                        }
                    }
                });
            }

            return update(state, {
                list: {
                    $unshift: [note]
                }
            });
        }

        case asyncActionSuccess(CREATE_NOTE): {
            const note = {
                ...action.note,
                task: action.note.task && sqlDatesToJsDates(action.note.task),
                file_version: action.file.version,
                created: new Date(action.note.created),
                metadata: isJsonString(action.note.metadata)
                    ? JSON.parse(action.note.metadata)
                    : action.note.metadata,
                user: action.note.user,
                comments: action.note.comments.map((c) => ({
                    ...c,
                    created: new Date(action.note.created),
                    user: action.note.user,
                    note_uuid: action.note.uuid,
                    user_profile: {
                        firstname: action.note.user.firstname,
                        lastname: action.note.user.lastname
                    }
                }))
            };
            const noteIndex = state.list.findIndex(
                (n) =>
                    n.uuid === action.note.uuid ||
                    (n.uuid === 'new' &&
                        n.comments[0].content === action.note.comments[0].content) ||
                    (n.type === 'global' && action.note.type === 'global')
            );
            if (noteIndex > -1)
                return update(state, {
                    list: {
                        [noteIndex]: {
                            $merge: note
                        }
                    }
                });
            return update(state, {
                list: {
                    $unshift: [note]
                }
            });
        }

        case asyncActionSuccess(CREATE_COMMENT): {
            const noteIndex = state.list.findIndex((n) => n.uuid === action.comment.note.uuid);
            const commentIndex = state.list[noteIndex].comments.findIndex(
                (c) => c.uuid === action.comment.uuid
            );
            if (commentIndex > -1) return state;
            return update(state, {
                list: {
                    [noteIndex]: {
                        comments: {
                            $unshift: [
                                {
                                    ...action.comment,
                                    created: new Date(action.comment.created)
                                }
                            ]
                        }
                    }
                }
            });
        }

        case asyncActionSuccess(EDIT_COMMENT): {
            const noteIndex = state.list.findIndex((n) => n.uuid === action.comment.note_uuid);
            const commentIndex = state.list[noteIndex].comments.findIndex(
                (c) => c.uuid === action.comment.uuid
            );
            if (commentIndex === -1) return state;
            return update(state, {
                list: {
                    [noteIndex]: {
                        comments: {
                            [commentIndex]: {
                                $merge: {
                                    ...action.comment,
                                    created: action.comment.created
                                        ? new Date(action.comment.created)
                                        : undefined
                                }
                            }
                        }
                    }
                }
            });
        }

        case asyncActionSuccess(FETCH_FILE_VIEW): {
            if (action.projectState.currentProject?.teams)
                action.noteState.selectedTeam = action.projectState.currentProject.teams.find(
                    ({ ownTeam }) => ownTeam
                ).uuid;
            return update(state, {
                $merge: action.noteState
            });
        }

        case SET_NOTE_ASSET:
            if (!action.noteUuid) return state;
            return update(state, {
                list: {
                    [state.list.findIndex(({ uuid }) => uuid === action.noteUuid)]: {
                        asset: {
                            $set: action.asset
                        }
                    }
                }
            });

        case asyncActionSuccess(UPDATE_ATTACHMENT): {
            if (!action.body.note_uuid) return state;
            return update(state, {
                updateAttachment: {
                    $set: {
                        note_uuid: action.body.note_uuid,
                        comment_uuid: action.body.comment_uuid
                    }
                }
            });
        }

        case SET_COMMENT_ASSET: {
            const updateAttachment = state.updateAttachment;
            if (!updateAttachment) return state;

            const comment_uuid = updateAttachment.comment_uuid;
            const note_uuid = updateAttachment.note_uuid;

            const listIndex = state.list.findIndex(({ uuid }) => uuid === note_uuid);
            if (listIndex === -1) return state;
            const commentIndex = state.list[listIndex].comments.findIndex(
                ({ uuid }) => uuid === comment_uuid
            );

            if (commentIndex === -1) return state;

            return update(state, {
                updateAttachment: { $set: null },
                list: {
                    [listIndex]: {
                        comments: {
                            [commentIndex]: {
                                $merge: {
                                    attachment: 1,
                                    asset: { ...action.asset, name: action.file_name }
                                }
                            }
                        }
                    }
                }
            });
        }

        case WS_CREATE_NOTE: {
            const note = {
                uuid: action.note.uuid,
                file: action.file,
                type: action.note.type,
                assets: action.note.assets,
                detailAsset: (
                    (action.note.assets &&
                        action.note.assets.find(({ quality }) => quality === 'detail')) ||
                    {}
                ).url,
                metadata: action.note.metadata,
                created: new Date(action.note.created),
                team: action.note.team,
                task: action.note.task ? sqlDatesToJsDates(action.note.task) : null,
                user: action.user,
                comments: action.note.comments.map((c) => ({
                    ...c,
                    user: action.user,
                    created: new Date(c.created)
                }))
            };
            const noteIndex = state.list.findIndex(
                ({ uuid, comments }) =>
                    uuid === action.note.uuid ||
                    (uuid === 'new' && comments[0].content === action.note.comments[0].content)
            );
            if (noteIndex > -1)
                return update(state, {
                    list: {
                        [noteIndex]: { $merge: note }
                    }
                });

            return update(state, {
                list: {
                    $unshift: [note]
                }
            });
        }

        case WS_CREATE_COMMENT: {
            const index = state.list.findIndex(({ uuid }) => action.comment?.note?.uuid === uuid);
            if (!state.list[index]) return state;
            const commentIndex = state.list[index].comments.findIndex(
                (c) => c.uuid === action.comment.uuid
            );
            if (commentIndex === -1) {
                return update(state, {
                    list: {
                        [index]: {
                            comments: {
                                $unshift: [
                                    {
                                        user: action.user,
                                        ...action.comment,
                                        created: new Date(action.comment.created)
                                    }
                                ]
                            }
                        }
                    }
                });
            }
            return state;
        }
        case WS_DELETE_COMMENT: {
            const noteIndex = state.list.findIndex(({ uuid }) => uuid === action.noteUuid);
            if (state.list[noteIndex].comments.length === 1) {
                return update(state, { list: { $splice: [[noteIndex, 1]] } });
            }
            return update(state, {
                list: {
                    [noteIndex]: {
                        comments: {
                            $splice: [
                                [
                                    state.list[noteIndex].comments.findIndex(
                                        ({ uuid }) => uuid === action.uuid
                                    ),
                                    1
                                ]
                            ]
                        }
                    }
                }
            });
        }

        case WS_DELETE_NOTE:
            return update(state, {
                list: {
                    $apply: (list) => list.filter(({ uuid }) => uuid !== action.noteUuid)
                }
            });

        case WS_SET_NOTE_ATTACHMENT: {
            const noteIndex = state.list.findIndex(({ uuid }) => uuid === action.note.uuid);
            if (noteIndex === -1) return state;
            const commentIndex = state.list[noteIndex].comments.findIndex(
                ({ uuid }) => uuid === action.comment.uuid
            );
            return update(state, {
                list: {
                    [noteIndex]: {
                        comments: {
                            [commentIndex]: {
                                assets: {
                                    $push: [action.asset]
                                }
                            }
                        }
                    }
                }
            });
        }

        case WS_UPDATE_NOTE: {
            const noteIndex = state.list.findIndex(({ uuid }) => uuid === action.uuid);
            const _update = {
                list: {
                    [noteIndex]: {
                        comments: {
                            [state.list[noteIndex].comments.length - 1]: {
                                content: { $set: action.content }
                            }
                        }
                    }
                }
            };
            if (state.list[noteIndex].task) {
                _update.list[noteIndex].task = {
                    description: { $set: action.content },
                    status: { $set: action.task.status }
                };
            }
            return update(state, _update);
        }

        case ADD_NOTE_COMMENT_WS: {
            const noteIndex = state.list.findIndex(({ uuid }) => uuid === action.note.uuid);
            const _update = {};

            const comment = {
                ...action.payload.comment,
                created: new Date(),
                user_uuid: action.note.user_uuid,
                note_uuid: action.note.uuid,
                user_profile: action.payload.extra.currentUser
            };

            if (noteIndex === -1) {
                _update.list = {
                    $unshift: [
                        {
                            uuid: action.note.uuid,
                            user_uuid: action.note.user_uuid,
                            file_version: action.payload.comment.file_version,
                            metadata: action.payload.extra.metadata || {},
                            comments: [comment]
                        }
                    ]
                };
            } else {
                _update.list = {
                    [noteIndex]: {
                        metadata: {},
                        comments: {
                            $unshift: [comment]
                        }
                    }
                };
            }
            return update(state, _update);
        }

        case SET_DISPLAY_ALL_VERSIONS:
            return update(state, { displayAllVersions: { $set: action.displayAllVersions } });
    }
    return state;
};

export default reducer;
