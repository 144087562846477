import './ClientGuests.scss';
import React, { useEffect, useRef, useState } from 'react';
import PageTitleContainer from '$components/base/titles/PageTitle/PageTitle.tsx';
import { __ } from '@he-novation/design-system/utils/i18n';
import SearchField from '$components/SearchField/SearchField';
import { View } from '$views/App/components/View/View';
import { Theme } from '@he-novation/design-system/enums';
import { DataLayoutClientGuests } from '$components/DataLayout/DataLayoutClientGuests/DataLayoutClientGuests';

export const ClientGuests = ({ fetchClientGuests, clientGuests } = {}) => {
    const [search, setSearch] = useState();
    useEffect(() => {
        fetchClientGuests();
    }, []);

    return (
        <View id="client-guests">
            <PageTitleContainer title={__('USERS_GUESTS')}>
                <SearchField
                    theme={Theme.Dark}
                    onInput={(e) => setSearch(e.target.value)}
                    onReset={() => setSearch('')}
                    value={search}
                />
            </PageTitleContainer>
            <DataLayoutClientGuests items={clientGuests} search={search} />
        </View>
    );
};
