import './LoadItem.scss';
import React from 'react';

const LoadItem = () => (
    <div className="c-load-item">
        <div className="loader">
            <span />
            <span />
            <span />
        </div>
    </div>
);

export default LoadItem;
