import './Header.scss';
import React from 'react';
import { useSelector } from 'react-redux';

import { activePanelSelector } from '$redux/sideMenu/sideMenuSelectors';

export const Header: React.FC = () => {
    const activeSidePanel = useSelector(activePanelSelector);
    return <header id="header" className={activeSidePanel ? 'has-open-panel' : ''} />;
};
