import './Toast.scss';
import React, { ReactNode, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { IconSize, Theme } from '../../../enums';
import { SizedIcon } from '@he-novation/icons';
import { Button, ButtonTone } from '../../buttons/Button/Button';

export type ToastProps = {
    className?: string;
    content?: ReactNode;
    icon?: string;
    iconSize?: IconSize;
    title?: string;
    autoCloseAfterMs?: number;
    style?: any;
    theme?: Theme;
    closeWording?: string;
    onClose?: () => void;
    button?: {
        text: string;
        tone?: ButtonTone;
        onClick: () => void;
    };
};

const DEFAULT_AUTO_CLOSE_MS = 3000;

export const Toast: React.FC<ToastProps> = ({
    className,
    title,
    content,
    icon,
    autoCloseAfterMs = DEFAULT_AUTO_CLOSE_MS,
    style,
    theme = Theme.Light,
    closeWording,
    onClose,
    button
}) => {
    const [isMounted, setIsMounted] = React.useState<boolean>(true);
    const [isVisible, setIsVisible] = React.useState<boolean>(false);
    const [isFading, setIsFading] = React.useState<boolean>(false);
    const timeoutRef = useRef<{ [timeout: string]: any }>({});

    useEffect(() => {
        setIsVisible(true);
        if (autoCloseAfterMs)
            timeoutRef.current.delayTimeout = setTimeout(() => close(), autoCloseAfterMs);
        return () => {
            clearTimeout(timeoutRef.current.delayTimeout);
            clearTimeout(timeoutRef.current.closeTimeout);
        };
    }, [title, button]);

    const close = () => {
        setIsFading(true);
        if (typeof onClose === 'function') {
            onClose();
        }
        timeoutRef.current.closeTimeout = setTimeout(() => setIsMounted(false), 500);
    };

    return isMounted
        ? ReactDOM.createPortal(
            <div
                className={classNames('c-toast', theme, className, {
                    'is-visible': isVisible,
                    'is-fading': isFading
                })}
                style={style}
            >
                {title && <h2>{title}</h2>}
                {icon && <SizedIcon icon={icon} size={IconSize.XS} />}
                {content && <p>{content}</p>}
                {(button || closeWording) && (
                    <Button
                        className="c-toast-close"
                        tone={button?.tone || ButtonTone.Outlined}
                        theme={Theme.Light}
                        onClick={() => {
                            close();
                            if (button) {
                                button.onClick();
                            }
                        }}
                    >
                        {button ? button.text : closeWording}
                    </Button>
                )}
            </div>,
            document.body
        )
        : null;
};
