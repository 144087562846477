import React, { useEffect, useRef, useState } from 'react';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { CustomControlType } from '@he-novation/design-system/components/form/Editor/Editor';
import { StyledFormField } from '@he-novation/design-system/components/form/StyledFormField/StyledFormField';
import { ExtraFormFieldTypes } from '@he-novation/design-system/enums';
import Icon from '@he-novation/icons';
import { dropFrames, timeCodeToSeconds } from '@he-novation/lib-timecodes';
import cn from 'classnames';
import TimeCodes from '../../form/fields/TimeCodes/TimeCodes';

const SidePanelSubtitlesRow = ({
    index: i,
    style,
    data: {
        subtitleEntries,
        editing,
        subtitleEntriesInPlayer,
        onBlur,
        onFocus,
        onChange,
        setTcIn,
        setTcOut,
        setSubtitleMetadata,
        onClickDelete,
        onClickEdit,
        timecode,
        saveChanges,
        frameRate,
        itemRefs,
        focused,
        goToTime
    }
}: any) => {
    const ref = useRef<any>();
    const [initialValue] = useState(subtitleEntries[i].content);

    const [align, setAlign] = useState(subtitleEntries[i].metadata?.align || 'center');
    useEffect(() => {
        itemRefs[subtitleEntries[i].uuid] = ref;
    }, []);

    return (
        <div
            id={subtitleEntries[i].uuid}
            style={style}
            className={cn(
                'subtitle-entry',
                focused === subtitleEntries[i].uuid && 'is-focused',
                subtitleEntriesInPlayer.includes(subtitleEntries[i].uuid) && 'is-displayed'
            )}
        >
            <header>
                <div className="index" onClick={() => goToTime(subtitleEntries[i].timeIn)}>
                    {i + 1}
                </div>
                {!editing.includes(subtitleEntries[i].uuid) ? (
                    <>
                        <div className={'timecodes'}>
                            {subtitleEntries[i].timecodeIn} <Icon icon="arrow-left" />
                            {subtitleEntries[i].timecodeOut}
                        </div>
                        <Button
                            icon="trash-can"
                            tone={ButtonTone.Hoverable}
                            onClick={() => onClickDelete(subtitleEntries[i].uuid)}
                        />
                        <Button
                            icon="pencil"
                            tone={ButtonTone.Hoverable}
                            onClick={() => onClickEdit(subtitleEntries[i].uuid)}
                        />
                    </>
                ) : (
                    <>
                        <TimeCodes
                            formId="subtitle-entry"
                            separator={<Icon icon="arrow-left" />}
                            tcIn={subtitleEntries[i].timecodeIn}
                            tcOut={subtitleEntries[i].timecodeOut}
                            setTcIn={(tcIn) => setTcIn(subtitleEntries[i].uuid, tcIn)}
                            setTcOut={(tcOut) => setTcOut(subtitleEntries[i].uuid, tcOut)}
                            timecode={timecode}
                            timeCodeToSeconds={(timecode) => timeCodeToSeconds(timecode, frameRate)}
                            mask={`__:__:__${dropFrames(frameRate) ? ';' : ':'}__`}
                        />
                        <Button
                            className="save"
                            icon="save"
                            tone={ButtonTone.Hoverable}
                            onClick={() => saveChanges(subtitleEntries[i].uuid)}
                        />
                    </>
                )}
            </header>
            <StyledFormField
                type={ExtraFormFieldTypes.Editor}
                readOnly={!editing.includes(subtitleEntries[i].uuid)}
                onFocus={(range) => onFocus(range, subtitleEntries[i].uuid)}
                className={`force-align-${align}`}
                onBlur={onBlur}
                ref={ref}
                onChange={(e, value) => {
                    onChange(
                        e,
                        subtitleEntries[i].uuid,
                        value || '',
                        ref.current.el.current.quill.getContents()
                    );
                }}
                customControls={[
                    {
                        type: CustomControlType.Tab,
                        value: align,
                        onClick: (e, v) => {
                            setAlign(v);
                            setSubtitleMetadata(subtitleEntries[i].uuid, {
                                ...(subtitleEntries[i].metadata || {}),
                                align: v
                            });
                        },
                        values: [
                            {
                                icon: (
                                    <svg viewBox="0 0 18 14">
                                        <rect x="0" y="0" width="9" height="2" />
                                        <rect x="0" y="6" width="13" height="2" />
                                        <rect x="0" y="12" width="18" height="2" />
                                    </svg>
                                ),
                                value: 'left'
                            },
                            {
                                icon: (
                                    <svg viewBox="0 0 18 14">
                                        <rect x="2" y="12" width="14" height="2" />
                                        <rect x="0" y="6" width="18" height="2" />
                                        <rect x="2" y="0" width="14" height="2" />
                                    </svg>
                                ),
                                value: 'center'
                            },
                            {
                                icon: (
                                    <svg viewBox="0 0 18 14">
                                        <rect x="9" y="12" width="9" height="2" />
                                        <rect x="7" y="6" width="13" height="2" />
                                        <rect x="0" y="0" width="18" height="2" />
                                    </svg>
                                ),
                                value: 'right'
                            }
                        ]
                    }
                ]}
                quillOptions={{
                    modules: {
                        toolbar: [
                            'bold',
                            'italic',
                            'underline',
                            {
                                color: [
                                    'white',
                                    'lime',
                                    'cyan',
                                    'red',
                                    'yellow',
                                    'magenta',
                                    'blue',
                                    'black'
                                ]
                            },
                            {
                                background: ['red', 'white', 'black', 'blue']
                            }
                        ]
                    }
                }}
                html={initialValue?.replace(/\n/g, '<br />')}
            />
        </div>
    );
};

export default SidePanelSubtitlesRow;
