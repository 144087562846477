import styles from './AbsoluteMenu.module.css';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import {
    MultiActionButtonList,
    MultiActionsList
} from '../../buttons/MultiActionButton/MultiActionButtonList';
import { onClickOutside } from '../../../utils/onClickOutside';
import {
    Direction,
    DirectionX,
    DirectionY,
    getAbsolutePosition
} from '../../../utils/getAbsolutePosition';
import { createPortal } from 'react-dom';
import { getParentWithClass, parentHasClass } from '../../../utils/dom/parentHasClass';

type AbsoluteMenuProps<T> = {
    id: string;
    data?: T;
};

const absoluteMenus: { [id: string]: AbsoluteMenuHandler<any> } = {};

function getStyle(
    e: MouseEvent,
    usePointerPosition: boolean,
    element:
        | React.MutableRefObject<HTMLElement | null | undefined>
        | React.RefObject<HTMLElement | undefined>,
    absoluteElement: HTMLElement,
    direction: [DirectionX, DirectionY]
): CSSProperties {
    return usePointerPosition
        ? {
              visibility: 'visible',
              left: Math.max(
                  0,
                  Math.min(e.clientX, window.innerWidth - absoluteElement.offsetWidth)
              ),
              top: Math.max(
                  0,
                  Math.min(e.clientY, window.innerHeight - absoluteElement.offsetHeight)
              )
          }
        : getAbsolutePosition(
              element,
              this.absoluteRef as React.MutableRefObject<HTMLElement>,
              direction
          );
}
export class AbsoluteMenuHandler<T> {
    setActions?: (actions?: MultiActionsList) => void;
    setStyle?: (style?: CSSProperties) => void;
    setActionsComponent?: (
        ActionsMenuComponent?: React.ComponentType<any>,
        getComponentProps?: () => any
    ) => void;
    absoluteRef?: React.MutableRefObject<HTMLElement | null>;

    data?: T;
    static init<T>(
        id: string,
        data?: T,
        setActions?: (actions?: MultiActionsList) => void,
        setActionsComponent?: (
            ActionsMenuComponent?: React.ComponentType<any>,
            getComponentProps?: () => any
        ) => void,
        setStyle?: (style: CSSProperties) => void,
        absoluteRef?: React.MutableRefObject<HTMLElement | null>
    ) {
        if (absoluteMenus[id]) {
            if (data) absoluteMenus[id].data = data;
            if (setActions) absoluteMenus[id].setActions = setActions;
            if (setActionsComponent) absoluteMenus[id].setActionsComponent = setActionsComponent;
            if (setStyle) absoluteMenus[id].setStyle = setStyle;
            if (absoluteRef) absoluteMenus[id].absoluteRef = absoluteRef;
            return absoluteMenus[id];
        }
        return (absoluteMenus[id] = new AbsoluteMenuHandler(
            id,
            data,
            setActions,
            setActionsComponent,
            setStyle,
            absoluteRef
        ));
    }
    constructor(
        id: string,
        data: T,
        setActions?: (actions?: MultiActionsList) => void,
        setActionsComponent?: (ActionsMenuComponent?: React.ComponentType<any>) => void,
        setStyle?: (style: CSSProperties) => void,
        absoluteRef?: React.MutableRefObject<HTMLElement | null>
    ) {
        this.id = id;
        this.data = data;
        this.setActions = setActions;
        this.setActionsComponent = setActionsComponent;
        this.setStyle = setStyle;
        this.absoluteRef = absoluteRef;
    }
    id: string;

    open(actions: MultiActionsList | ((data?: T) => MultiActionsList), style: () => CSSProperties) {
        if (!this.setActions || !this.setStyle) return;
        this.setActions(typeof actions === 'function' ? actions(this.data) : actions);
        this.setStyle(style());
    }

    close() {
        if (!this.setActions || !this.setStyle) return;
        this.setActions(undefined);
        this.setStyle(undefined);
    }

    addRightClickListener = (
        element: React.MutableRefObject<HTMLElement | null> | undefined,
        actions:
            | MultiActionsList
            | ((data: T | undefined, triggerElement: HTMLElement) => MultiActionsList),
        {
            direction = [DirectionX.Right, DirectionY.Middle],
            usePointerPosition = false,
            delegatedClass
        }: { direction?: Direction; usePointerPosition?: boolean; delegatedClass?: string } = {}
    ) => {
        if (!element?.current) return;
        const eventHandler = (e) => {
            if (delegatedClass && !e.target.classList.contains(delegatedClass)) return;
            e.preventDefault();
            e.stopPropagation();
            if (!this.absoluteRef?.current) return;
            const offClickOutside = onClickOutside(this.absoluteRef, () => {
                this.close();
                offClickOutside();
            });

            const absoluteRef = this.absoluteRef.current;

            this.open(typeof actions === 'function' ? actions(this.data, e.target) : actions, () =>
                getStyle(e, usePointerPosition, element, absoluteRef, direction)
            );
        };
        element.current.addEventListener('contextmenu', eventHandler);
        return () => {
            element.current?.removeEventListener('contextmenu', eventHandler);
        };
    };
    addRightClickListenerComponent = (
        element: React.MutableRefObject<HTMLElement | null> | undefined,
        ActionsMenuComponent: React.ComponentType<any>,
        {
            direction = [DirectionX.Right, DirectionY.Middle],
            usePointerPosition = false,
            getComponentProps,
            delegatedClass,
            excludeClass
        }: {
            direction?: Direction;
            usePointerPosition?: boolean;
            getComponentProps?: (e: HTMLElement) => any;
            delegatedClass?: string;
            excludeClass?: string;
        } = {}
    ) => {
        if (!element?.current) return;
        const eventHandler = (e) => {
            let targetElement = e.currentTarget;
            if (delegatedClass) {
                const parentWithClass = getParentWithClass(
                    e.target,
                    delegatedClass,
                    e.currentTarget
                );
                if (!parentWithClass) return;
                targetElement = parentWithClass;
            } else if (excludeClass && parentHasClass(e.target, excludeClass, e.currentTarget)) {
                return;
            }
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            if (!this.absoluteRef?.current) return;
            const offClickOutside = onClickOutside(this.absoluteRef, () => {
                this.close();
                offClickOutside();
            });

            if (!this.setActionsComponent || !this.setStyle) return;
            this.setActionsComponent(ActionsMenuComponent, () =>
                getComponentProps?.(targetElement)
            );
            setTimeout(() => {
                const style = getStyle(
                    e,
                    usePointerPosition,
                    element,
                    this.absoluteRef!.current!,
                    direction
                );
                this.setStyle!(style);
            }, 20);
        };
        element.current.addEventListener('contextmenu', eventHandler);
        return () => {
            element.current?.removeEventListener('contextmenu', eventHandler);
        };
    };
    getOnClickHandler(
        actions: MultiActionsList | ((data?: T) => MultiActionsList),
        direction?: [DirectionX, DirectionY]
    ) {
        return (e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            if (!this.absoluteRef?.current) return;
            const offClickOutside = onClickOutside(this.absoluteRef, () => {
                this.close();
                offClickOutside();
            });
            const absoluteRef = this.absoluteRef;
            this.open(typeof actions === 'function' ? actions(this.data) : actions, () =>
                getAbsolutePosition(
                    { current: e.currentTarget },
                    absoluteRef,
                    direction || [DirectionX.Right, DirectionY.Middle]
                )
            );
        };
    }
}

export function AbsoluteMenu<T extends Record<string, any> | undefined>({
    id,
    data
}: AbsoluteMenuProps<T>) {
    const [actions, setActions] = useState<MultiActionsList | undefined>();
    const [ActionsComponent, setActionsComponent] = useState<
        { Component: React.ComponentType<any>; getComponentProps?: () => any } | undefined
    >();
    const absoluteRef = useRef<HTMLDivElement>(null);
    const [style, setStyle] = useState<CSSProperties>();
    const absoluteMenuHandler = useRef<AbsoluteMenuHandler<T>>();
    useEffect(() => {
        if (absoluteRef.current) {
            absoluteMenuHandler.current = AbsoluteMenuHandler.init(
                id,
                data,
                setActions,
                (Component: React.ComponentType<any>, getComponentProps: () => any) =>
                    setActionsComponent({ Component, getComponentProps }),
                setStyle,
                absoluteRef
            );
        }
    }, [absoluteRef]);

    useEffect(() => {
        return () => {
            if (absoluteMenus[id]) delete absoluteMenus[id];
        };
    }, []);
    return createPortal(
        <div
            style={{ position: 'absolute', zIndex: 100, visibility: 'hidden', ...style }}
            ref={absoluteRef}
        >
            {ActionsComponent && (
                <ActionsComponent.Component
                    absoluteMenuHandler={absoluteMenuHandler.current}
                    {...ActionsComponent.getComponentProps?.()}
                />
            )}
            {actions ? (
                <MultiActionButtonList
                    className={styles.menu}
                    onClickableClick={() => {
                        setTimeout(() => absoluteMenuHandler.current?.close(), 0);
                    }}
                    actions={actions}
                />
            ) : null}
        </div>,
        document.body
    );
}

export function useAbsoluteMenu(id: string, { useRightClick }: { useRightClick?: boolean } = {}) {
    const absoluteMenuHandler = useRef<AbsoluteMenuHandler<any>>();
    useEffect(() => {
        absoluteMenuHandler.current = AbsoluteMenuHandler.init(id);
    }, []);
    return absoluteMenuHandler.current;
}
