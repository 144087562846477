import './ActivityItem.scss';
import React from 'react';
import { AvatarUser } from '@he-novation/design-system/components/avatars/Avatar/AvatarUser';
import { __ } from '@he-novation/design-system/utils/i18n';
import Icon from '@he-novation/icons';
import sanitizeHTML from 'sanitize-html';

import { ActivityItemV1 } from '$components/ActivityList/ActivityItem/ActivityItemV1';
import { ActivityItemV2 } from '$components/ActivityList/ActivityItem/ActivityItemV2';
import parseEJSCondition from '$helpers/parseEJSCondition';

const tagToIcon = (tag: string) => {
    switch (tag) {
        case 'access_revoke':
        case 'access_grant':
            return 'users';
        case 'comment_add':
            return 'speech-bubble';
        case 'event_update':
            return 'date';
        case 'file_add_subtitle':
            return 'subtitles';
        case 'file_delete':
        case 'file_delete_subtitle':
        case 'folder_delete':
            return 'trash-can';
        case 'file_download':
            return 'arrow-down';
        case 'file_move':
            return 'round-exchange';
        case 'file_new':
        case 'file_new_version':
            return 'file';
        case 'file_rename':
            return 'pencil';
        case 'file_restore':
            return 'file_restore';
        case 'folder_add':
            return 'folder';
        case 'access_admittance':
        case 'folder_admittance':
            return 'key';
        case 'folder_bundles':
            return 'package';
        case 'folder_default_presets':
            return 'arrow-up';
        case 'folder_metadata':
            return 'code';
        case 'folder_public':
        case 'folder_public_password_enabled':
            return 'earth';
        case 'project_modify':
            return 'briefcase';
        case 'task_modify':
            return 'add-task';
        default:
            return 'info';
    }
};

const localizeValues = (__, { i18n, ...values }) => {
    values.you = __('OWNER_YOU');
    if (i18n) {
        Object.keys(i18n).forEach((i) => {
            values[i] = __(i);
        });
    }

    return values;
};

const getUser = (_email, contacts, userInfos) =>
    _email === userInfos.email
        ? userInfos
        : contacts.find(({ email }) => email === _email) || { email: _email };

interface Props {
    children: React.ReactNode;
    detailed: boolean;
    group: {
        items: any[];
        key: string;
        i18n: any;
    };
    contacts: {
        list: {
            email: string;
        }[];
    };
    userInfos: {
        uuid: string;
        email: string;
    };
}

export const ActivityItem: React.FC<Props> = ({
    group,
    detailed,
    children,
    contacts: { list },
    userInfos
}) => {
    const firstItem = group.items[0];
    const isUser = userInfos.uuid ? true : false;

    const _values = firstItem.values ? localizeValues(__, firstItem.values) : {};
    return (
        <div className="c-activity-item">
            {firstItem.values && (
                <>
                    <header>
                        <AvatarUser
                            {...getUser(
                                firstItem.request_user_email || firstItem.values.user,
                                list,
                                userInfos
                            )}
                        />
                        <div className="activity-icon">
                            <Icon icon={tagToIcon(firstItem.tag)} />
                        </div>
                    </header>

                    <p
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHTML(parseEJSCondition(group.key, group.i18n, _values))
                        }}
                    />
                </>
            )}
            {detailed &&
                group.items.map((item, i) =>
                    item.index === process.env.OPEN_SEARCH_INDEX ? (
                        <ActivityItemV2 key={i} item={item} index={i} />
                    ) : (
                        <ActivityItemV1 isUser={isUser} key={i} item={item} index={i} />
                    )
                )}
            {children}
        </div>
    );
};
