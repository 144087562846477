import { Note } from '@he-novation/config/types/note.types';
import sqlDatesToJsDates from '../../../content/projects/maps/sqlDatesToJsDates';

export default (response: Record<string, Note>, displayAllVersions?: boolean) => ({
    displayAllVersions,
    list: response
        ? Object.keys(response).map((k) => ({
              ...response[k],
              created: new Date(response[k].created),
              comments: response[k].comments.map(sqlDatesToJsDates).map(({ tags, ...rest }) => ({
                  tags: !Array.isArray(tags) && tags ? tags.split(/\s|,/g) : tags,
                  ...rest
              })),
              task: response[k].task ? sqlDatesToJsDates(response[k].task) : null
          }))
        : [],
    noContent: !response
});
