import './FormField.scss';
import React, { ReactNode } from 'react';
import { ModularFormField } from 'react-modular-forms';
import { ModularFormFieldProps } from 'react-modular-forms/dist/declarations/types';
import { Theme } from '../../../enums';
import cn from 'classnames';
import Icon from '@he-novation/icons';
export { ModularForm } from 'react-modular-forms';
import { configureReactModularForms } from './configureReactModularForms';

type FormFieldProps = ModularFormFieldProps & {
    theme?: Theme;
    icon?: string;
    children?: ReactNode | ReactNode[];
    size?: 'half' | 'full';
    leftMargin?: boolean;
};

configureReactModularForms();

export const FormField = ({
    className,
    theme,
    icon,
    size = 'full',
    children,
    leftMargin,
    ...props
}: FormFieldProps) => {
    return (
        <ModularFormField
            className={cn(
                className,
                theme,
                icon && 'has-icon',
                `size-${size}`,
                leftMargin && 'has-left-margin'
            )}
            theme={theme}
            children={icon ? <Icon icon={icon} /> : children}
            {...props}
        />
    );
};

type FormFieldsProps = {
    formId: string;
    fields: any[];
    theme?: Theme;
};

export const FormFields = ({ formId, fields, theme }: FormFieldsProps) => {
    return (
        <>
            {fields
                .filter((f) => f && f.type)
                .map((f, i) => (
                    <FormField
                        theme={theme}
                        formId={formId}
                        {...f}
                        key={formId + (f.name || f.id) + i}
                    />
                ))}
        </>
    );
};
