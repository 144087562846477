import React from 'react';

import SelectableGroup from '$components/Selectable/SelectableGroup';
import {
    SelectableItemProps,
    SelectableItemState,
    SelectionHandler
} from '$components/Selectable/SelectionHandler';

class SelectableItem<T> extends React.Component<SelectableItemProps<T>, SelectableItemState> {
    ref: React.RefObject<HTMLElement>;
    group: SelectionHandler<T>;
    addedListeners?: boolean;

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            isSelected: false,
            isFocused: false
        };
        this.group = SelectableGroup.getGroup(this.props.selectableGroupId);
    }

    componentDidMount() {
        if (!this.group) return;
        this.group.register(this);
        this.addListeners();
        const components = this.group?.getSelectedComponents() || [];
        if (components.find((s) => s.props.id === this.props.id)) this.toggle(true);
    }

    componentWillUnmount() {
        if (!this.group) return;
        this.group.unregister(this);
        this.removeListeners();
    }
    addListeners() {
        const el = this.ref.current!;

        if (!el) return;
        this.addedListeners = true;
        this.getFocusElement()?.addEventListener('focus', this.onFocus);
        this.getFocusElement()?.addEventListener('blur', this.onBlur);
    }

    componentDidUpdate() {
        if (!this.addedListeners && this.group && this.ref.current) this.addListeners();
    }

    removeListeners() {
        const el = this.ref.current!;
        if (!el) return;
        this.getFocusElement()?.removeEventListener('focus', this.onFocus);
        this.getFocusElement()?.removeEventListener('blur', this.onBlur);
    }

    getElement() {
        return document.getElementById(this.props.id)!;
    }

    toggle = (isSelected?: boolean) => {
        this.setState({
            isSelected: typeof isSelected === 'undefined' ? !this.state.isSelected : isSelected
        });
    };

    render() {
        const { Component, componentProps } = this.props;
        return (
            <Component
                {...componentProps}
                className={
                    (componentProps.className ? componentProps.className + ' ' : '') +
                    'selectable-item'
                }
                selection={{
                    id: this.props.id,
                    selectableRef: this.ref,
                    selectableGroupId: this.props.selectableGroupId,
                    isSelected: this.state.isSelected,
                    isFocused: this.state.isFocused,
                    getSelectedItems: this.getSelectedItems,
                    toggleSelection: this.toggleSelection,
                    shiftSelect: this.group.shiftSelectTo.bind(this.group, this)
                }}
            />
        );
    }

    toggleSelection = (toggled?: boolean) => this.group.toggle(this, toggled);

    getSelectedItems = (useSelfIfNoSelection?: boolean): T[] => {
        const selectedItems = this.group?.getSelectedComponentProps() || ([] as T[]);
        return (
            selectedItems.length
                ? selectedItems
                : useSelfIfNoSelection
                ? [this.props.componentProps]
                : []
        ) as T[];
    };

    getFocusElement = () =>
        this.ref.current?.querySelectorAll('a, button:not(.c-file-version-button)')?.[0] as
            | HTMLElement
            | undefined;

    focus = () => {
        const button = this.getFocusElement();
        button?.focus();
        return button;
    };

    onFocus = () => {
        this.setState({ isFocused: true });
    };

    onBlur = () => {
        this.setState({ isFocused: false });
    };
}

export default SelectableItem;
