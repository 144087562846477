export const getDisplayName = (
    {
        firstname,
        lastname,
        email
    }: { firstname?: string | null; lastname?: string | null; email: string },
    withEmail = false,
    separators = ['<', '>']
) => {
    let displayName;
    if (firstname && lastname) {
        displayName = `${firstname} ${lastname}`;
        if (withEmail) displayName += ` ${separators[0]}${email}${separators[1]}`;
    } else displayName = email;

    return displayName;
};
