import React from 'react';
import { DatePicker as DatePickerLib } from 'react-calendars';
import { HourPicker } from '../HourPicker/HourPicker';
import { doubleDigit } from '../../../utils/doubleDigit';
import { Theme } from '../../../enums';

type DatePickerProps = {
    dateToValue?: (d: Date) => string;
    valueToDate?: (v?: string) => Date;
    isDisabledDay?: (year: number, month: number, day: number) => boolean;
    value?: Date;
    month?: number;
    onChange?: (e: any, date?: Date) => void;
    onSelect?: (e: any, date: Date) => void;
    year?: number;
    valueAsDate?: boolean;
    sharedProps: {
        id?: string;
        hourPicker?: boolean;
        value?: Date;
        direction?: 'TOP' | 'BOTTOM';
        onChange?: Function;
    };
};

type DatePickerState = {
    date?: Date;
    timezoneOffset: number;
};

export class DatePicker extends React.Component<DatePickerProps, DatePickerState> {
    datePicker: DatePickerLib;

    constructor(props: DatePickerProps) {
        super(props);
        this.state = {
            date: this.props.value,
            timezoneOffset: this.props.value
                ? -(this.props.value.getTimezoneOffset() / 60)
                : -(new Date().getTimezoneOffset() / 60)
        };
    }

    setDatePickerRef = (ref: DatePickerLib) => (this.datePicker = ref);

    componentDidUpdate(prevProps: DatePickerProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({ date: this.props.value });
        }
    }

    render() {
        const {
            sharedProps: { hourPicker, value, ...sharedProps }
        } = this.props;

        return (
            <DatePickerLib
                year={this.props.year}
                month={this.props.month}
                displayYearPicker={false}
                autoComplete="off"
                dateToValue={this.dateToValue}
                valueToDate={this.valueToDate}
                isDisabledDay={this.props.isDisabledDay}
                afterMonthPicker={
                    hourPicker ? (
                        <HourPicker
                            id={`${this.props.sharedProps.id}-hour-picker`}
                            theme={Theme.Light}
                            time={this.dateToTime(this.state.date)}
                            timezoneOffset={this.state.timezoneOffset}
                            setTime={this.setTime}
                        />
                    ) : null
                }
                renderMonthTitle={(month: number, year: number) => (
                    <>
                        {month} {year}
                    </>
                )}
                {...sharedProps}
                onSelect={this.onSelect}
                onChange={this.onChange}
                selectedDay={this.state.date}
                ref={this.setDatePickerRef}
            />
        );
    }

    dateToValue = (date: Date) => {
        if (typeof this.props.dateToValue === 'function') return this.props.dateToValue(date);
        if (!date) return '';
        if (!(date instanceof Date)) date = this.valueToDate(date);
        let str = date.toLocaleDateString();
        if (this.props.sharedProps.hourPicker) str += ` - ${this.dateToTime(date)}`;
        return str;
    };

    dateToTime = (date?: Date) => {
        if (!date) return '00:00';
        if (!(date instanceof Date)) date = this.valueToDate(date);
        return `${doubleDigit(date.getHours())}:${doubleDigit(date.getMinutes())}`;
    };

    valueToDate = (value?: string | Date): Date => {
        if (value instanceof Date) return value;
        if (typeof this.props.valueToDate === 'function') return this.props.valueToDate(value);
        if (!value) return new Date();
        let splitValue = value.split(/[:/.,-]/g).map((v) => parseInt(v));
        const testValue = new Date(2020, 0, 25, 7, 55, 49)
            // @ts-ignore
            .toLocaleString(navigator.locale, {
                hour12: false,
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            })
            .split(/[:/.,-]/g)
            .map((v) => parseInt(v));
        const yearIndex = testValue.indexOf(2020);
        const monthIndex = testValue.indexOf(1);
        const dayIndex = testValue.indexOf(25);
        const hoursIndex = testValue.indexOf(7);
        const minutesIndex = testValue.indexOf(55);
        const secondsIndex = testValue.indexOf(49);
        return new Date(
            splitValue[yearIndex],
            splitValue[monthIndex] - 1,
            splitValue[dayIndex],
            hoursIndex !== -1 ? splitValue[hoursIndex] || 0 : 0,
            minutesIndex !== -1 ? splitValue[minutesIndex] || 0 : 0,
            secondsIndex !== -1 ? splitValue[secondsIndex] || 0 : 0
        );
    };

    onSelect = (e: any, date: Date, previousDate?: Date) => {
        if (previousDate) {
            date.setHours(previousDate.getHours());
            date.setMinutes(previousDate.getMinutes());
            date.setSeconds(previousDate.getSeconds());
        }
        this.setState({ date }, () => {
            this.onChange(e);
            if (typeof this.props.onSelect === 'function') this.props.onSelect(e, date);
        });
    };

    onChange = (e?: any) => {
        if (typeof this.props.onChange === 'function') this.props.onChange(e, this.state.date);
        if (typeof this.props.sharedProps.onChange === 'function')
            setTimeout(() => this.props.sharedProps?.onChange?.(e), 0);
    };

    setTimezone = (date: Date, timezoneOffset: number) => {
        if (!this.props.sharedProps.hourPicker) return date;
        const ownTimezoneOffset = -(date.getTimezoneOffset() / 60);
        const hourOffset =
            ownTimezoneOffset -
            (typeof timezoneOffset !== 'undefined' ? timezoneOffset : this.state.timezoneOffset);
        date.setHours(date.getHours() + hourOffset);
        return date;
    };

    setTime = (time: string, timezoneOffset: number, cb: Function) => {
        let date = new Date(this.state.date || new Date());
        const splitTime = time.split(':');
        date.setHours(parseInt(splitTime[0]));
        date.setMinutes(parseInt(splitTime[1]));
        date = this.setTimezone(date, timezoneOffset);
        this.setState(
            {
                timezoneOffset: timezoneOffset,
                date
            },
            () => {
                this.onChange();
                if (typeof cb === 'function') cb();
            }
        );
    };
}
