import './Avatar.scss';
import type { ReactNode, SyntheticEvent } from 'react';
import React from 'react';
import { initialsColor } from '../../../utils/initialsColor';
import { Tooltip } from '../../widgets/Tooltip/Tooltip';
import classNames from 'classnames';
import { Direction, DirectionX, DirectionY } from '../../../utils/getAbsolutePosition';

export enum AvatarSize {
    Tiny = 'tiny',
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
    ExtraLarge = 'extra-large'
}

type AvatarProps = {
    className?: string;
    initials: string;
    onClick?: (e: SyntheticEvent) => void;
    picture?: string | null;
    pictureFallback?: ReactNode;
    size?: AvatarSize;
    square?: boolean;
    roundedSquare?: boolean;
    tooltip?: ReactNode;
    tooltipDirection?: Direction;
    children?: ReactNode | ReactNode[];
};

export type IAvatar = React.ComponentType & {
    propsToTooltip: (avatar: any) => ReactNode;
};

export class Avatar extends React.Component<AvatarProps> {
    static defaultProps = {
        size: AvatarSize.Medium
    };

    render() {
        return (
            <div
                onClick={this.props.onClick}
                className={classNames(
                    'c-avatar',
                    this.props.size ? `is-${this.props.size}` : null,
                    this.props.className,
                    {
                        'is-square': this.props.square,
                        'is-rounded-square': this.props.roundedSquare
                    }
                )}
            >
                {this.props.tooltip ? (
                    <Tooltip
                        content={this.props.tooltip}
                        direction={
                            this.props.tooltipDirection || [DirectionX.Center, DirectionY.Top]
                        }
                    >
                        {this.renderPicture()}
                        {this.props.children}
                    </Tooltip>
                ) : (
                    <>
                        {this.renderPicture()}
                        {this.props.children}
                    </>
                )}
            </div>
        );
    }

    renderPicture() {
        if (this.props.picture) {
            return <img src={this.props.picture} alt="" />;
        }
        if (this.props.pictureFallback) {
            return this.props.pictureFallback;
        }

        return (
            <div
                className="c-avatar-initials"
                style={{ backgroundColor: initialsColor(this.props.initials) }}
            >
                {this.props.initials}
            </div>
        );
    }
}

export default Avatar;
