import { __ } from '@he-novation/design-system/utils/i18n';
import { doubleDigit } from '@he-novation/utils/number';

export const offsetYears = (date, months) => {
    const result = new Date(date);
    result.setFullYear(result.getFullYear() + months);
    return result;
};

export const offsetMonths = (date, months) => {
    const result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
};

export const offsetWeeks = (date, weeks) => {
    const result = new Date(date);
    result.setDate(result.getDate() + weeks * 7);
    return result;
};

export const offsetDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

export const offsetHours = (date, hours) => {
    const result = new Date(date);
    result.setHours(result.getHours() + hours);
    return result;
};

export const offsetMinutes = (date, minutes) => {
    const result = new Date(date);
    result.setMinutes(result.getMinutes() + minutes);
    return result;
};

export const getDateFromString = (created) => {
    const dateObject = new Date(created);
    return `${dateObject.getDate()}-${dateObject.getMonth() + 1}-${dateObject.getFullYear()}`;
};

export const getFormattedDateAndHour = (d = new Date()) =>
    [d.getDate(), d.getMonth() + 1, d.getFullYear()]
        .map((n) => (n < 10 ? `0${n}` : `${n}`))
        .join('/') + ` - ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;

export const getMysqlStringFromDate = (date) =>
    `${date.getFullYear()}-${
        date.getMonth() + 1
    }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

export const getFullHourFromString = (created) => {
    const dateObject = new Date(created);
    return `${dateObject.getHours()}${dateObject.getMinutes()}${dateObject.getSeconds()}`;
};

export const getTimeFromString = (created) => new Date(created).getTime();

export const getLocalizedTime = (locale, created, type) => {
    try {
        const now = new Date();
        const timeStamp = typeof created === 'object' ? created : new Date(created);

        let opts;

        if (type === 'date') {
            opts = {
                month: 'long',
                day: 'numeric'
            };

            if (now.getFullYear() !== timeStamp.getFullYear()) {
                opts.year = 'numeric';
            }
        } else if (type === 'hour') {
            opts = {
                hour: 'numeric',
                minute: 'numeric'
            };
        }

        return new Intl.DateTimeFormat(locale, opts).format(timeStamp);
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const isEqualDate = (date1, date2) =>
    new Date(date1).getFullYear() === new Date(date2).getFullYear() &&
    new Date(date1).getMonth() === new Date(date2).getMonth() &&
    new Date(date1).getDate() === new Date(date2).getDate();

export const toRoundDate = (date) => {
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    return date;
};

export const dateToDateShortMonth = (date, locale) => ({
    date: date.getDate(),
    shortMonth: new Intl.DateTimeFormat(locale, { month: 'short' }).format(date).replace('.', '')
});

export const localeToDateLocale = (locale) => {
    switch (locale) {
        case 'fr':
            return 'fr-FR';
        case 'de':
            return 'de-DE';
        default:
            return 'en-GB';
    }
};

export const getLocalizedDate = (date, locale, intlDateFormatOptions = {}) =>
    date.toLocaleString(localeToDateLocale(locale), intlDateFormatOptions);

export const getLocaleDate = (date, locale) =>
    new Date(date).toLocaleDateString(localeToDateLocale(locale));

export const dayInterval = (d1, d2) => {
    d1 = toRoundDate(d1);
    d2 = toRoundDate(d2);
    return Math.round((d1.getTime() - d2.getTime()) / 1000 / 60 / 60 / 24);
};

export const monthInterval = (d1, d2) => {
    let months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
};

export const weekInterval = (d1, d2) => Math.ceil(dayInterval(d1, d2) / 7);

export const datesIntervalToString = (d1, d2) => {
    const interval = dayInterval(d1, d2);
    if (interval < -1) {
        return __('DATE_IN', { days: Math.floor(Math.abs(interval)).toString() });
    }
    if (interval === -1) return __('DATE_TOMOROW');
    if (interval === 0) return __('DATE_TODAY');
    if (interval === 1) return __('DATE_YESTERDAY');
    return __('DATE_AGO', { days: Math.floor(Math.abs(interval)).toString() });
};

export const datesIntervalToTimeBefore = (d1, d2) => {
    const minutes = getDiffDays(d1, d2, MS_PER_MINUTE);
    return minutes > 59
        ? __('BEFORE_HOURS', { hours: Math.ceil(minutes / 60) })
        : __('BEFORE_MINUTES', { minutes: minutes });
};

export const getCroppedMonth = (month, year) => {
    if (month > 11) {
        month = 0;
        year++;
    }
    if (month < 0) {
        month = 11;
        year--;
    }
    return {
        month,
        year
    };
};

export const MS_PER_SECOND = 1000;
export const MS_PER_MINUTE = MS_PER_SECOND * 60;
export const MS_PER_HOUR = MS_PER_MINUTE * 60;
export const MS_PER_DAY = MS_PER_HOUR * 24;
export const MS_PER_WEEK = MS_PER_DAY * 7;

export const getDiffDays = (date1, date2, divider = MS_PER_DAY) => {
    const _date1 = new Date(date1);
    const _date2 = new Date(date2);
    const diffTime = _date2 - _date1;

    return Math.ceil(diffTime / divider);
};

export const isMonday = (date) => date.getDay() === 1;

export const getNextMonday = (date) => {
    const nextMonday = new Date(date);
    if (isMonday(nextMonday)) {
        nextMonday.setDate(nextMonday.getDate() + 7);
        return nextMonday;
    }
    nextMonday.setDate(nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7));

    if (isEqualDate(nextMonday, new Date(date))) {
        nextMonday.setDate(nextMonday.getDate() + 7);
    }

    return nextMonday;
};

export const isSunday = (date) => date.getDay() === 0;

export const getNextSunday = (date) => {
    const nextSunday = new Date(date);

    if (isSunday(nextSunday)) {
        nextSunday.setDate(nextSunday.getDate() + 7);
    } else {
        const nextMonday = getNextMonday(date);
        nextSunday.setDate(nextMonday.getDate() - 1);
    }
    return nextSunday;
};

export const getPrevMonday = (date) => {
    const d = new Date(date);

    while (d.getDay() !== 1) {
        d.setDate(d.getDate() - 1);
    }

    return d;
};

export const setMidnight = (_date) => {
    const date = new Date(_date);
    date.setHours(0, 0, 0, 0);
    date.setDate((_date instanceof Date ? _date : new Date(_date)).getDate());
    return date;
};

export const FORMAT_DDMMYYHHMMSS = 'DD:MM:YY - HH:MM:SS';
export const FORMAT_FULLDATETRANSLATED = 'DAY:DATE:MONTH:YEAR • H:MM';
export const FORMAT_SHORT_DATETIME_TRASNLATED = 'DATE:SHORTMONTH • H:MM';

export const formatDate = (date = new Date(), format = FORMAT_DDMMYYHHMMSS, lang = 'fr') => {
    switch (format) {
        case FORMAT_DDMMYYHHMMSS:
            return getFormattedDateAndHour(date);
        case FORMAT_FULLDATETRANSLATED: {
            return `${daysTranslation[lang][date.getDay()]} ${date.getDate()} ${
                monthsTranslation[lang][date.getMonth()]
            } ${date.getFullYear()} • ${date.getHours()}h${
                date.getMinutes() > 0 ? doubleDigit(date.getMinutes()) : ''
            }`;
        }
        case FORMAT_SHORT_DATETIME_TRASNLATED:
            return `${date.getDate()} ${
                monthsTranslation[lang][date.getMonth()]
            } • ${date.getHours()}h${date.getMinutes() > 0 ? doubleDigit(date.getMinutes()) : ''}`;
        default:
            return date.toISOString();
    }
};

export const weekNumber = (d) => {
    const date = new Date(d);
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return (
        1 +
        Math.round(
            ((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7
        )
    );
};

export const datesOverlap = (d1Start, d1End, d2Start, d2End) =>
    Math.max(d1Start.getTime(), d2Start.getTime()) < Math.min(d1End.getTime(), d2End.getTime());

export const daysTranslation = {
    fr: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    de: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
};

export const monthsTranslation = {
    fr: ['Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
    en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    de: ['Jan', 'Feb', 'März', 'Apr', 'Mai', 'Juni', 'Juli', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
};

export const roundToMinutes = (date, minutes = 15, method = 'round') => {
    date = new Date(date);
    if (date.getMinutes() > 60 - minutes / 2) {
        date.setHours(date.getHours() + 1);
        date.setMinutes(0);
        date.setSeconds(0);
        return date;
    }
    date.setMinutes((Math[method](date.getMinutes() / minutes) * minutes) % 60);
    date.setSeconds(0);
    return date;
};

export const formatDateRange = (startDate, endDate) => {
    const formatToShortMonth = (date) =>
        new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);

    const humanStartDateYear = new Date(startDate).getFullYear();
    const humanEndDateYear = new Date(endDate).getFullYear();
    const humanStartDateMonth = formatToShortMonth(startDate);
    const humanEndDateMonth = formatToShortMonth(endDate);

    if (humanStartDateYear === humanEndDateYear && humanStartDateMonth === humanEndDateMonth) {
        return `${humanStartDateMonth}. ${humanStartDateYear}`;
    }

    if (humanStartDateYear === humanEndDateYear) {
        return `${humanStartDateMonth}. - ${humanEndDateMonth}. ${humanStartDateYear}`;
    }

    return `${humanStartDateMonth}. ${humanStartDateYear} - ${humanEndDateMonth}. ${humanEndDateYear}`;
};

export const roundToPeriodStart = (date, period) => {
    date.setMilliseconds(0);
    date.setSeconds(0);
    date.setMinutes(0);
    if (period === 'hour') return date;
    date.setHours(0);
    if (period === 'day') return date;
    if (period === 'week') {
        return getPrevMonday(date);
    }
    date.setDate(1);
    if (period === 'month') return date;
    date.setMonth(0);
    return date;
};
export function daysInMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 0).getDate();
}

export function daysInYear(year) {
    return (year % 4 === 0 && year % 100 > 0) || year % 400 === 0 ? 366 : 365;
}

export const roundToPeriodEnd = (date, period) => {
    date.setMilliseconds(59);
    date.setSeconds(59);
    date.setMinutes(59);
    if (period === 'hour') return date;
    date.setHours(23);
    if (period === 'day') return date;
    if (period === 'week') {
        return getNextSunday(date);
    }
    date.setDate(daysInMonth(date));
    if (period === 'month') return date;
    date.setMonth(11);
    return date;
};

export const offsetByPeriod = (date, period, amount = 1) => {
    switch (period) {
        case 'hour':
            return offsetHours(date, amount);
        case 'day':
            return offsetDays(date, amount);
        case 'week':
            return offsetWeeks(date, amount);
        case 'month':
            return offsetMonths(date, amount);
        case 'year':
            return offsetYears(date, amount);
        default:
            return date;
    }
};

export const getPeriodDelta = (start, end, period) => {
    switch (period) {
        case 'hour':
            return getDiffDays(start, end, MS_PER_HOUR);
        case 'day':
            return getDiffDays(start, end, MS_PER_DAY);
        case 'week':
            return getDiffDays(start, end, MS_PER_WEEK);
        case 'month':
            return monthInterval(start, end);
        case 'year':
            return end.getFullYear() - start.getFullYear();
    }
};

export const getIntlDateTimeFormat = (date, locale, fb) => {
    try {
        if (/^0000/.test(date)) return fb;
        if (isNaN(Date.parse(date))) return fb;
        return new Intl.DateTimeFormat(localeToDateLocale(locale)).format(
            date?.getTime ? date : new Date(date)
        );
    } catch (e) {
        return fb;
    }
};

export const isoDateToLocalMidnight = (dateIsoString) => {
    const [year, month, day] = dateIsoString.split('T')[0].split('-');
    return new Date(year, month - 1, day, 0, 0);
};
export const isoDateToLocalLatest = (dateIsoString) => {
    const [year, month, day] = dateIsoString.split('T')[0].split('-');
    return new Date(year, month - 1, day, 23, 59, 59);
};

export const msToHms = (ms) => {
    const d = new Date(ms);
    return { h: d.getUTCHours(), m: d.getUTCMinutes(), s: d.getUTCSeconds() };
};

export const msToHmsString = (ms) => {
    const hms = msToHms(ms);
    if (hms.h) return __('H_M_S', hms);
    return __('M_S', hms);
};
