import React from 'react';
import { connect } from 'react-redux';
import * as sockets from '@he-novation/config/utils/sockets/sockets.client';

const mapActionsToDispatchActions = (actions, dispatch) => {
    const newActions = {};

    for (const key in actions) {
        newActions[key] = (...params) => {
            const r = actions[key](...params);
            if (typeof r === 'function') {
                r(dispatch);
            } else if (typeof r === 'object' && r.type) {
                dispatch(r);
            } else if (Array.isArray(r)) {
                r.filter((a) => typeof a === 'object' && a.type).forEach(dispatch);
            }
        };
    }

    return newActions;
};

export type WithSocketIoProps = {
    socketIoSubscribe: (socketParams: SocketParams) => void;
    socketIoUnsubscribe: () => void;
};
type Props = {
    Component: React.ComponentType<Record<string, unknown> & WithSocketIoProps>;
    componentProps: Record<string, unknown>;
    propsOrFunc: SocketParams | ((props: unknown) => SocketParams);
    dispatchSocketAction: (action: unknown) => void;
};

type SocketParams = { room: string; name: string; actions: Record<string, Function> };

class WithSocketIo extends React.Component<Props> {
    names: string[] = [];
    sockets: (() => void)[] = [];
    componentDidMount() {
        if (this.props.propsOrFunc) {
            const propsOrFuncs = Array.isArray(this.props.propsOrFunc)
                ? this.props.propsOrFunc
                : [this.props.propsOrFunc];

            propsOrFuncs.forEach((propsOrFunc) =>
                this.socketIoSubscribe(
                    typeof propsOrFunc === 'function'
                        ? propsOrFunc(this.props.componentProps)
                        : propsOrFunc
                )
            );
        }
    }

    componentWillUnmount() {
        this.socketIoUnsubscribe();
    }

    render() {
        const { Component, componentProps } = this.props;
        return (
            <Component
                socketIoSubscribe={this.socketIoSubscribe}
                socketIoUnsubscribe={this.socketIoUnsubscribe}
                {...componentProps}
            />
        );
    }

    socketIoSubscribe = (socketParams: SocketParams) => {
        if (!socketParams.room) return;
        /* eslint-disable */
        const unsubscribe = sockets[socketParams.name].clientActionsInit(
            socketParams.room,
            mapActionsToDispatchActions(socketParams.actions, this.props.dispatchSocketAction)
        );
        if (!unsubscribe) return console.warn('Error initializing socket');
        /* eslint-enable */

        this.sockets.push(unsubscribe);
    };

    socketIoUnsubscribe = () => {
        this.sockets.forEach((unsubscribe) => unsubscribe());
    };
}

const WithSocketIoContainer = connect(undefined, (dispatch) => ({
    dispatchSocketAction: (action) => dispatch(action)
}))(WithSocketIo);

/**
 * @deprecated
 */
const withSocketIO =
    (...propsOrFunc) =>
    (Component) =>
        function withSocketIoComponent(props: Record<string, unknown>) {
            return props.isPublicFile ? (
                <Component {...props} />
            ) : (
                <WithSocketIoContainer
                    propsOrFunc={propsOrFunc}
                    Component={Component}
                    componentProps={props}
                />
            );
        };

export default withSocketIO;
