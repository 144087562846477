import { ROUTE } from './routeActions.js';
import { compose, pick, pipe, property } from 'lodash/fp';
import { deserialize } from '../../components/Filters/helpers';

const stateSelector = property(ROUTE);

export const routeSelector = compose(
    ({ configRoute, route, params, queryParams } = {}) => ({
        configRoute,
        route,
        params,
        queryParams
    }),
    stateSelector
);

export const activeRouteSelector = pipe(stateSelector, property('route'), (activeRoute) => ({
    activeRoute
}));

export const paramsFromLegacyRouteSelector = (routeTemplate) =>
    compose(
        (params) => ({ params }),
        ({ route }) => {
            const params = route.split('/');
            const templateParams = routeTemplate.split('/');

            return templateParams.reduce((acc, curr, i) => {
                if (curr.startsWith(':')) acc[curr.replace(':', '')] = params[i];

                return acc;
            }, {});
        },
        routeSelector
    );

export const isSharedFolderSelector = compose(
    ({ route }) => ({ isSharedFolder: route.indexOf('shared') > -1 }),
    stateSelector
);

export const routeParamsSelector = compose(
    ({ params }) => ({ routeParams: params }),
    routeSelector
);

export const modalDataSelector = pipe(
    stateSelector,
    pick(['queryParams', 'modalPayload']),
    ({ queryParams: { modal, ...extraParams }, modalPayload }) => ({
        modalData: {
            extraParams,
            modal,
            modalPayload
        }
    })
);

export const modalPayloadSelector = compose(
    ({ modalPayload }) => ({ modalPayload }),
    stateSelector
);

export const filtersSelector = compose(
    (filters) => ({ filters }),
    (filters) => deserialize(filters),
    property('queryParams.filters'),
    stateSelector
);

export const filtersLengthSelector = compose(
    ({ filters }) => ({
        filtersLength: Object.keys(filters)
            .filter((filter) => filter !== 'query')
            .reduce(
                (acc, curr) =>
                    Array.isArray(filters[curr]) ? acc + filters[curr].length : acc + 1,
                0
            )
    }),
    filtersSelector
);
export const routeParamAsPropSelector = (param) =>
    pipe(stateSelector, property('params'), pick(param));

export const queryParamAsPropSelector = (queryParam) =>
    pipe(stateSelector, property('queryParams'), pick(queryParam));

export const fileUrlInformationSelector = pipe(
    routeParamsSelector,
    property('routeParams'),
    pick(['branding', 'folderUuid', 'fileUuid', 'fileVersion', 'comparisonVersion']),
    (fileUrlInformation) => ({
        fileUrlInformation: {
            ...fileUrlInformation,
            fileVersion: parseInt(fileUrlInformation.fileVersion),
            comparisonVersion: fileUrlInformation.comparisonVersion
                ? parseInt(fileUrlInformation.comparisonVersion)
                : null
        }
    })
);
