import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Playlist } from '../../Playlist/Playlist';
import { isSmallScreenSelector } from '$redux/config/configSelectors';
import { closePanel } from '$redux/sideMenu/sideMenuActions';
import { togglePlaylist } from '$redux/content/file/fileActions';
import { SidePanelHeader } from '@he-novation/design-system/components/sidePanels/SidePanel/SidePanelHeader/SidePanelHeader';
import { __ } from '@he-novation/design-system/utils/i18n';

const SidePanelFinder = ({ isSmallScreen, closePanel, togglePlaylist }) => {
    const [isFilterOpen, toggleFilter] = useState(false);
    const [isPlaying, togglePlay] = useState(false);

    const buttons = [
        {
            icon: 'filter',
            className: isFilterOpen ? 'is-active' : null,
            onClick: () => toggleFilter(!isFilterOpen)
        },
        {
            icon: isPlaying ? 'pause' : 'play',
            onClick: () => {
                togglePlaylist(!isPlaying);
                togglePlay(!isPlaying);
            }
        }
    ];

    if (isSmallScreen)
        buttons.push({
            icon: 'cross',
            onClick: closePanel
        });

    return (
        <div className={`c-side-panel-finder ${isSmallScreen ? 'isSmallScreen' : ''}`}>
            <SidePanelHeader title={__('REACT_PANEL_FINDER_TITLE')} buttons={buttons} />
            <Playlist isFilterOpen={isFilterOpen} />
        </div>
    );
};
/*
SidePanelFinder.propTypes = {
    isSmallScreen: PropTypes.bool,
    closePanel: PropTypes.func,
    toggleFilter: PropTypes.func,
    togglePlaylist: PropTypes.func,
    isFilterOpen: PropTypes.bool
};*/

export default connect(isSmallScreenSelector, (dispatch) => ({
    closePanel: () => dispatch(closePanel()),
    togglePlaylist: (isPlaying) => dispatch(togglePlaylist(isPlaying))
}))(SidePanelFinder);
