export const getFileType = (mimeType) => {
    let fileType = false;

    switch (true) {
        case /^image\/gif/.test(mimeType):
            fileType = 'gif';
            break;

        case /^audio/.test(mimeType):
            fileType = 'audio';
            break;

        case /^video/.test(mimeType):
            fileType = 'video';
            break;

        case /^application\/mxf/.test(mimeType):
            fileType = 'video';
            break;

        case /^image/.test(mimeType):
            fileType = 'image';
            break;

        case /^application\/pdf/.test(mimeType):
            fileType = 'pdf';
            break;

        case /^application\/vnd\.adobe\.illustrator/.test(mimeType):
            fileType = 'pdf';
            break;

        case /^application\/vnd\.adobe\.photoshop/.test(mimeType):
            fileType = 'image';
            break;
    }

    return fileType;
};

export const getPlayerType = (mimeType) => {
    let playerType = null;

    switch (true) {
        case /^image\/gif/.test(mimeType):
            playerType = 'video';
            break;

        case /^audio/.test(mimeType):
            playerType = 'video';
            break;

        case /^video/.test(mimeType):
            playerType = 'video';
            break;

        case /^application\/mxf/.test(mimeType):
            playerType = 'video';
            break;

        case /^image/.test(mimeType):
            playerType = 'image';
            break;

        case /^application\/pdf/.test(mimeType):
            playerType = 'image';
            break;

        case /^application\/vnd\.adobe\.illustrator/.test(mimeType):
            playerType = 'image';
            break;

        case /^application\/vnd\.adobe\.photoshop/.test(mimeType):
            playerType = 'image';
            break;

        case /^bundle\/html/.test(mimeType):
            playerType = 'iframe';
            break;
    }

    return playerType;
};

export const isPdf = (mimeType) =>
    /^application\/pdf/.test(mimeType) || /^application\/vnd\.adobe\.illustrator/.test(mimeType);

export const isOffice = (App, fileName) => {
    if (!App.preferences.getNested('ui.previewOfficeMs', false)) {
        return false;
    }

    if (!fileName) {
        return false;
    }

    return !!getOfficeType(fileName);
};

export const getOfficeType = (fileName) => {
    if (!fileName) return;

    let officeType = '';

    switch (true) {
        case /\.doc[x]?$/i.test(fileName):
            officeType = 'word';
            break;

        case /\.xls[x]?$/i.test(fileName):
            officeType = 'excel';
            break;

        case /\.ppt[x]?$/i.test(fileName):
            officeType = 'powerpoint';
            break;
    }

    return officeType;
};

export const getOfficeMaxSize = (fileName) =>
    getOfficeType(fileName) !== 'excel' ? 10485760 : 5242880;

export const checkOfficeSize = (fileName, fileSize) => fileSize <= getOfficeMaxSize(fileName);

export const html5VideoTypes = (mimeType) => {
    let returnType = false;

    switch (true) {
        case /^audio/.test(mimeType):
            returnType = true;
            break;
        case /^video\/mp4/.test(mimeType):
            returnType = true;
            break;
        case /^video\/webm/.test(mimeType):
            returnType = false;
            break;
    }

    return returnType;
};

export const usesCollectionId = (mimeType) =>
    mimeType.match(/^application\/pdf/) || mimeType.match(/^application\/vnd\.adobe\.illustrator/);

export const isCorrupted = (mimeType) => {
    if (typeof mimeType !== 'string') {
        return true;
    }

    return mimeType.match(/\/error-/);
};

export const hasKnownIssues = (exifResult) => {
    if (
        !exifResult ||
        !exifResult.MIMEType ||
        typeof exifResult.MIMEType !== 'string' ||
        exifResult.MIMEType.length < 1
    ) {
        return true;
    }

    let res = false;

    switch (exifResult.MIMEType) {
        case 'image/vnd.fpx':
            if (!exifResult.ImageWidth || !exifResult.ImageHeight) {
                res = true;
            }
            break;
    }

    return res;
};
