export const mapUserAndProfileToUser = ({
    email,
    uuid,
    user_uuid,
    firm,
    firstname,
    lastname,
    phone,
    picture,
    role,
    profile,
    vcard,
    ...rest
}) => {
    if (!vcard) vcard = {};
    if (!profile) profile = {};
    return {
        ...rest,
        email,
        firm: vcard.firm || profile.firm || firm,
        firstname: vcard.firstname || profile.firstname || firstname,
        lastname: vcard.lastname || profile.lastname || lastname,
        phone: vcard.phone || profile.phone || phone,
        picture: vcard.picture || profile.picture || picture,
        profile: profile || {},
        uuid,
        user_uuid,
        role: vcard.role || profile.role || role
    };
};

export const mapRequestUserAndProfileToUser = ({
    request_user_email,
    request_user_uuid,
    request_user_profile
}) =>
    mapUserAndProfileToUser({
        email: request_user_email,
        user_uuid: request_user_uuid,
        profile: request_user_profile
    });
