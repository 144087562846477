import './Breadcrumb.scss';
import React from 'react';
import Link from '../router/Link';

type BreadcrumbProps<T extends { uuid: string; name: string; href?: string }> = {
    links: T[];
    clientName?: string;
    buildLink?: (T, i: number) => string;
    color?: string;
};

const Breadcrumb: React.FC<BreadcrumbProps<any>> = ({ links, color }) => (
    <ul className="c-breadcrumb">
        {links.map((l, i) => (
            <li key={(l.uuid || l.label) + i} style={{ color }}>
                {i === links.length - 1 ? (
                    <span className="is-active">{l.label}</span>
                ) : (
                    <Link href={l.href} style={{ color }}>
                        {l.label}
                    </Link>
                )}
            </li>
        ))}
    </ul>
);

export default Breadcrumb;
