import type { ProjectsState } from '@he-novation/config/types/project.types';
import {
    dateAsUTCDate,
    isoStringToUTCDate,
    objectIsoStringsToDates
} from '@he-novation/utils/datetime';
import update, { Spec } from 'immutability-helper';
import { cloneDeep } from 'lodash/fp';
import { publish, PubSubEvent } from '../../../hooks/useSubscribe';
import mapFetchProjectToUpdate from '../../content/projects/maps/mapFetchProjectToUpdate';
import mapFetchTeamsToUpdate from '../../content/projects/maps/mapFetchTeamsToUpdate';
import sqlDatesToJsDates from '../../content/projects/maps/sqlDatesToJsDates';
import { SET } from '../../route/routeActions';
import { FETCH_FILE_VIEW } from '../contentActions';
import {
    FETCH_CURRENT_USER_PROJECTS,
    FETCH_PLANNING_TASKS,
    FETCH_PROJECT,
    FETCH_PROJECT_ACTIVITY,
    FETCH_PROJECT_COMPANIES,
    FETCH_PROJECT_LAST_ACTIVITY,
    FETCH_PROJECT_SIZE,
    FETCH_PROJECT_TASKS,
    FETCH_PROJECT_TEAMS,
    FETCH_PROJECTS,
    FETCH_TASK_ASSETS,
    SET_LAST_PROJECT_UUID,
    SET_PROJECTS,
    SET_SELECTED_TEAM,
    TOGGLE_FAVORITE,
    UPDATE_MEMBER,
    UPDATE_TASK_STATUS_ORDER,
    WS_ARCHIVE_PROJECT,
    WS_CREATE_PROJECT,
    WS_DELETE_MEMBER,
    WS_DELETE_PROJECT,
    WS_RESTORE_PROJECT,
    WS_TASK_CREATE,
    WS_TASK_DELETE,
    WS_TASK_UPDATE,
    WS_TEAM_CREATE,
    WS_TEAM_INVITE,
    WS_TEAM_UPDATE,
    WS_UPDATE_CURRENT_PROJECT,
    WS_UPDATE_MEMBER,
    WS_UPDATE_PROJECT
} from './projectsActions';

import { asyncActionError, asyncActionSuccess } from '$helpers/asyncAction';
import { CASTS_CREATE } from '$redux/content/casts/castsActions';
import { WS_TEAM_DELETE } from '$redux/content/projects/projectsActions';
import { mapUserAndProfileToUser } from '$redux/helpers';

export const projectsInitialState: ProjectsState = {
    companies: [],
    projects: [],
    currentProject: null
};

const projectsReducer = (
    state: ProjectsState = projectsInitialState,
    action: { type: string; [key: string]: any }
) => {
    if (action.type === asyncActionSuccess(FETCH_PROJECT)) {
        return update(state, {
            currentProject: {
                $set: action.project
            }
        });
    }

    switch (action.type) {
        case CASTS_CREATE: {
            if (
                !action.projectUuid ||
                state.currentProject?.uuid !== action.projectUuid ||
                !action.defaultCastStyle
            ) {
                return state;
            }
            return update(state, {
                currentProject: {
                    defaultCastStyle: { $set: action.defaultCastStyle }
                }
            });
        }
        case WS_CREATE_PROJECT: {
            if (state.projects.find(({ uuid }) => uuid === action.project.uuid)) {
                return state;
            }
            const _update: Spec<ProjectsState, any> = {
                projects: { $push: [mapFetchProjectToUpdate(action.project)] }
            };

            if (
                action.project.company &&
                !state.companies.find(({ uuid }) => uuid === action.project.company.uuid)
            ) {
                _update.companies = {
                    $push: [action.project.company]
                };
            }

            return update(state, _update);
        }

        case WS_TASK_CREATE: {
            if (
                !state.currentProject ||
                state.currentProject.tasks?.find((t) => t.uuid === action.task.uuid)
            ) {
                return state;
            }
            const task = sqlDatesToJsDates(action.task);
            publish(PubSubEvent.TaskCreated, task);
            const _state = state.currentProject.tasks
                ? state
                : update(state, { currentProject: { tasks: { $set: [] } } });
            return update(_state, {
                currentProject: {
                    tasks: {
                        $push: [task]
                    }
                }
            });
        }

        case WS_TASK_UPDATE: {
            if (!state.currentProject) return state;
            const _update = {
                currentProject: { tasks: {} }
            };
            if (action.task) {
                const taskIndex = state.currentProject.tasks.findIndex(
                    ({ uuid }) => uuid === action.task.uuid
                );
                _update.currentProject.tasks[taskIndex] = {
                    $set: sqlDatesToJsDates(action.task)
                };
            }

            if (action.orderChangesByUuid) {
                for (const taskUuid in action.orderChangesByUuid) {
                    const taskIndex = state.currentProject!.tasks.findIndex(
                        ({ uuid }) => uuid === taskUuid
                    );

                    if (taskIndex < 0) {
                        continue;
                    }

                    _update.currentProject.tasks[taskIndex] = {
                        ordering: {
                            $set:
                                state.currentProject!.tasks[taskIndex].ordering +
                                action.orderChangesByUuid[taskUuid].orderingDelta
                        },
                        status: {
                            $set:
                                action.orderChangesByUuid[taskUuid].status ||
                                state.currentProject!.tasks[taskIndex].status
                        }
                    };
                }
            }

            return update(state, _update);
        }

        case WS_TASK_DELETE: {
            if (!state.currentProject) return state;
            const i = state.currentProject.tasks.findIndex(({ uuid }) => uuid === action.taskUuid);
            if (i === -1) return state;
            return update(state, {
                currentProject: {
                    tasks: {
                        $splice: [[i, 1]]
                    }
                }
            });
        }

        case WS_UPDATE_PROJECT: {
            return update(state, {
                projects: {
                    [state.projects.findIndex((p) => p.uuid === action.project.uuid)]: {
                        $merge: objectIsoStringsToDates([
                            'created',
                            'updated',
                            'startDate',
                            'endDate'
                        ])(action.project)
                    }
                }
            });
        }

        case WS_UPDATE_CURRENT_PROJECT: {
            return update(state, {
                currentProject: {
                    $merge: mapFetchProjectToUpdate(action.project)
                }
            });
        }

        case WS_ARCHIVE_PROJECT:
        case WS_RESTORE_PROJECT: {
            const projectIndex = state.projects.findIndex(
                ({ uuid }) => uuid === action.project.projectUuid
            );

            if (projectIndex === -1) return state;

            if (action.project.isOwner) {
                return update(state, {
                    projects: {
                        [projectIndex]: {
                            name: {
                                $set: action.project.name
                            },
                            status: {
                                $set: action.project.status
                            }
                        }
                    }
                });
            } else {
                return update(state, {
                    projects: { $splice: [[projectIndex, 1]] }
                });
            }
        }

        case WS_DELETE_PROJECT:
            return update(state, {
                projects: {
                    $splice: [[state.projects.findIndex(({ uuid }) => uuid === action.uuid), 1]]
                }
            });

        case asyncActionSuccess(TOGGLE_FAVORITE): {
            const projectIndex = state.projects.findIndex(
                ({ uuid }) => uuid === action.projectUuid
            );

            return update(state, {
                projects: {
                    [projectIndex]: {
                        isFavorite: {
                            $set: action.isFavorite
                        }
                    }
                }
            });
        }

        case SET_PROJECTS:
            return update(state, {
                projects: { $set: action.payload }
            });

        case SET_LAST_PROJECT_UUID:
            return update(state, {
                lastProjectUuid: { $set: action.uuid }
            });

        case asyncActionSuccess(FETCH_PROJECT):
            return update(state, {
                currentProject: {
                    $set: action.project
                }
            });

        case asyncActionError(FETCH_PROJECT):
            return state;

        case asyncActionSuccess(FETCH_PROJECTS):
            return {
                ...state,
                projects: action.projects
            };

        case asyncActionSuccess(FETCH_PROJECT_ACTIVITY): {
            if (!action.response.length) return state;
            return update(state, {
                currentProject: {
                    activity: {
                        [action.page !== 0 ? '$push' : '$set']: action.response
                    }
                }
            });
        }

        case asyncActionSuccess(FETCH_PROJECT_LAST_ACTIVITY):
            return update(state, {
                currentProject: {
                    $merge: { lastActivity: action.activity }
                }
            });

        case asyncActionSuccess(FETCH_PROJECT_COMPANIES):
            return update(state, {
                companies: { $set: action.companies }
            });

        case asyncActionSuccess(FETCH_PROJECT_TEAMS): {
            return update(state, {
                currentProject: {
                    [state.currentProject ? '$merge' : '$set']: mapFetchTeamsToUpdate(
                        action.teams,
                        state.currentProject && state.currentProject.selectedTeam
                    )
                }
            });
        }

        case asyncActionSuccess(FETCH_PROJECT_TASKS): {
            if (!state.currentProject) return state;
            return update(state, {
                currentProject: {
                    tasks: {
                        $set: action.tasks
                    }
                }
            });
        }

        case asyncActionSuccess(FETCH_TASK_ASSETS): {
            if (!state.currentProject) return state;
            return update(state, {
                currentProject: {
                    tasks: {
                        $set: action.assets.reduce((tasks, asset) => {
                            const _index = tasks.findIndex(({ uuid }) => uuid === asset.task_uuid);
                            if (_index === -1) return tasks;
                            if (asset.type === 'task') {
                                if (!tasks[_index].attachments) tasks[_index].attachments = [];
                                tasks[_index].attachments.push(asset);
                            } else {
                                if (!tasks[_index].note) tasks[_index].note = {};
                                if (asset.type === 'thumbnail') {
                                    tasks[_index].note.fileThumbnail = asset.url;
                                    // note asset: min, sd, detail or max
                                } else if (
                                    asset.type === 'note' &&
                                    (asset.quality === 'sd' || asset.quality === 'min')
                                ) {
                                    tasks[_index].note.thumbnail = asset.url;
                                }
                            }
                            return tasks;
                        }, cloneDeep(state.currentProject.tasks))
                    }
                }
            });
        }

        case asyncActionSuccess(FETCH_FILE_VIEW):
            if (!action.projectState) return state;
            return update(state, {
                currentProject: {
                    [state.currentProject ? '$merge' : '$set']: action.projectState.currentProject
                }
            });

        case asyncActionSuccess(UPDATE_MEMBER): {
            if (!state.currentProject?.teams || state.currentProject.uuid !== action.projectUuid) {
                return state;
            }
            let teamIndex = -1;
            let userIndex = -1;
            for (let i = 0; i < state.currentProject.teams.length; i++) {
                userIndex = state.currentProject.teams[i].members.findIndex(
                    (m) => m.uuid === action.userUuid
                );
                if (userIndex > -1) {
                    teamIndex = i;
                    break;
                }
            }
            if (teamIndex === -1 || userIndex === -1) return state;
            return update(state, {
                currentProject: {
                    teams: {
                        [teamIndex]: {
                            members: {
                                [userIndex]: {
                                    role: {
                                        $set:
                                            action.data.role ||
                                            state.currentProject.teams[teamIndex].members[userIndex]
                                                .role
                                    }
                                }
                            }
                        }
                    }
                }
            });
        }

        case SET:
            if (
                state.currentProject?.uuid ===
                action.route.replace(/.*project\/([a-z0-9-]+)(?:.*)?/, '$1')
            ) {
                return state;
            }
            return update(state, {
                currentProject: { $set: null }
            });

        case SET_SELECTED_TEAM:
            return update(state, {
                currentProject: {
                    selectedTeam: { $set: action.selectedTeam }
                }
            });

        case WS_TEAM_CREATE:
            return update(state, {
                currentProject: {
                    teams: {
                        $push: [{ ...action.team, members: action.members }]
                    }
                }
            });

        case WS_TEAM_DELETE: {
            if (!state.currentProject) return state;
            const teamIndex = state.currentProject.teams?.findIndex(
                ({ uuid }) => uuid === action.teamUuid
            );

            if (teamIndex === undefined || teamIndex === -1) return state;

            return update(state, {
                currentProject: {
                    teams: { $splice: [[teamIndex, 1]] }
                }
            });
        }

        case WS_TEAM_UPDATE: {
            if (!state.currentProject) return state;
            const teamIndex = state.currentProject.teams?.findIndex(
                ({ uuid }) => uuid === action.team.uuid
            );
            if (teamIndex === undefined || teamIndex === -1) return state;

            return update(state, {
                currentProject: {
                    teams: { [teamIndex]: { $merge: action.team } }
                }
            });
        }

        case WS_TEAM_INVITE: {
            if (!state.currentProject) return state;
            const teamIndex = state.currentProject.teams?.findIndex(
                ({ uuid }) => uuid === action.team.uuid
            );
            if (typeof teamIndex === 'undefined' || teamIndex === -1) return state;

            return update(state, {
                currentProject: {
                    teams: {
                        [teamIndex]: {
                            members: {
                                $push: action.members.map(mapUserAndProfileToUser)
                            }
                        }
                    }
                }
            });
        }

        case WS_DELETE_MEMBER: {
            if (!state.currentProject) return state;
            let memberIndex;
            const teamIndex = state.currentProject.teams?.findIndex(({ members }) =>
                members.find(({ uuid }, i) => {
                    if (uuid !== action.uuid) return;
                    memberIndex = i;
                    return true;
                })
            );

            if (typeof teamIndex === 'undefined' || teamIndex === -1) return state;

            return update(state, {
                currentProject: {
                    teams: {
                        [teamIndex]: {
                            members: {
                                $splice: [[memberIndex, 1]]
                            }
                        }
                    }
                }
            });
        }

        case WS_UPDATE_MEMBER: {
            if (!state.currentProject) return state;
            let memberIndex;
            const teamIndex = state.currentProject.teams?.findIndex(({ members }) =>
                members.find(({ uuid }, i) => {
                    if (uuid !== action.uuid) return;
                    memberIndex = i;
                    return true;
                })
            );
            if (teamIndex === undefined || teamIndex === -1) return state;

            const _update: Spec<ProjectsState, any> = {};
            if (action.projectRole) _update.projectRole = action.projectRole;
            if (action.download) _update.download = action.download;
            if (action.export) _update.export = action.export;

            if (
                action.teamUuid &&
                state.currentProject.teams![teamIndex].uuid !== action.teamUuid
            ) {
                const newTeamIndex = state.currentProject.teams!.findIndex(
                    ({ uuid }) => uuid === action.teamUuid
                );
                return update(state, {
                    currentProject: {
                        teams: {
                            [teamIndex]: {
                                members: {
                                    $splice: [[memberIndex, 1]]
                                }
                            },
                            [newTeamIndex]: {
                                members: {
                                    $push: [
                                        {
                                            ...state.currentProject.teams![teamIndex].members[
                                                memberIndex
                                            ],
                                            ..._update
                                        }
                                    ]
                                }
                            }
                        }
                    }
                });
            } else {
                return update(state, {
                    currentProject: {
                        teams: {
                            [teamIndex]: {
                                members: {
                                    [memberIndex]: {
                                        $merge: _update
                                    }
                                }
                            }
                        }
                    }
                });
            }
        }

        case asyncActionSuccess(FETCH_CURRENT_USER_PROJECTS):
            return {
                ...state,
                projects: action.response.result
            };

        case asyncActionError(FETCH_CURRENT_USER_PROJECTS):
            return state;

        case asyncActionSuccess(FETCH_PLANNING_TASKS): {
            return update(state, {
                planningTasks: {
                    $set: action.planningTasks
                }
            });
        }

        case asyncActionSuccess(FETCH_PROJECT_SIZE): {
            const i = state.projects.findIndex((p) => p.uuid === action.uuid);
            if (i === -1) return state;
            return update(state, {
                projects: {
                    [i]: {
                        $merge: { size: action.size, files: action.files }
                    }
                }
            });
        }

        case asyncActionSuccess(UPDATE_TASK_STATUS_ORDER): {
            if (!state.currentProject) return state;
            const _update = {};

            for (const taskUuid in action.changes) {
                const i = state.currentProject.tasks.findIndex((t) => t.uuid === taskUuid);
                if (i > -1) {
                    _update[i] = {
                        $merge: {
                            ordering:
                                state.currentProject.tasks[i].ordering +
                                action.changes[taskUuid].orderingDelta,
                            status:
                                action.changes[taskUuid].status ||
                                state.currentProject.tasks[i].status
                        }
                    };
                }
            }

            return update(state, {
                currentProject: {
                    tasks: _update
                }
            });
        }

        default:
            return state;
    }
};

export default projectsReducer;
