import './ReactSelectLabel.scss'
import React, { ReactNode } from 'react';

type ReactSelectLabelProps = {children: ReactNode | ReactNode[]};

export function ReactSelectLabel({ children }: ReactSelectLabelProps) {
    return <div className="react-select-label">
        {children}
    </div>;
}
