import React from 'react';
import { Note } from '@he-novation/config/types/note.types';
import { NoteCreateBody } from '@he-novation/config/types/payloads/note.payload';
import { __ } from '@he-novation/design-system/utils/i18n';
import {
    createComment as _createComment,
    createNote as _createNote,
    deleteComment as _deleteComment,
    deleteNote as _deleteNote,
    editComment as _editComment,
    fetchCastNotes as _fetchCastNotes,
    fetchNotes as _fetchNotes,
    uploadAttachments
} from '@he-novation/front-shared/async/note.async';
import { signupLink } from '@he-novation/paths/herawFrontUris';
import { bytesToSize } from '@he-novation/utils/bytes';
import { asyncActionSuccess } from '../../../helpers/asyncAction';

import { WS_PREFIX_IN } from '$constants/webSocket.constants';
import { openFeedbackModal } from '$redux/route/routeActions';

export const NOTE = 'NOTE';
export const FETCH_NOTE = `${NOTE}/FETCH_NOTE`;
export const FETCH_CAST_NOTE = `${NOTE}/FETCH_CAST_NOTE`;
export const SET_SELECTED_NOTE = `${NOTE}/SET_SELECTED_NOTE`;
export const CREATE_COMMENT = `${NOTE}/CREATE_COMMENT`;
export const CREATE_NOTE = `${NOTE}/CREATE_NOTE`;
export const EDIT_COMMENT = `${NOTE}/EDIT_COMMENT`;
export const DELETE_COMMENT = `${NOTE}/DELETE_COMMENT`;
export const DELETE_NOTE = `${NOTE}/DELETE_NOTE`;
export const UPDATE_ATTACHMENT = `${NOTE}/UPDATE_ATTACHMENT`;
export const SET_NOTE_ASSET = `${NOTE}/SET_NOTE_ASSET`;
export const SET_COMMENT_ASSET = `${NOTE}/SET_COMMENT_ASSET`;
export const ADD_NOTE_COMMENT_WS = `${NOTE}/ADD_NOTE_COMMENT_WS`;
export const UPDATE_NOTE = `${NOTE}/UPDATE_NOTE`;
export const UPDATE_COMMENT = `${NOTE}/UPDATE_COMMENT`;
export const WS_CREATE_NOTE = `${WS_PREFIX_IN}/${CREATE_NOTE}`;
export const WS_CREATE_COMMENT = `${WS_PREFIX_IN}/${CREATE_COMMENT}`;
export const WS_SET_NOTE_ATTACHMENT = `${WS_PREFIX_IN}/SET_NOTE_ATTACHMENT`;
export const WS_UPDATE_NOTE = `${WS_PREFIX_IN}/${UPDATE_NOTE}`;
export const WS_DELETE_NOTE = `${WS_PREFIX_IN}/${DELETE_NOTE}`;
export const WS_UPDATE_COMMENT = `${WS_PREFIX_IN}/${UPDATE_COMMENT}`;
export const WS_DELETE_COMMENT = `${WS_PREFIX_IN}/${DELETE_COMMENT}`;
export const SET_DISPLAY_ALL_VERSIONS = `${NOTE}/SET_DISPLAY_ALL_VERSIONS`;

export const fetchNotes = (uuid, version, success) => async (dispatch) => {
    dispatch({ type: FETCH_NOTE });
    const response = await _fetchNotes({ uuid, version });
    dispatch({
        type: asyncActionSuccess(FETCH_NOTE),
        response
    });
    if (typeof success === 'function') success(response);
};

export const fetchCastNotes =
    (
        castUid: string,
        castFileUuid: string,
        fileVersion: number,
        success?: (notes: Record<string, Note>) => void
    ) =>
    async (dispatch) => {
        dispatch({ type: FETCH_CAST_NOTE });
        const response = await _fetchCastNotes({ castUid, castFileUuid, fileVersion });
        dispatch({
            type: asyncActionSuccess(FETCH_CAST_NOTE),
            response
        });
        if (typeof success === 'function') success(response);
    };

const _attachmentUploadErrorHandlerFactory = (dispatch) => (e) => {
    let message;
    if (e.code === 413) {
        message = (
            <>
                {__('ERR_FILE_SIZE', { size: bytesToSize(e.data.limit) })}
                {e.data.status === 'ANONYMOUS' && (
                    <>
                        <br />
                        <span
                            dangerouslySetInnerHTML={{
                                __html: __('ACCOUNT_CREATE_TO_INCREASE_LIMIT', {
                                    url: signupLink()
                                })
                            }}
                        />
                    </>
                )}
            </>
        );
    }

    if (e.code === 429) message = __('ERR_TOO_MANY_UPLOADS');
    if (message)
        dispatch(
            openFeedbackModal(message, undefined, { title: __('ERR_MODAL_TITLE'), isError: true })
        );
};

export const createNote =
    (
        {
            assignees,
            attachments,
            content,
            draft,
            estimatedEndDate,
            fileUuid,
            fileVersion,
            folderUuid,
            isTask,
            notify,
            teamUuid,
            tags,
            type,
            metadata,
            imageData,
            postedAs,
            recaptcha
        }: NoteCreateBody & { attachments: File[]; folderUuid: string },
        isCastFile?: boolean,
        castFileUuid?: string,
        password?: string
    ) =>
    async (dispatch) => {
        dispatch({
            type: CREATE_NOTE,
            file: {
                uuid: fileUuid,
                version: fileVersion
            },
            note: {
                folderUuid,
                comments: [{ content, created: new Date() }],
                team: teamUuid ? { uuid: teamUuid } : null,
                created: new Date(),
                metadata,
                type,
                imageData
            }
        });
        const r = await _createNote(
            {
                assignees,
                attachmentsNumber: attachments.length,
                content,
                draft,
                estimatedEndDate,
                fileUuid,
                fileVersion,
                castFileUuid,
                isTask,
                notify,
                teamUuid,
                tags,
                type,
                metadata,
                imageData,
                postedAs,
                recaptcha
            },
            isCastFile,
            password
        );
        if (attachments.length) {
            const lastComment = r.comments[r.comments.length - 1];
            uploadAttachments({
                commentUuid: lastComment.uuid,
                noteUuid: r.uuid,
                attachments,
                castFileUuid
            }).catch(_attachmentUploadErrorHandlerFactory(dispatch));
        }
        dispatch({
            type: asyncActionSuccess(CREATE_NOTE),
            file: {
                uuid: fileUuid,
                version: fileVersion
            },
            note: { ...r }
        });
    };

export const createComment =
    (
        {
            attachments,
            content,
            isDraft,
            fileUuid,
            fileVersion,
            folderUuid,
            castFileUuid,
            shouldNotify,
            keywords,
            postedAs
        },
        noteUuid: string,
        password?: string
    ) =>
    async (dispatch) => {
        dispatch({ type: CREATE_COMMENT });
        const comment = await _createComment(
            noteUuid,
            {
                attachmentsNumber: attachments.length,
                content,
                draft: isDraft,
                fileUuid,
                fileVersion,
                folderUuid,
                castFileUuid,
                notify: !!shouldNotify,
                tags: keywords,
                postedAs
            },
            password
        );
        if (attachments.length) {
            uploadAttachments({
                commentUuid: comment.uuid,
                noteUuid,
                attachments,
                castFileUuid
            }).catch(_attachmentUploadErrorHandlerFactory(dispatch));
        }

        dispatch({ type: asyncActionSuccess(CREATE_COMMENT), comment });
    };

export const deleteNote = (uuid) => async (dispatch) => {
    dispatch({ type: DELETE_NOTE });
    await _deleteNote(uuid);
};

export const editComment =
    (
        noteUuid: string,
        commentUuid: string,
        {
            attachments = [],
            content,
            isDraft,
            tags
        }: {
            attachments?: File[];
            content: string;
            isDraft?: boolean;
            tags?: string[];
        },
        password?: string,
        cb?: () => void
    ) =>
    async (dispatch) => {
        dispatch({ type: EDIT_COMMENT });
        const r = await _editComment(noteUuid, commentUuid, {
            attachmentsNumber: attachments.length,
            content,
            draft: isDraft,
            tags
        });
        if (attachments.length > 0) {
            await uploadAttachments({
                commentUuid: r.comment.uuid,
                noteUuid: noteUuid,
                attachments: attachments
            }).catch(_attachmentUploadErrorHandlerFactory(dispatch));
        }
        dispatch({ type: asyncActionSuccess(EDIT_COMMENT), ...r });
        if (cb && typeof cb === 'function') cb();
    };

export const deleteComment = (data) => async (dispatch) => {
    dispatch({ type: DELETE_COMMENT });
    await _deleteComment(data);
};

export const setSelectedNote = (uuidNote) => ({
    type: SET_SELECTED_NOTE,
    uuidNote
});

export const setDisplayAllVersions = (displayAllVersions) => ({
    type: SET_DISPLAY_ALL_VERSIONS,
    displayAllVersions
});

export const noteSocketActions = {
    sioNoteCreate: (data) => ({
        type: WS_CREATE_NOTE,
        ...data
    }),
    sioNoteDelete: (data) => ({
        type: WS_DELETE_NOTE,
        ...data
    }),
    sioNoteUpdate: (data) => ({
        type: WS_UPDATE_NOTE,
        ...data
    }),
    sioCommentCreate: (data) => ({
        type: WS_CREATE_COMMENT,
        ...data
    }),
    sioCommentUpdate: (data) => ({
        type: WS_UPDATE_COMMENT,
        ...data
    }),
    sioCommentDelete: (data) => ({
        type: WS_DELETE_COMMENT,
        ...data
    }),
    sioNoteAttachment: (data) => ({
        type: WS_SET_NOTE_ATTACHMENT,
        ...data
    })
};
