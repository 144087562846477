import z from 'zod';

export type SubtitleGeneratePayload = {
    fileUuid: string;
    fileVersion: number;
    sourceLocale: string;
    targetLocales: string[];
    spotlVerified?: boolean;
};

export enum SubtitleType {
    UPLOADED = 'UPLOADED',
    MANUAL = 'MANUAL',
    SPOTL = 'SPOTL',
    SPOTL_VERIFIED = 'SPOTL_VERIFIED'
}

enum SpotlStatus {
    Processing = 'processing',
    Done = 'done'
}

type SpotlTranslation = {
    spotlProjectId: string;
    status: SpotlStatus;
    verified: boolean;
    sourceLocale: string;
};
export const subtitleEntrySchema = z.object({
    uuid: z.string().uuid(),
    timecodeIn: z.string(),
    timecodeOut: z.string(),
    start: z.number(),
    end: z.number(),
    content: z.string(),
    teletext: z.unknown().nullable(),
    metadata: z.record(z.unknown()).nullable().optional()
});

export type SubtitleEntry = z.infer<typeof subtitleEntrySchema>;
export const subtitleSchema = z.object({
    uuid: z.string().uuid(),
    assetUrl: z.string(),
    assetUrlExpires: z.date(),
    assetUuid: z.string().uuid(),
    created: z.date(),
    entries: z.array(subtitleEntrySchema),
    fileName: z.string(),
    fileUuid: z.string().uuid(),
    fileVersion: z.number(),
    locale: z.string(),
    name: z.string(),
    spotlTranslation: z
        .object({
            spotlProjectId: z.string(),
            status: z.enum([SpotlStatus.Processing, SpotlStatus.Done]),
            verified: z.boolean(),
            sourceLocale: z.string()
        })
        .nullable(),
    type: z.nativeEnum(SubtitleType),
    updated: z.date().nullable()
});

export type Subtitle = z.infer<typeof subtitleSchema>;

export type MappedSubtitleEntry = SubtitleEntry & {
    timeIn: number;
    timeOut: number;
    delta?: any;
    isNew?: boolean;
};

export type MappedSubtitle = Omit<Subtitle, 'entries'> & { entries: MappedSubtitleEntry[] };

export type SpotlLanguage = {
    language_code: string;
    professional_pre_edition: boolean;
    translates_to: {
        language_code: string;
        professional_post_edition: boolean;
    }[];
};
