import './ProjectProgress.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import { TaskCounts } from '@he-novation/config/types/project.types';
import { Tooltip } from '@he-novation/design-system/components/widgets/Tooltip/Tooltip';
import { __ } from '@he-novation/design-system/utils/i18n';
import Icon from '@he-novation/icons';
import cn from 'classnames';

import { DONE, IN_PROGRESS, TO_DO, TO_VALIDATE } from '$constants/columnManager';
import { getLocalizedDate } from '$helpers/datetime';
import { localeSelector } from '$redux/config/configSelectors';

const getStyles = (column, count) => {
    return {
        width: `${(count[column] / count.TOTAL) * 100}%`,
        padding: count[column] === 0 ? 0 : '2px'
    };
};

type ProjectProgressProps = {
    className?: string;
    startDate?: Date | null;
    endDate?: Date | null;
    taskCounts: TaskCounts;
    small?: boolean;
    showTotal?: boolean;
    onlyProgressBar?: boolean;
    children?: React.ReactNode | React.ReactNode[];
};

const ProjectProgress = ({
    className,
    endDate,
    startDate,
    taskCounts,
    small,
    showTotal,
    onlyProgressBar,
    children
}: ProjectProgressProps) => {
    const { locale } = useSelector(localeSelector);
    const count = {
        [TO_DO]: taskCounts.toDo,
        [IN_PROGRESS]: taskCounts.inProgress,
        [TO_VALIDATE]: taskCounts.toValidate,
        [DONE]: taskCounts.done,
        TOTAL: taskCounts.toDo + taskCounts.inProgress + taskCounts.toValidate + taskCounts.done
    };
    if (!count.TOTAL && !endDate && !startDate)
        return <div className={'c-project-progress no-progress'} />;
    return (
        <div className={cn('c-project-progress', { 'is-small': small }, className)}>
            {!onlyProgressBar && startDate && (
                <div className="start">
                    {small ? <Icon icon="date-in" /> : __('START') + ':'}{' '}
                    {getLocalizedDate(startDate, locale, {
                        day: 'numeric',
                        month: small ? 'short' : 'long'
                    })}
                </div>
            )}

            {!onlyProgressBar && !small ? (
                <div className="remaining">
                    {endDate &&
                        __('DATES_DAYS_REMAINING', {
                            days: Math.max(
                                0,
                                Math.round(
                                    (endDate.getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24
                                )
                            )
                        })}
                </div>
            ) : null}

            {!onlyProgressBar && endDate && (
                <div className="end">
                    {small ? <Icon icon="date-out" /> : __('END') + ':'}{' '}
                    {getLocalizedDate(endDate, locale, {
                        day: 'numeric',
                        month: small ? 'short' : 'long'
                    })}
                </div>
            )}

            {count.TOTAL ? (
                <ul>
                    <Tooltip content={__('TO_DO')} tagName="li" style={getStyles(TO_DO, count)} />
                    <Tooltip
                        content={__('IN_PROGRESS')}
                        tagName="li"
                        style={getStyles(IN_PROGRESS, count)}
                    />
                    <Tooltip
                        content={__('TO_VALIDATE')}
                        tagName="li"
                        style={getStyles(TO_VALIDATE, count)}
                    />
                    <Tooltip
                        content={__('COLUMN_DONE')}
                        tagName="li"
                        style={getStyles(DONE, count)}
                    />
                </ul>
            ) : (
                <div className="no-tasks" />
            )}

            {showTotal && (
                <span className="tasks-total-count">
                    {__(count.TOTAL <= 1 ? 'COUNT_TASK_SINGULAR' : 'COUNT_TASK_PLURAL', {
                        count: count.TOTAL
                    })}
                </span>
            )}

            {children}
        </div>
    );
};

export default React.memo(ProjectProgress);
