import './Empty.scss';
import React, { forwardRef } from 'react';
import { __ } from '@he-novation/design-system/utils/i18n';

type EmptyProps = {
    text?: string;
};
function Empty({ text }: EmptyProps, ref?: any) {
    return (
        <div ref={ref} className="c-empty">
            {text || __('NO_CONTENT')}
        </div>
    );
}

export default forwardRef(Empty);
