import {
    ClientLabel,
    ClientLabelPayload,
    ClientLabelType,
    ClientLabelUpdatePayload
} from '@he-novation/config/types/clientLabel.types';
import {
    ClientMemberDeleteBody,
    ClientProfileSchema
} from '@he-novation/config/types/payloads/client.payload';
import { LegacyProfile } from '@he-novation/config/types/user.types';
import {
    asyncClientUserRemove,
    asyncFetchClientStorage,
    clientGuestsFetch as _clientGuestsFetch,
    clientProfileUpdate as _clientProfileUpdate,
    clientUserAdd as _clientUserAdd,
    clientUserDelete as _clientUserDelete,
    clientUserEdit as _clientUserEdit,
    clientUsersFetch as _fetchClientUsers,
    clientUserTransfer as _clientUserTransfer,
    fetchClient as _fetchClient
} from '@he-novation/front-shared/async/client.async';
import { updatePassword as _updatePassword } from '@he-novation/front-shared/async/user.async';

import { asyncActionSuccess } from '$helpers/asyncAction';
import {
    asyncClientLabelCreate,
    asyncClientLabelDelete,
    asyncClientLabelsFetch,
    asyncClientLabelUpdate
} from '$redux/client/clientLabelsAsync';

export const CLIENT = 'CLIENT';
export const CLIENT_FETCH = `${CLIENT}/FETCH`;
export const CLIENT_GUESTS_FETCH = `${CLIENT}/FETCH_GUESTS`;
export const CLIENT_LICENSE_CANCEL = `${CLIENT}/LICENSE_CANCEL`;
export const CLIENT_STORAGE_SET = `${CLIENT}/STORAGE_SET`;
export const CLIENT_UPDATE_PASSWORD = `${CLIENT}/UPDATE_PASSWORD`;
export const CLIENT_UPDATE_PROFILE = `${CLIENT}/UPDATE_PROFILE`;
export const CLIENT_USER_ADD = `${CLIENT}/USER_ADD`;
export const CLIENT_USER_DELETE = `${CLIENT}/USER_DELETE`;
export const CLIENT_USER_EDIT = `${CLIENT}/USER_EDIT`;
export const CLIENT_USER_TRANSFER = `${CLIENT}/USER_TRANSFER`;
export const CLIENT_USERS_FETCH = `${CLIENT}/FETCH_USERS`;
export const CLIENT_LABELS_FETCH = `${CLIENT}/LABELS_FETCH`;
export const CLIENT_LABEL_ADD = `${CLIENT}/LABEL_ADD`;
export const CLIENT_LABEL_DELETE = `${CLIENT}/LABEL_DELETE`;
export const CLIENT_LABEL_UPDATE = `${CLIENT}/LABEL_UPDATE`;
export const CLIENT_LABELS_SET = `${CLIENT}/LABELS_SET`;

export const clientUpdatePassword = (body, cb) => async (dispatch) => {
    dispatch({ type: CLIENT_UPDATE_PASSWORD });
    try {
        await _updatePassword(body);
        dispatch({ type: asyncActionSuccess(CLIENT_UPDATE_PASSWORD) });
        cb?.();
    } catch (e) {
        cb?.(e);
    }
};

export const updateClientProfile =
    (profile: ClientProfileSchema, cb?: (profile: ClientProfileSchema) => void) =>
    async (dispatch) => {
        dispatch({ type: CLIENT_UPDATE_PROFILE });
        await _clientProfileUpdate(profile);
        dispatch({ type: asyncActionSuccess(CLIENT_UPDATE_PROFILE), profile });
        cb?.(profile);
    };

export const fetchClient = (cb, onError) => async (dispatch) => {
    dispatch({ type: CLIENT_FETCH });
    try {
        const client = await _fetchClient();
        dispatch({ type: asyncActionSuccess(CLIENT_FETCH), client });
        asyncFetchClientStorage().then((storage) =>
            dispatch({ type: CLIENT_STORAGE_SET, storage })
        );
        if (typeof cb === 'function') cb(client);
    } catch (e) {
        onError?.(e);
    }
};

export const clientUsersFetch = () => async (dispatch) => {
    dispatch({ type: CLIENT_USERS_FETCH });
    const users = await _fetchClientUsers();
    dispatch({ type: asyncActionSuccess(CLIENT_USERS_FETCH), users });
};

export const clientUserAdd = (data, cb) => async (dispatch) => {
    dispatch({ type: CLIENT_USER_ADD });
    let err;
    try {
        const user = await _clientUserAdd(data);
        dispatch({ type: asyncActionSuccess(CLIENT_USER_ADD), user });
    } catch (e) {
        err = e;
    }
    if (typeof cb === 'function') cb(err);
};

export const clientUserDelete =
    (userUuid: string, data: ClientMemberDeleteBody, cb: Function) =>
    async (dispatch: Function) => {
        dispatch({ type: CLIENT_USER_DELETE });
        try {
            await _clientUserDelete(userUuid, data);
            dispatch({
                type: asyncActionSuccess(CLIENT_USER_DELETE),
                uuid: userUuid,
                data
            });
            cb?.();
        } catch (e) {
            cb?.(e);
        }
    };

export const clientUserEdit = (uuid, data, cb) => async (dispatch) => {
    dispatch({ type: CLIENT_USER_EDIT });
    let err;
    try {
        const r = await _clientUserEdit(uuid, data);
        dispatch({ type: asyncActionSuccess(CLIENT_USER_EDIT), uuid, data, ...r });
    } catch (e) {
        err = e;
    }
    if (typeof cb === 'function') cb(err);
};

export const clientUserTransfer = (userUuid, userDstUuid, cb) => async (dispatch) => {
    dispatch({ type: CLIENT_USER_TRANSFER });
    let err;
    try {
        await _clientUserTransfer(userUuid, userDstUuid);
        dispatch({
            type: asyncActionSuccess(CLIENT_USER_TRANSFER),
            userUuid,
            userDstUuid
        });
    } catch (e) {
        err = e;
    }
    if (typeof cb === 'function') cb(err);
};

export const clientGuestsFetch = () => async (dispatch) => {
    dispatch({ type: CLIENT_GUESTS_FETCH });
    const guests = await _clientGuestsFetch();
    dispatch({ type: asyncActionSuccess(CLIENT_GUESTS_FETCH), guests });
};

export const clientUserRemove = (userUuid: string, cb?: () => void) => async (dispatch) => {
    dispatch({ type: CLIENT_LICENSE_CANCEL });
    await asyncClientUserRemove(userUuid);
    dispatch({ type: asyncActionSuccess(CLIENT_LICENSE_CANCEL), userUuid });
    if (typeof cb === 'function') cb();
};

export const clientLabelsFetch = (type: ClientLabelType, cb?: Function) => async (dispatch) => {
    dispatch({ type: CLIENT_LABELS_FETCH });
    const labels = await asyncClientLabelsFetch(type);
    dispatch({
        type: asyncActionSuccess(CLIENT_LABELS_FETCH),
        labelType: type,
        labels
    });
    cb?.();
};

export const clientLabelAdd =
    <T extends ClientLabelType>(type: T, payload: ClientLabelPayload<T>, cb: Function) =>
    async (dispatch) => {
        dispatch({ type: CLIENT_LABEL_ADD });
        const label = await asyncClientLabelCreate(type, payload);
        dispatch({
            type: asyncActionSuccess(CLIENT_LABEL_ADD),
            labelType: type,
            label
        });
        cb?.();
    };

export const clientLabelUpdate =
    <T extends ClientLabelType>(
        type: T,
        uid: string,
        payload: ClientLabelUpdatePayload<T>,
        cb: Function
    ) =>
    async (dispatch) => {
        dispatch({ type: CLIENT_LABEL_UPDATE });
        await asyncClientLabelUpdate(type, uid, payload);
        dispatch({
            type: asyncActionSuccess(CLIENT_LABEL_UPDATE),
            labelType: type,
            uid,
            payload
        });
        cb?.();
    };

export const clientLabelDelete =
    (type: ClientLabelType, uid: string, cb: Function) => async (dispatch) => {
        dispatch({ type: CLIENT_LABEL_DELETE });
        await asyncClientLabelDelete(type, uid);
        dispatch({
            type: asyncActionSuccess(CLIENT_LABEL_DELETE),
            labelType: type,
            uid
        });
        cb?.();
    };

export const clientLabelsSet = <T extends ClientLabelType>(type: T, labels: ClientLabel<T>[]) => ({
    type: CLIENT_LABELS_SET,
    labelType: type,
    labels
});
