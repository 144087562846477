import React from 'react';
import { scaleToFit } from '@he-novation/utils/scale';

class SvgFreeHand extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 85,
            height: 48,
            original: {
                width: props.original.width || parseInt(props.original.ImageWidth),
                height: props.original.height || parseInt(props.original.ImageHeight)
            }
        };
    }

    render() {
        return (
            <svg
                width={this.state.width}
                height={this.state.height}
                viewBox={`0 0 ${this.state.width} ${this.state.height}`}
            >
                <path
                    d={this.props.zoomed ? this.getZoomedPath() : this.getUnzoomedPath()}
                    fill="transparent"
                    stroke="red"
                    strokeWidth={this.props.zoomed ? 2 : 1}
                />
            </svg>
        );
    }

    getUnzoomedPath() {
        const thumbnail = scaleToFit(
            {
                width: this.state.original.width,
                height: this.state.original.height
            },
            {
                width: this.state.width,
                height: this.state.height
            }
        );

        const points = this.props.freehandItem.map(({ x, y }) => ({
            x: x * thumbnail.width + thumbnail.x,
            y: y * thumbnail.height + thumbnail.y
        }));
        return this.pointsToPath(points);
    }

    getZoomedPath() {
        const rect = this.props.rectangle || { x: 0, y: 0, width: 1, height: 1 };
        const scaledSourceRectangle = {
            x: this.state.original.width * rect.x,
            y: this.state.original.height * rect.y,
            width: this.state.original.width * rect.width,
            height: this.state.original.height * rect.height
        };

        const thumbnail = scaleToFit(scaledSourceRectangle, {
            width: this.state.width,
            height: this.state.height
        });

        const thumbnailToOriginalRatioX = this.state.original.width / scaledSourceRectangle.width;
        const thumbnailToOriginalRatioY = this.state.original.height / scaledSourceRectangle.height;
        const points = this.props.freehandItem.map(({ x, y }) => ({
            x: (x - rect.x) * thumbnail.width * thumbnailToOriginalRatioX + thumbnail.x,
            y: (y - rect.y) * thumbnail.height * thumbnailToOriginalRatioY + thumbnail.y
        }));

        return this.pointsToPath(points);
    }

    pointsToPath(points) {
        let path = 'M ';
        points.map((point, i) => {
            if (i === 0) path += `${point.x} ${point.y}`;
            else {
                const point0 = points[i - 1];
                path += ` Q ${point0.x} ${point0.y} ${(point0.x + point.x) / 2} ${
                    (point0.y + point.y) / 2
                }`;
            }
        });
        return path;
    }
}

export default SvgFreeHand;
