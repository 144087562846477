import { CastCreateBody, CastUpdateBody } from '@he-novation/config/types/payloads/cast.payload';
import { __ } from '@he-novation/design-system/utils/i18n';
import {
    asyncCastCreate,
    asyncCastDelete,
    asyncCastEdit,
    asyncCastFileCreate,
    asyncCastFileDelete,
    asyncCastFolderCreate,
    asyncCastFolderDelete,
    asyncCastFolderSelect,
    asyncCastFolderUpdate,
    asyncCastsFetch
} from '@he-novation/front-shared/async/cast.async';

import { asyncActionSuccess } from '$helpers/asyncAction';
import { openFeedbackModal } from '$redux/route/routeActions';

export const CASTS = 'CASTS';
export const CASTS_FETCH = `${CASTS}/FETCH`;
export const CASTS_CREATE = `${CASTS}/CREATE`;
export const CASTS_EDIT = `${CASTS}/EDIT`;
export const CASTS_UPDATE = `${CASTS}/UPDATE`;
export const CASTS_DELETE = `${CASTS}/DELETE`;
export const CASTS_FOLDER_CREATE = `${CASTS}/FOLDER_CREATE`;
export const CASTS_FOLDER_UPDATE = `${CASTS}/FOLDER_UPDATE`;
export const CASTS_FOLDER_DELETE = `${CASTS}/FOLDER_DELETE`;
export const CASTS_FILE_CREATE = `${CASTS}/FILE_CREATE`;
export const CASTS_FILE_DELETE = `${CASTS}/FILE_DELETE`;
export const CASTS_FOLDER_FETCH = `${CASTS}/FOLDER_FETCH`;
export const CASTS_FOLDER_RESET = `${CASTS}/FOLDER_RESET`;

export const castsFetch = (projectUuid?: string) => async (dispatch) => {
    dispatch({ type: CASTS_FETCH });
    dispatch({
        type: asyncActionSuccess(CASTS_FETCH),
        casts: await asyncCastsFetch(),
        projectUuid,
        projectCasts: projectUuid ? await asyncCastsFetch(projectUuid) : undefined
    });
};

export const resetCastFolders = () => ({
    type: CASTS_FOLDER_RESET
});

export const castCreate = (payload: CastCreateBody, cb) => async (dispatch) => {
    dispatch({
        type: CASTS_CREATE,
        saveDefaultCastDesign: payload.saveDefaultCastDesign,
        projectUuid: payload.projectUuid,
        defaultCastStyle: {
            logoUserFileUid: payload.logoUserFileUid,
            userFileUid: payload.userFileUid,
            backgroundColor: payload.backgroundColor,
            textColor: payload.textColor
        }
    });
    const cast = await asyncCastCreate(payload);
    dispatch({
        type: asyncActionSuccess(CASTS_CREATE),
        cast
    });
    if (typeof cb === 'function') await cb(cast);
};

export const castEdit =
    (uid, payload: CastUpdateBody, projectUuid?: string, cb?: Function) => async (dispatch) => {
        dispatch({ type: CASTS_EDIT });
        const cast = await asyncCastEdit(uid, payload);
        dispatch({
            type: asyncActionSuccess(CASTS_EDIT),
            projectUuid,
            cast: {
                ...cast,
                password: payload.password || cast.password,
                expires: payload.expires ? new Date(payload.expires) : undefined,
                maxViews: payload.maxViews
            }
        });
        if (typeof cb === 'function') cb();
    };

export const castDelete =
    (castUid: string, projectUuid?: string, cb?: Function) => async (dispatch) => {
        dispatch({ type: CASTS_DELETE });
        await asyncCastDelete(castUid);
        dispatch({ type: asyncActionSuccess(CASTS_DELETE), projectUuid, castUid });
        if (typeof cb === 'function') cb();
    };

export const castFileCreate = (castUid, castFolderUuid, fileUuid) => async (dispatch) => {
    dispatch({ type: CASTS_FILE_CREATE });
    try {
        await asyncCastFileCreate(castUid, castFolderUuid, fileUuid);
    } catch (e) {
        return dispatch(openFeedbackModal(__('CAST_FILE_EXISTS')));
    }

    dispatch({ type: asyncActionSuccess(CASTS_FILE_CREATE), fileUuid });
};

export const castsFolderFetch = (castUid, castFolderUuid) => async (dispatch) => {
    dispatch({ type: CASTS_FOLDER_FETCH });
    const folder = await asyncCastFolderSelect(castUid, castFolderUuid);
    dispatch({ type: asyncActionSuccess(CASTS_FOLDER_FETCH), castUid, folder });
};

export const castFolderCreate = (castUid, castFolderUuid, name, cb) => async (dispatch) => {
    dispatch({ type: CASTS_FOLDER_CREATE });
    const { uuid } = await asyncCastFolderCreate(castUid, castFolderUuid, name);
    dispatch({
        type: asyncActionSuccess(CASTS_FOLDER_CREATE),
        folder: {
            uuid,
            name,
            type: 'folder'
        },
        castParentFolderUuid: castFolderUuid,
        castUid
    });
    if (typeof cb === 'function') cb(uuid);
};

export const castFolderUpdate =
    (castUid, castFolderUuid, name, castParentFolderUuid?: string, cb?: Function) =>
    async (dispatch) => {
        dispatch({ type: CASTS_FOLDER_UPDATE });
        const r = await asyncCastFolderUpdate(castUid, castFolderUuid, name);
        dispatch({
            type: asyncActionSuccess(CASTS_FOLDER_UPDATE),
            name,
            uuid: castFolderUuid,
            castUid,
            castParentFolderUuid
        });
        cb?.(r);
    };

export const castFolderDelete =
    (castUid, castFolderUuid, castParentFolderUuid?: string, cb?: Function) => async (dispatch) => {
        dispatch({ type: CASTS_FOLDER_DELETE });
        await asyncCastFolderDelete(castUid, castFolderUuid);
        dispatch({
            type: asyncActionSuccess(CASTS_FOLDER_DELETE),
            castUid,
            castFolderUuid,
            castParentFolderUuid
        });
        cb?.();
    };

export const castFileDelete = (castUid, castFileUuid, castFolderUuid, cb) => async (dispatch) => {
    dispatch({ type: CASTS_FILE_DELETE });
    await asyncCastFileDelete(castUid, castFileUuid);
    dispatch({
        type: asyncActionSuccess(CASTS_FILE_DELETE),
        castUid,
        castFileUuid,
        castFolderUuid
    });
    if (typeof cb === 'function') cb();
};
