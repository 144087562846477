import './MemberItem.scss';
import React, { useState } from 'react';
import { AvatarUser } from '@he-novation/design-system/components/avatars/Avatar/AvatarUser';
import { StyledFormField } from '@he-novation/design-system/components/form/StyledFormField/StyledFormField';
import { isAdmin } from '@he-novation/config/constants/projects.constants';
import { __ } from '@he-novation/design-system/utils/i18n';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import Icon from '@he-novation/icons';
import cn from 'classnames';
import { StyledFormFields } from '@he-novation/design-system/components/form/StyledFormFields/StyledFormFields';
import { getDisplayName } from '@he-novation/utils/user';
import { ROLE_MANAGER } from '@he-novation/config/utils/acl';
import { Tooltip } from '@he-novation/design-system/components/widgets/Tooltip/Tooltip';

const MemberItem = ({
    displayEmail,
    currentUserUuid,
    ownerUuid,
    editable,
    member,
    onDelete,
    onDownloadChange,
    onExportChange,
    onRoleChange,
    onAcceptAdmittance,
    onRejectAdmittance,
    role,
    roles,
    theme,
    canDeleteAdmins
}) => {
    const [displayOptions, toggleOptions] = useState(false);
    const memberIsAdmin = isAdmin(member.role) || member.role === ROLE_MANAGER;
    const memberIsOwner = member.uuid === ownerUuid;
    const memberIsCurrentUser = member.user_uuid === currentUserUuid;

    const displayName = getDisplayName({ ...member });
    return (
        <div className={cn('c-project-member-item', displayEmail && 'display-email', theme)}>
            <div className="member">
                {(canDeleteAdmins || editable) && !memberIsOwner && !memberIsCurrentUser ? (
                    <button
                        type="button"
                        className="delete"
                        onClick={(e) => {
                            if (typeof onDelete === 'function') onDelete(member);
                        }}
                    >
                        <Icon icon="cross" />
                    </button>
                ) : null}

                <AvatarUser {...member} />

                <div className="member-info">
                    <div className="member-text-info">
                        <span
                            className={`member-name ${
                                displayName === member.email ? 'is-email' : ''
                            }`}
                        >
                            <Tooltip content={member.email} tagName="span">
                                {displayName}
                            </Tooltip>
                        </span>
                        {displayEmail && displayName !== member.email && (
                            <span className="member-email">{member.email}</span>
                        )}
                    </div>

                    {roles && (
                        <StyledFormField
                            type="select"
                            theme={theme}
                            onChange={onRoleChange ? (e) => onRoleChange(e, member) : null}
                            options={roles}
                            value={member.role || role || roles[0]}
                            disabled={!editable}
                        />
                    )}
                </div>

                {editable && !memberIsAdmin && (onDownloadChange || onExportChange) ? (
                    <Button
                        type="button"
                        theme={theme}
                        tone={ButtonTone.Hoverable}
                        icon="three-dots"
                        onClick={() => toggleOptions(!displayOptions)}
                    />
                ) : null}
            </div>

            {displayOptions ? (
                <div className="option-fields">
                    <StyledFormFields
                        theme={theme}
                        fields={[
                            onDownloadChange && {
                                label: __('RIGHTS_DOWNLOAD'),
                                type: 'checkbox',
                                checked: !!member.canDownload,
                                id:
                                    'project-member-item-can-download-' +
                                    (member.uuid || member.user_uuid),
                                name: 'can-download',
                                disabled: memberIsAdmin,
                                onChange: onDownloadChange
                                    ? (e) => onDownloadChange(e, member)
                                    : null
                            },
                            onExportChange && {
                                label: __('RIGHTS_EXPORT'),
                                type: 'checkbox',
                                checked: !!member.canExport,
                                id:
                                    'project-member-item-can-export-' +
                                    (member.uuid || member.user_uuid),
                                name: 'can-export',
                                disabled: memberIsAdmin,
                                onChange: onExportChange ? (e) => onExportChange(e, member) : null
                            }
                        ]}
                    />
                </div>
            ) : null}

            {member.pending && (
                <div className="pending">
                    <Button
                        onClick={() => {
                            if (typeof onAcceptAdmittance === 'function')
                                onAcceptAdmittance(member);
                        }}
                        className="pending-accept"
                        tone={ButtonTone.Outlined}
                        type="button"
                    >
                        {__('BTN_ACCEPT')}
                    </Button>
                    <Button
                        onClick={() => {
                            if (typeof onRejectAdmittance === 'function')
                                onRejectAdmittance(member);
                        }}
                        className="pending-decline"
                        tone={ButtonTone.Outlined}
                        type="button"
                    >
                        {__('BTN_DECLINE')}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default MemberItem;
