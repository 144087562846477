import './SidePanel.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { __ } from '@he-novation/design-system/utils/i18n';

export const SIDE_PANEL_ANIMATION_DURATION = 300;

class SidePanel extends React.Component {
    componentDidUpdate(prevProps) {
        if (
            this.props.Component !== prevProps.Component &&
            this.props.panelPayload &&
            typeof this.props.panelPayload.onOpen === 'function'
        ) {
            this.props.panelPayload.onOpen();
        }
    }

    render() {
        const { className, close, titleKey, Component } = this.props;

        return Component
            ? ReactDOM.createPortal(
                  <div id="c-side-panel" className={className}>
                      {titleKey && <h2>{__(titleKey)}</h2>}
                      <div className="c-side-panel-content">
                          <Component closeModal={close} payload={this.props.panelPayload} />
                      </div>
                  </div>,
                  document.getElementById('side-panel-wrapper')
              )
            : null;
    }
}

export default SidePanel;
