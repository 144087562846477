import React from 'react';
import { FileEnum } from '@he-novation/config/types/file.types';
import { useAtomValue } from 'jotai';
import { audioAtom } from '../../../atoms/file-atoms/audio-atom';
import { videoAtom } from '../../../atoms/file-atoms/video-atom';

function TimeCodeVideo() {
    const videoState = useAtomValue(videoAtom);
    return videoState.timecodeWithTimecodeStart.replace(';', '.');
}

function TimeAudio() {
    const audioState = useAtomValue(audioAtom);
    return audioState.timecode;
}

export function FormCommentTimeCode({ fileType }: { fileType: FileEnum }) {
    if (![FileEnum.Audio, FileEnum.Video].includes(fileType)) return null;
    return (
        <div className="timecode-info">
            {fileType === FileEnum.Video && <TimeCodeVideo />}
            {fileType === FileEnum.Audio && <TimeAudio />}
        </div>
    );
}
