import './TaskStatusPicker.scss';
import React from 'react';
import {
    taskStatusToClass,
    taskStatusToI18n
} from '@he-novation/config/constants/projects.constants';
import { MultiActionButton } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import cn from 'classnames';
import { __ } from '@he-novation/design-system/utils/i18n';
import { TaskStatus } from '@he-novation/config/types/task.types';

const buildAction = (status, props) => ({
    children: __(taskStatusToI18n(status)),
    onClick: (e) => {
        if (status === props.status) {
            e.preventDefault();
            return;
        }
        props.onChange(e, props.uuid, status);
    },
    className: props.status === status ? 'is-active' : undefined
});

const TaskStatusPicker = (props) => (
    <MultiActionButton
        className={cn('c-task-status-picker', taskStatusToClass(props.status))}
        menuClassName="c-task-status-picker-menu"
        icon="task-done"
        actions={[
            buildAction(TaskStatus.TO_DO, props),
            buildAction(TaskStatus.IN_PROGRESS, props),
            buildAction(TaskStatus.TO_VALIDATE, props),
            buildAction(TaskStatus.DONE, props)
        ]}
    />
);

export default TaskStatusPicker;
