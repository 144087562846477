import styles from './ActivityItem.module.css';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { FileConvertToVersionLog, FileVoteFinalLog } from '@he-novation/config/types/log.types';
import { CommentLogKey } from '@he-novation/config/types/payloads/logs/comment-logs.payload';
import { FileLogKey } from '@he-novation/config/types/payloads/logs/file-logs.payload';
import { FolderLogKey } from '@he-novation/config/types/payloads/logs/folder-logs.payload';
import { AnyLogPayload } from '@he-novation/config/types/payloads/logs/log.payload';
import { ProjectLogKey } from '@he-novation/config/types/payloads/logs/project-logs.payload';
import { TaskLogKey } from '@he-novation/config/types/payloads/logs/task-logs.payload';
import { AvatarUser } from '@he-novation/design-system/components/avatars/Avatar/AvatarUser';
import { __ } from '@he-novation/design-system/utils/i18n';
import Icon from '@he-novation/icons';
import {
    folderLink,
    playerLink,
    projectFoldersLink,
    projectTasksLink
} from '@he-novation/paths/herawFrontUris';
import { getDisplayName } from '@he-novation/utils/user';
import sanitizeHTML from 'sanitize-html';

import Link from '$components/router/Link';
import { currentUserUuidSelector } from '$redux/user/userSelectors';

type ActivityItemV2Props = {
    item: AnyLogPayload;
    index: number;
};

const htmlLink = (text: string, link: string) => `<a href="${link}">${text}</a>`;
const htmlSpan = (text: string) => `<span>${text}</span>`;

function legacyTitleKey(translationKey: string, isRequestUser: boolean) {
    return `${translationKey}_${isRequestUser ? 'SELF' : 'INFO'}`;
}

const itemToContent = (item: AnyLogPayload, currentUserUuid: string) => {
    let icon: string | undefined;
    let text: string | undefined;
    let thumbnail: string | null | undefined;
    let title: string | undefined;
    let link: string | undefined;

    const isRequestUser = !!(
        item.requestUser &&
        'uuid' in item.requestUser &&
        currentUserUuid === item.requestUser.uuid
    );

    const createLink = (text: string, href: string) => `<a href="${href}">${text}</a>`;

    switch (item.key) {
        case FileLogKey.FileExport: {
            title = item.file.name;
            text = __('User {{user}} exported the {{file}} file to {{plugin}}', {
                user: `<span>${getDisplayName(item.requestUser)}</span>`,
                file: createLink(item.file.name, playerLink(item.file.uuid, item.file.version)),
                plugin: `<span>${item.plugin}</span>`
            });
            break;
        }
        case FileLogKey.FileDelete: {
            title = item.file.name;
            icon = 'trash-can';
            text = __(legacyTitleKey('LOGS_FILE_DELETE', isRequestUser), {
                user: `<span>${getDisplayName(item.requestUser)}</span>`,
                name: createLink(item.file.name, playerLink(item.file.uuid, item.file.version)),
                parent_name: createLink(
                    item.parentFolder.name,
                    item.project
                        ? projectFoldersLink(item.project.uuid, item.parentFolder.uuid)
                        : folderLink(item.parentFolder.uuid)
                )
            });
            break;
        }
        case FileLogKey.FileNew: {
            title = item.file.name;
            icon = 'file';
            text = __('User {{user}} added the {{file}} file to the {{folder}} folder', {
                user: `<span>${getDisplayName(item.requestUser)}</span>`,
                file: createLink(item.file.name, playerLink(item.file.uuid, item.file.version)),
                folder: createLink(
                    item.parentFolder.name,
                    item.project
                        ? projectFoldersLink(item.project.uuid, item.parentFolder.uuid)
                        : folderLink(item.parentFolder.uuid)
                )
            });
            break;
        }
        case FileLogKey.FileDownload:
            title = item.file.name;
            icon = 'arrow-down';
            text = __('User {{user}} downloaded the {{file}} file', {
                user: `<span>${
                    item.requestUser ? getDisplayName(item.requestUser) : 'Anonymous'
                }</span>`,
                file: createLink(item.file.name, playerLink(item.file.uuid, item.file.version))
            });
            break;
        case FileLogKey.FileMove:
            icon = 'round-exchange';
            text = __(legacyTitleKey('LOGS_FILE_MOVE', isRequestUser), {
                user: `<span>${getDisplayName(item.requestUser)}</span>`,
                name: createLink(item.file.name, playerLink(item.file.uuid, item.file.version)),
                source: item.parentFolder.name,
                parent_name: createLink(
                    item.targetFolder.name,
                    item.project
                        ? projectFoldersLink(item.project.uuid, item.parentFolder.uuid)
                        : folderLink(item.parentFolder.uuid)
                )
            });
            break;
        case FileLogKey.FileRestore:
            title = item.file.name;
            icon = 'file_restore';
            text = __(legacyTitleKey('REACT_LOGS_FILE_RESTORE', isRequestUser), {
                user: `<span>${getDisplayName(item.requestUser)}</span>`,
                name: createLink(item.file.name, playerLink(item.file.uuid, item.file.version))
            });
            break;
        case FileLogKey.FileSetFinal:
            title = item.file.name;
            text = __(
                item.final
                    ? 'User {{user}} marked {{file}} as final'
                    : 'User {{user}} unmarked {{file}} as final',
                {
                    user: `<span>${getDisplayName(item.requestUser)}</span>`,
                    file: createLink(item.file.name, playerLink(item.file.uuid, item.file.version))
                }
            );
            break;
        case FileLogKey.FileSubtitleAdd:
            title = item.file.name;
            text = __(legacyTitleKey('LOGS_FILE_ADD_SUBTITLE', isRequestUser), {
                user: `<span>${getDisplayName(item.requestUser)}</span>`,
                name: createLink(item.file.name, playerLink(item.file.uuid, item.file.version)),
                parent_name: createLink(
                    item.parentFolder.name,
                    item.project
                        ? projectFoldersLink(item.project.uuid, item.parentFolder.uuid)
                        : folderLink(item.parentFolder.uuid)
                ),
                nb_subtitles: 1,
                subtitles: __('SUBTITLES')
            });
            break;
        case FileLogKey.FileUpdate:
            title = item.file.name;
            text = __('User {{user}} updated the {{file}} file', {
                user: `<span>${getDisplayName(item.requestUser)}</span>`,
                file: createLink(item.file.name, playerLink(item.file.uuid, item.file.version))
            });
            break;
        case FileLogKey.FileVersionDelete:
            title = item.file.name;
            icon = 'trash-can';
            text = __(legacyTitleKey('REACT_LOGS_FILE_VERSION_DELETE', isRequestUser), {
                user: `<span>${getDisplayName(item.requestUser)}</span>`,
                name: createLink(item.file.name, playerLink(item.file.uuid, item.file.version)),
                parent_name: createLink(
                    item.parentFolder.name,
                    item.project
                        ? projectFoldersLink(item.project.uuid, item.parentFolder.uuid)
                        : folderLink(item.parentFolder.uuid)
                )
            });
            break;
        case FileLogKey.FileVersionRestore:
            title = item.file.name;
            icon = 'file_restore';
            text = __(legacyTitleKey('REACT_LOGS_FILE_VERSION_RESTORE', isRequestUser), {
                user: `<span>${getDisplayName(item.requestUser)}</span>`,
                name: createLink(item.file.name, playerLink(item.file.uuid, item.file.version)),
                parent_name: createLink(
                    item.parentFolder.name,
                    item.project
                        ? projectFoldersLink(item.project.uuid, item.parentFolder.uuid)
                        : folderLink(item.parentFolder.uuid)
                )
            });
            break;
        case FolderLogKey.FolderAccessGrant:
            title = item.folder.name;
            icon = 'users';
            text = __(legacyTitleKey('LOGS_FOLDER_GRANT_ACCESS', isRequestUser), {
                user: `<span>${getDisplayName(item.requestUser)}</span>`,
                member: getDisplayName(item.invitedUsers[0]),
                folder_name: createLink(
                    item.folder.name,
                    item.project
                        ? projectFoldersLink(item.project.uuid, item.folder.uuid)
                        : folderLink(item.folder.uuid)
                ),
                role: item.invitedUsers[0].role
            });
            break;
        case FolderLogKey.FolderAccessRequest:
            title = item.folder.name;
            icon = 'users';
            text = __(legacyTitleKey('LOGS_FOLDER_GRANT_ACCESS', isRequestUser), {
                user: `<span>${getDisplayName(item.requestUser)}</span>`,
                member: getDisplayName(item.invitedUser),
                folder_name: createLink(
                    item.folder.name,
                    item.project
                        ? projectFoldersLink(item.project.uuid, item.folder.uuid)
                        : folderLink(item.folder.uuid)
                ),
                role: item.invitedUser.role
            });
            break;
        case FolderLogKey.FolderAccessRevoke:
            title = item.folder.name;
            icon = 'users';
            text = __(legacyTitleKey('LOGS_FOLDER_REVOKE_ACCESS', isRequestUser), {
                user: `<span>${getDisplayName(item.requestUser)}</span>`,
                member: getDisplayName(item.targetUser),
                folder_name: createLink(
                    item.folder.name,
                    item.project
                        ? projectFoldersLink(item.project.uuid, item.folder.uuid)
                        : folderLink(item.folder.uuid)
                )
            });
            break;
        case FolderLogKey.FolderAdd:
            title = item.folder.name;
            icon = 'folder';
            text = __(legacyTitleKey('LOGS_FOLDER_ADD', isRequestUser), {
                user: `<span>${getDisplayName(item.requestUser)}</span>`,
                name: createLink(
                    item.folder.name,
                    item.project
                        ? projectFoldersLink(item.project.uuid, item.folder.uuid)
                        : folderLink(item.folder.uuid)
                ),
                parent_name: item.parentFolder?.name
                    ? createLink(
                          item.parentFolder.name,
                          item.project
                              ? projectFoldersLink(item.project.uuid, item.parentFolder.uuid)
                              : folderLink(item.parentFolder.uuid)
                      )
                    : 'unknown'
            });
            break;
        case FolderLogKey.FolderCopyTree:
            text = __(legacyTitleKey('REACT_LOGS_FOLDER_COPY_TREE', isRequestUser), {
                user: `<span>${getDisplayName(item.requestUser)}</span>`,
                name: createLink(
                    item.folder.name,
                    item.project
                        ? projectFoldersLink(item.project.uuid, item.folder.uuid)
                        : folderLink(item.folder.uuid)
                ),
                source: item.sourceParentFolder.name,
                parent_name: item.parentFolder?.name
                    ? createLink(
                          item.parentFolder.name,
                          item.project
                              ? projectFoldersLink(item.project.uuid, item.parentFolder.uuid)
                              : folderLink(item.parentFolder.uuid)
                      )
                    : 'unknown'
            });
            break;
        case FolderLogKey.FolderDelete:
            title = item.folder.name;
            icon = 'trash-can';
            text = __(legacyTitleKey('LOGS_FOLDER_DELETE', isRequestUser), {
                user: `<span>${getDisplayName(item.requestUser)}</span>`,
                name: item.folder.name,
                parent_name: item.parentFolder?.name
                    ? createLink(
                          item.parentFolder.name,
                          item.project
                              ? projectFoldersLink(item.project.uuid, item.parentFolder.uuid)
                              : folderLink(item.parentFolder.uuid)
                      )
                    : 'unknown'
            });
            break;
        case FolderLogKey.FolderDownload:
            title = item.folder.name;
            icon = 'arrow-down';
            text = __(legacyTitleKey('REACT_LOGS_FOLDER_DOWNLOAD', isRequestUser), {
                user: `<span>${
                    item.requestUser ? getDisplayName(item.requestUser) : 'Anonymous'
                }</span>`,
                name: createLink(
                    item.folder.name,
                    item.project
                        ? projectFoldersLink(item.project.uuid, item.folder.uuid)
                        : folderLink(item.folder.uuid)
                ),
                file_count: 'n'
            });
            break;
        case FolderLogKey.FolderRestore:
            title = item.folder.name;
            text = __(legacyTitleKey('REACT_LOGS_FOLDER_RESTORE', isRequestUser), {
                user: `<span>${getDisplayName(item.requestUser)}</span>`,
                name: createLink(
                    item.folder.name,
                    item.project
                        ? projectFoldersLink(item.project.uuid, item.folder.uuid)
                        : folderLink(item.folder.uuid)
                )
            });
            break;
        case FolderLogKey.FolderSetRecursive:
            title = item.folder.name;
            text = __(legacyTitleKey('REACT_LOGS_FOLDER_RESTORE', isRequestUser), {
                user: `<span>${getDisplayName(item.requestUser)}</span>`,
                name: createLink(
                    item.folder.name,
                    item.project
                        ? projectFoldersLink(item.project.uuid, item.folder.uuid)
                        : folderLink(item.folder.uuid)
                )
            });
            break;
        case FolderLogKey.FolderUpdate:
            title = item.folder.name;
            text = __('User {{user}} updated the {{folder}} folder', {
                user: `<span>${getDisplayName(item.requestUser)}</span>`,
                folder: createLink(
                    item.folder.name,
                    item.project
                        ? projectFoldersLink(item.project.uuid, item.folder.uuid)
                        : folderLink(item.folder.uuid)
                )
            });
            break;
        case CommentLogKey.CommentAdd:
            title = item.file.name;
            text = __(legacyTitleKey('REACT_LOGS_COMMENT_ADD', isRequestUser), {
                user: item.requestUser ? getDisplayName(item.requestUser) : 'Anonymous',
                name: createLink(item.file.name, playerLink(item.file.uuid, item.file.version)),
                parent_name: createLink(
                    item.parentFolder.name,
                    item.project
                        ? projectFoldersLink(item.project.uuid, item.parentFolder.uuid)
                        : folderLink(item.parentFolder.uuid)
                )
            });
            break;
        case CommentLogKey.CommentDelete:
            title = item.file.name;
            text = __(legacyTitleKey('LOGS_COMMENTS_SET_DELETED', isRequestUser), {
                user: getDisplayName(item.requestUser),
                name: createLink(item.file.name, playerLink(item.file.uuid, item.file.version)),
                parent_name: createLink(
                    item.parentFolder.name,
                    item.project
                        ? projectFoldersLink(item.project.uuid, item.parentFolder.uuid)
                        : folderLink(item.parentFolder.uuid)
                )
            });
            break;
        case CommentLogKey.CommentEdit:
            title = item.file.name;
            text = __(legacyTitleKey('LOGS_COMMENTS_MODIFIED', isRequestUser), {
                user: getDisplayName(item.requestUser),
                name: createLink(item.file.name, playerLink(item.file.uuid, item.file.version)),
                parent_name: createLink(
                    item.parentFolder.name,
                    item.project
                        ? projectFoldersLink(item.project.uuid, item.parentFolder.uuid)
                        : folderLink(item.parentFolder.uuid)
                )
            });
            break;
        case ProjectLogKey.ProjectAdd: {
            title = item.project.name;
            icon = 'briefcase';
            text = __(legacyTitleKey('REACT_LOGS_PROJECT_ADD', isRequestUser), {
                user: getDisplayName(item.requestUser),
                name: createLink(item.project.name, projectFoldersLink(item.project.uuid))
            });
            break;
        }
        case ProjectLogKey.ProjectDelete:
            title = item.project.name;
            icon = 'briefcase';
            text = __(legacyTitleKey('REACT_LOGS_PROJECT_DELETE', isRequestUser), {
                user: getDisplayName(item.requestUser),
                name: `<b>${item.project.name}</b>`
            });
            break;
        case ProjectLogKey.ProjectEdit:
            title = item.project.name;
            icon = 'briefcase';
            text = __(legacyTitleKey('REACT_LOGS_PROJECT_MODIFY', isRequestUser), {
                user: getDisplayName(item.requestUser),
                name: createLink(item.project.name, projectFoldersLink(item.project.uuid))
            });
            break;
        case TaskLogKey.TaskAdd:
            title = item.project.name;
            text = __(legacyTitleKey('REACT_LOGS_TASK_ADD', isRequestUser), {
                you: __('YOU'),
                user: getDisplayName(item.requestUser),
                project_name: createLink(item.project.name, projectTasksLink(item.project.uuid)),
                task_description: item.task?.description
            });
            break;
        case TaskLogKey.TaskEdit:
            title = item.project.name;
            text = __(legacyTitleKey('REACT_LOGS_TASK_MODIFY', isRequestUser), {
                user: getDisplayName(item.requestUser),
                you: __('YOU'),
                project_name: createLink(item.project.name, projectTasksLink(item.project.uuid)),
                task_description: item.task?.description
            });
            break;
        case FileLogKey.FileConvertToVersion: {
            const _item = item as FileConvertToVersionLog;
            icon = 'file';
            link = playerLink(_item.targetFile.uuid, _item.targetFile.version);
            text = __('LOG_FILE_CONVERT_TO_VERSION_TEXT', {
                newVersion: _item.targetFile.version + 1,
                sourceFileName: htmlSpan(_item.file.name),
                targetFileName: htmlLink(item.targetFile.name, link)
            });
            title = _item.targetFile.name;
            thumbnail = _item.targetFile.thumbnail;
            break;
        }
        case FileLogKey.FileVoteFinal: {
            const _item = item as FileVoteFinalLog;
            icon = 'file';
            link = playerLink(_item.file.uuid, _item.file.version);
            text = __('LOGS_FILE_VOTE_FINAL_INFO', {
                user: getDisplayName(_item.requestUser),
                parent_name: `<a href="${folderLink(_item.parentFolder.uuid)}">${
                    _item.parentFolder.name
                }</a>`,
                name: createLink(_item.file.name, playerLink(_item.file.uuid, _item.file.version)),
                ifDelegateForIsNotUndefinedDelegateFor: _item.votedFor
                    ? `${__('REACT_LOG_FOR_USER')} ${getDisplayName(_item.votedFor)}`
                    : ' '
            });
            thumbnail = _item.file.thumbnail;
            break;
        }
    }
    if (text) text = sanitizeHTML(text);

    return { icon, text, thumbnail, title, link };
};

export function ActivityItemV2({ item }: ActivityItemV2Props) {
    const { currentUserUuid } = useSelector(currentUserUuidSelector);
    const { icon, text, thumbnail, title, link } = itemToContent(item, currentUserUuid);
    let thmb: ReactNode;
    if (thumbnail) {
        const Tag = link ? Link : 'div';
        thmb = (
            <Tag className={styles.thumbnail} href={link}>
                <img src={thumbnail} alt={''} />
                {title && <span>{title}</span>}
            </Tag>
        );
    }

    return (
        <div className={styles.item}>
            <div className={styles.top}>
                <div className={styles.avatar}>
                    {!!item.requestUser?.email && <AvatarUser {...item.requestUser} />}
                    {icon && <Icon icon={icon} />}
                </div>
                {text && <p dangerouslySetInnerHTML={{ __html: text }} />}
            </div>
            {thmb}
        </div>
    );
}
