import './FormCommentType.scss';
import React, { ChangeEvent } from 'react';
import { AreaSelectionState } from '@he-novation/config/types/area-selection.types';
import { FileEnum } from '@he-novation/config/types/file.types';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { Tooltip } from '@he-novation/design-system/components/widgets/Tooltip/Tooltip';
import { __ } from '@he-novation/design-system/utils/i18n';
import Icon from '@he-novation/icons';
import ExtensionDownloadTooltip from './ExtensionDownloadTooltip';

import { TYPE_NOTE } from '$constants/constants.note';

type FormCommentTypeProps = {
    areaSelection: AreaSelectionState;
    areaSelectionToggle: (toggled?: boolean) => void;
    fileType: FileEnum;
    iframeCapture?: boolean;
    type: string;
    onTypeChange: (e: ChangeEvent<HTMLInputElement>) => void;
    formId: string;
};

export default function FormCommentType({
    areaSelection,
    areaSelectionToggle,
    fileType,
    iframeCapture,
    type,
    onTypeChange,
    formId
}: FormCommentTypeProps) {
    return (
        <div className="c-form-comment-type">
            <FormField
                type={'radio'}
                label={__('REACT_PLAYER_NOTE_TYPE_GLOBAL')}
                checked={type === TYPE_NOTE.GLOBAL}
                name="type"
                id="c-form-comment-global"
                value={TYPE_NOTE.GLOBAL}
                formId={formId}
                onChange={onTypeChange}
            />

            {[FileEnum.Video, FileEnum.Audio].includes(fileType) && (
                <FormField
                    checked={TYPE_NOTE.SEQUENCE === type}
                    type={'radio'}
                    label={__('REACT_PLAYER_NOTE_TYPE_SEQUENCE')}
                    name="type"
                    id="c-form-comment-sequence"
                    value={TYPE_NOTE.SEQUENCE}
                    formId={formId}
                    onChange={onTypeChange}
                />
            )}

            {fileType !== 'office' && (fileType !== 'html' || iframeCapture) && (
                <div className="c-form-comment-selection-types">
                    <FormField
                        checked={TYPE_NOTE.RECTANGLE === type}
                        type={'radio'}
                        label={<Icon icon="rectangle-corners" />}
                        name="type"
                        id="c-form-comment-rectangle"
                        value={TYPE_NOTE.RECTANGLE}
                        formId={formId}
                        onChange={onTypeChange}
                    />
                    <FormField
                        checked={TYPE_NOTE.DRAW === type}
                        type={'radio'}
                        label={<Icon icon="brush" />}
                        name="type"
                        id="c-form-comment-draw"
                        value={TYPE_NOTE.DRAW}
                        formId={formId}
                        onChange={onTypeChange}
                    />
                    {fileType === 'image' && (
                        <Tooltip content={__('TOOLTIP_AREA_SELECTION_MOVEMENT')} className="swipe">
                            <FormField
                                type={'checkbox'}
                                label={<Icon icon="swipe" />}
                                checked={!areaSelection.toggled}
                                onChange={(e) => areaSelectionToggle(!e.target.checked)}
                                name="swipe"
                                disabled={type === TYPE_NOTE.GLOBAL}
                                formId={formId}
                                value={TYPE_NOTE.DRAW}
                            />
                        </Tooltip>
                    )}
                </div>
            )}
            {fileType === 'html' && !iframeCapture && <ExtensionDownloadTooltip />}
        </div>
    );
}
