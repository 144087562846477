import React from 'react';
import { branch, compose, lifecycle, renderComponent, withStateHandlers } from 'recompose';
import { connect } from 'react-redux';
import {
    activeBrandingSelector,
    isTeamsAppSelector,
    localeSelector
} from '$redux/config/configSelectors';
import { activeSidePanelSelector } from '$redux/sideMenu/sideMenuSelectors';
import combineSelectors from '../../helpers/combineSelectors';
import { fetchContacts } from '$redux/content/contacts/contactsActions';
import withProvider from '$components/HOC/withProvider';
import store from '$redux/store.ts';
import { setFullscreen, setSize, socketActions } from '$redux/config/configActions';
import App from './App';
import {
    fetchAccount,
    fetchUser,
    fetchPlugins,
    fetchStorageAndLicenses
} from '$redux/user/userActions';
import { resetContent } from '$redux/content/contentActions';
import { closePanel } from '$redux/sideMenu/sideMenuActions';
import { routeSelector } from '$redux/route/routeSelectors';
import { APP_ID } from '$helpers/fullscreen';
import fscreen from 'fscreen';
import { fetchClient } from '$redux/client/clientActions';
import { currentClientUuidSelector } from '$redux/client/clientSelectors';
import { currentUserUuidSelector, fetchedAccountSelector } from '$redux/user/userSelectors';
import { openFeedbackModal } from '$redux/route/routeActions';
import { isSuperAdminSelector } from '$redux/client/clientSelectors';
import { Loader } from '@he-novation/design-system/components/widgets/Loader/Loader';
import withSocketIO from '$components/HOC/withSocketIO';
import { set } from '$redux/route/routeActions';
import { folderLink, projectsLink, sharedRootLink } from '@he-novation/paths/herawFrontUris';
import { setActiveClientCss } from '$helpers/setActiveClientCSS';
import strToQueryParams from '@he-novation/paths/utils/strToQueryParams';
import { asyncWordingListFetch } from '@he-novation/front-shared/async/wording.async';
import { init } from '@he-novation/design-system/init';
import Link from '$components/router/Link';
import * as uris from '@he-novation/paths/herawFrontUris';
import { setDatePickerConfiguration } from '$helpers/datepicker';
import { WordingList } from '$types/wordingTypes';
import { setI18n } from '@he-novation/utils/i18n';

import * as sockets from '@he-novation/config/utils/sockets/sockets.client';
import io from 'socket.io-client';
import { apiFetch } from '@he-novation/front-shared/async/apiFetch';
const mapStateToProps = combineSelectors(
    activeSidePanelSelector,
    routeSelector,
    localeSelector,
    fetchedAccountSelector,
    isSuperAdminSelector,
    activeBrandingSelector,
    currentClientUuidSelector,
    currentUserUuidSelector,
    isTeamsAppSelector
);

const mapDispatchToProps = (dispatch) => ({
    onRouteUpdate(prevUrl, url) {
        dispatch(resetContent(prevUrl, url));
        dispatch(closePanel());
    },
    fetchClient: (cb, onError) => dispatch(fetchClient(cb, onError)),
    fetchUser: (cb, onError) => dispatch(fetchUser(cb, onError)),
    fetchAccount: (cb, onError) => dispatch(fetchAccount(cb, onError)),
    fetchContacts: () => dispatch(fetchContacts()),
    fetchStorageAndLicenses: (cb) => dispatch(fetchStorageAndLicenses(cb)),
    onFullscreenChange: () =>
        dispatch(setFullscreen(fscreen.fullscreenElement === document.getElementById(APP_ID))),
    fetchPlugins: () => new Promise((resolve) => dispatch(fetchPlugins(resolve))),
    feedback: (message) => dispatch(openFeedbackModal(message)),
    setRoute: (route, silent, replace) => dispatch(set(route, silent, replace))
});

const initApp = (wording, locale, websocketHost, callback) => {
    const host =
        process.env.WEBSOCKET_HOST && process.env.WEBSOCKET_HOST !== 'NULL'
            ? process.env.WEBSOCKET_HOST
            : websocketHost;
    const ws = {
        host,
        port: 443,
        hostAndPort: host + ':443'
    };

    try {
        sockets.misc.clientInit(io, ws.hostAndPort);
        sockets.activity.clientInit(io, ws.hostAndPort);

        if (!window.location.href.includes('/client/')) {
            sockets.user.clientInit(io, ws.hostAndPort);
            sockets.companies.clientInit(io, ws.hostAndPort);
            sockets.events.clientInit(io, ws.hostAndPort);
            sockets.items.clientInit(io, ws.hostAndPort);
            sockets.projects.clientInit(io, ws.hostAndPort);
            sockets.archives.clientInit(io, ws.hostAndPort);
            sockets.folder.clientInit(io, ws.hostAndPort);
            sockets.note.clientInit(io, ws.hostAndPort);
            sockets.subtitles.clientInit(io, ws.hostAndPort);
            sockets.folders.clientInit(io, ws.hostAndPort);
        }
    } catch (e) {
        console.warn('WEBSOCKETS failed');
    }

    init(
        wording,
        locale,
        React.forwardRef((props, ref) => <Link {...props} forwardedRef={ref} />),
        uris
    );
    setI18n(wording, locale);
    setDatePickerConfiguration(locale);
    if (callback) {
        callback();
    }
};

const AppContainer = compose(
    withProvider(store),
    connect(mapStateToProps, mapDispatchToProps),
    withStateHandlers(
        { loaded: false },
        {
            setLoaded: () => () => ({ loaded: true })
        }
    ),
    withSocketIO(),
    lifecycle({
        async componentDidMount() {
            const { host: websocketHost } = await apiFetch('/websocket-host');
            const { login } = strToQueryParams(window.location.href);

            if (window.location.pathname.includes('index#summary/')) {
                return asyncWordingListFetch(WordingList.App).then(({ wording, locale }) =>
                    initApp(wording, locale, websocketHost, this.props.setLoaded)
                );
            }
            if (
                window.location.pathname.includes('/public/') ||
                window.location.pathname.includes('index#public/')
            ) {
                return asyncWordingListFetch(WordingList.App).then(({ wording, locale }) =>
                    initApp(wording, locale, websocketHost, () => {
                        this.props.fetchAccount(
                            () =>
                                this.props.fetchUser(() => {
                                    this.props.setLoaded();
                                }),
                            () => {
                                this.props.setLoaded();
                            }
                        );
                    })
                );
            }

            if (window.location.pathname.includes('/cast/')) {
                return asyncWordingListFetch(WordingList.App).then(({ wording, locale }) =>
                    initApp(wording, locale, websocketHost, () => {
                        this.props.fetchAccount(
                            async () => {
                                await this.props.fetchPlugins();
                                this.props.fetchUser(() => {
                                    this.props.setLoaded();
                                });
                            },
                            () => {
                                this.props.setLoaded();
                            }
                        );
                    })
                );
            }

            const fetchAccountPromise = () =>
                new Promise((resolve, reject) =>
                    this.props.fetchAccount(
                        async (r) => {
                            const { plugins } = await this.props.fetchPlugins();
                            const hasProjectPlugin = plugins && plugins.includes('projects');
                            if (!r.isClient) {
                                this.props.fetchUser(({ projects, client_name, uuid }) => {
                                    this.props.fetchStorageAndLicenses();
                                    this.props.fetchContacts();

                                    // From Login
                                    if (login) {
                                        // User Has Project Plugin Or Assigned on a Project
                                        if (hasProjectPlugin || projects) {
                                            resolve();
                                            return this.props.setRoute(projectsLink());
                                        }

                                        // User has Storage
                                        if (r.storage) {
                                            resolve();
                                            return this.props.setRoute(folderLink(null));
                                        }
                                        resolve();
                                        return this.props.setRoute(sharedRootLink());
                                    }

                                    if ((!r.licenses && client_name === 'private') || !r.storage) {
                                        if (
                                            this.props.configRoute.startsWith('folders/') ||
                                            this.props.configRoute === '/'
                                        ) {
                                            if (projects) {
                                                resolve();
                                                return this.props.setRoute(projectsLink());
                                            }
                                            resolve();
                                            return this.props.setRoute(sharedRootLink());
                                        }
                                    }

                                    resolve();
                                }, reject);
                            } else {
                                this.props.fetchClient(resolve, reject);
                            }
                        },
                        (e, redirect) => {
                            console.log('err', e, redirect);
                            reject();
                            redirect();
                        }
                    )
                );
            Promise.allSettled([
                fetchAccountPromise(),
                asyncWordingListFetch(WordingList.App)
            ]).then(([{ status }, { value }]) => {
                if (status !== 'fulfilled') {
                    //return (window.location.href = loginLink({ locale: value.locale }));
                }
                initApp(value.wording, value.locale, websocketHost, this.props.setLoaded);

                this.props.socketIoSubscribe({
                    room: 'misc',
                    actions: socketActions,
                    name: 'misc'
                });
            });
        },

        componentDidUpdate(prevProps) {
            if (this.props.activeBranding && this.props.activeBranding !== prevProps.activeBranding)
                setActiveClientCss(this.props.activeBranding);
        },

        componentWillUnmount() {
            this.props.socketIoUnsubscribe();
        }
    }),
    branch(({ loaded }) => !loaded, renderComponent(Loader))
)(App);

store.dispatch(setSize(window.innerWidth, window.innerHeight));
window.addEventListener('resize', () =>
    store.dispatch(setSize(window.innerWidth, window.innerHeight))
);

export default AppContainer;
