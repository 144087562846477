import { v4 as uuid } from 'uuid';
import { apiFetch, fetchAsFormData } from './apiFetch';

export const verifyEmailOrPassword = (recaptcha: string, email: string, password?: string) =>
    apiFetch('/transfer/verify', {
        method: 'POST',
        body: JSON.stringify({
            username: email,
            password,
            recaptcha
        })
    });

export const checkAccount = (
    recaptcha: string,
    { email, firstname, lastname, phone, firm, role },
    files: File[],
    emails: string[]
) =>
    apiFetch('/transfer/account', {
        method: 'POST',
        body: JSON.stringify({
            username: email,
            profile: {
                firstname,
                lastname,
                phone,
                firm,
                role
            },
            fileList: Array.from(files).map((f) => ({ name: f.name, size: f.size })),
            members: emails.map((email) => ({ email })),
            recaptcha
        })
    });

export const addContacts = (recaptcha: string, emails: string[]) =>
    apiFetch('/transfer/contacts', {
        method: 'POST',
        body: JSON.stringify({
            members: emails.map((email) => ({ email })),
            recaptcha
        })
    });

export const createFolder = (recaptcha: string, emails: string[]) =>
    apiFetch('/api/folder/add/transfer', {
        method: 'POST',
        body: {
            members: emails.map((email) => ({ email })),
            recaptcha
        }
    });

export const uploadFile = ({ folderUuid }, file: File) =>
    fetchAsFormData('/upload', {
        method: 'POST',
        body: {
            parent: folderUuid, // order is important, parent should be sent first
            upload_group: uuid(),
            upload_group_id: 0,
            upload_group_count: 1,
            file_name: file.name,
            file_upload_message: '',
            files: [file] // order is important, files should be sent last
        }
    });
