import { connect } from 'react-redux';
import SidePanelList from '../../SidePanel/SidePanelList/SidePanelList';
import { closePanel } from '../../../redux/sideMenu/sideMenuActions';
import { isSmallScreenSelector } from '../../../redux/config/configSelectors';

export default connect(isSmallScreenSelector, (dispatch) => ({
    closePanel: () => {
        dispatch(closePanel());
    }
}))(SidePanelList);
