export enum ExtraFormFieldTypes {
    AutoComplete = 'AutoComplete',
    DatePicker = 'DatePicker',
    Mask = 'Mask',
    Editor = 'Editor'
}

export enum FormFieldTypes {
    Checkbox = 'checkbox',
    Color = 'color',
    Radio = 'radio',
    Email = 'email',
    Hidden = 'hidden',
    Number = 'number',
    Password = 'password',
    Select = 'select',
    Submit = 'submit',
    Tel = 'tel',
    Text = 'text',
    Textarea = 'textarea'
}

export enum Colors {
    Alert = '#ff5050',
    Success = '#49f281',
    Light = '#efefef',
    Medium = '#98999a',
    Dark = '#16181D',
    Darkest = '#000'
}

export enum Key {
    Enter = 'enter',
    Escape = 'escape',
    Down = 'down',
    Left = 'left',
    Right = 'right',
    Shift = 'shift',
    Tab = 'tab',
    Up = 'up',
    D = 'd',
    M = 'm'
}

export enum Role {
    Manager = 'ROLE_MANAGER',
    Reporter = 'ROLE_REPORTER',
    Provider = 'ROLE_PROVIDER',
    Contributor = 'ROLE_CONTRIBUTOR',
    Follower = 'ROLE_FOLLOWER',
    Spectator = 'ROLE_SPECTATOR',
    Public = 'ROLE_PUBLIC'
}

export const TEAM_ADMIN = 'ADMINISTRATORS';
export const TEAM_COLORS = ['#BB9C1F', '#E45B3B', '#00B3BB', '#ECEFF5', '#BB1F20'];

export enum ProjectRole {
    Manager = 'PROJECT_MANAGER',
    Reporter = 'PROJECT_REPORTER',
    Provider = 'PROJECT_PROVIDER',
    Contributor = 'PROJECT_CONTRIBUTOR',
    Follower = 'PROJECT_FOLLOWER',
    Spectator = 'PROJECT_SPECTATOR',
    Public = 'PROJECT_PUBLIC'
}

export enum Theme {
    Dark = 'theme-dark',
    Light = 'theme-light'
}

export enum IconSize {
    XS = 'xs',
    S = 's',
    L = 'l'
}

export enum WidgetTone {
    Dark = 't-dark',
    Light = 't-light',
    Green = 't-green',
    Red = 't-red'
}
