import { FolderRole } from '../types/folder.types';
import { ROLES } from '../constants/projects.constants';

export const ROLE_OWNER = FolderRole.ROLE_OWNER;
export const ROLE_MANAGER = FolderRole.ROLE_MANAGER;
export const ROLE_REPORTER = FolderRole.ROLE_REPORTER;
export const ROLE_PROVIDER = FolderRole.ROLE_PROVIDER;
export const ROLE_CONTRIBUTOR = FolderRole.ROLE_CONTRIBUTOR;
export const ROLE_FOLLOWER = FolderRole.ROLE_FOLLOWER;
export const ROLE_SPECTATOR = FolderRole.ROLE_SPECTATOR;
export const ROLE_PUBLIC = FolderRole.ROLE_PUBLIC;

export const acl = {
    player: {
        show: (role?: FolderRole | null) =>
            !!role &&
            [
                ROLE_OWNER,
                ROLE_MANAGER,
                ROLE_PROVIDER,
                ROLE_REPORTER,
                ROLE_CONTRIBUTOR,
                ROLE_FOLLOWER,
                ROLE_SPECTATOR
            ].indexOf(role) !== -1
    },

    content: {
        show: (role?: FolderRole | null) =>
            !!role &&
            [
                ROLE_OWNER,
                ROLE_MANAGER,
                ROLE_PROVIDER,
                ROLE_REPORTER,
                ROLE_CONTRIBUTOR,
                ROLE_FOLLOWER,
                ROLE_SPECTATOR
            ].indexOf(role) !== -1,

        showRoles: () => [
            ROLE_MANAGER,
            ROLE_PROVIDER,
            ROLE_REPORTER,
            ROLE_CONTRIBUTOR,
            ROLE_FOLLOWER,
            ROLE_SPECTATOR,
            ROLE_PUBLIC
        ],

        download: (role: FolderRole) =>
            [
                ROLE_OWNER,
                ROLE_MANAGER,
                ROLE_PROVIDER,
                ROLE_REPORTER,
                ROLE_CONTRIBUTOR,
                ROLE_FOLLOWER,
                ROLE_SPECTATOR,
                ROLE_PUBLIC
            ].indexOf(role) !== -1,

        export: (role: FolderRole) =>
            [
                ROLE_OWNER,
                ROLE_MANAGER,
                ROLE_PROVIDER,
                ROLE_REPORTER,
                ROLE_CONTRIBUTOR,
                ROLE_FOLLOWER,
                ROLE_SPECTATOR
            ].indexOf(role) !== -1
    },

    subtitles: {
        manage: (role?: FolderRole | null) =>
            !!role && [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER].includes(role)
    },

    summary: {
        show: () => false,

        download: () => false
    },

    comments: {
        show: (role: FolderRole) =>
            [
                ROLE_OWNER,
                ROLE_MANAGER,
                ROLE_PROVIDER,
                ROLE_REPORTER,
                ROLE_CONTRIBUTOR,
                ROLE_FOLLOWER
            ].indexOf(role) !== -1,

        showAll: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].indexOf(role) !== -1,

        showRoles: () => [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER, ROLE_CONTRIBUTOR],

        add: (role?: FolderRole) =>
            role &&
            [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER, ROLE_CONTRIBUTOR].includes(
                role
            ),

        attachFiles: (role: FolderRole) =>
            [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER, ROLE_CONTRIBUTOR].indexOf(
                role
            ) !== -1,

        modify: (role: FolderRole) =>
            [
                ROLE_OWNER,
                ROLE_MANAGER,
                ROLE_PROVIDER,
                ROLE_REPORTER,
                ROLE_CONTRIBUTOR,
                ROLE_FOLLOWER
            ].indexOf(role) !== -1,

        deleteExisting: (role: FolderRole) =>
            [
                ROLE_OWNER,
                ROLE_MANAGER,
                ROLE_PROVIDER,
                ROLE_REPORTER,
                ROLE_CONTRIBUTOR,
                ROLE_FOLLOWER
            ].indexOf(role) !== -1,

        modifyExisting: (role: string) => false
    },

    members: {
        logRoles: () => [ROLE_OWNER, ROLE_MANAGER],

        show: (role: FolderRole) =>
            [
                ROLE_OWNER,
                ROLE_MANAGER,
                ROLE_PROVIDER,
                ROLE_REPORTER,
                ROLE_CONTRIBUTOR,
                ROLE_FOLLOWER
            ].indexOf(role) !== -1,

        showRoles: () => [
            ROLE_OWNER,
            ROLE_MANAGER,
            ROLE_PROVIDER,
            ROLE_REPORTER,
            ROLE_CONTRIBUTOR,
            ROLE_FOLLOWER
        ],

        showRolesAll: () => [
            ROLE_OWNER,
            ROLE_MANAGER,
            ROLE_PROVIDER,
            ROLE_REPORTER,
            ROLE_CONTRIBUTOR,
            ROLE_FOLLOWER,
            ROLE_SPECTATOR,
            ROLE_PUBLIC
        ],

        add: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].indexOf(role) !== -1,

        modify: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].indexOf(role) !== -1,

        revokeMyAccess: (role: FolderRole) =>
            [
                ROLE_OWNER,
                ROLE_MANAGER,
                ROLE_PROVIDER,
                ROLE_REPORTER,
                ROLE_CONTRIBUTOR,
                ROLE_FOLLOWER,
                ROLE_SPECTATOR
            ].includes(role),

        del: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].indexOf(role) !== -1
    },

    files: {
        add: (role?: FolderRole | null) =>
            !!role && [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER].indexOf(role) !== -1,

        modify: (role: FolderRole | null) => !!role && [ROLE_OWNER, ROLE_MANAGER].includes(role),

        sendMailRolesOnFinal: () => [
            ROLE_OWNER,
            ROLE_MANAGER,
            ROLE_PROVIDER,
            ROLE_REPORTER,
            ROLE_CONTRIBUTOR,
            ROLE_FOLLOWER,
            ROLE_SPECTATOR,
            ROLE_PUBLIC
        ],

        setFinal: (role?: FolderRole | null) => !!role && [ROLE_OWNER, ROLE_MANAGER].includes(role),
        setStatus: (role?: FolderRole | null) =>
            role && [ROLE_OWNER, ROLE_MANAGER, ROLE_REPORTER, ROLE_PROVIDER].includes(role),
        voteFinal: (role?: FolderRole | null) => role && [ROLE_REPORTER].indexOf(role) !== -1,

        voteFinalRoles: () => [ROLE_REPORTER],

        showReviewersList: (role: FolderRole) => [ROLE_PROVIDER].indexOf(role) !== -1,

        getViews: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].indexOf(role) !== -1,

        move: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].indexOf(role) !== -1,

        setInfos: (role?: FolderRole) =>
            role && [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER].includes(role),
        del: (role?: FolderRole | null) =>
            !!role && [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER].indexOf(role) !== -1,

        sendEmail: (role: FolderRole) => [ROLE_MANAGER, ROLE_OWNER].includes(role)
    },

    folders: {
        add: (role?: FolderRole | null) =>
            !!role && [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER].includes(role),

        addRole: () => ROLE_MANAGER,

        cast: (role?: FolderRole | null) => !!role && [ROLE_OWNER, ROLE_MANAGER].includes(role),

        displayStats: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].indexOf(role) !== -1,

        sendMailRolesOnAdd: () => [ROLE_PROVIDER],

        modify: (role?: FolderRole | null) => !!role && [ROLE_OWNER, ROLE_MANAGER].includes(role),

        modifyRoles: () => [ROLE_OWNER, ROLE_MANAGER],

        modifyMetadataUser: (role: FolderRole) =>
            [
                ROLE_OWNER,
                ROLE_MANAGER,
                ROLE_PROVIDER,
                ROLE_REPORTER,
                ROLE_CONTRIBUTOR,
                ROLE_FOLLOWER,
                ROLE_SPECTATOR
            ].indexOf(role) !== -1,

        modifyMetadataUserRoles: () => [
            ROLE_OWNER,
            ROLE_MANAGER,
            ROLE_PROVIDER,
            ROLE_REPORTER,
            ROLE_CONTRIBUTOR,
            ROLE_FOLLOWER,
            ROLE_SPECTATOR
        ],

        revokeMyAccessRoles: () => [
            ROLE_OWNER,
            ROLE_MANAGER,
            ROLE_PROVIDER,
            ROLE_REPORTER,
            ROLE_CONTRIBUTOR,
            ROLE_FOLLOWER,
            ROLE_SPECTATOR,
            ROLE_PUBLIC
        ],

        move: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].indexOf(role) !== -1,

        moveRoles: () => [ROLE_OWNER, ROLE_MANAGER],

        copyTree: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].indexOf(role) !== -1,

        del: (role?: FolderRole | null) => !!role && [ROLE_OWNER, ROLE_MANAGER].includes(role),

        castSync: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].includes(role),

        delRoles: () => [ROLE_OWNER, ROLE_MANAGER],

        sendEmail: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].includes(role),
        share: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].includes(role)
    },

    logs: {
        show: (role: FolderRole) =>
            [
                ROLE_OWNER,
                ROLE_MANAGER,
                ROLE_PROVIDER,
                ROLE_REPORTER,
                ROLE_CONTRIBUTOR,
                ROLE_FOLLOWER
            ].indexOf(role) !== -1
    },

    rights: {
        all: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].indexOf(role) !== -1
    },

    teams: {
        showAll: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].indexOf(role) !== -1
    },

    projects: {
        add: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].indexOf(role) !== -1,

        list: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].indexOf(role) !== -1,

        addEvent: (role?: ROLES | null) =>
            role &&
            [ROLES.MANAGER, ROLES.CONTRIBUTOR, ROLES.PROVIDER, ROLES.REPORTER].includes(role)
    },

    plugin: {
        free: () => [ROLE_PUBLIC],

        basic: () => [ROLE_REPORTER, ROLE_PUBLIC],

        basicDefault: () => ROLE_REPORTER,

        premium: () => [
            ROLE_OWNER,
            ROLE_MANAGER,
            ROLE_PROVIDER,
            ROLE_REPORTER,
            ROLE_CONTRIBUTOR,
            ROLE_FOLLOWER,
            ROLE_SPECTATOR,
            ROLE_PUBLIC
        ],

        premiumDefault: () => ROLE_REPORTER
    },

    task: {},

    getAvailableRoles: (hasPluginRoles: boolean, hasPluginPublic: boolean) => {
        let roles = [ROLE_REPORTER];
        if (hasPluginRoles) {
            roles = [
                ROLE_MANAGER,
                ROLE_PROVIDER,
                ROLE_REPORTER,
                ROLE_CONTRIBUTOR,
                ROLE_FOLLOWER,
                ROLE_SPECTATOR
            ];
        }

        if (hasPluginPublic) roles.push(ROLE_PUBLIC);

        return roles;
    }
};
