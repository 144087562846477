import styles from './SidePanelCastFinder.module.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CastContentFile, PublicCast } from '@he-novation/config/types/cast.types';
import { SidePanelHeader } from '@he-novation/design-system/components/sidePanels/SidePanel/SidePanelHeader/SidePanelHeader';
import { __ } from '@he-novation/design-system/utils/i18n';
import { castFileLink } from '@he-novation/paths/herawFrontUris';
import cn from 'classnames';

import Link from '$components/router/Link';
import { getPlayerType } from '$helpers/fileType';
import { togglePlaylist } from '$redux/content/file/fileActions';
import { fileUuidSelector, isPlayingPlaylistSelector } from '$redux/content/file/fileSelectors';

type SidePanelCastFinderProps = {
    payload: {
        cast: PublicCast;
    };
};
export function SidePanelCastFinder({ payload }: SidePanelCastFinderProps) {
    const { fileUuid } = useSelector(fileUuidSelector);
    const { isPlayingPlaylist } = useSelector(isPlayingPlaylistSelector);
    const dispatch = useDispatch();

    return (
        <div className={`c-side-panel-finder ${styles.panel}`}>
            <SidePanelHeader
                title={__('REACT_PANEL_FINDER_TITLE')}
                buttons={[
                    {
                        icon: isPlayingPlaylist ? 'pause' : 'play',
                        onClick: () => {
                            dispatch(togglePlaylist(!isPlayingPlaylist));
                        }
                    }
                ]}
            />

            <ul>
                {payload.cast.content
                    .filter((c) => {
                        if (c.type !== 'file') return false;
                        const sourceAsset = c.assets?.find(
                            (a) => a.quality === 'source' && a.version === c.version
                        );
                        return getPlayerType(sourceAsset?.mimeType);
                    })
                    .map(({ castFileUuid, name, assets, version, uuid }: CastContentFile) => {
                        const thumbnail = assets?.find((a) => a.type === 'thumbnail')?.url;
                        return (
                            <li key={castFileUuid} className={styles.item}>
                                <Link
                                    href={castFileLink(payload.cast.uid, castFileUuid, version)}
                                    className={cn(fileUuid === uuid && styles.active)}
                                >
                                    {thumbnail ? (
                                        <img src={thumbnail} alt="" className={styles.img} />
                                    ) : (
                                        <div className={styles.img}></div>
                                    )}
                                    <div className={styles.content}>{name}</div>
                                </Link>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
}
