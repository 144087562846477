import { asyncActionSuccess } from '../../../helpers/asyncAction';
import { FETCH_PAGE, FIRST_FETCH_PAGE, ADD } from './activityActions';
import { sortActivityEvent } from '../../../helpers/sortActivityEvent';
import update from 'immutability-helper';

export const acitivityInitialState = {
    list: [],
    noContent: false,
    filters: []
};

const reducer = (state = acitivityInitialState, action = {}) => {
    switch (action.type) {
        case asyncActionSuccess(FIRST_FETCH_PAGE):
            if (action.status !== 204) {
                return update(state, {
                    noContent: {
                        $set: false
                    },
                    list: {
                        $set: sortActivityEvent(action.response)
                    }
                });
            } else {
                return update(state, {
                    noContent: {
                        $set: true
                    },
                    list: {
                        $set: []
                    }
                });
            }

        case asyncActionSuccess(FETCH_PAGE): {
            let u = {
                noContent: {
                    $set: action.status !== 204
                }
            };
            if (action.status !== 204) u.list = { $push: sortActivityEvent(action.response) };
            return update(state, u);
        }

        case ADD: {
            const $unshift = sortActivityEvent([action.activities]);
            return update(state, {
                list: { $unshift }
            });
        }
    }
    return state;
};

export default reducer;
