import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import type { Folder as ContentToUpload } from '@he-novation/design-system/components/form/DropUpload/DropUpload';
import { __ } from '@he-novation/design-system/utils/i18n';
import { fetchStorage } from '@he-novation/front-shared/async/user.async';
import { CONTACT_ADMIN } from '@he-novation/paths/modals.constants';
import { v4 as uuid } from 'uuid';

import { Uploader } from '$helpers/Uploader';
import { UploadCallbacks, UploaderState } from '$helpers/Uploader.types';
import { UploaderV2 } from '$helpers/UploaderV2';
import { uploadTree } from '$helpers/uploadTree';
import { openFeedbackModal, openModal } from '$redux/route/routeActions';
import { clientNameSelector, pluginsSelector } from '$redux/user/userSelectors';

export function useUploader(debounceProgressMs = 200) {
    const [state, setState] = useState<UploaderState>({
        uploads: [],
        pending: [],
        finished: [],
        errors: []
    });
    const dispatch = useDispatch();
    const { clientName } = useSelector(clientNameSelector);
    const { plugins } = useSelector(pluginsSelector);
    const uploader = plugins.includes('upload-v2') ? UploaderV2 : Uploader;

    useEffect(() => {
        const onBeforeUnload = (e: BeforeUnloadEvent) => {
            if (uploader.uploads.length) {
                e.preventDefault();
                e.returnValue = '';
            }
        };
        window.addEventListener('beforeunload', onBeforeUnload);
        uploader.register(setState);
        return () => {
            uploader.unregister(setState);
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, []);

    const checkStorage = useCallback(
        async (clientName: string, folderUuid: string, size: number) => {
            const { available } = await fetchStorage(folderUuid);
            if (available !== -1 && size > available) {
                dispatch(
                    openFeedbackModal(__('ERR_NO_STORAGE_LEFT'), 0, {
                        extraButtons:
                            clientName === 'private'
                                ? undefined
                                : [
                                      {
                                          onClick: () => dispatch(openModal(CONTACT_ADMIN)),
                                          children: __('CONTACT_ADMIN_TITLE'),
                                          tone: ButtonTone.Primary
                                      }
                                  ]
                    })
                );
                return false;
            }
            return true;
        },
        []
    );

    return {
        ...state,
        upload: async (
            file: File,
            folder: { uuid: string; name: string; shared?: boolean | number },
            {
                fileUuid,
                message,
                ...callbacks
            }: UploadCallbacks & { fileUuid?: string; message?: string } = {}
        ) => {
            if (!(await checkStorage(clientName, folder.uuid, file.size!))) return;
            uploader.resetInvalidFiles();

            return await uploader.upload(
                {
                    uploadGroup: uuid(),
                    file,
                    folder,
                    uploadIndex: 0,
                    uploadsTotal: 1,
                    parentFileUuid: fileUuid
                },
                {
                    ...callbacks,
                    debounceProgressMs
                }
            );
        },
        uploadTree: async (
            clientName: string,
            userUuid: string,
            rootFolder: {
                uuid: string;
                name: string;
                shared: boolean | number;
                metadata: object;
                triggers: any;
            },
            contentToUpload: ContentToUpload,
            hasPluginFiletree: boolean,
            preferences: object = {},
            callbacks?: UploadCallbacks
        ) => {
            if (!(await checkStorage(clientName, rootFolder.uuid, contentToUpload.size!))) return;
            uploader.resetInvalidFiles();

            return await uploadTree(
                clientName,
                userUuid,
                rootFolder,
                { ...contentToUpload, uuid: rootFolder.uuid, name: rootFolder.name, path: '/' },
                hasPluginFiletree,
                preferences,
                { ...callbacks, debounceProgressMs },
                plugins.includes('upload-v2')
            );
        }
    };
}
