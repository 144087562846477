import { Asset } from '@he-novation/config/types/asset.types';
import { ContentFile } from '@he-novation/config/types/file.types';
import { SubtitleGeneratePayload } from '@he-novation/config/types/subtitle.types';
import { __ } from '@he-novation/design-system/utils/i18n';
import {
    asyncFileEncode,
    asyncFileRestore,
    download,
    fetchSubtitles,
    fileUpdate
} from '@he-novation/front-shared/async/file.async';
import { file } from 'jszip';
import { asyncSubtitlesGenerate } from '../../../async/asyncSubtitles';

import { WS_PREFIX_IN } from '$constants/webSocket.constants';
import { asyncActionSuccess } from '$helpers/asyncAction';
import { openFeedbackModal } from '$redux/route/routeActions';
import { openToast } from '$redux/ui/uiActions';

export const FILE = 'FILE';
export const FILE_ENCODE = 'FILE/ENCODE';
export const FILE_RESTORE = `${FILE}/RESTORE`;
export const HIGHLIGHT_VERSION = `${FILE}/HIGHLIGHT_VERSION`;
export const IFRAME_CAPTURE_SET = `${FILE}/IFRAME_CAPTURE_SET`;
export const PLAY = `${FILE}/PLAY`;
export const PLAYLIST_FILTERS_SET = `${FILE}/PLAYLIST_FILTERS_SET`;
export const PAUSE = `${FILE}/PAUSE`;

export const FILE_SET_ACTIVE_ASSET = `${FILE}/SET_ACTIVE_ASSET`;
export const SET_ACTIVE_AUDIO_TRACK = `${FILE}/SET_ACTIVE_AUDIO_TRACK`;
export const SET_ACTIVE_SUBTITLES = `${FILE}/SET_ACTIVE_SUBTITLES`;
export const SET_FILE_VOTE = `${FILE}/SET_VOTE`;
export const SET_FINAL = `${FILE}/SET_FINAL`;
export const SET_PAGE = `${FILE}/SET_PAGE`;
export const SET_WIDTH_HEIGHT = `${FILE}/SET_WIDTH_HEIGHT`;
export const SUBTITLES_FETCH = `${FILE}/SUBTITLES_FETCH`;
export const SUBTITLES_GENERATE = `${FILE}/SUBTITLES_GENERATE`;
export const SUBTITLES_SET_TIMESTAMP = `${FILE}/SUBTITLES_SET_TIMESTAMP`;
export const SUBTITLES_TOGGLE = `${FILE}/SUBTITLES_TOGGLE`;
export const TOGGLE_PLAYLIST = `${FILE}/TOGGLE_PLAYLIST`;
export const VOTE_FINAL = `${FILE}/VOTE_FINAL`;

export const WS_FILE_UPDATE = `${WS_PREFIX_IN}/${FILE}/UPDATE`;
export const WS_SUBTITLES_CREATE = `${WS_PREFIX_IN}/${FILE}/SUBTITLES_CREATE`;
export const WS_SUBTITLES_UPDATE = `${WS_PREFIX_IN}/${FILE}/SUBTITLES_UPDATE`;
export const WS_SUBTITLES_READY = `${WS_PREFIX_IN}/${FILE}/SUBTITLES_READY`;

export const fileRestore = (fileUuid: string, fileVersion: number) => async (dispatch) => {
    dispatch({ type: FILE_RESTORE });
    try {
        await asyncFileRestore(fileUuid, fileVersion);
        dispatch({ type: asyncActionSuccess(FILE_RESTORE), fileUuid });
    } catch (e) {
        if (e?.message) {
            dispatch(openFeedbackModal(__(e?.message)));
        }
    }
};

export const iframeCaptureSet = (iframeCapture) => ({
    type: IFRAME_CAPTURE_SET,
    iframeCapture
});

export const setFinal =
    (uuid: string, version: number, final: boolean, success?: () => void) => async (dispatch) => {
        dispatch({ type: SET_FINAL });
        await fileUpdate(uuid, version, { final });
        dispatch({ type: asyncActionSuccess(SET_FINAL), uuid, final });
        if (typeof success === 'function') success();
    };

export const togglePlaylist = (isPlayingPlaylist: boolean) => ({
    type: TOGGLE_PLAYLIST,
    isPlayingPlaylist
});

export const setPage = (page: number) => ({
    type: SET_PAGE,
    page
});

export const highlightVersion = (highlightedVersion) => ({
    type: HIGHLIGHT_VERSION,
    highlightedVersion
});

export const setFileVote = ({ file }: { file: Partial<ContentFile> }) => ({
    type: SET_FILE_VOTE,
    file
});

export const fileFolderSocketActions = {
    sioFileSet: setFileVote,
    sioFileUpdate: (data) => ({
        type: WS_FILE_UPDATE
    })
};

export const play = () => ({
    type: PLAY
});

export const playslistFiltersSet = (playlistFilters) => ({
    type: PLAYLIST_FILTERS_SET,
    playlistFilters
});

export const pause = () => ({
    type: PAUSE
});

export const setActiveAudioTrack = (activeAudioTrack) => ({
    type: SET_ACTIVE_AUDIO_TRACK,
    activeAudioTrack
});

export const setActiveSubtitles = (activeSubtitles) => ({
    type: SET_ACTIVE_SUBTITLES,
    activeSubtitles
});

export const subtitlesFetch =
    (fileUuid: string, fileVersion: number, password?: string, cb?: (subtitles: any) => void) =>
    async (dispatch) => {
        dispatch({ type: SUBTITLES_FETCH });
        const subtitles = await fetchSubtitles(fileUuid, fileVersion, password);
        dispatch({ type: asyncActionSuccess(SUBTITLES_FETCH), subtitles });
        cb?.(subtitles);
    };

export const setSubtitlesTimeStamp = () => ({
    type: SUBTITLES_SET_TIMESTAMP,
    subtitlesTimeStamp: new Date().getTime()
});

export const setWidthHeight = (width: number, height: number) => ({
    type: SET_WIDTH_HEIGHT,
    width,
    height
});

export const toggleSubtitles = (uuid) => ({
    type: SUBTITLES_TOGGLE,
    uuid
});

export const subtitlesGenerate =
    (data: SubtitleGeneratePayload, cb?: (subtitles: any) => void) => async (dispatch) => {
        dispatch({ type: SUBTITLES_GENERATE });
        const subtitles = await asyncSubtitlesGenerate(data);
        dispatch({ type: asyncActionSuccess(SUBTITLES_GENERATE), subtitles });
        cb?.(subtitles);
    };

export const subtitlesSocketActions = {
    sioSubtitlesCreate: (data) => ({
        type: WS_SUBTITLES_CREATE,
        ...data
    }),
    sioSubtitlesUpdate: (data) => ({
        type: WS_SUBTITLES_UPDATE,
        ...data
    }),
    sioSubtitlesReady: (data) => (dispatch) => {
        dispatch(
            openToast({
                content: __('New subtitles available') + '\n' + data.subtitle.name,
                button: {
                    text: __('REACT_ACTIVATE'),
                    onClick: () => dispatch(toggleSubtitles(data.subtitle.uuid))
                },
                autoCloseAfterMs: 6_000
            })
        );
        dispatch({
            type: WS_SUBTITLES_READY,
            ...data
        });
    },
    sioBurnSubtitlesReady:
        (data: {
            fileName: string;
            fileUuid: string;
            fileVersion: number;
            subtitleUuid: string;
            exportAsset: Asset;
        }) =>
        (dispatch) => {
            dispatch(
                openToast({
                    content: __('Burnt subtitles file available \n' + data.fileName),
                    button: {
                        text: __('DOWNLOAD'),
                        onClick: () => download(data.exportAsset.url!)
                    },
                    autoCloseAfterMs: 6_000
                })
            );
        }
};

export const fileEncode = (uuid: string, version: number) => (dispatch) => {
    dispatch({ type: FILE_ENCODE, uuid });
    asyncFileEncode(uuid, version);
};

export function setActiveAsset(asset: Asset) {
    return {
        type: FILE_SET_ACTIVE_ASSET,
        asset
    };
}
