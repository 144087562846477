import React, { useEffect, useRef } from 'react';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { __ } from '@he-novation/design-system/utils/i18n';

export const SubMenu = ({ close, closeMenu, toggled }) => {
    const ref = useRef();

    useEffect(() => {
        ref.current.addEventListener('click', (e) => {
            if (e.target.tagName === 'A') {
                close();
            }
        });
    }, []);

    return (
        <div id="c-sub-menu" className={toggled ? 'is-toggled' : undefined}>
            <div id="c-sub-menu-content" ref={ref} />
            <Button tone={ButtonTone.Hoverable} onClick={closeMenu}>
                {__('DISPLAY_MAIN_MENU')}
            </Button>
        </div>
    );
};
