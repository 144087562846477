import './Filters.scss';
import React from 'react';
import { GenericForm } from '@he-novation/design-system/components/form/StyledFormField/StyledFormField';
import FiltersCheckboxList from './FiltersCheckboxList';
import FiltersDate from './FiltersDate';
import FiltersRadioList from './FiltersRadioList';
import { __ } from '@he-novation/design-system/utils/i18n';

const Filters = ({
    id,
    checkboxLists = [],
    radioLists = [],
    dates = [],
    setCheckboxFilter,
    setRadioFilter,
    setDateFilter,
    filters,
    filtersLength,
    wording,
    order = ['checkboxLists', 'radioLists', 'dates'],
    locale,
    seeAllList,
    resetFilters
}) => (
    <GenericForm id={id} className="c-filters">
        {order &&
            order.length &&
            order.map((el) => {
                if (el === 'checkboxLists')
                    return checkboxLists.map(({ title, hasSearch, name, list, Component }, i) => (
                        <FiltersCheckboxList
                            key={`checkbox-list-${i}`}
                            formId={id}
                            filterName={name}
                            title={title}
                            hasSearch={hasSearch}
                            list={list}
                            filters={filters}
                            Component={Component}
                            seeAllList={seeAllList}
                            onChange={setCheckboxFilter}
                            wording={wording}
                        />
                    ));
                if (el === 'radioLists')
                    return radioLists.map(
                        (
                            { title, hasSearch, name, list, Component, onlyChecked, setAllInput },
                            i
                        ) => (
                            <FiltersRadioList
                                key={`radio-list-${i}`}
                                formId={id}
                                filterName={name}
                                title={title}
                                seeAllList={seeAllList}
                                onlyChecked={onlyChecked}
                                hasSearch={hasSearch}
                                list={list}
                                filters={filters}
                                Component={Component}
                                onChange={setRadioFilter}
                                wording={wording}
                                setAllInput={setAllInput}
                            />
                        )
                    );
                if (el === 'dates')
                    return (
                        <>
                            <hr />
                            {dates.map(({ title, label, name }, i) => (
                                <FiltersDate
                                    key={`date-${i}`}
                                    formId={id}
                                    title={title}
                                    label={label}
                                    filterName={name}
                                    filters={filters}
                                    locale={locale}
                                    onChange={setDateFilter}
                                />
                            ))}
                        </>
                    );
            })}

        {filtersLength > 0 && (
            <button type="button" className="filters-reset" onClick={resetFilters}>
                {__('filtersReset')}
            </button>
        )}
    </GenericForm>
);
/*
Filters.propTypes = {
    id: PropTypes.string,
    locale: PropTypes.string,
    order: PropTypes.array,
    checkboxLists: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            name: PropTypes.string,
            list: PropTypes.array
        })
    ),
    radioLists: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            name: PropTypes.string,
            list: PropTypes.array
        })
    ),
    dates: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            name: PropTypes.string,
            label: PropTypes.string
        })
    ),
    setCheckboxFilter: PropTypes.func,
    setRadioFilter: PropTypes.func,
    setDateFilter: PropTypes.func,
    resetFilters: PropTypes.func,
    filters: PropTypes.object,
    seeAllList: PropTypes.bool,
    filtersLength: PropTypes.number,
    wording: PropTypes.shape({
        filterListLess: PropTypes.string,
        filterListMore: PropTypes.string,
        filtersAll: PropTypes.string,
        filtersReset: PropTypes.string
    })
};*/

export default Filters;
