import { compose, pipe, property, pick } from 'lodash/fp';
import { CONFIG } from './configActions';

export const stateSelector = (obj) => property(CONFIG)(obj);

export const isSmallScreenSelector = compose(
    ({ isSmallScreen }) => ({ isSmallScreen }),
    stateSelector
);

export const userAgentSelector = compose(({ agent }) => ({ userAgent: agent }), stateSelector);

export const localeSelector = compose(({ locale }) => ({ locale }), stateSelector);

export const isFullscreenSelector = pipe(stateSelector, pick('isFullscreen'));

export const originSelector = pipe(stateSelector, pick('origin'));

export const sizeSelector = pipe(stateSelector, ({ width, height }) => ({
    size: [width, height]
}));

export const activeBrandingSelector = pipe(stateSelector, pick('activeBranding'));

export const isTeamsAppSelector = pipe(stateSelector, pick('isTeamsApp'));
