import './TaskRemaining.scss';
import React from 'react';
import { taskStatusToClass } from '@he-novation/config/constants/projects.constants';
import Icon from '@he-novation/icons';
import { __ } from '@he-novation/design-system/utils/i18n';
import cn from 'classnames';
import { TaskStatus } from '@he-novation/config/types/task.types';

const TaskRemaining = ({ task }) =>
    task.estimate_end_date ? (
        <div className={cn('c-task-remaining', taskStatusToClass(task.status))}>
            <Icon icon={task.status === TaskStatus.DONE ? 'task-done' : 'task-date'} />
            <span>
                {__('DATES_DAYS_REMAINING', {
                    days: Math.max(
                        0,
                        Math.round(
                            ((task.estimate_end_date instanceof Date
                                ? task.estimate_end_date
                                : new Date(task.estimate_end_date)
                            ).getTime() -
                                new Date().getTime()) /
                                1000 /
                                60 /
                                60 /
                                24
                        )
                    )
                })}
            </span>
        </div>
    ) : null;

export default TaskRemaining;
