export const AssetStatus = {
    PENDING: 'PENDING',
    READY: 'READY',
    DELETED: 'DELETED',
    PURGED: 'PURGED',
    ERROR: 'ERROR'
} as const;
export type AssetStatus = (typeof AssetStatus)[keyof typeof AssetStatus];
export const FileStatus = {
    default: 'default',
    retake: 'retake',
    in_progress: 'in_progress',
    to_validate: 'to_validate',
    final: 'final'
} as const;
export type FileStatus = (typeof FileStatus)[keyof typeof FileStatus];
export const SubtitleType = {
    UPLOADED: 'UPLOADED',
    MANUAL: 'MANUAL',
    SPOTL: 'SPOTL',
    SPOTL_VERIFIED: 'SPOTL_VERIFIED'
} as const;
export type SubtitleType = (typeof SubtitleType)[keyof typeof SubtitleType];
export const TranscoderStatus = {
    MOUNTING: 'MOUNTING',
    READY: 'READY',
    ERROR: 'ERROR',
    REFUSING_JOBS: 'REFUSING_JOBS',
    DOWN: 'DOWN'
} as const;
export type TranscoderStatus = (typeof TranscoderStatus)[keyof typeof TranscoderStatus];
export const TranscoderTaskStatus = {
    PENDING: 'PENDING',
    RUNNING: 'RUNNING',
    FINISHED: 'FINISHED',
    ERROR: 'ERROR'
} as const;
export type TranscoderTaskStatus = (typeof TranscoderTaskStatus)[keyof typeof TranscoderTaskStatus];
export const TranscoderTaskPresetStatus = {
    PENDING: 'PENDING',
    RUNNING: 'RUNNING',
    FINISHED: 'FINISHED',
    ERROR: 'ERROR',
    SKIPPED: 'SKIPPED'
} as const;
export type TranscoderTaskPresetStatus =
    (typeof TranscoderTaskPresetStatus)[keyof typeof TranscoderTaskPresetStatus];
export const WeekDay = {
    MONDAY: 'MONDAY',
    TUESDAY: 'TUESDAY',
    WEDNESDAY: 'WEDNESDAY',
    THURSDAY: 'THURSDAY',
    FRIDAY: 'FRIDAY',
    SATURDAY: 'SATURDAY',
    SUNDAY: 'SUNDAY'
} as const;
export type WeekDay = (typeof WeekDay)[keyof typeof WeekDay];
export const triggers_handler = {
    upload_before: 'upload_before',
    upload_after: 'upload_after',
    encode: 'encode'
} as const;
export type triggers_handler = (typeof triggers_handler)[keyof typeof triggers_handler];
export const presets_type = {
    archive: 'archive',
    video: 'video',
    image: 'image',
    audio: 'audio'
} as const;
export type presets_type = (typeof presets_type)[keyof typeof presets_type];
export const triggers_name = {
    bundles: 'bundles',
    metadata: 'metadata',
    thumbnail: 'thumbnail',
    player: 'player',
    preview: 'preview'
} as const;
export type triggers_name = (typeof triggers_name)[keyof typeof triggers_name];
export const teams_status = {
    default: 'default',
    hidden: 'hidden'
} as const;
export type teams_status = (typeof teams_status)[keyof typeof teams_status];
export const projects_status = {
    IN_PROGRESS: 'IN_PROGRESS',
    ARCHIVED: 'ARCHIVED'
} as const;
export type projects_status = (typeof projects_status)[keyof typeof projects_status];
export const TaskStatus = {
    TO_DO: 'TO_DO',
    IN_PROGRESS: 'IN_PROGRESS',
    TO_VALIDATE: 'TO_VALIDATE',
    DONE: 'DONE',
    DELETE: 'DELETE'
} as const;
export type TaskStatus = (typeof TaskStatus)[keyof typeof TaskStatus];
export const jobs_status = {
    queued: 'queued',
    started: 'started',
    canceled: 'canceled',
    success: 'success',
    error: 'error'
} as const;
export type jobs_status = (typeof jobs_status)[keyof typeof jobs_status];
export const SpotlStatus = {
    processing: 'processing',
    done: 'done'
} as const;
export type SpotlStatus = (typeof SpotlStatus)[keyof typeof SpotlStatus];
