import { __ } from '@he-novation/design-system/utils/i18n';

export const getContactGroupContextMenu = ({ editGroup, deleteGroup, ...props }) => {
    return [
        {
            children: __('RCC_CONTACT_EDIT'),
            onClick: () =>
                props.item.editGroup({
                    uuid: props.item.uuid,
                    name: props.item.name
                })
        },
        {
            children: __('RCC_CONTACT_DELETE'),
            onClick: () => props.item.deleteGroup(props.item.uuid)
        }
    ];
};
