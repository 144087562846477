import {
    AssetStatusBody,
    AssetUploadAbortBody,
    AssetUploadCommandParam,
    AssetUploadCompleteBody,
    AssetUuidSchema
} from '@he-novation/config/types/payloads/asset.payload';
import { assetPaths, pathWithParams } from '@he-novation/paths/herawApiPaths';
import { apiFetch } from './apiFetch';

export const asyncAssetFetch = (assetUuid: string) =>
    apiFetch(pathWithParams(assetPaths.single), {
        params: { assetUuid },
        method: 'GET'
    });

export const asyncCompleteMultipartUpload = (assetUuid: string, body: AssetUploadCompleteBody) =>
    apiFetch(assetPaths.upload, {
        method: 'PATCH',
        params: { assetUuid } satisfies AssetUploadCommandParam,
        body
    });
export const asyncAbortMultipartUpload = (assetUuid: string, body: AssetUploadAbortBody) =>
    apiFetch(assetPaths.upload, {
        method: 'DELETE',
        params: { assetUuid } satisfies AssetUploadCommandParam,
        body
    });

export const asyncAssetStatusUpdate = (assetUuid: string, body: AssetStatusBody) =>
    apiFetch(assetPaths.status, {
        method: 'PUT',
        params: { assetUuid } satisfies AssetUuidSchema,
        body
    });

export async function getSignedUrl(assetUuid: string): Promise<string> {
    const { url } = await apiFetch(assetPaths.sign, {
        params: { assetUuid },
        method: 'GET'
    });
    return url;
}
