import pipe from 'lodash/fp/pipe';
import __ from './i18n';
const pad = (num: number) => ('00' + num).slice(-2);

export function isoStringToDate(isoString: string) {
    const b = isoString.split(/\D+/).map((n) => parseInt(n));
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
}

export function isoStringToUTCDate(isoString: string) {
    const b = isoString.split(/\D+/).map((n) => parseInt(n));
    return new Date(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]);
}

export function objectIsoStringsToDates<T>(keys: (keyof T)[]): (obj: T) => T {
    return (obj: T) => {
        for (const key of keys) {
            if (typeof obj[key] === 'string')
                obj[key as keyof T] = isoStringToDate(obj[key as keyof T] as string) as any;
        }
        return obj;
    };
}
export const dateToSqlDatetime = (date: Date) =>
    date.getUTCFullYear() +
    '-' +
    pad(date.getUTCMonth() + 1) +
    '-' +
    pad(date.getUTCDate()) +
    ' ' +
    pad(date.getUTCHours()) +
    ':' +
    pad(date.getUTCMinutes()) +
    ':' +
    pad(date.getUTCSeconds());

export const roundToMinutes = (
    date: Date | string | number,
    minutes = 15,
    method: 'round' | 'floor' | 'ceil' = 'round'
) => {
    date = new Date(date);
    date.setMinutes((Math[method](date.getMinutes() / minutes) * minutes) % 60);
    date.setSeconds(0);
    return date;
};

export const addDays = (date: Date | string | number, days: number) => {
    date = new Date(date);
    date.setDate(date.getDate() + days);
    return date;
};

export const subDays = (date: Date | string | number, days: number) => {
    date = new Date(date);
    date.setDate(date.getDate() - days);
    return date;
};

export const msToSeconds = (ms: number) => ms / 1000;
export const msToMinutes = pipe(msToSeconds, (s: number) => s / 60);
export const msToHours = pipe(msToMinutes, (s: number) => s / 60);
export const msToDays = pipe(msToHours, (s: number) => s / 24);

export const secondsToMs = (s: number) => s * 1000;
export const minutesToMs = pipe((m: number) => m * 60, secondsToMs);
export const hoursToMs = pipe((h: number) => h * 60, minutesToMs);
export const daysToMs = pipe((d: number) => d * 24, hoursToMs);
export const isSameDay = (d1: Date, d2: Date): boolean =>
    d1?.getFullYear() === d2?.getFullYear() &&
    d1?.getMonth() === d2?.getMonth() &&
    d1?.getDate() === d2?.getDate();

export function secondsToRemainingTime(seconds: number) {
    if (seconds < 7200) {
        return __('LESS_THAN_N_MINUTES', { n: Math.ceil(seconds / 60) });
    }
    return __('LESS_THAN_N_HOURS', {
        n: Math.ceil(seconds / 3600)
    });
}

export const setLatestHour = (_date: Date | string, utc?: boolean) => {
    const date = new Date(_date);
    date[utc ? 'setUTCHours' : 'setHours'](23, 59, 59, 999);
    return date;
};

export const dateAsUTCDate = (date: Date) => {
    const UTCDate = new Date(date);
    UTCDate.setUTCDate(date.getDate());
    UTCDate.setUTCFullYear(date.getFullYear());
    UTCDate.setUTCMonth(date.getMonth());
    UTCDate.setUTCHours(date.getHours());
    UTCDate.setUTCMinutes(date.getMinutes());

    return UTCDate;
};

export const offsetDays = (date: Date | number, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

export const offsetMonths = (date: Date | number, months: number) => {
    const result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
};
