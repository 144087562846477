import './SidePanelNote.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileEnum } from '@he-novation/config/types/file.types';
import { type Comment, Note } from '@he-novation/config/types/note.types';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { __ } from '@he-novation/design-system/utils/i18n';
import { DELETE_CONFIRM } from '@he-novation/paths/modals.constants';
import { useAtom, useAtomValue } from 'jotai';
import { audioAtom } from '../../../atoms/file-atoms/audio-atom';
import { videoAtom } from '../../../atoms/file-atoms/video-atom';
import { selectedNoteAtom } from '../../../atoms/note-atoms';
import { useAudioControls } from '../../../hooks/useAudioControls';
import { useVideoControls } from '../../../hooks/useVideoControls';
import FormCommentOrTask from '../../form/FormComment/FormCommentOrTask';
import NoteCommentList from '../../Notes/NoteCommentList/NoteCommentList';
import NoteOrTaskContent from '../../Notes/NoteOrTaskContent/NoteOrTaskContent';
import SidePanelNoteHeader from './SidePanelNoteHeader/SidePanelNoteHeader';

import { SIDE_PANEL_ANIMATION_DURATION } from '$components/SidePanel/SidePanel';
import { NOTES_PANEL } from '$constants/constants.sidePanels';
import { isEqualDate } from '$helpers/datetime';
import { deleteNote, noteSocketActions } from '$redux/content/note/noteActions';
import { notesSelector } from '$redux/content/note/noteSelectors';
import { closeModal, openModal, setFilter } from '$redux/route/routeActions';
import { openPanel } from '$redux/sideMenu/sideMenuActions';

const TYPE_FORM_NOTE = {
    COMMENT: 'comment',
    TASK: 'task'
};

type SidePanelNoteProps = {
    selectedNote: Note;
    locale: string;
    currentUserUuid: string;
    fileThumbnail: string;
    goBackToNotes: () => void;
    setActiveAudioTrack: (track: string) => void;
    page: number;
    setPage: (page: number) => void;
    setActiveSubtitles: (subtitles: string) => void;
    fileType: string;
    setIframeCapture: (detailAsset: any) => void;
    socketIoSubscribe: (options: any) => void;
    areaSelectionDisplay: (areas: any) => void;
    areaSelectionReset: () => void;
    setSelectedNote: (note: Note | null) => void;
    isProjectManager: boolean;
    currentProjectUuid: string;
    payload?: {
        isPublicFile?: boolean;
        password?: string;
    };
    deleteNoteAndTask: (uuid: string) => void;
    openEditTaskModal: (task: any) => void;
};

const formatComments = (
    comments: (Omit<Comment, 'note' | 'file'> & {
        isSameDateAsPrevious?: boolean;
        displayAvatar?: boolean;
    })[]
) => {
    if (!comments.length) return comments;
    let previousDate = comments[0].created;

    return comments.map((comment, i) => {
        comment.isSameDateAsPrevious = i !== 0 && isEqualDate(previousDate, comment.created);
        const next = comments[i + 1];
        const user = comment.user || { email: comment.postedAs || '-' };
        const nextUser = (next && next.user) || { email: next?.postedAs || '-' };
        const isSameDateAsNext =
            comments[i + 1] && isEqualDate(comments[i + 1].created, comment.created);
        const isSameUserAsNext = comments[i + 1] && nextUser?.email === user.email;
        comment.displayAvatar =
            ((comment.user || { email: comment.postedAs || '-' }) && !isSameUserAsNext) ||
            !isSameDateAsNext;
        previousDate = comment.created;
        return comment;
    });
};

export default function SidePanelNote({
    fileThumbnail,
    payload,
    currentUserUuid,
    openEditTaskModal,
    locale,
    setActiveAudioTrack,
    setPage,
    setActiveSubtitles,
    setIframeCapture,
    socketIoSubscribe,
    fileType,
    areaSelectionDisplay,
    areaSelectionReset,
    page,
    isProjectManager,
    currentProjectUuid
}: SidePanelNoteProps) {
    const [activeForm, setActiveForm] = useState<null | string>(null);

    const dispatch = useDispatch();
    const [selectedNote, setSelectedNote] = useAtom(selectedNoteAtom);
    const videoState = useAtomValue(videoAtom);
    const videoControls = useVideoControls();
    const audioState = useAtomValue(audioAtom);
    const audioControls = useAudioControls();
    const comments = formatComments(selectedNote?.comments || []);

    const comment = comments[comments.length - 1];

    const { notes } = useSelector(notesSelector);
    useEffect(() => {
        if (selectedNote) {
            const note = notes.find((note) => note.uuid === selectedNote.uuid);
            if (selectedNote.comments.length != note?.comments.length) {
                setSelectedNote(note);
            }
        }
    }, [notes, selectedNote, setSelectedNote]);

    const goBackToNotes = useCallback(() => {
        setSelectedNote(null);
        dispatch(openPanel(NOTES_PANEL, { isPublicFile: payload?.isPublicFile }));
        dispatch(setFilter('selected_note', null));
        if (fileType === FileEnum.Audio && audioState.selectedRange) {
            audioControls.setSelectedRange(null);
        }
    }, [audioState]);

    const renderAreaSelection = useCallback(() => {
        if (
            selectedNote?.metadata.rectangle &&
            (!selectedNote.metadata.tcIn ||
                (fileType === FileEnum.Video &&
                    selectedNote.metadata.tcIn?.replace(/;:,/g, ':') ===
                        (videoState.timecodeWithTimecodeStart as string).replace(/;:,/g, ':')))
        ) {
            setTimeout(() => {
                areaSelectionDisplay([
                    {
                        rectangle: selectedNote.metadata.rectangle,
                        circle: selectedNote.metadata.circle,
                        freehand: selectedNote.metadata.freehand
                    }
                ]);
            }, SIDE_PANEL_ANIMATION_DURATION);
        }
    }, [selectedNote, videoState.timecodeWithTimecodeStart]);

    useEffect(() => {
        if (selectedNote?.metadata) {
            if (typeof selectedNote.metadata.activeAudioTrack !== 'undefined') {
                setActiveAudioTrack(selectedNote.metadata.activeAudioTrack);
            }

            if (selectedNote.metadata.tcIn) {
                videoControls.pause();
                if (selectedNote.metadata.tcIn) {
                    videoControls.setTimecode(selectedNote.metadata.tcIn);
                }
            }

            if (selectedNote.metadata.page && page !== selectedNote.metadata.page) {
                setPage(selectedNote.metadata.page);
            }

            if (selectedNote.metadata.activeSubtitles) {
                setActiveSubtitles(selectedNote.metadata.activeSubtitles);
            }

            const thumbnail = selectedNote.assets.find(
                (asset) => asset.type === 'thumbnail' && asset.url
            );
            const detailAsset = selectedNote.assets.find(
                (asset) => asset.type === 'detail' && asset.url
            );
            if (fileType === 'html' && thumbnail) {
                setIframeCapture(detailAsset);
            }
        }
        if (selectedNote?.team) {
            socketIoSubscribe({
                room: selectedNote.team.uuid,
                actions: noteSocketActions,
                name: 'note'
            });
        }
        renderAreaSelection();

        if (
            fileType === FileEnum.Audio &&
            selectedNote?.metadata.tcIn &&
            selectedNote.metadata.tcOut
        ) {
            audioControls.setTimecodeRange([
                selectedNote.metadata.tcIn,
                selectedNote.metadata.tcOut
            ]);
        }

        return () => {
            //@todo this breaks note panel opening in strict mode
            if (fileType === FileEnum.Audio && audioState.selectedRange) {
                audioControls.setSelectedRange(null);
            }
            areaSelectionReset();
        };
    }, [selectedNote?.uuid]);

    useEffect(() => {
        return () => {
            setSelectedNote(null);
            dispatch(setFilter('selected_note', null));
        };
    }, []);

    useEffect(() => {
        if (!comments.length) {
            goBackToNotes();
        }
    }, [comments]);

    useEffect(() => {
        areaSelectionReset();
        renderAreaSelection();
    }, [
        selectedNote?.uuid,
        videoState.timecodeWithTimecodeStart,
        audioState.currentTime,
        selectedNote?.metadata.page === page
    ]);

    if (!selectedNote || !comment) return null;
    return (
        <div className="c-side-panel-note-wrapper">
            <div
                className={`c-side-panel-note ${selectedNote.task ? 'has-task' : ''} ${
                    activeForm ? 'form-is-active' : ''
                } ${activeForm !== TYPE_FORM_NOTE.COMMENT ? 'c-task-form' : ''}`}
            >
                <SidePanelNoteHeader
                    selectedNote={selectedNote}
                    fileThumbnail={fileThumbnail}
                    goBackToNotes={goBackToNotes}
                />
                {selectedNote.task && (
                    <NoteOrTaskContent
                        actions={
                            isProjectManager || selectedNote.user?.uuid === currentUserUuid
                                ? [
                                      {
                                          children: __('EDIT_TASK'),
                                          onClick: () => openEditTaskModal(selectedNote.task)
                                      },
                                      {
                                          children: __('DELETE_TASK'),
                                          onClick: () =>
                                              dispatch(
                                                  openModal(
                                                      DELETE_CONFIRM,
                                                      {
                                                          onDelete: () => {
                                                              goBackToNotes();
                                                              dispatch(
                                                                  deleteNote(selectedNote.uuid)
                                                              );
                                                              dispatch(closeModal());
                                                          }
                                                      },
                                                      null,
                                                      true
                                                  )
                                              )
                                      }
                                  ]
                                : null
                        }
                        attachments={comment.assets}
                        user={comment.user}
                        description={comment.content}
                        projectUuid={currentProjectUuid}
                        tags={comment.tags}
                        task={selectedNote.task}
                        taskStatusPicker={true}
                    />
                )}

                <NoteCommentList
                    comments={selectedNote.task ? comments.slice(0, -1) : comments}
                    currentUserUuid={currentUserUuid}
                    locale={locale}
                />
            </div>
            {activeForm ? (
                <FormCommentOrTask
                    isTask={false}
                    close={() => setActiveForm(null)}
                    isPublicFile={payload?.isPublicFile}
                    password={payload?.password}
                />
            ) : (
                <Button
                    tone={ButtonTone.Primary}
                    icon="reply"
                    className="c-button-annotate"
                    onClick={() => setActiveForm(TYPE_FORM_NOTE.COMMENT)}
                >
                    {__('COMMENT')}
                </Button>
            )}
        </div>
    );
}
