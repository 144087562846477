import React, { ReactNode } from 'react';
import pick from 'lodash/fp/pick';

const matchSearchWithKey = (key: string, search: string) =>
    search
        ? key.replace(new RegExp(search, 'gi'), '<span class="highlighted-content">$&</span>')
        : key;

export const withHighLight = (key: string, search?: string | null) =>
    key ? (
        search ? (
            <span dangerouslySetInnerHTML={{ __html: matchSearchWithKey(key, search) }} />
        ) : (
            key
        )
    ) : undefined;

export type GetHighlightedPropsProps = { [k: string]: string | undefined };

export type GetHighlightedPropsOptions = {
    searchKey?: string;
    renameSuffix?: string;
    truncate?: number;
    path?: string;
};

type GetOrGetterHighlightedPropsOptions =
    | GetHighlightedPropsOptions
    | ((props: any) => GetHighlightedPropsOptions | undefined);

export const getHighlightedProps = (
    search = '',
    props: GetHighlightedPropsProps,
    options?: GetOrGetterHighlightedPropsOptions
): { [k: string]: string } => {
    const { renameSuffix = 'Highlighted', truncate } =
        (typeof options === 'function' ? options(props) : options) || {};

    return Object.keys(props).reduce((acc: any, key) => {
        let v = props[key];
        acc[key] = props[key];
        if (typeof v === 'undefined') return acc;
        if (truncate && v.length > truncate) {
            const half = Math.ceil(truncate / 2);
            v = v.substr(0, half) + '...' + v.substr(v.length - (truncate - half));
        }
        acc[`${key}${renameSuffix}`] = search ? withHighLight(v, search) : v;
        acc[key] = props[key];
        return acc;
    }, {});
};

export function getHighlightObject<T extends Record<string, unknown>>(obj: T, search?: string) {
    if (!search) return obj;
    return Object.keys(obj).reduce((acc: Record<string, ReactNode>, key) => {
        if (obj[key]) {
            acc[key] = withHighLight(obj[key] as string, search) as ReactNode;
        }
        return acc;
    }, {});
}

const propsToHighlightedSearchNode =
    (propsToHighlight: string[], options?: GetOrGetterHighlightedPropsOptions) =>
    (Component: any) =>
        function PropsToHighlightedSearchNode(props: any) {
            const o = (typeof options === 'function' ? options(props) : options) || {};
            const newProps = getHighlightedProps(
                props[o.searchKey || 'search'],
                pick(propsToHighlight)(o.path ? props[o.path] : props),
                options
            );
            return <Component {...{ ...props, ...newProps }} />;
        };

export default propsToHighlightedSearchNode;
