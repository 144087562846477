import { apiFetch } from '@he-novation/front-shared/async/apiFetch';

export const fetchPage = ({
    page = 0,
    count = 30,
    order = 'desc',
    collapse = undefined,
    log = {},
    filters,
    hidden = false
}) =>
    apiFetch('/es/logs/list/page', {
        method: 'POST',
        body: JSON.stringify({
            hidden,
            log,
            collapse,
            pagination: {
                count,
                offset: page * count,
                order
            },
            filters
        })
    }).then((r) =>
        r?.hits?.hits
            ? r.hits.hits
                  .map(({ _source, _id }) => ({
                      ..._source,
                      id: _id,
                      created: new Date(_source.created)
                  }))
                  .sort((a, b) => b.created - a.created)
            : []
    );
