import styles from './UploadTrackerList.module.css';
import React, { SyntheticEvent } from 'react';
import { ProgressCircle } from '@he-novation/design-system/components/widgets/ProgressCircle/ProgressCircle';
import { __ } from '@he-novation/design-system/utils/i18n';
import { folderLink } from '@he-novation/paths/herawFrontUris';
import { bitsToSize, bytesToSize } from '@he-novation/utils/bytes';
import { secondsToRemainingTime } from '@he-novation/utils/datetime';
import cn from 'classnames';

import Link from '$components/router/Link';
import type { FinishedUpload, PendingUpload, Upload, UploadError } from '$helpers/Uploader.types';

type UploadGroup = {
    uuid: string;
    folderName: string;
    folderUuid: string;
    totalBytes: number;
    loadedBytes: number;
    finished: number;
    remainingMs: number;
    errors: UploadError[];
    status: 'progress' | 'pending' | 'finished';
    list: { name: string }[];
};

function findOrCreateGroup(
    groups: UploadGroup[],
    groupUuid: string,
    folder: { name: string; uuid: string; path?: string }
): UploadGroup {
    return (
        groups.find((g) => g.uuid === groupUuid) ||
        groups[
            groups.push({
                folderName: folder.path ? folder.path.replace(/^\/|\/.+/g, '') || '/' : folder.name,
                folderUuid: folder.uuid,
                uuid: groupUuid,
                totalBytes: 0,
                loadedBytes: 0,
                finished: 0,
                remainingMs: 0,
                status: 'pending',
                list: [],
                errors: []
            }) - 1
        ]
    );
}

type UploadTrackerListProps = {
    uploads: Upload[];
    pending: PendingUpload[];
    finished: FinishedUpload[];
    errors: UploadError[];
    onLinkClick?: (e: SyntheticEvent) => void;
};

export function UploadTrackerList({
    uploads,
    pending,
    finished,
    errors,
    onLinkClick
}: UploadTrackerListProps) {
    let groups: UploadGroup[] = [];

    let bitrateTotal = 0; //per MS
    let bitrateAccountedFor = 0;
    let remainingTimes: number[] = [];

    for (const f of finished) {
        const g = findOrCreateGroup(groups, f.uploadGroup, f.folder);

        g.totalBytes += f.file.size;
        g.loadedBytes += f.file.size;
        g.finished++;
        g.list.push({ name: f.file.name });
    }

    for (const upload of uploads) {
        const g = findOrCreateGroup(groups, upload.uploadGroup, upload.folder);

        g.status = 'progress';
        g.totalBytes += upload.file.size;
        g.loadedBytes += upload.progression.loaded;
        g.list.push({ name: upload.file.name });

        if (upload.progression.bitrate) {
            bitrateTotal += upload.progression.bitrate;
            bitrateAccountedFor++;

            if (upload.progression.remainingMs) {
                remainingTimes.push(upload.progression.remainingMs);
                if (upload.progression.remainingMs > g.remainingMs)
                    g.remainingMs = upload.progression.remainingMs;
            }
        }
    }
    remainingTimes = remainingTimes.sort((a, b) => a - b);
    const averageBitrate = bitrateAccountedFor === 0 ? 0 : bitrateTotal / bitrateAccountedFor;

    const pendingEstimatedUploadTimes: number[] = [];
    for (let i = 0; i < pending.length; i++) {
        const p = pending[i];
        const g = findOrCreateGroup(groups, p.uploadGroup, p.folder);
        if (g.status !== 'progress') g.status = 'pending';
        g.totalBytes += p.file.size;
        g.list.push({ name: p.file.name });

        const estimatedTimeBeforeUploaded =
            (remainingTimes[i]
                ? remainingTimes[i]
                : pendingEstimatedUploadTimes[pendingEstimatedUploadTimes.length - 1]) +
            p.file.size / averageBitrate;

        pendingEstimatedUploadTimes.push(estimatedTimeBeforeUploaded);
        g.remainingMs = Math.max(g.remainingMs, estimatedTimeBeforeUploaded);
    }

    for (const e of errors) {
        const g = findOrCreateGroup(groups, e.uploadGroup, e.folder);
        g.errors.push(e);
    }

    let remainingMs = 0;
    let finishedUploads = 0;
    let totalUploads = 0;
    let loadedBytes = 0;
    let totalBytes = 0;

    for (const g of groups) {
        if (g.finished === g.list.length) g.status = 'finished';
        if (g.status !== 'progress' && g.status !== 'pending') continue;
        if (g.remainingMs > remainingMs) remainingMs = g.remainingMs;
        finishedUploads += g.finished;
        totalUploads += g.list.length;
        loadedBytes += g.loadedBytes;
        totalBytes += g.totalBytes;
    }

    groups = sortGroupsByStatus(groups);

    const numberOfInProgressGroups = groups.filter((g) => g.status === 'progress').length;

    return (
        <div className={styles.wrapper}>
            {!!uploads.length && (
                <header className={styles.list}>
                    <div className={styles.item}>
                        <ProgressCircle
                            className={styles.progress}
                            total={totalBytes}
                            value={loadedBytes}
                            color="white"
                            backgroundColor="#f69908"
                            ringBackgroundColor="#fbc986"
                        />
                        <h3>
                            {__('N_UPLOADS_IN_PROGRESS', { n: numberOfInProgressGroups })}
                            {!!remainingMs && <> • {secondsToRemainingTime(remainingMs / 1000)}</>}
                        </h3>
                        <p>
                            {__('{{n}} remaining files', {
                                n: `${totalUploads - finishedUploads}`
                            })}{' '}
                            • {bytesToSize(loadedBytes)} / {bytesToSize(totalBytes)}
                            {averageBitrate &&
                                ` • ${bitsToSize(Math.round(bitrateTotal * 8 * 1000))}/s`}{' '}
                        </p>
                    </div>
                </header>
            )}

            <ul className={cn(styles.list, styles.groups)}>
                {groups.map((g) => {
                    const errors = g.errors.length;
                    const color = errors ? '#ff5050' : 'black';
                    return (
                        <li key={g.uuid} className={styles.item}>
                            <ProgressCircle
                                className={styles.progress}
                                total={g.totalBytes}
                                value={g.loadedBytes}
                                color={color}
                                backgroundColor={
                                    g.totalBytes === g.loadedBytes ? color : 'rgb(215, 215, 215)'
                                }
                                textColor={g.totalBytes === g.loadedBytes ? 'white' : color}
                                ringBackgroundColor="#c1c1c1"
                            />
                            <h3 className={errors ? styles.error : undefined}>
                                <Link href={folderLink(g.folderUuid)} onClick={onLinkClick}>
                                    {' '}
                                    {g.folderName}
                                </Link>{' '}
                                {!!g.remainingMs && (
                                    <>• {secondsToRemainingTime(g.remainingMs / 1000)}</>
                                )}
                            </h3>
                            <p>
                                {!!errors && (
                                    <>
                                        <div className={styles.error}>
                                            {__('N_ERRORS', { n: g.errors.length })}
                                        </div>{' '}
                                        •{' '}
                                    </>
                                )}
                                {__('N_FILES', { n: `${g.finished}/${g.list.length}` })} •{' '}
                                {bytesToSize(g.loadedBytes)} / {bytesToSize(g.totalBytes)}
                            </p>
                            {!!errors && (
                                <ul className={styles.errors}>
                                    {g.errors.map((e, i) => {
                                        return (
                                            <li key={i} className={styles.error}>
                                                {`${e.folder.path}/${e.file.name}`.replace(
                                                    '//',
                                                    '/'
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

function sortGroupsByStatus(groups: UploadGroup[]): UploadGroup[] {
    return groups
        .map((group, index) => ({ index, group }))
        .sort(({ group: group1, index: index1 }, { group: group2, index: index2 }) => {
            if (group1.status === group2.status) {
                return index1 - index2;
            }

            if (group1.status === 'progress') {
                return -1;
            }

            if (group2.status === 'progress') {
                return 1;
            }

            if (group1.status === 'finished') {
                return 1;
            }

            if (group2.status === 'finished') {
                return -1;
            }

            return 0;
        })
        .map(({ index: _index, group }) => group);
}
