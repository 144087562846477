//@ts-nocheck
let i18n: Record<string, string> = {};
let locale: string;

export function setI18n(_i18n: Record<string, string>, _locale: string) {
    i18n = _i18n;
    locale = _locale;
}

export const __ = (
    str: string,
    obj?: Record<string, string | number> | null,
    _i18n?: Record<string, string>
) => {
    if (!_i18n && i18n) _i18n = i18n;
    let iStr = _i18n[str];
    let oStr = iStr;

    if (iStr && obj) {
        try {
            oStr = Object.keys(obj).reduce(
                (acc, curr) => acc.replace(`{{${curr}}}`, obj[curr] as string),
                iStr
            );
        } catch (e) {
            if (typeof window === 'undefined' || !localStorage.getItem('noWordingErrors')) {
                console.log(`ERR_I18N_REPLACE: '${iStr}'`);
                console.log(obj);
            }
        }
    }

    if (!oStr) {
        if (typeof window === 'undefined' || !localStorage.getItem('noWordingErrors')) {
            console.log(`ERR_I18N_MISSING: '${str}'`);
        }

        return str;
    }

    if (/\{\{\w*}}/.test(oStr)) {
        if (typeof window === 'undefined' || !localStorage.getItem('noWordingErrors')) {
            console.log(`ERR_I18N_OBJECT: '${oStr}'`);
            if (obj) {
                console.log(obj);
            }
        }

        return str;
    }

    return oStr;
};

const localeToDateLocale = (locale: string) => {
    switch (locale) {
        case 'fr':
            return 'fr-FR';
        case 'de':
            return 'de-DE';
        default:
            return 'en-GB';
    }
};
export function __curr(amount: number, currency: string) {
    return new Intl.NumberFormat(localeToDateLocale(locale), {
        style: 'currency',
        currency: currency.toUpperCase()
    }).format(amount / 100);
}

export const getLocale = () => locale;

export default __;
