import './utils/registerExtraGenericFormTypes';
import './libs/_calendars.scss';
import './libs/_generic-form.scss';
import './_animations.scss';
import React from 'react';

import { I18nType, setI18n } from './utils/i18n';
import { Locale } from '@he-novation/config/types/enums';

export let RouterLink: React.FC<any>;
export let Uris: any;

export const init = (
    i18n: I18nType,
    locale: Locale,
    RouterLinkComponent: React.FC<any> | null,
    uris: any
) => {
    setI18n(i18n, locale);
    RouterLink =
        RouterLinkComponent || (({ children, ...props }: any) => <a {...props}>{children}</a>);
    Uris = uris;
};
