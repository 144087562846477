import './DataLayoutListItem.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import { AbsoluteContentButton } from '@he-novation/design-system/components/buttons/AbsoluteContentButton/AbsoluteContentButton';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { useAbsoluteMenu } from '@he-novation/design-system/components/widgets/AbsoluteMenu/AbsoluteMenu';
import { Theme } from '@he-novation/design-system/enums';
import { useForwardedRef } from '@he-novation/design-system/hooks/useForwardedRef';
import { getLocalizedDate } from '@he-novation/design-system/utils/datetime';
import Icon from '@he-novation/icons';
import cn from 'classnames';
import { property } from 'lodash/fp';

import {
    DataLayoutColumn,
    DataLayoutSelectionProps
} from '$components/DataLayout/DataLayout.types';
import { localeSelector } from '$redux/config/configSelectors';

type DataLayoutItemListProps<T, U extends boolean | undefined, V> = {
    item: T;
    columns: DataLayoutColumn<T, U, V>[];
    selection: U extends true ? undefined : DataLayoutSelectionProps<T>;
    ActionsMenuComponent?: React.ComponentType<any>;
    notSelectable?: U;
    extra: V;
    className: string;
};

export function DataLayoutListItem<T, U extends boolean | undefined, V>({
    item,
    columns,
    selection,
    ActionsMenuComponent,
    notSelectable,
    extra,
    className
}: DataLayoutItemListProps<T, U, V>) {
    const { locale } = useSelector(localeSelector);
    const s: Partial<DataLayoutSelectionProps<T>> = selection || {};
    const ref = useForwardedRef<HTMLUListElement>(selection?.selectableRef);
    const absoluteMenu = ActionsMenuComponent && useAbsoluteMenu('data-layout-actions-menu');
    return (
        <ul
            className={cn('data-layout-list-item', s.isSelected && 'is-selected', className)}
            role="row"
            ref={ref}
            id={s.id}
        >
            {!notSelectable && (
                <li style={{ flex: '0 0 55px' }}>
                    <FormField
                        key={`data-layout-list-item-checkbox-${s.id}`}
                        id={`data-layout-list-item-checkbox-${s.id}`}
                        formId="data-layout-list-item-checkbox"
                        className="ignore-mouseup data-layout-item-checkbox"
                        type="checkbox"
                        theme={Theme.Dark}
                        label={' '}
                        checked={s.isSelected}
                        wrapperProps={{
                            onClick: (e) => {
                                if (e.shiftKey) {
                                    s.shiftSelect?.();
                                }
                            }
                        }}
                        onChange={
                            s.toggleSelection
                                ? () => {
                                      s.toggleSelection?.(!s.isSelected);
                                  }
                                : undefined
                        }
                    />
                </li>
            )}
            {columns.map((column) => {
                const value = property(column.key)(item);
                let content: React.ReactNode;
                if (typeof column.render !== 'undefined')
                    content = column.render(item, selection, extra);
                else if (value instanceof Date) content = getLocalizedDate(value, locale);
                else if (typeof value === 'boolean')
                    content = (
                        <Button
                            className="data-layout-boolean-button"
                            tone={ButtonTone.Hoverable}
                            icon={value ? 'check' : 'cross2'}
                            disabled
                        />
                    );
                else if (typeof value === 'number') content = value !== 0 ? value : null;
                else content = value;
                return (
                    <li
                        key={column.key}
                        role="cell"
                        className={cn(
                            `data-layout-col-${column.key.replace(/\./g, '-')}`,
                            column.grow && 'left'
                        )}
                        style={{
                            width: column.width,
                            flex: `${column.grow ? 1 : 0} 0 ${
                                column.width ? `${column.width}px` : ''
                            }`
                        }}
                    >
                        <div className="cell-content">
                            {content}
                            {!!content && column.icon && <Icon icon={column.icon} />}
                        </div>
                    </li>
                );
            })}
            {ActionsMenuComponent && (
                <li className="actions-cell">
                    <AbsoluteContentButton
                        className="action-cell-inner rotate-icon-90"
                        closeOnClickOutside
                        triggersOnClick
                        triggersOnHover={false}
                        tagName={Button}
                        content={
                            <ActionsMenuComponent
                                item={item}
                                selection={selection}
                                extra={extra}
                                absoluteMenuHandler={absoluteMenu}
                            />
                        }
                        icon="three-dots"
                        tone={ButtonTone.Hoverable}
                    />
                </li>
            )}
        </ul>
    );
}
