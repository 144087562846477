import './InfiniteScroll';
import React, {ReactNode, SyntheticEvent, useState} from 'react';
import cn from 'classnames';

type InfiniteScrollProps = {
    fetchPage: Function;
    finished?: boolean;
    page: number;
    className?: string;
    children?: ReactNode | ReactNode[];
};

export const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
    children,
    className,
    fetchPage,
    finished,
    page
}) => {
    const [scrollHeight, setScrollHeight] = useState(0);
    const onScroll = (e: SyntheticEvent) => {
        const _page = page + 1;
        const threshold = ((2 * _page - 1) / (2 * _page)) * e.currentTarget.scrollHeight;
        const contentViewed = e.currentTarget.scrollTop + e.currentTarget.clientHeight;
        const isScrollHeightUpdated = e.currentTarget.scrollHeight !== scrollHeight;

        if (threshold < contentViewed && isScrollHeightUpdated) {
            const scrollHeight = e.currentTarget.scrollHeight;
            setScrollHeight(scrollHeight);
            fetchPage();
        }
    };

    return (
        <div
            onScroll={finished ? undefined : onScroll}
            className={cn('c-infinite-scroll', className)}
        >
            {children}
        </div>
    );
};

export default InfiniteScroll;
