import SidePanel from './SidePanel';
import { compose, withProps, branch, renderNothing } from 'recompose';
import { connect } from 'react-redux';
import { activeSidePanelSelector, panelPayloadSelector } from '$redux/sideMenu/sideMenuSelectors';
import { closePanel } from '$redux/sideMenu/sideMenuActions';
import sidePanels from './sidePanels';
import combineSelectors from '$helpers/combineSelectors';
import { NONE } from '$constants/constants.sidePanels';

export default compose(
    connect(combineSelectors(activeSidePanelSelector, panelPayloadSelector), (dispatch) => ({
        close: () => dispatch(closePanel())
    })),
    branch(({ activeSidePanel }) => !activeSidePanel || activeSidePanel === NONE, renderNothing),
    withProps(({ activeSidePanel }) => ({
        Component: sidePanels[activeSidePanel].Component,
        titleKey: sidePanels[activeSidePanel].titleKey,
        className: activeSidePanel
    }))
)(SidePanel);
