import { combineReducers } from 'redux';
import { ACTIVITY } from './activity/activityActions';
import activityReducer from './activity/activityReducer';
import { REDUX_AREA_SELECTION } from './areaSelection/areaSelectionActions';
import { areaSelectionReducer } from './areaSelection/areaSelectionReducer';
import { CASTS } from './casts/castsActions';
import { castsReducer } from './casts/castsReducer';
import { COMPANIES } from './companies/companiesActions';
import companiesReducer from './companies/companiesReducer';
import { CONTACTS } from './contacts/contactsActions';
import contactsReducer from './contacts/contactsReducer';
import { ITEMS } from './items/itemsActions';
import itemsReducer from './items/itemsReducer';
import { PROJECTS } from './projects/projectsActions';
import projectsReducer from './projects/projectsReducer';
import { TEAMS } from './teams/teamsActions';
import teamsReducer from './teams/teamsReducer';
import { FILE } from './file/fileActions';
import fileReducer from './file/fileReducer';
import { FOLDER } from '../storeNames';
import folderReducer from './folder/folderReducer';
import { NOTE } from './note/noteActions';
import noteReducer from './note/noteReducer';
import { EVENTS } from './events/eventsActions';
import eventReducer from './events/eventsReducer';

export default combineReducers({
    [ACTIVITY]: activityReducer,
    [REDUX_AREA_SELECTION]: areaSelectionReducer,
    [CASTS]: castsReducer,
    [COMPANIES]: companiesReducer,
    [CONTACTS]: contactsReducer,
    [ITEMS]: itemsReducer,
    [FILE]: fileReducer,
    [FOLDER]: folderReducer,
    [NOTE]: noteReducer,
    [PROJECTS]: projectsReducer,
    [EVENTS]: eventReducer,
    [TEAMS]: teamsReducer
});
