import './ContextMenu.scss';
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { AbsoluteContent } from '../../buttons/AbsoluteContentButton/AbsoluteContent';
import type { MultiActionButtonListProps } from '../../buttons/MultiActionButton/MultiActionButtonList';
import { MultiActionButtonList } from '../../buttons/MultiActionButton/MultiActionButtonList';
import { isTouchDevice } from '../../../utils/isTouchDevice';
import { ActionType } from '../../buttons/MultiActionButton/MultiActionButton';
import cn from 'classnames';
import { DirectionX, DirectionY } from '../../../utils/getAbsolutePosition';

export type ContextMenuOptions = {
    WrapperComponent?: any;
    Component?: any;
    componentProps?: any;
    portalElement?: HTMLElement;
    touchDelay?: number;
    className?: string;
    style?: any;
    toggleDelay?: number;
    offset?: [number, number];
};
export type ContextMenuActions =
    | null
    | (ActionType | false | null)[]
    | ((props: any) => (ActionType | false | null)[] | null);

export type ContextMenuTriggerProps = Omit<MultiActionButtonListProps, 'actions'> &
    ContextMenuOptions & {
        actions: ContextMenuActions;
    };

export const ContextMenu: React.FC<ContextMenuTriggerProps> = ({
    Component,
    componentProps,
    WrapperComponent = 'div',
    portalElement,
    actions,
    touchDelay = 1000,
    className,
    style: wrapperStyle,
    toggleDelay = 0,
    offset = [5, 5] //default offset for weird mac bug that deselects item
}) => {
    const ref = useRef<HTMLElement>();
    const absoluteRef = useRef<HTMLElement>();
    const [visible, setVisible] = useState(false);
    const [coords, setCoords] = useState({ left: 0, top: 0 });
    const [style, setStyle] = useState<CSSProperties>({ left: 0, top: 0, visibility: 'hidden' });
    const pointerDownTimeout = useRef<any>();

    const displayEvent = useCallback(
        (
            e: any,
            {
                offset: clickOffset = [0, 0],
                preventDefault = true,
                stopPropagation = true
            }: {
                offset?: [number, number];
                preventDefault?: boolean;
                stopPropagation?: boolean;
            } = {}
        ) => {
            if (preventDefault) e.preventDefault();
            if (stopPropagation) e.stopPropagation();

            const coords = isTouchDevice()
                ? {
                      left: e.touches[0].clientX + offset[0] + clickOffset[0],
                      top: e.touches[0].clientY + offset[1] + clickOffset[1]
                  }
                : {
                      left: e.clientX + offset[0] + clickOffset[0],
                      top: e.clientY + offset[1] + clickOffset[1]
                  };

            setTimeout(() => {
                setVisible(true);
                setCoords(coords);
            }, toggleDelay || 0);
        },
        [setVisible, setCoords, toggleDelay, offset]
    );

    useEffect(() => {
        if (!ref.current) return;

        if (isTouchDevice()) {
            let open = false;
            const onTouchStart = (e: any) => {
                pointerDownTimeout.current = setTimeout(() => {
                    open = true;
                    displayEvent(e);
                }, touchDelay);
            };

            const onTouchEnd = (e: any) => {
                if (open) {
                    e.preventDefault();
                    open = false;
                }
                clearTimeout(pointerDownTimeout.current);
            };

            ref.current.addEventListener('touchstart', onTouchStart);
            ref.current.addEventListener('touchend', onTouchEnd);
            return () => {
                if (!ref.current) return;
                ref.current.removeEventListener('touchstart', onTouchStart);
                ref.current.removeEventListener('touchend', onTouchEnd);
            };
        } else {
            ref.current.addEventListener('contextmenu', displayEvent);

            return () => {
                if (!ref.current) return;
                ref.current.removeEventListener('contextmenu', displayEvent);
            };
        }
    }, [ref.current, displayEvent]);

    useEffect(() => {
        if (!absoluteRef.current) return;
        if (visible) {
            const s = window.getComputedStyle(absoluteRef.current);
            const h = Math.ceil(parseFloat(s.height) + parseFloat(s.marginTop));
            const w = Math.ceil(parseFloat(s.width) + parseFloat(s.marginLeft));
            return setStyle({
                top: Math.min(coords.top, window.innerHeight - h),
                left: Math.min(coords.left, window.innerWidth - w),
                visibility: 'visible'
            });
        }

        setStyle({ visibility: 'hidden' });
    }, [visible, coords, absoluteRef.current]);

    const _actions = (typeof actions === 'function' ? actions(componentProps) : actions)?.map(
        (a) => {
            if (!a || !a.actions || a.actions.length === 1) return a;
            return {
                ...a,
                direction: a.direction || [DirectionX.Right, DirectionY.Middle],
                contentClassName: cn(a.contentClassName, 'c-context-menu-sub')
            };
        }
    );

    return (
        <WrapperComponent
            ref={ref}
            className={cn('c-context-menu-wrapper', className)}
            style={wrapperStyle}
        >
            <Component {...(componentProps || {})} displayContextMenuFromEvent={displayEvent} />
            {visible &&
                _actions &&
                ReactDOM.createPortal(
                    <AbsoluteContent
                        triggerOnRightClick={true}
                        closeOnClickOutside={true}
                        style={style}
                        excludedClasses={['c-context-menu-sub']}
                        contentClassName="c-context-menu"
                        content={
                            <MultiActionButtonList
                                onClickableClick={() => {
                                    setTimeout(() => setVisible(false), 0);
                                }}
                                actions={_actions}
                            />
                        }
                        absoluteRef={absoluteRef}
                        hide={() => setVisible(false)}
                        show={() => setVisible(true)}
                    />,
                    portalElement || document.body
                )}
        </WrapperComponent>
    );
};

export const withContextMenu =
    (actions: ContextMenuActions, options: ContextMenuOptions = {}) =>
    (Component: any) =>
    (props: any) =>
        <ContextMenu actions={actions} {...options} Component={Component} componentProps={props} />;
