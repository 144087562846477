import './FormCommentActions.scss';
import React, { ChangeEvent } from 'react';
import { TeamMember } from '@he-novation/config/types/team.types';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { Theme } from '@he-novation/design-system/enums';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import { __ } from '@he-novation/design-system/utils/i18n';
import Icon from '@he-novation/icons';
import { getDisplayName } from '@he-novation/utils/user';

type FormCommentActionsProps = {
    setAttachments: (attachments: any[]) => void;
    attachments: any[];
    setKeywords: (keywords: string[]) => void;
    keywords: string[];
    openKeywordsModal: (setKeywords: (keywords: any[]) => void, keywords: any[]) => void;
    openAttachmentsModal: (
        setAttachments: (attachments: any[]) => void,
        attachments: any[]
    ) => void;
    teamMembers: TeamMember[] | null;
    changeNotify: (e: ChangeEvent<HTMLInputElement>) => void;
    isTask?: boolean;
    isPublicFile?: boolean;
    formId: string;
};
export default function FormCommentActions({
    setAttachments,
    attachments,
    setKeywords,
    keywords,
    openKeywordsModal,
    openAttachmentsModal,
    teamMembers,
    changeNotify,
    isTask = false,
    isPublicFile,
    formId
}: FormCommentActionsProps) {
    const [selectedMemberUuids, setSelectedMemberUuids] = React.useState<string[]>([]);
    return (
        <div className="c-form-comment-actions ">
            <div className="row-buttons">
                <FormField
                    formId={formId}
                    type={'checkbox'}
                    name="shouldNotify"
                    onChange={changeNotify}
                    id="c-form-comment-notify"
                    label={<Icon icon="notify" />}
                />

                <button
                    type="button"
                    onClick={() => openAttachmentsModal(setAttachments, attachments)}
                >
                    {attachments.length > 0 && <span className="number">{attachments.length}</span>}
                    <Icon icon="attachment" />
                </button>
                <button type="button" onClick={() => openKeywordsModal(setKeywords, keywords)}>
                    {keywords.length > 0 && <span className="number">{keywords.length}</span>}
                    <Icon icon="tag" />
                </button>
            </div>
            {isTask && !isPublicFile && (
                <div className="column-buttons">
                    <FormField
                        formId={formId}
                        theme={Theme.Dark}
                        type={'date-picker'}
                        name="estimatedEndDate"
                        label={__('TASK_MODAL_STEPPER_DATE')}
                        direction="TOP"
                        valueAsDate
                    />

                    {teamMembers && (
                        <FormField
                            formId={formId}
                            name={'assignees'}
                            coerceType="array"
                            type="checkbox-dropdown"
                            dropdownListClassName={'form-comment-assignees-list'}
                            icon="users"
                            direction={[DirectionX.RightInner, DirectionY.Top]}
                            theme={Theme.Dark}
                            label={
                                selectedMemberUuids.length
                                    ? __('N_USERS', { n: selectedMemberUuids.length })
                                    : __('SELECT_ASSIGNEES')
                            }
                            onChange={(_e, values: { value: string; label: string }[]) => {
                                setSelectedMemberUuids(values.map((v) => v.value));
                            }}
                            value={selectedMemberUuids}
                            options={teamMembers.map((m) => ({
                                label: getDisplayName(m, true),
                                value: m.uuid
                            }))}
                        />
                    )}
                </div>
            )}
        </div>
    );
}
