import './PageTitle.scss';
import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';

import { TitleContent } from '$components/base/titles/TitleContent/TitleContent';

type PageTitleProps = {
    backLink?: { href: string };
    breadcrumb?: { label: string; href?: string }[];
    tags?: ReactNode[];
    title: string;
    subtitle?: string;
    activeMobileSearch?: boolean;
    actions?: any[];
    tag?: keyof JSX.IntrinsicElements;
    className?: string;
    extra?: ReactNode | ReactNode[];
    children?: ReactNode | ReactNode[];
};
const PageTitle = ({
    backLink,
    breadcrumb,
    children,
    title,
    tags,
    subtitle,
    activeMobileSearch,
    actions = [],
    tag,
    className,
    extra
}: PageTitleProps) => {
    return document.getElementById('header')
        ? ReactDOM.createPortal(
              <div
                  className={cn(
                      'c-page-title',
                      activeMobileSearch && 'active-mobile-search',
                      className
                  )}
              >
                  <TitleContent
                      tags={tags}
                      breadcrumb={breadcrumb}
                      backLink={backLink}
                      title={title}
                      subtitle={subtitle}
                      tag={tag}
                      actions={actions}
                      extra={extra}
                  >
                      {children}
                  </TitleContent>
              </div>,
              document.getElementById('header')!
          )
        : null;
};

export default PageTitle;
