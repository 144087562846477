import { SubtitleType } from '@he-novation/config/types/subtitle.types';

export const subtitleTypesToData = {
    [SubtitleType.MANUAL]: {
        tooltip: 'SUBTITLES_MANUAL',
        icon: 'pencil'
    },
    [SubtitleType.UPLOADED]: {
        tooltip: 'SUBTITLES_UPLOADED',
        icon: 'export'
    },
    [SubtitleType.SPOTL]: {
        tooltip: 'SUBTITLES_SPOTL',
        icon: 'money'
    },
    [SubtitleType.SPOTL_VERIFIED]: {
        tooltip: 'SUBTITLES_SPOTL_VERIFIED',
        icon: 'money'
    }
};
