import { Locale } from '@he-novation/config/types/enums';

export type I18nType = { [key: string]: string };
type I18nOptionsType = { [key: string]: string | number | undefined };

let i18n: I18nType;
let locale: Locale;

export function setI18n(_i18n: I18nType, _locale: Locale) {
    i18n = _i18n;
    locale = _locale;
}

export const __ = (str: string, options?: I18nOptionsType, _i18n?: I18nType, legacy?: boolean) => {
    if (!_i18n) _i18n = i18n;
    if (typeof _i18n === 'undefined') {
        // key not valid || server side translation
        return str;
    }
    let iStr = _i18n[str];
    let oStr = iStr;

    if (iStr && options) {
        try {
            oStr = Object.keys(options).reduce((acc, curr) => {
                if (legacy) {
                    acc = acc.replace(
                        new RegExp(`<%=\\s?${curr}\\s?%>`, 'g'),
                        options[curr] as string
                    );
                }
                return acc.replace(new RegExp(`{{\\s?${curr}\\s?}}`, 'g'), options[curr] as string);
            }, iStr);
        } catch (e) {
            console.log(`ERR_I18N_REPLACE: '${iStr}'`);
            console.log(options);
        }
    }

    if (!oStr) {
        if (typeof window === 'undefined' || !localStorage.getItem('noWordingErrors')) {
            console.log(`ERR_I18N_MISSING: '${str}'`);
            if (options) {
                console.log(options);
            }
        }

        return str;
    }

    if (
        /\{\{[\w]*\}\}/.test(oStr) &&
        typeof window !== 'undefined' &&
        !localStorage.getItem('noWordingErrors')
    ) {
        console.log(`ERR_I18N_OBJECT: '${oStr}'`);
        if (options && !localStorage.getItem('noWordingErrors')) {
            console.log(options);
        }

        return str;
    }

    return oStr;
};

export const localeToDateLocale = (locale: Locale) => {
    switch (locale) {
        case 'fr':
            return 'fr-FR';
        case 'de':
            return 'de-DE';
        default:
            return 'en-GB';
    }
};
export const __curr = (amount: number, currency: string) => {
    return new Intl.NumberFormat(localeToDateLocale(locale), {
        style: 'currency',
        currency: currency.toUpperCase()
    }).format(amount / 100);
};
