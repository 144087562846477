import './UserPicker.scss';
import React, { useEffect, useState } from 'react';
import { FieldComponentProps } from 'react-modular-forms/dist/declarations/types';
import { FormField } from '../../FormField';
import { AvatarSize, AvatarUser } from '../../../../avatars/Avatar/AvatarUser';
import { getDisplayName } from '../../../../../utils/getDisplayName';
import { Option, Group } from '../ReactSelect/ReactSelect';
import { ReactSelectLabel } from '../ReactSelectLabel/ReactSelectLabel';
import Icon from '@he-novation/icons';
import { __ } from '../../../../../utils/i18n';
import { userToUuid } from '../../../../../utils/userToUuid';
import { Tooltip } from '../../../../widgets/Tooltip/Tooltip';

type User = {
    email: string;
    firstname?: string;
    lastname?: string;
    picture?: string;
    labels?: { name: string }[];
} & (
    | {
          user_uuid: string;
      }
    | { uuid: string }
);

type UserGroup = {
    uuid: string;
    name: string;
    users: User[];
};
type AvatarBulletFunction = (user: User) => { color: string; tooltip: string } | void;

type UserPickerProps = FieldComponentProps & {
    users: User[];
    userGroups?: UserGroup[];
    canRemove?: (user: User) => boolean;
    currentUserUuid?: string;
    avatarBullet?: AvatarBulletFunction;
};

const userToOption = (
    u: User,
    currentUserUuid?: string | null,
    avatarBullet?: AvatarBulletFunction
): Option => {
    const bullet = avatarBullet?.(u);
    const displayName = getDisplayName(u, true);
    return {
        searchValue: displayName + ' ' + (u.labels || []).map((l) => l.name).join(' '),
        label: (
            <ReactSelectLabel>
                {bullet && (
                    <Tooltip
                        className="bullet"
                        content={bullet.tooltip}
                        style={{ background: bullet.color }}
                    />
                )}
                <AvatarUser size={AvatarSize.Small} {...u} />{' '}
                {userToUuid(u) === currentUserUuid ? __('YOU') : displayName}
            </ReactSelectLabel>
        ),
        value: userToUuid(u)
    };
};

const userGroupToGroup = (group: UserGroup, avatarBullet?: AvatarBulletFunction): Group => {
    return {
        label: (
            <ReactSelectLabel>
                <Icon icon="users" />
                {group.name}
            </ReactSelectLabel>
        ),
        searchValue: group.name,
        value: group.uuid,
        items: group.users.map((g) => userToOption(g, null, avatarBullet))
    };
};

const getOptions = (
    users: User[],
    userGroups: UserGroup[],
    currentUserUuid?: string,
    avatarBullet?: AvatarBulletFunction
) => {
    let maxDisplayName = 0;
    const options = users
        .sort((a, b) => {
            if (userToUuid(a) === currentUserUuid) return -1;
            if (userToUuid(b) === currentUserUuid) return 1;
            const displayNameA = getDisplayName(a, true);
            if (displayNameA.length > maxDisplayName) maxDisplayName = displayNameA.length;
            return displayNameA.toLowerCase() < getDisplayName(b, true).toLowerCase() ? -1 : 1;
        })
        .map((u) => userToOption(u, currentUserUuid, avatarBullet));

    const groups = userGroups
        .sort((a, b) => (a.name < b.name ? -1 : 1))
        .map((u) => userGroupToGroup(u, avatarBullet));

    return { options, groups, maxDisplayName };
};

export function UserPicker({
    formId,
    name,
    theme,
    users,
    userGroups = [],
    canRemove,
    currentUserUuid,
    avatarBullet,
    menuIsOpen,
    value: _value
}: UserPickerProps) {
    const { options, groups, maxDisplayName } = getOptions(
        users,
        userGroups,
        currentUserUuid,
        avatarBullet
    );
    const [value, setValue] = useState<string[]>();
    useEffect(() => {
        setValue(_value || []);
    }, [_value]);

    const maxWidth = maxDisplayName * 8 + 50;

    return (
        <FormField
            theme={theme}
            formId={formId}
            name={name}
            id={`${formId}-${name}-select`}
            type="react-select"
            isMulti
            menuIsOpen={menuIsOpen}
            menuClassName="is-user-picker"
            hideSelectedOptions
            isSearchable
            onChange={(e, v) => setValue(v)}
            coerceType={'array'}
            value={value}
            filterBy={'searchValue'}
            getMenuWidth={(w: any) => {
                return Math.max(maxWidth, w);
            }}
            components={{
                ClearIndicator: (): null => null,
                MultiValue: ({ data, removeProps, innerRef }: any): null => null
            }}
            options={options}
            groups={groups}
            after={
                <ul className={'deletables'}>
                    {value?.map((uuid) => {
                        const user = users.find((u) => userToUuid(u) === uuid);
                        if (!user) return null;
                        const isRemovable = !canRemove || canRemove(user);
                        return (
                            <li key={uuid}>
                                <AvatarUser
                                    size={AvatarSize.Small}
                                    {...user}
                                    onClick={
                                        isRemovable
                                            ? () =>
                                                  setValue(
                                                      value.filter(
                                                          (uuid) => uuid !== userToUuid(user)
                                                      )
                                                  )
                                            : undefined
                                    }
                                    tooltip
                                />
                            </li>
                        );
                    })}
                </ul>
            }
        />
    );
}
