import React from 'react';
import { connect } from 'react-redux';
import { AnyLogPayload } from '@he-novation/config/types/payloads/logs/log.payload';
import { __ } from '@he-novation/design-system/utils/i18n';
import { folderLink, playerLink, projectFoldersLink } from '@he-novation/paths/herawFrontUris';
import { compose } from 'recompose';

import withSocketIO from '$components/HOC/withSocketIO';
import { openFeedbackModal, set } from '$redux/route/routeActions';
import { ACTIVITY_NEW_INCREMENT } from '$redux/ui/uiActions';
import { userSocketActions } from '$redux/user/userActions';

const livenotify = async (
    setRoute,
    data: { livenotify?: { content: string; icon?: string }; log: AnyLogPayload },
    feedback
) => {
    if (data.livenotify) {
        if (Notification.permission === 'default') await Notification.requestPermission();
        if (Notification.permission === 'denied')
            return feedback(__('ERR_DESKTOP_NOTIFICATIONS'), 30000);

        const div = document.createElement('div');
        div.innerHTML = data.livenotify.content;

        const notificationNew = new Notification('HERAW', {
            icon: data.livenotify.icon,
            body: div.innerText
        });

        notificationNew.onclick = (e) => {
            e.preventDefault();

            notificationNew.close();

            if ('file' in data.log && data.log.file) {
                setRoute(playerLink(data.log.file.uuid, data.log.file.version));
                window.focus();
                return;
            }
            if ('folder' in data.log && data.log.folder) {
                setRoute(
                    data.log.project
                        ? projectFoldersLink(data.log.project.uuid, data.log.folder.uuid)
                        : folderLink(data.log.folder.uuid)
                );
                window.focus();
            }
        };
    }
};

const Notifications = () => <></>;

export default compose(
    connect(undefined, (dispatch) => ({
        feedback: (msg, timeout) => dispatch(openFeedbackModal(msg, timeout)),
        setRoute: (route: string) => dispatch(set(route))
    })),
    withSocketIO(({ currentUserUuid, currentClientUuid, feedback, setRoute }) => ({
        room: currentUserUuid || currentClientUuid,
        name: 'activity',
        actions: {
            sioActivityAdd: (data) => {
                if (currentUserUuid) livenotify(setRoute, data, feedback);

                if (data.request_user_uuid === currentUserUuid) {
                    return;
                }

                return {
                    type: ACTIVITY_NEW_INCREMENT
                };
            }
        }
    })),
    withSocketIO(({ currentUserUuid }) => ({
        room: currentUserUuid,
        name: 'user',
        actions: userSocketActions
    }))
)(Notifications);
