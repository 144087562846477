import React from 'react';
import { setConfiguration } from 'react-calendars';
import Icon from '@he-novation/icons';

const translate = {
    daysTitles: {
        fr: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
        en: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
        de: ['M', 'D', 'M', 'D', 'F', 'S', 'S']
    },
    monthTitles: {
        fr: [
            'Janvier',
            'Février',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Août',
            'Septembre',
            'Octobre',
            'Novembre',
            'Decembre'
        ],
        en: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ],
        de: [
            'Januar',
            'Februar',
            'März',
            'April',
            'Mai',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Dezember'
        ]
    }
};

export const setDatePickerConfiguration = (locale) => {
    setConfiguration({
        daysTitles: translate.daysTitles[locale || 'fr'],
        firstDay: 1, // set to 0 to start week sunday
        libClassName: 'react-calendar',
        monthsTitles: translate.monthTitles[locale || 'fr'],
        previousButton: <Icon icon="arrow-left" />,
        nextButton: <Icon icon="arrow-left" />
    });
};
