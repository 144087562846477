import React, { DragEventHandler } from 'react';

import { DataLayoutSelectionProps } from '$components/DataLayout/DataLayout.types';
import SelectableItem from '$components/Selectable/SelectableItem';

export type SelectionOptions = {
    keyboardNavigation?: boolean;
    selectionPaddingX?: number;
    onDrop?: DragEventHandler<HTMLElement>;
    onItemDrop?: OnItemDrop;
    DraggedComponent?: React.ComponentType<any>;
};

export type SelectableItemComponentProps = { index: number; [key: string]: unknown };
type SelectionHandlerOptions = {
    onSetDragging?: (itemsProps?: SelectableItemComponentProps[]) => void;
    onSetSelecting?: (selecting) => void;
};
export type SelectionProps<T> = {
    id: string;
    selectableRef: React.MutableRefObject<any>;
    selectableGroupId: string;
    isSelected: boolean;
    isFocused: boolean;
    getSelectedItems: (getSelfIfNoSelection?: boolean) => T[];
    toggleSelection: (toggled: boolean) => void;
    shiftSelect: () => void;
};

export type SelectionItemChild<T> = React.ComponentType<
    {
        selection: DataLayoutSelectionProps<T>;
    } & any
>;
export type OnItemDrop = (
    e: React.DragEvent,
    selectedItemsProps: unknown[],
    itemProps: unknown
) => void;

export type SelectableItemProps<T> = {
    id: string;
    selectableGroupId: string;
    Component: SelectionItemChild<T>;
    componentProps: SelectableItemComponentProps;
    onDrop?: OnItemDrop;
    noClickHandler?: boolean;
};

export type SelectableItemState = {
    isSelected: boolean;
    isFocused: boolean;
};

const selectionsHandlers = {};

export class SelectionHandler<T> {
    id: string;
    children: SelectableItem<T>[] = [];
    selected: (string | number)[] = [];
    dragging = false;
    shouldDrag = false;
    mouseCoords: [number, number] = [0, 0];
    selecting = false;
    options: SelectionHandlerOptions;
    mouseDown: boolean;
    dragInitiator: SelectableItem<T> | undefined;

    static instantiate(id: string, options?: SelectionHandlerOptions) {
        if (selectionsHandlers[id]) {
            selectionsHandlers[id].references++;
        } else {
            selectionsHandlers[id] = {
                references: 1,
                instance: new SelectionHandler(id, options)
            };
        }
        return selectionsHandlers[id].instance;
    }

    constructor(id: string, options: SelectionHandlerOptions = {}) {
        this.id = id;
        this.options = options;
    }

    register(item: SelectableItem<T>) {
        this.children.push(item);
    }

    unregister(item: SelectableItem<T>) {
        if (this.selected.includes(item.props.id))
            this.selected = this.selected.filter((i) => i !== item.props.id);
        const i = this.children.indexOf(item);
        if (i > -1) this.children.splice(i, 1);
    }

    toggle = (item: SelectableItem<T>, toggled?: boolean) => {
        const index = this.selected.findIndex((id) => id === item.props.id);
        const child = this.children.find(({ props }) => props.id === item.props.id);

        if (index > -1) {
            if (!toggled) this.selected = this.selected.filter((i) => i !== item.props.id);
        } else {
            if (
                (typeof toggled === 'undefined' || toggled) &&
                !this.selected.includes(item.props.id)
            )
                this.selected.push(item.props.id);
        }
        child?.toggle(toggled);
    };

    isSelected(item: SelectableItem<T>) {
        return this.selected.indexOf(item.props.id) > -1;
    }

    setShouldDrag(dragging: boolean, initiator?: SelectableItem<T>) {
        this.shouldDrag = dragging;
        this.dragInitiator = initiator;

        document.body.classList.toggle('is-dragging', this.shouldDrag);
    }

    setDragging(dragging) {
        const changed = dragging != this.dragging;
        if (!changed) return;

        this.dragging = dragging;

        if (this.dragging && this.dragInitiator) {
            this.toggle(this.dragInitiator, true);
        }
        if (typeof this.options.onSetDragging === 'function')
            this.options.onSetDragging(
                this.dragging ? this.getSelectedComponentProps() : undefined
            );
    }

    setMouseCoords(coords) {
        this.mouseCoords = coords;
    }

    setSelecting(selecting) {
        this.selecting = selecting;
        if (typeof this.options.onSetSelecting === 'function')
            this.options.onSetSelecting(selecting);
    }

    shiftSelectTo(item) {
        if (!this.selected.length) return;
        const index = this.children.findIndex((c) => c.props.id === item.props.id);
        const selectedIndexes = this.children
            .filter((c) => this.selected.includes(c.props.id))
            .map((s) => this.children.indexOf(s));
        const closestIndex = selectedIndexes.reduce(
            (acc, i) => (Math.abs(index - i) < acc ? i : acc),
            selectedIndexes[0]
        );

        const start = Math.min(index, closestIndex);
        const end = Math.max(index, closestIndex);
        for (let i = start + 1; i < end; i++) {
            this.toggle(this.children[i], true);
        }
    }

    selectIndex(index: number) {
        this.toggle(this.children[index], true);
    }

    selectAll() {
        this.children.forEach((s) => this.toggle(s, true));
    }

    deselectAll() {
        this.children.forEach((s) => this.toggle(s, false));
    }

    getSelectedComponents = () => {
        const selected: SelectableItem<T>[] = [];
        for (let i = 0; i < this.selected.length; i++) {
            const child = this.children.find((c) => c.props.id === this.selected[i]);
            if (child) selected.push(child);
        }
        return selected;
    };

    getSelectedComponentProps = () => {
        const components = this.getSelectedComponents();
        return this.getSelectedComponents().map((c) => c.props.componentProps);
    };
}
