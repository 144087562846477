export const setActiveClientCss = (client: string) => {
    let found;
    Array.from(document.getElementsByClassName('client-css')).forEach((cssTag: HTMLLinkElement) => {
        cssTag.disabled = cssTag.id !== `client-css-${client}`;
        if (!cssTag.disabled) found = true;
    });
    if (!found) {
        const link = document.createElement('link');
        link.id = `client-css-${client}`;
        link.className = 'client-css';
        link.rel = 'stylesheet';
        link.crossOrigin = 'anonymous';
        link.href = `${process.env.API_URL}/css/v2/${client}.css`;
        document.head.appendChild(link);
    }
};
