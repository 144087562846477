export const ACTIVITY_FILTERS = 'activity-filters';
export const CONTACT_GROUPS = 'contact-groups';
export const CREATE_NOTE_PANEL = 'create_note';
export const CREATE_TASK_PANEL = 'create_task';
export const FINDER_PANEL = 'finder-panel';
export const CAST_FINDER_PANEL = 'cast-finder-panel';
export const FOLDER_ACTIVITY = 'folder-activity';
export const FOLDER_MEMBERS = 'folder-members';
export const FOLDER_SETTINGS = 'folder-settings';
export const ITEM_KITS = 'item-kits';
export const NOTES_PANEL = 'notes';
export const NOTE_PANEL = 'note';
export const PROJECT_INFO = 'project-info';
export const PROJECT_MEMBERS = 'project-members';
export const SUBTITLES = 'subtitles';
export const TASKS_FILTERS = 'tasks-filters';
export const NONE = 'none';
