import { BaseTaskLogPayload } from './log.payload';

export enum TaskLogKey {
    TaskAdd = 'task_add',
    TaskEdit = 'task_edit'
}

export interface LogTaskAddPayload extends BaseTaskLogPayload {
    key: TaskLogKey.TaskAdd;
}
export interface LogTaskEditPayload extends BaseTaskLogPayload {
    key: TaskLogKey.TaskEdit;
}

export type LogTaskPayload = LogTaskAddPayload | LogTaskEditPayload;
