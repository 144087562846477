export const getTypes = {
    export: function() {
        return ['brightcove', 'libcast', 'vimeo', 'youtube', 'dailymotion', 'twitter'];
    },

    fileviews: function() {
        return ['videostats'];
    }
};

export const exportable = {
    file: {
        aws: function(mime: string) {
            return true;
        },

        brightcove: function(mime: string) {
            return /^video/.test(mime) || /^application\/mxf/.test(mime);
        },

        libcast: function(mime: string) {
            return /^video/.test(mime) || /^application\/mxf/.test(mime);
        },

        ftp: function(mime: string) {
            return true;
        },

        vimeo: function(mime: string) {
            return /^video/.test(mime) || /^application\/mxf/.test(mime);
        },

        youtube: function(mime: string) {
            return /^video/.test(mime) || /^application\/mxf/.test(mime);
        },

        dailymotion: function(mime: string) {
            return /^video/.test(mime) || /^application\/mxf/.test(mime);
        },

        twitter: function(mime: string) {
            return /^image/.test(mime) || /^video/.test(mime) || /^application\/mxf/.test(mime);
        }
    },

    comments: {
        avid: function(mime: string) {
            return /^video/.test(mime) || /^application\/mxf/.test(mime);
        }
    }
};

export const configurable = function(type: string) {
    return (
        [
            'aws',
            'brightcove',
            'gcalendar',
            'libcast',
            'vimeo',
            'youtube',
            'dailymotion',
            'outlook',
            'twitter',
            'vantage'
        ].indexOf(type) !== -1
    );
};

export const configurableViaOauth = function(type: string) {
    return (
        ['vimeo', 'youtube', 'dailymotion', 'gcalendar', 'twitter', 'outlook'].indexOf(type) !== -1
    );
};

export const proxyFileTypes = function(mime: string) {
    return /^video/.test(mime) || /^application\/mxf/.test(mime) || /^image\/gif/.test(mime);
};

export const access = {
    export: function(pluginAccess: any) {
        var exportPlugins = getTypes.export();
        var pluginAccessTypes = pluginAccess.get('types');
        for (var pluginAccessTypesIt in pluginAccessTypes) {
            if (exportPlugins.indexOf(pluginAccessTypes[pluginAccessTypesIt]) !== -1) {
                return true;
            }
        }

        return false;
    },

    fileviews: function(pluginAccess: any) {
        var infoPlugins = getTypes.fileviews();
        var pluginAccessTypes = pluginAccess.get('types');
        for (var pluginAccessTypesIt in pluginAccessTypes) {
            if (infoPlugins.indexOf(pluginAccessTypes[pluginAccessTypesIt]) !== -1) {
                return true;
            }
        }

        return false;
    }
};

export const roles = {
    get: function(pluginAccess: any, acl: any) {
        if (
            !pluginAccess ||
            !pluginAccess.length ||
            pluginAccess.length < 1 ||
            pluginAccess.indexOf('roles') === -1
        ) {
            return acl.plugin.basic();
        }

        return acl.plugin.premium();
    },

    getDefault: function(pluginAccess: any, acl: any) {
        if (
            !pluginAccess ||
            !pluginAccess.length ||
            pluginAccess.length < 1 ||
            pluginAccess.indexOf('roles') === -1
        ) {
            return acl.plugin.basicDefault();
        }

        return acl.plugin.premiumDefault();
    }
};

export const get = {
    translationKey: function(name: string) {
        return 'PLUGIN_' + name.toUpperCase() + '_TITLE';
    },

    hasAdmittance: function(pluginAccess: any) {
        return !pluginAccess || pluginAccess.length < 1
            ? false
            : pluginAccess.indexOf('admittance') !== -1;
    },

    hasCommentsAvid: function(pluginAccess: any) {
        return !pluginAccess || pluginAccess.length < 1
            ? false
            : pluginAccess.indexOf('comments_avid') !== -1;
    },

    hasVantage: function(pluginAccess: any) {
        return !pluginAccess || pluginAccess.length < 1
            ? false
            : pluginAccess.indexOf('vantage') !== -1;
    },

    hasVersioning: function(pluginAccess: any) {
        return !pluginAccess || pluginAccess.length < 1
            ? false
            : pluginAccess.indexOf('versioning') !== -1;
    },

    hasFileTree: function(pluginAccess: any) {
        return !pluginAccess || pluginAccess.length < 1
            ? false
            : pluginAccess.indexOf('filetree') !== -1;
    },

    hasContacts: function(pluginAccess: any) {
        return !pluginAccess || pluginAccess.length < 1
            ? false
            : pluginAccess.indexOf('contacts') !== -1;
    },

    hasPlanning: function(pluginsAccess: any) {
        return !pluginsAccess || pluginsAccess.length < 1
            ? false
            : pluginsAccess.indexOf('planning') !== -1;
    },

    hasPublic: function(pluginAccess: any) {
        return !pluginAccess || pluginAccess.length < 1
            ? false
            : pluginAccess.indexOf('public') !== -1;
    },

    hasPublicFileTree: function(pluginAccess: any) {
        return !pluginAccess || pluginAccess.length < 1
            ? false
            : pluginAccess.indexOf('public_filetree') !== -1;
    },

    hasPublicKeyboardNav: function(pluginAccess: any) {
        return !pluginAccess || pluginAccess.length < 1
            ? false
            : pluginAccess.indexOf('public_keyboard_nav') !== -1;
    },

    hasRoles: function(pluginAccess: any) {
        return !pluginAccess || pluginAccess.length < 1
            ? false
            : pluginAccess.indexOf('roles') !== -1;
    },

    hasSubtitles: function(pluginAccess: any) {
        return !pluginAccess || pluginAccess.length < 1
            ? false
            : pluginAccess.indexOf('subtitles') !== -1;
    },

    hasSummary: function(pluginAccess: any) {
        return !pluginAccess || pluginAccess.length < 1
            ? false
            : pluginAccess.indexOf('summary') !== -1;
    },

    hasWatermark: function(pluginAccess: any) {
        return !pluginAccess || pluginAccess.length < 1
            ? false
            : pluginAccess.indexOf('watermark') !== -1;
    },

    hasBypass: function(pluginAccess: any) {
        return !pluginAccess || pluginAccess.length < 1
            ? false
            : pluginAccess.indexOf('bypass') !== -1;
    },

    hasProjects: function(pluginAccess: any) {
        return !pluginAccess || pluginAccess.length < 1
            ? false
            : pluginAccess.indexOf('projects') !== -1;
    },

    hasItems: function(pluginAccess: any) {
        return !pluginAccess || pluginAccess.length < 1
            ? false
            : pluginAccess.indexOf('items') !== -1;
    },

    hasTasks: function(pluginAccess: any) {
        return !pluginAccess || pluginAccess.length < 1
            ? false
            : pluginAccess.indexOf('tasks') !== -1;
    },

    hasTeams: function(pluginAccess: any) {
        return !pluginAccess || pluginAccess.length < 1
            ? false
            : pluginAccess.indexOf('teams') !== -1;
    },

    hasBundles: function(pluginAccess: any) {
        return !pluginAccess || pluginAccess.length < 1
            ? false
            : pluginAccess.indexOf('bundles') !== -1;
    },

    hasMetadata: function(pluginAccess: any) {
        return !pluginAccess || pluginAccess.length < 1
            ? false
            : pluginAccess.indexOf('metadata') !== -1;
    },

    hasEncrypted: function(pluginAccess: any) {
        return !pluginAccess || pluginAccess.length < 1
            ? false
            : pluginAccess.indexOf('encrypted') !== -1;
    },

    canAddSubtitles: function(selectedItems: any) {
        if (!selectedItems || selectedItems.length !== 1 || !selectedItems[0].mime) {
            return false;
        }

        return (
            /^video/.test(selectedItems[0].mime) || /^application\/mxf/.test(selectedItems[0].mime)
        );
    },

    canCompare: function(pluginAccess: any) {
        if (!pluginAccess || pluginAccess.length < 1) {
            return false;
        }

        return pluginAccess.indexOf('versioning') !== -1 && pluginAccess.indexOf('compare') !== -1;
    },

    canCopyMove: function(pluginAccess: any) {
        return !pluginAccess || pluginAccess.length < 1
            ? false
            : pluginAccess.indexOf('copymove') !== -1;
    },

    canShowStats: function(pluginAccess: any, type: string, mime: string) {
        if (!pluginAccess || pluginAccess.length < 1) {
            return false;
        }

        for (var pluginListIt in pluginAccess) {
            if (['videostats'].indexOf(pluginAccess[pluginListIt]) !== -1) {
                if (type !== 'file') {
                    return false;
                }

                return (
                    /^video/.test(mime) ||
                    /^application\/mxf/.test(mime) ||
                    /^image\/gif/.test(mime)
                );
            }
        }
    },

    canDownloadProxies: function(
        pluginAccess: any,
        mime: string,
        countAssets: number,
        readySd: boolean
    ) {
        if (!pluginAccess || pluginAccess.length < 1) {
            return false;
        }

        if (pluginAccess.indexOf('proxies') === -1) {
            return false;
        }

        if (!countAssets || !readySd) {
            return false;
        }

        return proxyFileTypes(mime);
    },

    canGetFileViews: function(pluginAccess: any) {
        if (!pluginAccess || pluginAccess.length < 1) {
            return false;
        }

        for (var pluginAccessIt in pluginAccess) {
            if (['videostats'].indexOf(pluginAccess[pluginAccessIt]) !== -1) {
                return true;
            }
        }

        return false;
    },

    canSetFileViews: function(pluginAccess: any) {
        if (!pluginAccess || pluginAccess.length < 1) {
            return false;
        }

        for (var pluginAccessIt in pluginAccess) {
            if (['videostats'].indexOf(pluginAccess[pluginAccessIt]) !== -1) {
                return true;
            }
        }

        return false;
    },

    canExport: function(exportMode: string, selectedItems: any) {
        var canExport = false;
        switch (exportMode) {
            case 'always':
                canExport = true;
                break;

            case 'final':
                canExport = true;
                for (var selectedItemsIt in selectedItems) {
                    if (!selectedItems[selectedItemsIt].final) {
                        canExport = false;
                        break;
                    }
                }
                break;
        }

        return canExport;
    }
};
export const plugins = [
    'admittance',
    'bypass',
    'comments_avid',
    'contacts',
    'filetree',
    'gcalendar',
    'outlook',
    'planning',
    'public',
    'public_filetree',
    'public_keyboard_nav',
    'roles',
    'subtitles',
    'summary',
    'versioning',
    'watermark',
    'vantage',
    'projects',
    'items',
    'tasks',
    'teams',
    'bundles',
    'metadata',
    'encrypted'
];
export default {
    getTypes,
    exportable,
    configurable,
    configurableViaOauth,
    proxyFileTypes,
    access,
    roles,
    get
};
