const mapFetchTeamsToUpdate = (teams, selectedTeam) => {
    const ownTeam = teams.find(({ ownTeam }) => ownTeam);
    return {
        teams: teams,
        team_uuid: ownTeam.uuid,
        team_name: ownTeam.name,
        castTeamAccess: ownTeam.castTeamAccess,
        selectedTeam:
            selectedTeam && teams.find(({ uuid }) => uuid === selectedTeam)
                ? selectedTeam
                : ownTeam.uuid
    };
};

export default mapFetchTeamsToUpdate;
