import './Tag.scss';
import React, {ReactNode} from 'react';
import cn from 'classnames';
import { Button } from '../../buttons/Button/Button';
import { Theme } from '../../../enums';

export type TagProps = {
    className?: string;
    theme?: Theme;
    remove?: Function;
    children?: ReactNode | ReactNode[];
};
export const Tag: React.FC<TagProps> = ({ children, className, remove, theme }) => (
    <div className={cn('c-tag', theme, className)}>
        {children}
        {remove && <Button icon="cross" type="button" onClick={remove} />}
    </div>
);
