import { AuthPasswordUpdatePayload } from '@he-novation/config/types/payloads/auth.payload';
import { PluginConfigurationSchema } from '@he-novation/config/types/payloads/plugin.payload';
import {
    UserInfoSchema,
    UserPreferencesSchema
} from '@he-novation/config/types/payloads/user.payload';
import { PluginConfigurationType } from '@he-novation/config/types/plugin.types';
import { ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { __ } from '@he-novation/design-system/utils/i18n';
import { asyncPluginConfigurationUpdate } from '@he-novation/front-shared/async/plugin.async';
import {
    fetchAccount as _fetchAccount,
    fetchPlugins as _fetchPlugins,
    fetchPluginsEnabled,
    fetchStorage as _fetchStorage,
    fetchUserAppInfos as _fetchUser,
    pluginAddConfiguration as _pluginAddConfiguration,
    pluginDeleteConfiguration as _pluginDeleteConfiguration,
    updatePassword as _updatePassword,
    updateProfile as _updateProfile,
    updateUserPreferences as _updateUserPreferences,
    userLicensesFetch
} from '@he-novation/front-shared/async/user.async';
import { SERVER_ERROR } from '@he-novation/paths/modals.constants';
import { bytesToSize } from '@he-novation/utils/bytes';
import { Dispatch } from 'redux';
import { v4 as uuidV4 } from 'uuid';
import asyncAction, { asyncActionSuccess } from '../../helpers/asyncAction';
import { openModal } from '../route/routeActions';

import { openToast } from '$redux/ui/uiActions';

export const USER = 'USER';

export const ADD_PLUGIN_CONFIGURATION = `${USER}/ADD_PLUGIN_CONFIGURATION`;
export const DELETE_PLUGIN_CONFIGURATION = `${USER}/DELETE_PLUGIN_CONFIGURATION`;
export const DISABLE_TOTP = `${USER}/DISABLE_TOTP`;
export const EDIT_PLUGIN_CONFIGURATION = `${USER}/EDIT_PLUGIN_CONFIGURATION`;
export const PLUGIN_MERGE_PROPERTIES = `${USER}/PLUGIN_MERGE_PROPERTIES`;
export const FETCH = `${USER}/FETCH`;
export const FETCH_ACCOUNT = `${USER}/FETCH_ACCOUNT`;
export const FETCH_PLUGINS_ACCESS = `${USER}/FETCH_PLUGINS_ACCESS`;
export const FETCH_STORAGE_LICENSES = `${USER}/FETCH_STORAGE_LICENSES`;
export const FETCH_STORAGE_TOTAL = `${USER}/FETCH_STORAGE_TOTAL`;
export const FETCH_STORAGE_USED = `${USER}/FETCH_STORAGE_USED`;
export const REQUEST_TOKEN = `${USER}REQUEST_TOKEN`;
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PREFERENCES = 'UPDATE_PREFERENCES';
export const UPDATE_PROFILE = `${USER}UPDATE_PROFILE`;
export const UPDATE_TOTP = `${USER}/UPDATE_TOTP`;

export const fetchUser =
    (cb?: (user: any) => void, onError?: (error: Error) => void) => async (dispatch) => {
        dispatch({ type: FETCH });
        try {
            const user = await _fetchUser();
            dispatch({ type: asyncActionSuccess(FETCH), user });
            if (typeof cb === 'function') cb(user);
        } catch (e) {
            onError?.(e);
        }
    };

export const updatePassword =
    (body: AuthPasswordUpdatePayload, cb: (e?: any) => void) => async (dispatch) => {
        dispatch({ type: UPDATE_PASSWORD });
        try {
            await _updatePassword(body);
            dispatch({ type: asyncActionSuccess(UPDATE_PASSWORD) });
            cb?.();
        } catch (e) {
            cb?.(e);
        }
    };

export const fetchPlugins =
    (cb?: ({ plugins, pluginsEnabled }: { plugins: string[]; pluginsEnabled: any }) => void) =>
    async (dispatch) => {
        dispatch({ type: FETCH_PLUGINS_ACCESS });
        const [plugins, pluginsEnabled] = await Promise.all([
            _fetchPlugins(),
            fetchPluginsEnabled()
        ]);
        dispatch({ type: asyncActionSuccess(FETCH_PLUGINS_ACCESS), plugins, pluginsEnabled });
        cb?.({ plugins, pluginsEnabled });
    };

export const fetchStorageAndLicenses = (cb) => async (dispatch) => {
    dispatch({ type: FETCH_STORAGE_LICENSES });

    const [storage, licenses] = await Promise.all([_fetchStorage(), userLicensesFetch()]);
    dispatch({
        type: asyncActionSuccess(FETCH_STORAGE_LICENSES),
        storage,
        licenses
    });
    cb?.({ storage, licenses });
};

export const fetchAccount = (cb, onError) => async (dispatch) => {
    dispatch({ type: FETCH_ACCOUNT });
    try {
        const account = await _fetchAccount();
        dispatch({ type: asyncActionSuccess(FETCH_ACCOUNT), account });
        if (typeof cb === 'function') cb(account);
    } catch (e) {
        console.error(e);
        const redirectToLogin = () => {
            window.location.href = `${
                process.env.MARKETING_URL
            }/fr/login?redirect=${window.location.pathname.replace('?', '&')}`;
        };
        if (!onError) {
            redirectToLogin();
        } else {
            onError(e, redirectToLogin);
        }
    }
};

export const requestToken = () =>
    asyncAction(REQUEST_TOKEN, {
        method: 'POST',
        url: `proxy/get/access/token`
    })({ request_token: uuidV4(4) });

export const updateProfile =
    (profile: UserInfoSchema, cb: (profile: UserInfoSchema) => void) => async (dispatch) => {
        dispatch({ type: UPDATE_PROFILE });
        const { picture } = await _updateProfile(profile);
        dispatch({ type: asyncActionSuccess(UPDATE_PROFILE), profile, picture });
        if (typeof cb === 'function') cb(profile);
    };

export function updateUserPreferences(preferences: UserPreferencesSchema) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: UPDATE_PREFERENCES });
        try {
            await _updateUserPreferences(preferences);
        } catch (e) {
            console.error(e);
            if (e && e.error)
                return dispatch(openModal(SERVER_ERROR, { message: e.error.message }));
            return dispatch(openModal(SERVER_ERROR));
        }
        dispatch({ type: asyncActionSuccess(UPDATE_PREFERENCES), preferences });
    };
}

export const pluginAddConfiguration =
    (pluginConfiguration, cb?: (plugin: any) => void) => async (dispatch) => {
        dispatch({ type: ADD_PLUGIN_CONFIGURATION });
        const plugin = await _pluginAddConfiguration(pluginConfiguration);
        dispatch({ type: asyncActionSuccess(ADD_PLUGIN_CONFIGURATION), plugin });
        if (typeof cb === 'function') cb(plugin);
    };

export const pluginEditConfiguration =
    (
        pluginUuid: string,
        pluginConfiguration: PluginConfigurationSchema,
        cb: (plugin: PluginConfigurationType) => void
    ) =>
    async (dispatch) => {
        dispatch({ type: EDIT_PLUGIN_CONFIGURATION });
        const plugin = await asyncPluginConfigurationUpdate(pluginUuid, pluginConfiguration);
        dispatch({ type: asyncActionSuccess(EDIT_PLUGIN_CONFIGURATION), plugin });
        if (typeof cb === 'function') cb(plugin);
    };

export function pluginDeleteConfiguration<T>(
    pluginConfiguration: T,
    cb?: (pluginConfig: T) => void
) {
    return async (dispatch) => {
        dispatch({ type: DELETE_PLUGIN_CONFIGURATION });
        await _pluginDeleteConfiguration(pluginConfiguration);
        dispatch({
            type: asyncActionSuccess(DELETE_PLUGIN_CONFIGURATION),
            plugin: pluginConfiguration
        });
        if (typeof cb === 'function') cb(pluginConfiguration);
    };
}

export const pluginMergeProperties = (pluginUuid, properties) => ({
    type: PLUGIN_MERGE_PROPERTIES,
    pluginUuid,
    properties
});

export const userSocketActions = {
    sioArchiveReady: (data) =>
        openToast({
            title: __('ARCHIVE_READY'),
            autoCloseAfterMs: 0,
            content: `${data.name} (${bytesToSize(data.size)})`,
            button: {
                text: __('DOWNLOAD'),
                tone: ButtonTone.Primary,
                onClick: () => {
                    window.open(data.downloadUrl, '_blank');
                }
            }
        })
};
