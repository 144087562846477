import React from 'react';
import './PlaylistThumbnail.scss';
import LoadItem from '../LoadItem/LoadItem';
import cn from 'classnames';
import Icon from '@he-novation/icons';

const PlaylistThumbnail = ({ isActive, mimeType, thumbnail, isPlayable, isBundle }) => {
    let img;

    if (thumbnail) {
        img = thumbnail ? <img src={thumbnail} alt="" /> : <LoadItem />;
    } else if (isBundle) {
        img = <img src={'/assets/assets/html5-thumb.jpg'} alt="" />;
    } else {
        img = <Icon icon="file" />;
    }

    return (
        <div
            className={cn('c-playlist-thumbnail', { 'is-active': isActive })}
            aria-label="thumbnail"
        >
            {isActive && <Icon icon="eye" />}
            {img}
        </div>
    );
};
/*
PlaylistThumbnail.propTypes = {
    mime_type: PropTypes.string,
    type: PropTypes.string,
    thumbnail: PropTypes.string,
    isActive: PropTypes.bool,
    readySd: PropTypes.number
};*/

export default PlaylistThumbnail;
