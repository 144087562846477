import './LockVersion.scss';
import React from 'react';
import { useDispatch } from 'react-redux';
import { FolderRole } from '@he-novation/config/types/folder.types';
import { acl } from '@he-novation/config/utils/acl';
import { Tooltip } from '@he-novation/design-system/components/widgets/Tooltip/Tooltip';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import { __ } from '@he-novation/design-system/utils/i18n';
import { fileVoteFinal } from '@he-novation/front-shared/async/file.async';
import Icon from '@he-novation/icons';

import { setFinal } from '$redux/content/file/fileActions';

interface Props {
    classname?: string;
    final_vote_user?: { uuid: string; email: string };
    final_votes_total: number;
    final_voted?: number;
    fileUuid: string;
    fileVersion: number;
    folderRole: FolderRole;
    versionLocked: boolean;
}

export function LockVersion({
    classname,
    final_vote_user,
    final_votes_total,
    final_voted,
    fileUuid,
    fileVersion,
    folderRole,
    versionLocked
}: Props) {
    const dispatch = useDispatch();

    return (
        <Tooltip
            direction={[DirectionX.Center, DirectionY.Top]}
            content={__('RCC_SET_FINAL')}
            className={`c-lock-version ${final_vote_user ? 'final-vote-user' : ''} ${
                versionLocked ? 'final' : ''
            } ${final_voted ? 'final-voted' : ''}`}
            onClick={(e) => e.stopPropagation()}
        >
            {final_votes_total > 0 && <span>{final_votes_total}</span>}
            <input
                id={`lock-file-version-${fileUuid}${classname ? classname : ''}`}
                type="checkbox"
                checked={versionLocked}
                onChange={(e) => {
                    if (folderRole === FolderRole.ROLE_OWNER || acl.files.setFinal(folderRole)) {
                        return dispatch(setFinal(fileUuid, fileVersion, e.currentTarget.checked));
                    }

                    if (acl.files.voteFinal(folderRole)) {
                        fileVoteFinal(fileUuid, fileVersion, { final: e.target.checked });
                    }
                }}
            />
            <label htmlFor={`lock-file-version-${fileUuid}${classname ? classname : ''}`}>
                <Icon icon="check" />
            </label>
        </Tooltip>
    );
}

export default LockVersion;
