import './MobileFooterButton.scss';
import React from 'react';
import { ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { MultiActionButton } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import cn from 'classnames';

interface Props {
    actions: any;
    extraActions?: any;
    icon?: string;
    extraIcon?: string;
}

const MobileFooterButton = ({ actions, extraActions, icon, extraIcon }: Props) => (
    <div className={cn('c-mobile-footer-button', actions && extraActions && 'has-extra')}>
        {actions && (
            <MultiActionButton
                actions={actions}
                direction={[DirectionX.Center, DirectionY.Top]}
                icon={icon}
                tone={ButtonTone.Primary}
                fireIfSingle
            />
        )}
        {extraActions && (
            <MultiActionButton
                actions={extraActions}
                direction={[DirectionX.Center, DirectionY.Top]}
                icon={extraIcon}
                tone={ButtonTone.Primary}
                fireIfSingle
            />
        )}
    </div>
);

export default MobileFooterButton;
