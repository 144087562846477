import { compose, branch, renderComponent } from 'recompose';
import { connect } from 'react-redux';
import SidePanelItemKits from '../../SidePanel/SidePanelItemKits/SidePanelItemKits';
import { kitsSelector } from '../../../redux/content/items/itemsSelectors';
import { Loader } from '@he-novation/design-system/components/widgets/Loader/Loader';
import { closeModal, openModal } from '../../../redux/route/routeActions';
import { DELETE_CONFIRM, ITEM_KIT } from '@he-novation/paths/modals.constants';
import { itemKitDelete } from '@he-novation/front-shared/async/item.async';
import { __ } from '@he-novation/design-system/utils/i18n';
import combineSelectors from '$helpers/combineSelectors';
import { canCreateItemSelector } from '../../../redux/user/userSelectors';

export default compose(
    connect(combineSelectors(kitsSelector, canCreateItemSelector), (dispatch) => ({
        openItemKitModal: () => dispatch(openModal(ITEM_KIT)),
        deleteItemKit: (uuid) =>
            dispatch(
                openModal(DELETE_CONFIRM, {
                    onDelete: () => {
                        itemKitDelete(uuid);
                        dispatch(closeModal());
                    },
                    closeModal: () => dispatch(closeModal()),
                    message: __('POPIN_DELETE_TXT_CONFIRM_CONTENT'),
                    title: __('RCC_DELETE'),
                    cancel: __('POPIN_BTN_CANCEL')
                })
            )
    })),
    branch(({ kits }) => !kits.isLoaded, renderComponent(Loader))
)(SidePanelItemKits);
