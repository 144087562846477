import styles from './DataLayoutSorter.module.css';
import React, { useState } from 'react';
import { __ } from '@he-novation/design-system/utils/i18n';
import Icon from '@he-novation/icons';
import cn from 'classnames';

import { DataLayoutColumn } from '$components/DataLayout/DataLayout.types';

type DataLayoutSorterProps<T extends DataLayoutColumn<any, any, any>> = {
    columns: T[];
    sorting?: [column: T, desc: boolean] | null;
    setSorting: (sorting: [column: T, desc: boolean]) => void;
    onSort?: (column: T, desc: boolean) => void;
};
export function DataLayoutSorter<T extends DataLayoutColumn<any, any, any>>({
    columns,
    sorting,
    setSorting,
    onSort
}: DataLayoutSorterProps<T>) {
    const [active, setActive] = useState(false);
    return (
        <div className={styles.sorter}>
            <button
                className={cn(styles.trigger, active && styles.active)}
                onClick={() => setActive(!active)}
            >
                {__('SORT_BY')}:&nbsp;
                {sorting?.[0]?.header}
                <Icon icon="arrow-left" />
            </button>
            {active && (
                <ul>
                    {columns.map((c, i) => (
                        <li key={i}>
                            <button
                                className={cn(
                                    sorting?.[0].key === c.key &&
                                        (sorting[1] ? styles.desc : styles.asc)
                                )}
                                onClick={() => {
                                    const desc = !!(sorting && !sorting[1]);
                                    setSorting([c, desc]);
                                    onSort?.(c, desc);
                                }}
                            >
                                {c.header}
                                {sorting?.[0].key === c.key && <Icon icon="arrow-left" />}
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}
