import './AbsoluteContent.scss';
import React, { MutableRefObject, ReactNode, RefObject, SyntheticEvent, useEffect } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { Direction, DirectionX, DirectionY } from '../../../utils/getAbsolutePosition';
import { Theme } from '../../../enums';
import { onClickOutside } from '../../../utils/onClickOutside';
import { parentHasClass } from '../../../utils/dom/parentHasClass';
import { isTouchDevice } from '../../../utils/isTouchDevice';

type AbsoluteContentProps = {
    absoluteRef: MutableRefObject<HTMLElement | undefined>;
    autoClose?: number;
    className?: string;
    closeOnClickOutside?: boolean;
    closeOnContentClick?: boolean;
    content: ReactNode | ((hide: Function) => ReactNode);
    contentClassName?: string;
    direction?: Direction;
    hide: (e: SyntheticEvent | MouseEvent) => void;
    show: (e: SyntheticEvent) => void;
    style?: any;
    theme?: Theme;
    triggerRef?: MutableRefObject<any>;
    triggersOnHover?: boolean;
    triggerOnRightClick?: boolean;
    excludedElements?: HTMLElement[];
    excludedClasses?: string[];
    capture?: boolean;
};

export const AbsoluteContent = ({
    absoluteRef,
    autoClose,
    closeOnClickOutside,
    closeOnContentClick,
    content,
    contentClassName,
    direction = [DirectionX.Center, DirectionY.Bottom],
    show,
    theme,
    hide,
    triggerRef,
    triggersOnHover,
    triggerOnRightClick,
    style = {},
    excludedElements = [],
    excludedClasses,
    capture: captureEvent = true
}: AbsoluteContentProps) => {
    useEffect(() => {
        let timeout = autoClose ? setTimeout(hide, autoClose) : undefined;
        let unregister = closeOnClickOutside
            ? onClickOutside(absoluteRef, hide, {
                  excludedElements: triggerRef
                      ? [triggerRef.current, ...excludedElements]
                      : excludedElements,
                  excludedClasses,
                  triggerOnRightClick
              })
            : null;

        const capture = (e: any) => (captureEvent ? e.stopPropagation() : null);
        if (!isTouchDevice()) {
            absoluteRef.current!.addEventListener('mousedown', capture, true);
            absoluteRef.current!.addEventListener('mouseup', capture, true);
        }

        return () => {
            clearTimeout(timeout);
            if (unregister) unregister();
            if (!isTouchDevice() && absoluteRef.current) {
                absoluteRef.current.removeEventListener('mousedown', capture, true);
                absoluteRef.current.removeEventListener('mouseup', capture, true);
            }
        };
    }, []);

    const parseHTML = typeof content === 'string';
    const innerContent = !parseHTML
        ? typeof content === 'function'
            ? content(hide)
            : content
        : null;

    return ReactDOM.createPortal(
        <div
            //@ts-ignore
            ref={absoluteRef}
            style={{
                position: 'absolute',
                ...style
            }}
            onClick={(e: any) => {
                if (closeOnContentClick && parentHasClass(e.target, 'c-btn')) {
                    hide(e);
                }
            }}
            onMouseOver={triggersOnHover ? show : undefined}
            onMouseOut={triggersOnHover ? hide : undefined}
            className={classNames(
                'c-absolute-content',
                contentClassName,
                theme,
                `is-${direction[0]}`,
                `is-${direction[1]}`
            )}
            dangerouslySetInnerHTML={parseHTML && content ? { __html: content } : undefined}
        >
            {innerContent}
        </div>,
        document.body
    );
};
