import React from 'react';
import { CopyField } from '@he-novation/design-system/components/form/CopyField/CopyField';
import { Theme } from '@he-novation/design-system/enums';

import ModalHeader from '$components/modal/ModalHeader/ModalHeaderContainer';

export const COPY_PASTE = 'copy-paste';

type ModalCopyPasteProps = {
    payload: {
        title: string;
        value: string;
    };
};
export default function ModalCopyPaste({ payload }: ModalCopyPasteProps) {
    return (
        <div>
            <ModalHeader title={payload.title} />
            <main>
                <CopyField id="modal-copy-paste-field" theme={Theme.Light} value={payload.value} />
            </main>
        </div>
    );
}
