import './SidePanelAddNote.scss';
import React from 'react';
import Icon from '@he-novation/icons';
import FormCommentOrTask from '../../form/FormComment/FormCommentOrTask';

import NoteProjectTeamsPickerContainer from '$views/projects/Components/ProjectTeamsPicker/NoteProjectTeamsPickerContainer';

type SidePanelAddNoteProps = {
    title: string;
    goBackToNotes: () => void;
    isNote: boolean;
    payload: {
        isPublicFile?: boolean;
        password?: string;
    };
};
const SidePanelAddNote = ({
    title,
    goBackToNotes,
    isNote,
    payload: { isPublicFile, password } = {}
}: SidePanelAddNoteProps) => (
    <section className="c-side-panel-add-note">
        <header>
            <button type="button" className="btn-back" onClick={() => goBackToNotes()}>
                <Icon icon="arrow-left-thick" />
            </button>
            <h2 className="title">{title}</h2>
            <NoteProjectTeamsPickerContainer />
        </header>

        <FormCommentOrTask
            isTask={!isNote}
            onSubmit={goBackToNotes}
            isPublicFile={isPublicFile}
            password={password}
        />
    </section>
);

export default SidePanelAddNote;
