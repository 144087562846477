import { SpotlLanguage, SubtitleGeneratePayload } from '@he-novation/config/types/subtitle.types';
import { apiFetch } from '@he-novation/front-shared/async/apiFetch';
import { subtitlesPaths } from '@he-novation/paths/herawApiPaths';

export const asyncSubtitlesGenerate = (body: SubtitleGeneratePayload) =>
    apiFetch(subtitlesPaths.generatedSingle, {
        method: 'POST',
        body
    });

export const asyncSubtitlesAvailableLanguagesFetch = (): Promise<SpotlLanguage[]> =>
    apiFetch(subtitlesPaths.spotlLanguages);
