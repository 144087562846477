import { Asset } from '../asset.types';

export enum WSFolderEventType {
    SourceAssetType = 'source/asset/type',
    AssetReady = 'asset/ready',
    TranscodingPending = 'transcoding/pending',
    TranscodingProgress = 'transcoding/progress',
    TranscodingFinished = 'transcoding/finished'
}
export interface WSFolderEventSourceAssetType {
    type: WSFolderEventType.SourceAssetType;
    fileUuid: string;
    sourceAssetVersion: number;
    sourceAssetType: string;
}

export interface WSFolderEventAssetReady {
    type: WSFolderEventType.AssetReady;
    fileUuid: string;
    asset: Asset;
}

export interface WSFolderEventTranscodingPending {
    type: WSFolderEventType.TranscodingPending;
    fileUuid: string;
    fileVersion: number;
}

export interface WSFolderEventTranscodingProgress {
    type: WSFolderEventType.TranscodingProgress;
    fileUuid: string;
    fileVersion: number;
    estimatedEndDate: string;
}

export interface WSFolderEventTranscodingFinished {
    type: WSFolderEventType.TranscodingFinished;
    fileUuid: string;
    fileVersion: number;
}
