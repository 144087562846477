import React, { Suspense } from 'react';
import {
    ACTIVITY,
    CAST_FILE,
    CAST_FOLDER,
    CASTS,
    CLIENT_DASHBOARD,
    CLIENT_GUESTS,
    CLIENT_USERS,
    DIRECTORY,
    FILE,
    PLANNING,
    PROJECTS,
    SETTINGS,
    FILE_COMPARE,
    FOLDER,
    FOLDER_SHARED,
    SHARED_FILE,
    SHARED_FILE_COMPARE,
    PROJECT,
    PROJECT_CAST,
    PROJECT_FOLDERS,
    SEARCH,
    CLIENT_PROJECTS,
    PUBLIC_FOLDER,
    CAST,
    PDF_SUMMARY,
    AVID_SUMMARY,
    TRASH,
    ADMIN,
    ADMIN_WORDING,
    CLIENT_SETTINGS,
    ADMIN_CLIENTS
} from '@he-novation/paths/herawFrontUris';
import { Loader } from '@he-novation/design-system/components/widgets/Loader/Loader';
import ClientGuests from '$views/client/ClientGuests/ClientGuestsContainer';

const Activity = React.lazy(() =>
    import(/* webpackChunkName: "activity" */ './views/activity/ActivityContainer')
);
const AdminGeneral = React.lazy(() =>
    import(/* webpackChunkName: "admin" */ './views/admin/AdminGeneral/AdminGeneral')
);
const AdminClients = React.lazy(() =>
    import(
        /* webpackChunkName: "admin-clients" */ './views/admin/AdminClients/AdminClientsContainer'
    )
);
const AdminWording = React.lazy(() =>
    import(/* webpackChunkName: "admin-wording" */ './views/admin/AdminWording/AdminWording')
);

const CastContainer = React.lazy(() => import(/**/ '$views/cast/Cast/CastContainer'));
const CastsContainer = React.lazy(() => import(/**/ '$views/cast/Casts/CastsContainer'));

const CastFile = React.lazy(() =>
    import(/* webpackChunkName: "cast-file" */ '$views/cast/CastFile/CastFile')
);
const CastFolderContainer = React.lazy(() =>
    import(/* webpackChunkName: "cast-folder" */ '$views/cast/CastFolder/CastFolder')
);

const ClientDashboard = React.lazy(() =>
    import(
        /* webpackChunkName: "client-projects" */ './views/client/ClientDashboard/ClientDashboardContainer'
    )
);

const ClientProjects = React.lazy(() =>
    import(
        /* webpackChunkName: "client-projects" */ './views/client/ClientProjects/ClientProjectsContainer'
    )
);
const ClientUsers = React.lazy(() =>
    import(/* webpackChunkName: "client-users" */ './views/client/ClientUsers/ClientUsersContainer')
);
const Directory = React.lazy(() =>
    import(/* webpackChunkName: "contacts" */ './views/directory/DirectoryContainer')
);
const File = React.lazy(() => import(/* webpackChunkName: "file" */ './views/file/FileContainer'));
const Folder = React.lazy(() =>
    import(/* webpackChunkName: "folder" */ './views/folder/FolderContainer')
);
const Search = React.lazy(() => import(/* webpackChunkName: "search" */ './views/search/Search'));
const Projects = React.lazy(() =>
    import(/* webpackChunkName: "projects" */ './views/projects/Projects/Projects')
);

const Planning = React.lazy(() =>
    import(/* webpackChunkName: "planning" */ '$views/planning/PlanningContainer')
);

const Project = React.lazy(() =>
    import(/* webpackChunkName: "project" */ './views/projects/Project/ProjectContainer')
);

const PublicContainer = React.lazy(() =>
    import(/* webpackChunkName: "public" */ '$views/public/PublicContainer')
);
const Settings = React.lazy(() =>
    import(/* webpackChunkName: "settings" */ './views/settings/Settings')
);
const Summary = React.lazy(() =>
    import(/* webpackChunkName: "summary" */ '$views/summary/Summary')
);
const SummaryAvid = React.lazy(() =>
    import(/* webpackChunkName: "summary-avid" */ '$views/summary/SummaryAvid')
);

const Trash = React.lazy(() => import(/* webpackChunkName: "trash" */ '$views/trash/Trash'));

/* eslint-disable */
export default {
    '/': {
        Component: ({ route }) => (
            <Suspense fallback={<Loader />}>
                {route?.isClient ? (
                    <ClientDashboard />
                ) : route?.isSuperAdmin ? (
                    <AdminGeneral />
                ) : (
                    <Folder />
                )}
            </Suspense>
        )
    },
    [ADMIN_CLIENTS]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <AdminClients />
            </Suspense>
        )
    },
    [ADMIN]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <AdminGeneral />
            </Suspense>
        )
    },
    [ADMIN_WORDING]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <AdminWording />
            </Suspense>
        )
    },
    [ACTIVITY]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <Activity />
            </Suspense>
        ),
        preserveQueryParams: true
    },
    [CASTS]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <CastsContainer />
            </Suspense>
        )
    },
    [CAST]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <CastContainer castUid={params.castUid} />
            </Suspense>
        )
    },
    [CLIENT_DASHBOARD]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <ClientDashboard />
            </Suspense>
        )
    },
    [CLIENT_PROJECTS]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <ClientProjects />
            </Suspense>
        )
    },
    [CLIENT_USERS]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <ClientUsers />
            </Suspense>
        )
    },
    [CLIENT_GUESTS]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <ClientGuests />
            </Suspense>
        )
    },
    [CLIENT_SETTINGS]: {
        Component: ({ route: { params, isClient } }) => (
            <Suspense fallback={<Loader />}>
                <Settings route={params.route} isClient={true} />
            </Suspense>
        )
    },
    [DIRECTORY]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <Directory route={params.route} subroute={params.subroute} />
            </Suspense>
        )
    },
    [FILE]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <File shared={false} uuid={params.fileUuid} />
            </Suspense>
        )
    },
    [SHARED_FILE]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <File shared={true} uuid={params.fileUuid} />
            </Suspense>
        )
    },
    [FILE_COMPARE]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <File shared={false} />
            </Suspense>
        )
    },
    [SHARED_FILE_COMPARE]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <File shared={true} />
            </Suspense>
        )
    },
    [FOLDER]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <Folder uuid={params.folderUuid} />
            </Suspense>
        )
    },
    [FOLDER_SHARED]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <Folder isSharedRoot={true} uuid={params.folderUuid} />
            </Suspense>
        )
    },
    [PLANNING]: {
        Component: ({ route: { params, queryParams } }) => (
            <Suspense fallback={<Loader />}>
                <Planning
                    route={params.route}
                    start={queryParams.start}
                    view={queryParams.view}
                    highlightedEvent={queryParams.event}
                />
            </Suspense>
        )
    },
    [PROJECTS]: {
        Component: ({ route: { params, queryParams } }) => (
            <Suspense fallback={<Loader />}>
                <Projects route={params.route} start={queryParams.start} view={queryParams.view} />
            </Suspense>
        )
    },
    [PROJECT]: {
        Component: ({ route: { params, queryParams } }) => (
            <Suspense fallback={<Loader />}>
                <Project uuid={params.uuid} route={params.route} task={queryParams.task} />
            </Suspense>
        )
    },
    [PROJECT_CAST]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <CastContainer castUid={params.castUid} projectUuid={params.uuid} />
            </Suspense>
        )
    },
    [PROJECT_FOLDERS]: {
        Component: ({ route: { params, queryParams } }) => (
            <Suspense fallback={<Loader />}>
                <Project
                    uuid={params.uuid}
                    route="folders"
                    folderUuid={params.folderUuid}
                    task={queryParams.task}
                />
            </Suspense>
        )
    },
    [SEARCH]: {
        Component: ({ route: { queryParams } }) => (
            <Suspense fallback={<Loader />}>
                <Search search={queryParams.q} />
            </Suspense>
        )
    },
    [SETTINGS]: {
        Component: ({ route: { params, isClient } }) => (
            <Suspense fallback={<Loader />}>
                <Settings route={params.route} isClient={false} />
            </Suspense>
        )
    },
    [TRASH]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <Trash folderUuid={params.folderUuid} />
            </Suspense>
        )
    }
};

export const publicRoutes = {
    [PUBLIC_FOLDER]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <PublicContainer />
            </Suspense>
        )
    }
};

export const castRoutes = {
    [CAST_FOLDER]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <CastFolderContainer
                    castUid={params.castUid}
                    castFolderUuid={params.castFolderUuid}
                />
            </Suspense>
        ),
        ignoreParamsInKey: true
    },
    [CAST_FILE]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <CastFile routeParams={params} />
            </Suspense>
        )
    }
};

export const summaryRoutes = {
    [PDF_SUMMARY]: {
        Component: ({ route: { params, queryParams } }) => (
            <Suspense fallback={<Loader />}>
                <Summary
                    fileUuid={params.fileUuid}
                    fileVersion={params.fileVersion}
                    filters={queryParams?.filters}
                />
            </Suspense>
        )
    },
    [AVID_SUMMARY]: {
        Component: ({ route: { params, queryParams } }) => (
            <Suspense fallback={<Loader />}>
                <SummaryAvid
                    file={{ uuid: params.fileUuid, version: parseInt(params.fileVersion) }}
                    filters={queryParams?.filters}
                    lineBreak={queryParams?.bl}
                />
            </Suspense>
        )
    }
};

/* eslint-enable */
