import './BottomButtons.scss';
import React from 'react';
import { Button } from '../Button/Button';
import type { ButtonProps } from '../Button/Button';
import { Theme } from '../../../enums';
import { ButtonTone } from '../Button/Button';
import { __ } from '../../../utils/i18n';
export { ButtonTone } from '../Button/Button';

type BottomButtons = {
    buttons: (ButtonProps | false | null | undefined)[];
    cancel?: Function;
};

export const BottomButtons: React.FC<BottomButtons> = ({ buttons, cancel }) => (
    <div className="c-bottom-buttons">
        {cancel && (
            <Button onClick={cancel} theme={Theme.Light} tone={ButtonTone.Outlined}>
                {__('CANCEL')}
            </Button>
        )}
        {buttons.map((b, i) => (b ? <Button key={i} {...b} /> : null))}
    </div>
);
