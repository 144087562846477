import { Contact } from '@he-novation/config/types/contact.types';
import { Note } from '@he-novation/config/types/note.types';
import { timeCodeToSeconds } from '@he-novation/lib-timecodes';
import { filter, find, pick, pipe, property } from 'lodash/fp';
import memoizeOne from 'memoize-one';
import { folderRightsSelector, isSharedSelector } from '../../content/folder/folderSelectors';
import { selectedTeamSelector } from '../../content/projects/projectsSelector';
import { preferencesSelector, userInfosSelector } from '../../user/userSelectors';
import { contactsSelector } from '../contacts/contactsSelectors';
import { stateSelector as contentSelector } from '../contentSelectors';
import { NOTE } from './noteActions';

import combineSelectors from '$helpers/combineSelectors';
import { clientPreferencesSelector } from '$redux/client/clientSelectors';

const stateSelector = pipe(contentSelector, property(NOTE));

export const notesSelector = memoizeOne(
    pipe(
        combineSelectors(
            (s) => ({ notes: stateSelector(s) }),
            selectedTeamSelector,
            contactsSelector,
            userInfosSelector
        ),
        ({
            notes: { list },
            selectedTeam,
            contacts,
            userInfos
        }: {
            notes: { list: Note[] };
            selectedTeam?: string;
            contacts: { list: Contact[] };
            userInfos: { uuid: string; firstname?: string; lastname?: string };
        }) => {
            list = list.map((n) => {
                const contact = contacts.list.find(({ email }) => email === n.user?.email);
                if (n.user && n.user.uuid === userInfos.uuid) {
                    n.user.firstname = userInfos.firstname;
                    n.user.lastname = userInfos.lastname;
                    return n;
                }
                if (contact) n.user = contact;
                return n;
            });

            if (list.length && selectedTeam) {
                if (selectedTeam !== 'cast')
                    return list.filter(({ team }) => team?.uuid === selectedTeam);
                else return list.filter(({ cast }) => cast);
            }
            return list;
        },
        (notes) => ({ notes })
    )
);

export const selectedNoteUuidSelector = pipe(stateSelector, ({ selectedNoteUuid }) => ({
    selectedNoteUuid
}));

export const selectedNoteSelector = pipe(
    stateSelector,
    pick(['selectedNoteUuid', 'list']),
    ({ list, selectedNoteUuid }: { list: Note[]; selectedNoteUuid?: string }) => {
        const sortedList = list.sort((a, b) => {
            if ((a.metadata && a.metadata.tcIn) || (b.metadata && b.metadata.tcIn)) {
                if (a.type === 'global') return -1;
                if (b.type === 'global') return 1;
                if (a.metadata && a.metadata.tcIn && b.metadata && b.metadata.tcIn)
                    return (
                        timeCodeToSeconds(a.metadata.tcIn, 25) -
                        timeCodeToSeconds(b.metadata.tcIn, 25)
                    );
            }
            return a.created.getTime() - b.created.getTime();
        });

        const noteIndex = sortedList.findIndex(({ uuid }) => uuid === selectedNoteUuid);
        if (noteIndex === -1) return;
        return {
            ...sortedList[noteIndex],
            previousNoteUuid:
                noteIndex > 0 ? sortedList[noteIndex - 1].uuid : sortedList[list.length - 1].uuid,
            nextNoteUuid:
                noteIndex < sortedList.length - 1
                    ? sortedList[noteIndex + 1].uuid
                    : sortedList[0].uuid
        };
    },
    (selectedNote) => ({ selectedNote })
);

export const noteTagsSelector = pipe(
    notesSelector,
    ({ notes }) =>
        notes.reduce((acc, { comments }) => {
            const tags = comments.reduce((acc: string[], { tags }) => {
                if (tags && tags.length) acc = acc.concat(tags);
                return acc;
            }, []);
            if (tags.length) acc = acc.concat(tags);
            return acc;
        }, [] as string[]),
    (noteTags) => [...new Set(noteTags)],
    (noteTags) => ({ noteTags })
);

export const commentSelectorFromUuid = (_uuid) =>
    pipe(
        selectedNoteSelector,
        property('selectedNote.comments'),
        find(({ uuid }) => uuid === _uuid),
        (comment) => ({ comment })
    );

export const commentRightsSelector = pipe(
    combineSelectors(isSharedSelector, clientPreferencesSelector, folderRightsSelector),
    ({ isShared, folderRights, clientPreferences }) =>
        isShared ? folderRights : clientPreferences?.rights,
    (commentRights) => ({ commentRights })
);

export const castNotesSelector = pipe(
    stateSelector,
    property('list'),
    filter(property('cast')),
    (castNotes) => ({
        castNotes
    })
);

export const displayAllVersionsSelector = pipe(stateSelector, pick('displayAllVersions'));
