import React from 'react';
import Icon from '@he-novation/icons';
import { Tooltip } from '@he-novation/design-system/components/widgets/Tooltip/Tooltip';
import { __ } from '@he-novation/design-system/utils/i18n';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import { EDGE, FIREFOX, CHROME, getBrowserString } from '@he-novation/front-shared/utils/browser';

const SUPPORTED_BROWSERS = [EDGE, FIREFOX, CHROME];

class ExtensionDownloadTooltip extends React.Component {
    componentDidMount() {
        this.browser = getBrowserString(navigator.userAgent);
    }

    render() {
        if (SUPPORTED_BROWSERS.indexOf(this.browser) === -1) return null;
        return (
            <Tooltip
                content={this.renderContent}
                direction={[DirectionX.RightInner, DirectionY.Bottom]}
                delay={1000}
                tooltipClassName="extension-download-tooltip"
                hasArrow={true}
            >
                <div className="tooltip-icon">
                    <Icon icon="rectangle-corners" />
                </div>
                <div className="tooltip-icon">
                    <Icon icon="brush" />
                </div>
            </Tooltip>
        );
    }

    renderContent = () => {
        return (
            <div
                className="tooltip-content"
                dangerouslySetInnerHTML={{
                    __html: __('EXTENSION_DOWNLOAD_MESSAGE', { href: this.getExtensionLink() })
                }}
            />
        );
    };

    getExtensionLink() {
        switch (this.browser) {
            case EDGE:
                return 'https://microsoftedge.microsoft.com/addons/detail/lmnlghjclffpmcfbafbgiedknhcdbpog';
            case FIREFOX:
                return 'https://addons.mozilla.org/en-US/firefox/addon/heraw-html-review/';
            case CHROME:
                return 'https://chrome.google.com/webstore/detail/heraw-the-all-in-one-coll/dplkgjkhekgcnbiknhpfdgbdgcnkdmbo?authuser=1&hl=fr';
        }
    }
}

export default ExtensionDownloadTooltip;
