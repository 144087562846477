import './Loader.scss';
import React, { CSSProperties } from 'react';
import { Theme } from '../../../enums';
import classNames from 'classnames';
import { __ } from '../../../utils/i18n';

export enum LoaderSize {
    Small = 'small',
    Big = 'big'
}

type LoaderProps = {
    theme?: Theme;
    size?: LoaderSize;
    style?: CSSProperties;
    className?: string;
};

export const Loader: React.FC<LoaderProps> = ({
    theme = Theme.Dark,
    size = LoaderSize.Big,
    className,
    style
}) => (
    <div className={classNames('c-loader', theme, size, className)} style={style}>
        <div>
            <span className="visually-hidden">{__('LOADING')}</span>
        </div>
    </div>
);
