import { connect } from 'react-redux';
import {
    clientPreferencesSelector,
    currentClientProfileSelector
} from '$redux/client/clientSelectors';
import { homeLink } from '@he-novation/paths/herawFrontUris';
import { isEqual } from 'lodash/fp';
import {
    loadQueryParams,
    openModal,
    saveQueryParams,
    set,
    setFilters
} from '$redux/route/routeActions';
import { PASSWORD_UPDATE_FORM } from '@he-novation/paths/modals.constants';
import { preferencesSelector, userInfosSelector } from '$redux/user/userSelectors';
import { routeSelector, filtersSelector } from '$redux/route/routeSelectors';
import combineSelectors from '$helpers/combineSelectors';
import debounce from 'lodash/fp/debounce';
import React from 'react';
import store from '$redux/store';

const checkPasswordExpiration = debounce(10000, (openModal, clientPreferences, accountInfo) => {
    if (clientPreferences?.password?.expires && clientPreferences?.password?.expires_days) {
        if (/^0000/.test(accountInfo.password_reset)) {
            return openModal();
        }

        const passwordExpirationDate = new Date(accountInfo.password_reset);
        passwordExpirationDate.setTime(
            passwordExpirationDate.getTime() +
                clientPreferences.password.expires_days * 24 * 60 * 60 * 1000
        );
        const now = new Date();
        if (passwordExpirationDate < now) {
            return openModal();
        }
    }
});

class Router extends React.Component {
    componentDidMount() {
        window.addEventListener('popstate', this.onPopState);
        //facebook redirect
        if (this.props.route.startsWith('_=')) {
            this.props.setRoute(homeLink(), false, true);
        }

        if (window.location.pathname === '/index') {
            const path = '/' + window.location.hash.replace(/^#\/?/, '');
            this.props.setRoute(path, false, true);
        }

        if (/\/shared\/[a-z0-9-]+.*/.test(window.location.pathname)) {
            this.props.setRoute(
                window.location.pathname.replace('/shared/', '/folders/'),
                false,
                true
            );
        }

        if (/\/sharedplayer\/[a-z0-9-]+.*/.test(window.location.pathname)) {
            this.props.setRoute(
                window.location.pathname.replace('/sharedplayer/', '/player/'),
                false,
                true
            );
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.configRoute !== this.props.configRoute) {
            if (this.props.routes[this.props.configRoute]?.preserveQueryParams) {
                this.props.loadQueryParams(this.props.configRoute);
            }

            if (this.props.onUpdate) {
                this.props.onUpdate(prevProps.configRoute, this.props.configRoute);
            }

            if (this.props.isClient) {
                checkPasswordExpiration(
                    this.props.openModalPassword,
                    this.props.preferences,
                    this.props.currentClientProfile
                );
            } else {
                checkPasswordExpiration(
                    this.props.openModalPassword,
                    this.props.clientPreferences,
                    this.props.userInfos
                );
            }
        }

        if (!isEqual(prevProps.filters, this.props.filters)) {
            this.props.setFilters(this.props.filters);
        }
    }

    render() {
        if (this.props.configRoute) {
            const Comp = this.props.routes[this.props.configRoute].Component;
            let key = `${this.props.configRoute}`;
            if (!this.props.routes[this.props.configRoute].ignoreParamsInKey)
                key += `-${JSON.stringify(this.props.params)}`;
            return (
                <Comp
                    key={key}
                    route={{
                        route: this.props.route,
                        params: this.props.params,
                        queryParams: this.props.queryParams,
                        isClient: this.props.isClient,
                        isSuperAdmin: this.props.isSuperAdmin
                    }}
                />
            );
        }
        return null;
    }

    onPopState = (e) => {
        store.dispatch(set(window.location.href.replace(window.location.origin, ''), true));
    };
}

export default connect(
    combineSelectors(
        currentClientProfileSelector,
        filtersSelector,
        preferencesSelector,
        routeSelector,
        clientPreferencesSelector,
        userInfosSelector
    ),
    (dispatch) => ({
        openModalPassword: () =>
            dispatch(openModal(PASSWORD_UPDATE_FORM, { passwordExpired: true })),
        saveQueryParams: (routeName, queryParamsToSave) =>
            dispatch(saveQueryParams(routeName, queryParamsToSave)),
        loadQueryParams: (routeName) => dispatch(loadQueryParams(routeName)),
        setFilters: (filters) => dispatch(setFilters(filters))
    })
)(Router);
