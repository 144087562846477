import './App.scss';
import React from 'react';
import { __ } from '@he-novation/design-system/utils/i18n';
import { HubSpotChat } from '@he-novation/front-shared/components/HubSpotChatScript';
import fscreen from 'fscreen';
import { Provider } from 'jotai';
import routes from '../../routes';

import { HubspotButton } from '$components/Buttons/HubspotButton/HubspotButton';
import MainMenu from '$components/layout/MainMenu/MainMenuContainer';
import ToastContainer from '$components/layout/ToastContainer';
import Modal from '$components/modal/Modal/ModalContainer';
import Router from '$components/router/Router';
import SidePanels from '$components/SidePanelsContainer';
import { inIframe } from '$helpers/inIframe';
import { BrandHeader } from '$views/App/components/BrandHeader/BrandHeader';
import { Footer } from '$views/App/components/Footer/Footer';
import { Header } from '$views/App/components/Header/Header';
import { Main } from '$views/App/components/Main/Main';
import { getFrontRoutesFromLocation } from '$views/App/getFrontRoutesFromLocation';
import NotificationsContainer from '$views/App/NotificationsContainer';

type AppProps = {
    isTeamsApp?: boolean;
    onFullscreenChange: () => void;
    feedback: (message: string) => void;
    setRoute: (route: string, silent?: boolean, replace?: boolean) => void;
    isSuperAdmin?: boolean;
    isClient?: boolean;
    onRouteUpdate: (prevUrl: string, newUrl: string) => void;
    currentUserUuid: string;
    currentClientUuid?: string;
    route: string;
};
const getClassName = (url) => {
    if (url.startsWith('/cast/')) return 'is-cast';
    if (url.startsWith('/public/')) return 'is-public';
    if (url.startsWith('/summary')) return 'is-summary';
    return 'is-app';
};
class App extends React.Component<AppProps> {
    isApp: boolean;
    constructor(props) {
        super(props);
        this.isApp = !/^\/public\/|^\/cast\/|^\/summary(_avid)?\//.test(window.location.pathname);
    }
    componentDidMount() {
        if (inIframe() || this.props.isTeamsApp) {
            document.body.classList.add('msteams-iframe');
        }
        fscreen.addEventListener('fullscreenchange', this.props.onFullscreenChange);
        if (document.referrer.endsWith('/transfer'))
            setTimeout(() => this.props.feedback(__('FEEDBACK_TRANSFER_PROGRESS')), 500);

        document.getElementById('app')!.classList.add(getClassName(window.location.pathname));
    }

    componentWillUnmount() {
        fscreen.removeEventListener('fullscreenchange', this.props.onFullscreenChange);
    }

    render() {
        const shouldLoadHubspotChatScript = /prod|production/.test(process.env.NODE_ENV as string);

        const content = !this.isApp ? (
            <Router
                setRoute={this.props.setRoute}
                isSuperAdmin={this.props.isSuperAdmin}
                isClient={this.props.isClient}
                routes={getFrontRoutesFromLocation()}
                onUpdate={this.props.onRouteUpdate}
            />
        ) : (
            <>
                {this.props.currentUserUuid && (
                    <NotificationsContainer currentUserUuid={this.props.currentUserUuid} />
                )}
                {this.props.currentClientUuid && (
                    <NotificationsContainer currentClientUuid={this.props.currentClientUuid} />
                )}
                <MainMenu isClient={this.props.isClient} />
                <Header />
                <Main>
                    <Router
                        setRoute={this.props.setRoute}
                        isSuperAdmin={this.props.isSuperAdmin}
                        isClient={this.props.isClient}
                        routes={routes}
                        onUpdate={this.props.onRouteUpdate}
                    />
                </Main>
                <Footer />
                {shouldLoadHubspotChatScript && <HubSpotChat />}
            </>
        );

        if (
            window.location.pathname.startsWith('/summary') ||
            this.props.route.startsWith('summary') ||
            this.props.route.startsWith('cast')
        )
            return content;

        const appContent = (
            <>
                <BrandHeader />
                {content}
                <div id="side-menu-wrapper" />
                <div id="side-panel-wrapper" />
                <SidePanels />
                <Modal />
                <ToastContainer />
                <HubspotButton />
            </>
        );
        return process.env.REACT_STRICT_MODE_DISABLED ? (
            appContent
        ) : (
            <React.StrictMode>
                <Provider>{appContent}</Provider>
            </React.StrictMode>
        );
    }
}

export default App;
