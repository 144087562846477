import './MainMenu.scss';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserPrefs } from '@he-novation/config/types/user.types';
import { ROLE_OWNER } from '@he-novation/config/utils/acl';
import { AvatarUser } from '@he-novation/design-system/components/avatars/Avatar/AvatarUser';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import { __ } from '@he-novation/design-system/utils/i18n';
import {
    activityLink,
    adminClientsLink,
    adminWordingLink,
    castsLink,
    clientDashboardLink,
    clientGuestsLink,
    clientProjectslink,
    clientSettingsLink,
    clientUsersLink,
    directoryLink,
    folderLink,
    indexLink,
    planningGlobalLink,
    projectsDashboardLink,
    projectsLink,
    projectsTasksLink,
    settingsLink
} from '@he-novation/paths/herawFrontUris';
import cn from 'classnames';
import { SubMenu } from '../../layout/MainMenu/SubMenu';
import Link from '../../router/Link';
import { UploadTracker } from '../UploadTracker/UploadTracker';

import { inIframe } from '$helpers/inIframe';
import { isTeamsAppSelector } from '$redux/config/configSelectors';
import { folderRoleSelector } from '$redux/content/folder/folderSelectors';
import { isClientSelector, preferencesSelector, UserInfos } from '$redux/user/userSelectors';

const getSubmenu = (route: string) => {
    switch (true) {
        case /project\/.+/g.test(route):
            return 'project';
        case /projects(?:\/.+)?/g.test(route):
            return 'projects';
        case /directory.*/g.test(route):
            return 'directory';
        case /planning.*/g.test(route):
            return 'planning';
    }
    return false;
};

const getProjectsLink = (defaultTab?: string) => {
    switch (defaultTab) {
        case 'dashboard':
            return projectsDashboardLink();
        case 'tasks':
            return projectsTasksLink();
        case 'projects':
        default:
            return projectsLink();
    }
};

type MainMenuProps = {
    hasPluginCast: boolean;
    hasPluginContacts: boolean;
    hasPluginPlanning: boolean;
    hasPluginProjects: boolean;
    hasPluginVideochat: boolean;
    route: string;
    setRoute: (route: string) => void;
    userInfos: UserInfos;
    clientName: string;
    isSmallScreen: boolean;
    isSuperAdmin: boolean;
    storage: { total: number };
    fileProjectUuid: string;
    userProjects: number;
    userEvents: number;
    countNewActivities: number;
    preferences: UserPrefs;
};

const MainMenu = ({
    hasPluginCast,
    hasPluginContacts,
    hasPluginPlanning,
    hasPluginProjects,
    hasPluginVideochat,
    route,
    setRoute,
    userInfos,
    clientName,
    isSmallScreen,
    isSuperAdmin,
    storage,
    fileProjectUuid,
    userProjects,
    userEvents,
    countNewActivities
}: MainMenuProps) => {
    const [mobileMenu, setMobileMenu] = useState(false);
    const [subMenu, setSubMenu] = useState<string | false>();
    const { isTeamsApp } = useSelector(isTeamsAppSelector);
    const isClient = useSelector(isClientSelector);
    const { folderRole } = useSelector(folderRoleSelector);
    const { preferences } = useSelector(preferencesSelector);
    useEffect(() => setSubMenu(getSubmenu(route)), [mobileMenu, route]);
    return (
        <nav id="c-main-menu" className={mobileMenu ? 'is-toggled' : undefined}>
            {mobileMenu ? (
                <Button
                    className="mobile-button-close"
                    tone={ButtonTone.Hoverable}
                    icon="cross2"
                    onClick={() => setMobileMenu(false)}
                />
            ) : (
                <Button
                    className="mobile-button"
                    tone={ButtonTone.Hoverable}
                    icon="burger"
                    onClick={() => setMobileMenu(true)}
                />
            )}
            {isSmallScreen && (
                <SubMenu
                    toggled={subMenu}
                    closeMenu={() => setSubMenu(false)}
                    close={() => setMobileMenu(false)}
                />
            )}
            <ul className={cn('main-menu-list', (!isSmallScreen || !subMenu) && 'is-toggled')}>
                {clientName !== 'admin' && (
                    <li>
                        <Button
                            tone={ButtonTone.Hoverable}
                            icon="bell"
                            id="mm-activity"
                            href={activityLink()}
                            tooltip={__('MAIN_MENU_ACTIVITY')}
                            tooltipOptions={{
                                direction: [DirectionX.Right, DirectionY.Middle]
                            }}
                            onDragStart={(e: Event) => {
                                e.preventDefault();
                                return false;
                            }}
                            onClick={() => setMobileMenu(false)}
                            className={route.startsWith('/activity') ? 'is-active' : null}
                        >
                            {__('MAIN_MENU_ACTIVITY')}
                        </Button>
                        {countNewActivities > 0 && (
                            <div className="activity-counter">
                                <span>{countNewActivities}</span>
                            </div>
                        )}
                    </li>
                )}
                {!isClient && (
                    <>
                        {(hasPluginProjects || userProjects > 0) && (
                            <li>
                                <Button
                                    id="mm-project"
                                    className={
                                        route.startsWith('/project') ||
                                        (fileProjectUuid && route.startsWith('sharedplayer'))
                                            ? 'is-active'
                                            : null
                                    }
                                    tone={ButtonTone.Hoverable}
                                    icon="briefcase"
                                    tooltip={__('MAIN_MENU_PROJECTS')}
                                    tooltipOptions={{
                                        direction: [DirectionX.Right, DirectionY.Middle]
                                    }}
                                    href={getProjectsLink(preferences.ui?.projectsDefaultActiveTab)}
                                    onClick={(e: Event) => {
                                        setMobileMenu(false);
                                        if (window.localStorage.previousProject === 'null') {
                                            window.localStorage.removeItem('previousProject');
                                        }
                                        if (
                                            !/^\/project/.test(window.location.pathname) &&
                                            window.localStorage.previousProject
                                        ) {
                                            e.preventDefault();
                                            setRoute(
                                                '/project/' +
                                                    window.localStorage.previousProject +
                                                    '/' +
                                                    (window.localStorage.previousProjectRoute ||
                                                        'folders')
                                            );
                                        }
                                    }}
                                >
                                    {__('MAIN_MENU_PROJECTS')}
                                </Button>
                            </li>
                        )}
                        {window.location.pathname !== '/index_client' &&
                            (hasPluginPlanning || userEvents > 0) && (
                                <li>
                                    <Button
                                        id="mm-planning"
                                        tone={ButtonTone.Hoverable}
                                        icon="date"
                                        tooltip={__('PLANNING')}
                                        tooltipOptions={{
                                            direction: [DirectionX.Right, DirectionY.Middle]
                                        }}
                                        href={planningGlobalLink()}
                                        onClick={() => setMobileMenu(false)}
                                        className={
                                            route.startsWith('/planning') ? 'is-active' : null
                                        }
                                    >
                                        {__('PLANNING')}
                                    </Button>
                                </li>
                            )}
                        {(storage.total === -1 || storage.total > 0) && (
                            <li>
                                <Button
                                    id="mm-folder"
                                    tone={ButtonTone.Hoverable}
                                    icon="folder-thick"
                                    href={folderLink()}
                                    tooltip={__('MAIN_MENU_FOLDERS')}
                                    tooltipOptions={{
                                        direction: [DirectionX.Right, DirectionY.Middle]
                                    }}
                                    onClick={() => {
                                        setMobileMenu(false);
                                    }}
                                    className={
                                        folderRole === ROLE_OWNER &&
                                        (route.startsWith('/folder') || !route)
                                            ? 'is-active'
                                            : null
                                    }
                                >
                                    {__('MAIN_MENU_FOLDERS')}
                                </Button>
                            </li>
                        )}
                        <li>
                            <Button
                                id="mm-shared"
                                tone={ButtonTone.Hoverable}
                                icon="folder-shared"
                                href={'/shared'}
                                disabled={route === '/shared'}
                                onClick={() => setMobileMenu(false)}
                                tooltip={__('MAIN_MENU_SHARED_FOLDERS')}
                                tooltipOptions={{
                                    direction: [DirectionX.Right, DirectionY.Middle]
                                }}
                                className={
                                    (route.startsWith('/shared') && !fileProjectUuid) ||
                                    (route.startsWith('/folders') &&
                                        folderRole !== ROLE_OWNER &&
                                        !fileProjectUuid)
                                        ? 'is-active'
                                        : null
                                }
                            >
                                {__('MAIN_MENU_SHARED_FOLDERS')}
                            </Button>
                        </li>

                        {hasPluginCast && (
                            <li>
                                <Button
                                    id="mm-cast"
                                    tone={ButtonTone.Hoverable}
                                    icon="cast"
                                    href={castsLink()}
                                    tooltip={__('CASTS')}
                                    tooltipOptions={{
                                        direction: [DirectionX.Right, DirectionY.Middle]
                                    }}
                                    className={route.startsWith('/casts') ? 'is-active' : undefined}
                                >
                                    {__('CASTS')}
                                </Button>
                            </li>
                        )}

                        {hasPluginContacts && (
                            <li>
                                <Button
                                    id="mm-contacts"
                                    tone={ButtonTone.Hoverable}
                                    icon="contacts"
                                    href={directoryLink()}
                                    tooltip={__('MAIN_MENU_CONTACTS')}
                                    tooltipOptions={{
                                        direction: [DirectionX.Right, DirectionY.Middle]
                                    }}
                                    onClick={() => setMobileMenu(false)}
                                    className={route.startsWith('/directory') ? 'is-active' : null}
                                >
                                    {__('MAIN_MENU_CONTACTS')}
                                </Button>
                            </li>
                        )}
                    </>
                )}

                {isClient && (
                    <>
                        {!isSuperAdmin ? (
                            <>
                                <li>
                                    <Button
                                        tone={ButtonTone.Hoverable}
                                        icon="dashboard"
                                        className={!route ? 'is-active' : null}
                                        href={clientDashboardLink()}
                                        onClick={() => setMobileMenu(false)}
                                    >
                                        {__('DASHBOARD')}
                                    </Button>
                                </li>
                                <li>
                                    <Button
                                        tone={ButtonTone.Hoverable}
                                        icon="users"
                                        className={
                                            route.startsWith('/client/users') ? 'is-active' : null
                                        }
                                        href={clientUsersLink()}
                                        onClick={() => setMobileMenu(false)}
                                    >
                                        {__('USERS')}
                                    </Button>
                                </li>
                                <li>
                                    <Button
                                        tone={ButtonTone.Hoverable}
                                        icon="key"
                                        className={
                                            route.startsWith('/client/guests') ? 'is-active' : null
                                        }
                                        href={clientGuestsLink()}
                                        onClick={() => setMobileMenu(false)}
                                    >
                                        {__('GUESTS')}
                                    </Button>
                                </li>
                                <li>
                                    <Button
                                        tone={ButtonTone.Hoverable}
                                        icon="briefcase"
                                        className={
                                            route.startsWith('/client/projects')
                                                ? 'is-active'
                                                : null
                                        }
                                        href={clientProjectslink()}
                                        onClick={() => setMobileMenu(false)}
                                    >
                                        {__('PROJECTS')}
                                    </Button>
                                </li>
                            </>
                        ) : (
                            <>
                                <li>
                                    <Button
                                        tone={ButtonTone.Hoverable}
                                        icon="cog"
                                        href={indexLink()}
                                        className={
                                            route === indexLink() || !route ? 'is-active' : null
                                        }
                                    />
                                </li>
                                <li>
                                    <Button
                                        tone={ButtonTone.Hoverable}
                                        icon="city"
                                        href={adminClientsLink()}
                                        className={
                                            route === adminClientsLink() || !route
                                                ? 'is-active'
                                                : null
                                        }
                                    />
                                </li>
                                <li>
                                    <Button
                                        tone={ButtonTone.Hoverable}
                                        icon="list"
                                        href={adminWordingLink()}
                                        className={
                                            route === adminWordingLink() ? 'is-active' : null
                                        }
                                    />
                                </li>
                            </>
                        )}
                    </>
                )}
                {hasPluginVideochat && !inIframe() && !isTeamsApp && (
                    <li>
                        <Button
                            id="mm-chat"
                            tone={ButtonTone.Hoverable}
                            icon="camera"
                            href={process.env.CHAT_URL}
                            target="_blank"
                            tooltip={__('CHAT')}
                            tooltipOptions={{
                                direction: [DirectionX.Right, DirectionY.Middle]
                            }}
                            external
                        >
                            {__('CHAT')}
                        </Button>
                    </li>
                )}
                <li className="settings-link">
                    <UploadTracker />
                    {isClient ? (
                        <Link href={clientSettingsLink()} onClick={() => setMobileMenu(false)}>
                            <AvatarUser email={clientName} />
                        </Link>
                    ) : (
                        <Link href={settingsLink()} onClick={() => setMobileMenu(false)}>
                            <AvatarUser {...userInfos} />
                        </Link>
                    )}
                </li>
            </ul>
        </nav>
    );
};

export default MainMenu;
