import './DataLayoutChildren.scss';
import React, { CSSProperties } from 'react';
import cn from 'classnames';

import { DataLayoutGridItem } from '$components/DataLayout/components/DataLayoutChildren/DataLayoutGridItem/DataLayoutGridItem';
import { DataLayoutListItem } from '$components/DataLayout/components/DataLayoutChildren/DataLayoutListItem/DataLayoutListItem';
import type { DataLayoutColumn } from '$components/DataLayout/DataLayout.types';
import { DataLayoutDisplayMode, ItemComponent } from '$components/DataLayout/DataLayout.types';
import SelectableItem from '$components/Selectable/SelectableItem';
import { SelectionOptions } from '$components/Selectable/SelectionHandler';

type DataLayoutChildrenProps<T, U extends boolean | undefined, V> = {
    items: T[];
    displayMode: DataLayoutDisplayMode;
    computeItemStyle?: (index: number) => CSSProperties;
    offset: number;
    columns: DataLayoutColumn<T, U, V>[];
    ItemGridComponent?: ItemComponent<T, U, V>;
    selectableGroupId: string;
    selectionOptions?: SelectionOptions;
    style?: React.CSSProperties;
    group?: string;
    ActionsMenuComponent?: React.ComponentType<any>;
    notSelectable?: U;
    extra?: V;
    hideHeader?: boolean;
    itemToId: (item: T) => string;
};

function DataLayoutChildrenInner<T, U extends boolean | undefined, V>({
    items,
    computeItemStyle,
    displayMode,
    columns,
    ItemGridComponent,
    selectableGroupId,
    selectionOptions,
    itemToId,
    style,
    offset,
    group,
    ActionsMenuComponent,
    notSelectable,
    extra,
    hideHeader
}: DataLayoutChildrenProps<T, U, V>) {
    const Component =
        ItemGridComponent && displayMode === DataLayoutDisplayMode.Grid
            ? DataLayoutGridItem
            : DataLayoutListItem;

    return (
        <ul
            className={cn(
                'data-layout-children',
                displayMode === DataLayoutDisplayMode.List && !hideHeader && 'has-header'
            )}
            style={style}
        >
            {items.map((item, i) => {
                return (
                    <DataLayoutChild
                        key={`${i}-${offset}-${displayMode}`}
                        i={i + offset}
                        offset={offset}
                        item={item}
                        extra={extra}
                        columns={columns}
                        Component={Component}
                        //@ts-expect-error complex
                        ItemGridComponent={ItemGridComponent}
                        selectableGroupId={selectableGroupId}
                        selectionOptions={selectionOptions}
                        style={computeItemStyle?.(i + offset) || {}}
                        group={group}
                        ActionsMenuComponent={ActionsMenuComponent}
                        notSelectable={notSelectable}
                        displayMode={displayMode}
                        itemToId={itemToId}
                    />
                );
            })}
        </ul>
    );
}

type DataLayoutChildProps<T, U extends boolean | undefined, V> = {
    i: number;
    offset: number;
    item: T;
    extra?: V;
    columns: DataLayoutColumn<T, U, V>[];
    ItemGridComponent?: ItemComponent<T, U, V>;
    Component: ItemComponent<T, U, V>;
    selectableGroupId: string;
    selectionOptions?: SelectionOptions;
    style?: React.CSSProperties;
    group?: string;
    ActionsMenuComponent?: React.ComponentType<any>;
    notSelectable?: U;
    displayMode: DataLayoutDisplayMode;
    itemToId: (item: T) => string;
};
function DataLayoutChild<T, U extends boolean | undefined, V>({
    i,
    offset,
    item,
    extra,
    columns,
    ItemGridComponent,
    Component,
    selectableGroupId,
    selectionOptions,
    style,
    group,
    ActionsMenuComponent,
    notSelectable,
    displayMode,
    itemToId
}: DataLayoutChildProps<T, U, V>) {
    const componentProps = {
        index: i,
        item,
        extra,
        columns,
        ActionsMenuComponent,
        notSelectable,
        ItemGridComponent
    };

    const id = itemToId(item);

    return (
        <li
            key={`${i}-${offset}-${displayMode}`}
            className={cn(
                'li-' + i + '-' + offset,
                i % 2 ? 'is-odd' : 'is-even',
                'data-layout-child'
            )}
            data-id={id}
            style={style}
        >
            {!notSelectable ? (
                <SelectableItem
                    key={id}
                    id={id}
                    selectableGroupId={selectableGroupId}
                    noClickHandler={displayMode === DataLayoutDisplayMode.List}
                    Component={Component}
                    componentProps={componentProps}
                    onDrop={selectionOptions?.onItemDrop}
                />
            ) : (
                //@ts-expect-error complex
                <Component {...componentProps} selection={undefined} />
            )}
        </li>
    );
}

export const DataLayoutChildren = React.memo(DataLayoutChildrenInner);
