import DatesNotesList from './DatesNotesList';
import { connect } from 'react-redux';
import { branch, compose, renderComponent, renderNothing } from 'recompose';
import { localeSelector } from '$redux/config/configSelectors';
import { TimeCodesNotesList } from '$components/Notes/NotesList/TimeCodesNotesList';

export default compose(
    connect(localeSelector),
    branch(({ sorter }) => sorter === 'timecode', renderComponent(TimeCodesNotesList))
)(DatesNotesList);
