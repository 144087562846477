import { connect } from 'react-redux';
import { __ } from '@he-novation/design-system/utils/i18n';
import { compose, mapProps } from 'recompose';
import SidePanelAddNote from './SidePanelAddNote';

import { CREATE_TASK_PANEL, NOTES_PANEL } from '$constants/constants.sidePanels';
import { openPanel } from '$redux/sideMenu/sideMenuActions';

export type SidePanelAddTaskPayload = {
    isPublicFile?: boolean;
};

const mapDispatchToProps = (dispatch) => ({
    goBackToNotes: () => dispatch(openPanel(NOTES_PANEL))
});
export const actionSidePanelAddTaskOpen = (payload: SidePanelAddTaskPayload) =>
    openPanel(CREATE_TASK_PANEL, payload);

export default compose(
    connect(undefined, mapDispatchToProps),
    mapProps(({ wording, ...rest }) => ({
        title: __('REACT_NEW_TASK'),
        isNote: false,
        ...rest
    }))
)(SidePanelAddNote);
