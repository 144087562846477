import { Asset } from './asset.types';
import { ContentUser } from './user.types';
import { BaseClientType } from './client.types';
import { Company } from './company.types';
import { ClientContactLabel } from './clientLabel.types';
import { Team } from './team.types';

export enum TaskColumn {
    ToDo = 'toDo',
    InProgress = 'inProgress',
    ToValidate = 'toValidate',
    Done = 'done'
}

export enum TaskStatus {
    TO_DO = 'TO_DO',
    IN_PROGRESS = 'IN_PROGRESS',
    TO_VALIDATE = 'TO_VALIDATE',
    DONE = 'DONE',
    DELETED = 'DELETE'
}
export type TaskAssignedUser = ContentUser & { labels: ClientContactLabel[] };
export type Task = {
    uuid: string;
    description: string;
    status: TaskStatus;
    number: number | null;
    ordering: number | null;
    estimatedEndDate: Date | null;
    endDate: Date | null;
    created: Date;
    updated: Date | null;
    assets: Asset[];
    note: {
        uuid: string;
        assets: Asset[];
        type: string;
        content?: string | null;
        file: { uuid: string; name: string; version: number; assets: Asset[] } | null;
        metadata?: any;
    } | null;
    team: Team;
    assignedUsers: TaskAssignedUser[];
    user: ContentUser | { email: string; uuid?: undefined };
    project: { uuid: string; name: string; isFavorite?: boolean };
    client: BaseClientType;
    company?: Company | null;
};

export interface TaskNoteUuid extends Omit<Task, 'note' | 'client'> {
    noteUuid: string;
}

export type TaskChange = {
    taskUuid: string;
    status: TaskStatus;
    ordering: number;
};
