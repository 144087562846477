import { __ } from '@he-novation/design-system/utils/i18n';
import {
    fetchFileView as _fetchFileView,
    fetchFolderView as _fetchFolderView,
    fetchSettingsView as _fetchSettingsView,
    fetchTrashView as _fetchTrashView,
    publicFileViewFetch as _publicFileViewFetch
} from '../content/contentAsync';
import { fetchPage } from './activity/activityActions';
import { fetchUsers } from './contacts/contactsActions';

import { asyncActionError, asyncActionSuccess } from '$helpers/asyncAction';

export const CONTENT = 'CONTENT';
export const RESET_CONTENT = `${CONTENT}/RESET`;
export const FETCH_FILE_VIEW = `${CONTENT}/FETCH_FILE_VIEW`;
export const PUBLIC_FILE_VIEW_FETCH = `${CONTENT}/PUBLIC_FILE_VIEW_FETCH`;
export const FETCH_FOLDER_VIEW = `${CONTENT}/FETCH_FOLDER_VIEW`;
export const FETCH_SETTINGS_VIEW = `${CONTENT}/FETCH_SETTINGS_VIEW`;
export const FETCH_TRASH_VIEW = `${CONTENT}/FETCH_TRASH_VIEW`;

export const resetContent = (prevUrl, url) => ({
    type: RESET_CONTENT,
    prevUrl,
    url
});

export const fetchContactsAndActivityPage = (page, filters, wording, isUserRoute) =>
    isUserRoute
        ? fetchPage(page, filters)
        : fetchUsers((dispatch) => dispatch(fetchPage(page, filters)));

export const fetchFileView =
    ({ shared, uuid, version, versionToCompare }, cb) =>
    async (dispatch) => {
        dispatch({ type: FETCH_FILE_VIEW });
        try {
            const state = await _fetchFileView({
                shared,
                uuid,
                version,
                versionToCompare
            });

            dispatch({ type: asyncActionSuccess(FETCH_FILE_VIEW), ...state });
            if (typeof cb === 'function') cb(state);
        } catch (e) {
            console.error(e);
            if (e.code === 404) {
                dispatch({ type: asyncActionError(FETCH_FILE_VIEW), error: e });
            }
            if (typeof cb === 'function') cb();
        }
    };

export const publicFileViewFetch =
    ({
        uuid,
        version,
        public_password,
        onError,
        castUid,
        castFolderUuid,
        castFileUuid
    }: {
        uuid: string;
        version: number;
        public_password?: string | null;
        onError?: (e: any) => void;
        castUid?: string;
        castFolderUuid?: string | null;
        castFileUuid?: string;
    }) =>
    async (dispatch) => {
        dispatch({ type: PUBLIC_FILE_VIEW_FETCH });
        try {
            const state = await _publicFileViewFetch({
                uuid,
                version,
                public_password,
                castUid,
                castFolderUuid,
                castFileUuid
            });
            dispatch({ type: asyncActionSuccess(PUBLIC_FILE_VIEW_FETCH), ...state });
        } catch (e) {
            if (typeof onError === 'function') onError(e);
        }
    };

export const fetchFolderView = (uuid, clientName, isSharedRoot, cb) => async (dispatch) => {
    dispatch({ type: FETCH_FOLDER_VIEW });
    let state;
    try {
        state = await _fetchFolderView(uuid, clientName, isSharedRoot);
        dispatch({ type: asyncActionSuccess(FETCH_FOLDER_VIEW), ...state });
    } catch (e) {
        dispatch({
            type: asyncActionError(FETCH_FOLDER_VIEW),
            error: e?.error?.message ? __(e?.error?.message) : null
        });
    }
    if (typeof cb === 'function') cb(state);
};

export const fetchSettingsView = (cb?: Function) => async (dispatch) => {
    dispatch({ type: FETCH_SETTINGS_VIEW });
    const r = await _fetchSettingsView();
    dispatch({ type: asyncActionSuccess(FETCH_SETTINGS_VIEW), ...r });
    if (typeof cb === 'function') cb(r);
};

export const fetchTrashView = (folderUuid?: string) => async (dispatch) => {
    dispatch({ type: FETCH_TRASH_VIEW });
    const r = await _fetchTrashView(folderUuid);
    dispatch({
        type: asyncActionSuccess(FETCH_TRASH_VIEW),
        ...r
    });
};
