export const isMediaType = (mimeType?: string | null) =>
    mimeType &&
    /^(image\/gif|audio|video|application\/mxf|image|application\/pdf|^application\/vnd\.adobe\.illustrator|application\/vnd\.adobe\.photoshop)/.test(
        mimeType
    );

export const isVideoType = (mimeType?: string | null) =>
    mimeType &&
    (/^image\/gif/.test(mimeType) ||
        /^application\/mxf/.test(mimeType) ||
        /^audio/.test(mimeType) ||
        /^video/.test(mimeType));

export const isVideoSource = (mimeType?: string | null) =>
    mimeType && /^video|application\/mxf/.test(mimeType);

export const mimeTypeToVideoExtension = (mimeType: string, withDot?: boolean) => {
    let ext: null | string = null;
    switch (true) {
        case /^video/.test(mimeType):
            if (/quicktime$/.test(mimeType)) ext = 'mov';
            else ext = mimeType.replace('video/', '');
            break;
        case /^application\/mxf/.test(mimeType):
            ext = 'mxf';
            break;
    }

    return ext && withDot ? `.${ext}` : ext;
};

export const canHaveSubtitles = (mimeType?: string | null) =>
    mimeType && (/^video/.test(mimeType) || /^application\/mxf/.test(mimeType));

export function mediaHasKnownIssues(exifResult: any) {
    if (
        !exifResult ||
        !exifResult.MIMEType ||
        typeof exifResult.MIMEType !== 'string' ||
        exifResult.MIMEType.length < 1
    ) {
        return true;
    }

    var res = false;

    switch (exifResult.MIMEType) {
        case 'image/vnd.fpx':
            if (!exifResult.ImageWidth || !exifResult.ImageHeight) {
                res = true;
            }

            break;
    }

    return res;
}

export const getFileType = function (mimeType?: string | null) {
    let fileType: 'gif' | 'audio' | 'video' | 'image' | 'pdf' | 'zip' | boolean = false;

    if (!mimeType) return fileType;
    switch (true) {
        case /^image\/gif/.test(mimeType):
            fileType = 'gif';
            break;

        case /^audio/.test(mimeType):
            fileType = 'audio';
            break;

        case /^video/.test(mimeType):
            fileType = 'video';
            break;

        case /^application\/mxf/.test(mimeType):
            fileType = 'video';
            break;

        case /^image/.test(mimeType):
            fileType = 'image';
            break;

        case /^application\/pdf/.test(mimeType):
            fileType = 'pdf';
            break;

        case /^application\/vnd\.adobe\.illustrator/.test(mimeType):
            fileType = 'pdf';
            break;

        case /^application\/vnd\.adobe\.photoshop/.test(mimeType):
            fileType = 'image';
            break;

        case /^application\/zip/.test(mimeType):
            fileType = 'zip';
            break;
    }

    return fileType;
};

export const getOfficeType = (fileName: string) => {
    if (!fileName) {
        return false;
    }
    if (/\.doc[x]?$/i.test(fileName)) return 'word';
    if (/\.xls[x]?$/i.test(fileName)) return 'excel';
    if (/\.ppt[x]?$/i.test(fileName)) return 'powerpoint';

    return false;
};

export const OFFICE_MIME_TYPES = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.ms-word.document.macroEnabled.12',
    'application/vnd.ms-word.template.macroEnabled.12',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.ms-excel.template.macroEnabled.12',
    'application/vnd.ms-excel.addin.macroEnabled.12',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.template',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.ms-powerpoint.addin.macroEnabled.12',
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
    'application/vnd.ms-powerpoint.template.macroEnabled.12',
    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
    'application/vnd.ms-access'
];

export function isOfficeMimeType(mimeType: string): boolean {
    return OFFICE_MIME_TYPES.includes(mimeType);
}

export const getPlayerType = function (mimeType: string): string | false {
    let playerType: string | false = false;

    switch (true) {
        case /^image\/gif/.test(mimeType):
            playerType = 'video';
            break;

        case /^audio/.test(mimeType):
            playerType = 'video';
            break;

        case /^video/.test(mimeType):
            playerType = 'video';
            break;

        case /^application\/mxf/.test(mimeType):
            playerType = 'video';
            break;

        case /^image/.test(mimeType):
            playerType = 'image';
            break;

        case /^application\/pdf/.test(mimeType):
            playerType = 'image';
            break;

        case /^application\/vnd\.adobe\.illustrator/.test(mimeType):
            playerType = 'image';
            break;

        case /^application\/vnd\.adobe\.photoshop/.test(mimeType):
            playerType = 'image';
            break;
    }

    return playerType;
};

const MIME_TYPES_WHITE_LIST = [
    'application/3ds', // autodesk
    'application/7z-compressed', // archive
    'application/bzip2', // archive
    'application/CDFV2', // office
    'application/compress', // archive
    'application/compressed', // archive
    'application/cpio', // archive
    'application/dbf', // database
    'application/dbt', // database
    'application/dfont', // font
    'application/dzip', // archive
    'application/font-otf', // font
    'application/font-ttf', // font
    'application/font-woff', // font
    'application/gzip', // archive
    'application/hdf', // archive
    'application/httpd-php', // script
    'application/indesign', // adobe
    'application/iwork-keynote-sffkey', // apple word
    'application/iwork-pages-sffpages', // apple word
    'application/javascript', // script
    'application/json', // document
    'application/mmxp', // adobe
    'application/msword', // office
    'application/mxf', // media
    'application/octet-stream', // binary
    'application/pdf', // document
    'application/pgp-keyring', // identity
    'application/photoshop', // adobe
    'application/pkcs7-signature', // identity
    'application/plist', // apple
    'application/postscript', // script
    'application/rar', // archive
    'application/rar-compressed', //archive
    'application/rdf+xml', // document
    'application/rtf', // office
    'application/shockwave-flash', // script
    'application/sqlite3', // database
    'application/stargallery-thm', // office
    'application/subrip', // subtitles
    'application/tar', // archive
    'application/vnd.3gpp.pic-bw-small', // adobe?
    'application/vnd.adobe.fla', // adobe
    'application/vnd.adobe.illustrator', // adobe
    'application/vnd.adobe.photoshop', // adobe
    'application/vnd.apple.mpegurl', // hls
    'application/vnd.apple.numbers', // apple spreadsheet
    'application/vnd.apple.pages', // apple word
    'application/vnd.audiograph', // adobe aftereffect?
    'application/vnd.iccprofile', // colors
    'application/vnd.mif', // adobe
    'application/vnd.ms-excel', // office
    'application/vnd.ms-excel.sheet.binary.macroenabled.12', // office
    'application/vnd.ms-fontobject', // font
    'application/vnd.ms-ims', // office
    'application/vnd.ms-opentype', // font
    'application/vnd.ms-powerpoint', // office
    'application/vnd.oasis.opendocument.spreadsheet', // office
    'application/vnd.oasis.opendocument.text', // office
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // office
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // office
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // office
    'application/vnd.rar', // archive
    'application/webarchive', // archive
    'application/xar', // archive
    'application/xml', // document
    'application/zip', // archive
    'application/zip-compressed', // archive
    'application/zlib', // archive
    'application/zxp', // archive
    'audio/aac', // audio
    'audio/aiff', // audio
    'audio/amr', // audio
    'audio/basic', // audio
    'audio/flac', // audio
    'audio/hx-aac-adts', // audio
    'audio/m4a', // audio
    'audio/midi', // audio
    'audio/mp3', // audio
    'audio/mp4', // audio
    'audio/mp4a-latm', // audio
    'audio/mpeg', // audio
    'audio/ms-wma', // audio
    'audio/ogg', // audio
    'audio/opus', // audio
    'audio/vnd.dlna.adts', // audio
    'audio/vnd.wave', // audio
    'audio/wav', // audio
    'audio/wave', // audio
    'audio/webm', // audio
    'font/otf', // font
    'font/ttf', // font
    'font/woff', // font
    'font/woff2', // font
    'image/adobe-dng', // image
    'image/apng', // image
    'image/avif', // image
    'image/bmp', // image
    'image/canon-cr2', // image
    'image/canon-cr3', // image
    'image/dpx', // image
    'image/exr', // image
    'image/fujifilm-raf', // image
    'image/gif', // image
    'image/heic', // image
    'image/heif', // image
    'image/icns', // image
    'image/jp2', // image
    'image/jpeg', // image
    'image/nikon-nef', // image
    'image/panasonic-rw2', // image
    'image/png', // image
    'image/portable-pixmap', // image
    'image/raw', // image
    'image/sony-arw', // image
    'image/svg+xml', // image
    'image/targa', // image
    'image/tga', // image
    'image/tiff', // image
    'image/vnd.adobe.photoshop', // image
    'image/vnd.dwg', // image
    'image/vnd.fpx', // image
    'image/vnd.microsoft.icon', // image
    'image/webp', // image
    'image/xcf', // image
    'inode/empty', // script
    'model/iges', // 3D
    'model/stl', // subtitle
    'model/mesh', // 3D
    'model/vnd.mts', // 3D
    'multipart/related',
    'text/css', // script
    'text/csv', // document
    'text/html', // script
    'text/javascript', // script
    'text/PGP', // identity
    'text/plain', // document
    'text/srt', // subtitles
    'text/rtf', // document
    'text/tab-separated-values', // document
    'text/vnd.trolltech.linguist', // script
    'text/srt', // subtitles
    'text/vtt', // subtitles
    'text/xml', // document
    'video/3gpp', // video
    'video/avi', // video
    'video/flv', // video
    'video/m2ts', // video
    'video/m4v', // video
    'video/matroska', // video
    'video/mp4', // video
    'video/mpeg', // video
    'video/ms-asf', // video
    'video/ms-wmv', // video
    'video/msvideo', // video
    'video/ogg', // video
    'video/quicktime', // video
    'video/webm' // video
];

export function isMimeTypeWhiteListed(mimeType: string): boolean {
    // Remove experimental 'x-' prefix to mime types
    const fixedMimeType = mimeType.replace('/x-', '/');

    return MIME_TYPES_WHITE_LIST.includes(fixedMimeType);
}
