import {
    ACTIVITY_FILTERS,
    CONTACT_GROUPS,
    CREATE_NOTE_PANEL,
    CREATE_TASK_PANEL,
    FINDER_PANEL,
    FOLDER_ACTIVITY,
    FOLDER_MEMBERS,
    FOLDER_SETTINGS,
    ITEM_KITS,
    NOTES_PANEL,
    NOTE_PANEL,
    PROJECT_INFO,
    PROJECT_MEMBERS,
    SUBTITLES,
    CAST_FINDER_PANEL
} from '$constants/constants.sidePanels';
import SidePanelGroups from './SidePanelGroups/SidePanelGroupsContainer';
import SidePanelActivityFilter from './SidePanelActivityFilter/SidePanelActivityFilter';
import SidePanelFinder from './SidePanelFinder/SidePanelFinder';
import SidePanelNotes from './SidePanelNotes/SidePanelNotesContainer';
import SidePanelAddNote from './SidePanelAddNote/SidePanelAddNoteContainer';
import SidePanelAddTask from './SidePanelAddNote/SidePanelAddTaskContainer';
import SidePanelNote from './SidePanelNote/SidePanelNoteContainer';
import SidePanelProjectInfo from '$components/SidePanel/SidePanelProjectInfo/SidePanelProjectInfoContainer';
import SidePanelProjectMembers from '$components/SidePanel/SidePanelProjectMembers/SidePanelProjectMembers';
import SidePanelItemKits from '../SidePanel/SidePanelItemKits/SidePanelItemKitsContainer';
import { SidePanelSubtitles } from './SidePanelSubtitles/SidePanelSubtitles';
import SidePanelFolderMembers from './SidePanelFolderMembers/SidePanelFolderMembers';
import SidePanelFolderSettings from './SidePanelFolderSettings/SidePanelFolderSettingsContainer';
import SidePanelActivity from './SidePanelActivity/SidePanelActivityContainer';
import { SidePanelCastFinder } from '$components/SidePanel/SidePanelCastFinder/SidePanelCastFinder';

export default {
    [ACTIVITY_FILTERS]: {
        titleKey: 'REACT_ACTIVITY_FILTER_TITLE',
        Component: SidePanelActivityFilter
    },
    [CONTACT_GROUPS]: {
        Component: SidePanelGroups
    },
    [CREATE_NOTE_PANEL]: {
        Component: SidePanelAddNote
    },
    [CREATE_TASK_PANEL]: {
        Component: SidePanelAddTask
    },
    [FINDER_PANEL]: {
        Component: SidePanelFinder
    },
    [CAST_FINDER_PANEL]: {
        Component: SidePanelCastFinder
    },
    [FOLDER_ACTIVITY]: {
        Component: SidePanelActivity
    },
    [FOLDER_MEMBERS]: {
        Component: SidePanelFolderMembers
    },
    [FOLDER_SETTINGS]: {
        Component: SidePanelFolderSettings
    },
    [ITEM_KITS]: {
        Component: SidePanelItemKits
    },
    [NOTES_PANEL]: {
        Component: SidePanelNotes
    },
    [NOTE_PANEL]: {
        Component: SidePanelNote
    },
    [PROJECT_INFO]: {
        Component: SidePanelProjectInfo
    },
    [PROJECT_MEMBERS]: {
        Component: SidePanelProjectMembers
    },
    [SUBTITLES]: {
        Component: SidePanelSubtitles
    }
};
