import SidePanelProject from '$components/SidePanel/SidePanelProjectInfo/SidePanelProjectInfo';
import { compose, branch, renderNothing } from 'recompose';
import { connect } from 'react-redux';
import {
    currentProjectSelector,
    isProjectManagerSelector
} from '../../../redux/content/projects/projectsSelector';
import { openModal } from '../../../redux/route/routeActions';
import { PROJECT_FORM } from '@he-novation/paths/modals.constants';
import combineSelectors from '$helpers/combineSelectors';
import { localeSelector } from '$redux/config/configSelectors';

export default compose(
    connect(
        combineSelectors(currentProjectSelector(), isProjectManagerSelector, localeSelector),
        (dispatch) => ({
            openEditProjectModal: (uuid) => dispatch(openModal(PROJECT_FORM, null, { uuid }))
        })
    ),
    branch(({ currentProject }) => !currentProject, renderNothing)
)(SidePanelProject);
