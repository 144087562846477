import './MemberList.scss';
import React, { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { ROLES } from '@he-novation/config/constants/projects.constants';
import { FolderRole } from '@he-novation/config/types/folder.types';
import { Theme } from '@he-novation/design-system/enums';
import cn from 'classnames';
import MemberItem from './MemberItem/MemberItem';

import { currentUserUuidSelector } from '$redux/user/userSelectors';

type Member = {
    uuid?: string;
    email: string;
    role?: FolderRole | ROLES;
};

type MemberListProps<T extends Member> = {
    displayEmail?: boolean;
    editable?: boolean;
    ownerUuid?: string;
    members: T[];
    onDelete: (member: T) => void;
    onDownloadChange?: (e: SyntheticEvent<HTMLInputElement>, member: T) => void;
    onExportChange?: (e: SyntheticEvent<HTMLInputElement>, member: T) => void;
    onAcceptAdmittance?: (member: T) => void;
    onRejectAdmittance?: (member: T) => void;
    onRoleChange?: (e: SyntheticEvent<HTMLSelectElement>, member: T) => void;
    theme?: Theme;
    role?: string;
    lockAdmins?: boolean;
    roles?: { label: string; value: string }[];
    canDeleteAdmins?: boolean;
};
export default function MemberList<T extends Member>({
    displayEmail = true,
    editable = false,
    ownerUuid,
    members,
    onDelete,
    onDownloadChange,
    onExportChange,
    onAcceptAdmittance,
    onRejectAdmittance,
    onRoleChange,
    theme = Theme.Dark,
    role,
    lockAdmins,
    roles,
    canDeleteAdmins
}: MemberListProps<T>) {
    const { currentUserUuid } = useSelector(currentUserUuidSelector);
    return (
        <ul className={cn('c-member-list', displayEmail && 'display-email', theme)}>
            {members.map((member, i) => (
                <li key={i}>
                    <MemberItem
                        canDeleteAdmins={canDeleteAdmins}
                        role={role}
                        roles={roles}
                        ownerUuid={ownerUuid}
                        member={member}
                        currentUserUuid={currentUserUuid}
                        displayEmail={displayEmail}
                        theme={theme}
                        onDelete={onDelete}
                        onDownloadChange={onDownloadChange}
                        onExportChange={onExportChange}
                        onRoleChange={onRoleChange}
                        editable={
                            (member.role !== FolderRole.ROLE_MANAGER || !lockAdmins) && editable
                        }
                        onAcceptAdmittance={onAcceptAdmittance}
                        onRejectAdmittance={onRejectAdmittance}
                    />
                </li>
            ))}
        </ul>
    );
}
