import { asyncActionSuccess } from '$helpers/asyncAction';
import {
    COMPANIES_FETCH,
    WS_COMPANIES_CREATE,
    WS_COMPANIES_UPDATE,
    WS_COMPANIES_DELETE,
    COMPANY_SET_PICTURE_ASSET
} from '../../content/companies/companiesActions';
import update from 'immutability-helper';
import { v4 } from 'uuid';

export const companiesInitialState = { isLoaded: false, list: [] };

export default (state = companiesInitialState, action = {}) => {
    switch (action.type) {
        case asyncActionSuccess(COMPANIES_FETCH):
            return update(state, {
                isLoaded: { $set: true },
                list: { $set: action.companies }
            });

        case COMPANY_SET_PICTURE_ASSET: {
            const i = state.list.findIndex(({ uuid }) => uuid === action.companyUuid);
            if (i === -1) return state;
            return update(state, {
                list: {
                    [i]: {
                        pictureAsset: {
                            $set: {
                                url: action.url,
                                urlExpires: null,
                                uuid: `tmp-${v4()}`,
                                key: action.key
                            }
                        }
                    }
                }
            });
        }

        case WS_COMPANIES_CREATE:
            return update(state, {
                list: { $push: [action.data] }
            });

        case WS_COMPANIES_UPDATE: {
            const i = state.list.findIndex(({ uuid }) => uuid === action.data.uuid);
            if (i === -1) return state;

            return update(state, {
                list: {
                    [i]: { $merge: action.data }
                }
            });
        }

        case WS_COMPANIES_DELETE: {
            const i = state.list.findIndex(({ uuid }) => uuid === action.data.uuid);
            if (i === -1) return state;

            return update(state, {
                list: { $splice: [[i, 1]] }
            });
        }
    }

    return state;
};
