import './Error.scss';
import React, {ReactNode} from 'react';

type ErrorProps = {
    error?: string | ReactNode;
};

export const Error: React.FC<ErrorProps> = ({ error }) => {
    const props = typeof error === 'string' ? { dangerouslySetInnerHTML: { __html: error }} : { children: error}
    return <div className="c-error" {...props}/>;
};
