import React from 'react';
import { v4 as uuid } from 'uuid';
import parentHasClass from '@he-novation/front-shared/utils/parentHasClass';

class WithClickOutside extends React.Component {
    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.registerCallback = this.registerCallback.bind(this);
        this.clickOutsideRef = React.createRef();
        if (this.props.options && this.props.options.createAndPassExcludedClass)
            this.excludedClass = uuid();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    registerCallback(callback) {
        this.callback = callback;
    }

    render() {
        const Component = this.props.Component;
        return (
            <Component
                {...this.props.componentProps}
                clickOutsideExcludedClass={this.excludedClass}
                clickOutsideRef={this.clickOutsideRef}
                onClickOutside={this.registerCallback}
            />
        );
    }

    handleClickOutside(e) {
        // check if right click => do nothing
        if (e.button <= 1 || (this.props.options && this.props.options.triggerOnRightClick)) {
            if (
                (this.clickOutsideRef && !this.clickOutsideRef.current) ||
                !this.clickOutsideRef.current.contains(e.target)
            ) {
                if (!this.targetHasExcludedClass(e.target)) {
                    if (typeof this.callback === 'function') {
                        this.callback(e);
                    } else {
                        /*eslint-disable */
                        console.warn('withClickOutside requires a callback');
                        /*eslint-enable */
                    }
                }
            }
        }
    }

    targetHasExcludedClass(target) {
        if (!this.props.options) return false;
        const excludedClasses = (this.props.options && this.props.options.excludedClasses) || [];
        if (this.excludedClass) excludedClasses.push(this.excludedClass);
        if (!excludedClasses.length) return false;
        return parentHasClass(target, ...excludedClasses);
    }
}

/*eslint-disable */
export default (Component, options) => (props) =>
    <WithClickOutside options={options} Component={Component} componentProps={props} />;
/*eslint-enable */
