import './FiltersCheckboxList.scss';
import React from 'react';
import {
    StyledFormField,
    FormFieldTypes
} from '@he-novation/design-system/components/form/StyledFormField/StyledFormField';
import { withProps } from 'recompose';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import Icon from '@he-novation/icons';
import { __ } from '@he-novation/design-system/utils/i18n';

class FiltersCheckboxList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            seeAll: false,
            search: null,
            searchActive: false
        };
        this.toggleSeeAll = this.toggleSeeAll.bind(this);
        this.toggleSearch = this.toggleSearch.bind(this);
        this.setSearch = this.setSearch.bind(this);
        this.resetSearch = this.resetSearch.bind(this);
    }

    render() {
        const { title, hasSearch } = this.props;
        const list = this.filter();

        const filtersListLess =
            (this.props.wording && this.props.filtersListLess) ||
            __('REACT_ACTIVITY_FILTER_LIST_LESS');
        const filtersListMore =
            (this.props.wording && this.props.filtersListLess) ||
            __('REACT_ACTIVITY_FILTER_LIST_MORE');

        return (
            <div className="c-filters-checkbox-list">
                {title && <h3>{title}</h3>}
                {hasSearch && (
                    <Button
                        tone={ButtonTone.Hoverable}
                        type="button"
                        icon={'magnifier'}
                        onClick={this.toggleSearch}
                    />
                )}
                {this.renderSearchField()}
                <div
                    className={
                        this.state.seeAll
                            ? 'c-filter-checkbox-list-container'
                            : 'c-filter-checkbox-list-container-small'
                    }
                >
                    {this.renderList(list)}
                </div>
                <button className="see-more" type="button" onClick={this.toggleSeeAll}>
                    {this.state.seeAll ? filtersListLess : filtersListMore}
                </button>
            </div>
        );
    }

    filter() {
        if (!this.state.search) return this.props.list;
        return this.props.list.filter(
            ({ label }) => label.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1
        );
    }

    renderList(list) {
        const { filters, filterName, formId, onChange } = this.props;
        const { search } = this.state;

        const Component = this.props.Component || StyledFormField;

        list = list.sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));
        if (filters[filterName] && filters[filterName].length) {
            list = list.sort((a, b) =>
                filters[filterName].includes(a.value) && !filters[filterName].includes(b.value)
                    ? -1
                    : !filters[filterName].includes(a.value) &&
                      filters[filterName].includes(b.value)
                    ? 1
                    : 0
            );
        }

        return list.map((checkbox, i) => (
            <Component
                formId={formId}
                id={`filter-checkbox-${filterName}-${i}`}
                key={checkbox.value}
                className={this.props.fieldClassName}
                type={Component === StyledFormField ? FormFieldTypes.Checkbox : null}
                {...checkbox}
                search={search}
                checked={filters[filterName] && filters[filterName].indexOf(checkbox.value) > -1}
                onChange={(e) => onChange(filterName, e)}
            />
        ));
    }

    renderSearchField() {
        if (this.props.hasSearch && this.state.searchActive) {
            return (
                <div className="c-filters-checkbox-list-search">
                    <StyledFormField
                        autoFocus
                        className="search-field"
                        formId={this.props.formId}
                        onChange={this.setSearch}
                        value={this.state.search || ''}
                        type={FormFieldTypes.Text}
                    />
                    {this.state.search && (
                        <button type="button" onClick={this.resetSearch}>
                            <Icon icon="cross2" />
                        </button>
                    )}
                </div>
            );
        }
    }

    toggleSeeAll() {
        this.setState({
            seeAll: !this.state.seeAll
        });
    }

    toggleSearch() {
        this.setState({
            searchActive: !this.state.searchActive
        });
    }

    setSearch(e) {
        this.setState({
            search: e.target.value
        });
    }

    resetSearch() {
        this.setState({
            search: null
        });

        document.querySelector('.search-field input').focus();
    }
}

/*FiltersCheckboxList.propTypes = {
    fieldClassName: PropTypes.string,
    hasSearch: PropTypes.bool,
    title: PropTypes.string,
    formId: PropTypes.string,
    list: PropTypes.arrayOf(
        PropTypes.shape({
            formId: PropTypes.string,
            label: PropTypes.node,
            type: PropTypes.string
        })
    ),
    filters: PropTypes.object,
    onChange: PropTypes.func,
    locale: PropTypes.string,
    filterName: PropTypes.string,
    Component: PropTypes.func,
    wording: PropTypes.shape({
        filtersListLess: PropTypes.string,
        filtersListMore: PropTypes.string
    })
};*/

export default withProps(({ filters = {} }) => ({
    filters: Object.keys(filters).reduce(
        (acc, curr) =>
            Array.isArray(filters[curr])
                ? { ...acc, [curr]: filters[curr] }
                : { ...acc, [curr]: [filters[curr]] },
        {}
    )
}))(FiltersCheckboxList);
