import React from 'react';
import './SidePanelGroups.scss';
import SidePanelList from '../../SidePanel/SidePanelList/SidePanelListContainer';
import { contactGroupLink } from '@he-novation/paths/herawFrontUris';
import { SidePanelHeader } from '@he-novation/design-system/components/sidePanels/SidePanel/SidePanelHeader/SidePanelHeader';
import { __ } from '@he-novation/design-system/utils/i18n';
import { getContactGroupContextMenu } from '$components/rightClickMenus/getContactGroupContextMenu';

const SidePanelGroups = ({
    addGroup,
    groups: { list },
    routeParams,
    editGroup,
    deleteGroup,
    hasClientRightContactAdmin
}) => (
    <>
        <SidePanelHeader
            title={__('CONTACT_GROUPS')}
            buttons={[
                {
                    icon: 'users-add',
                    onClick: addGroup
                }
            ]}
        />
        <div className="c-side-panel-groups">
            <SidePanelList
                items={list.map(({ name, count_contacts, uuid, isClientGroup }) => ({
                    name,
                    count: count_contacts,
                    href: contactGroupLink(uuid),
                    uuid,
                    isClientGroup,
                    className: isClientGroup ? 'is-client-group' : undefined
                }))}
                contextMenuActions={getContactGroupContextMenu}
                canEdit={({ isClientGroup }) => !isClientGroup || hasClientRightContactAdmin}
                editItem={editGroup}
                deleteItem={({ uuid }) => deleteGroup(uuid, routeParams)}
            />
        </div>
    </>
);

export default SidePanelGroups;
