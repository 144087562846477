import './ProjectTeamsPicker.scss';
import React from 'react';
import {
    StyledFormField,
    FormFieldTypes
} from '@he-novation/design-system/components/form/StyledFormField/StyledFormField';
import cn from 'classnames';
import { Theme } from '@he-novation/design-system/enums';
import { TEAM_CAST, TEAM_ADMIN } from '@he-novation/config/constants/projects.constants';
import { __ } from '@he-novation/design-system/utils/i18n';

export const getTeamName = (teamName) => {
    if (teamName === TEAM_ADMIN) return __('ADMINISTRATORS');
    if (teamName === TEAM_CAST) return __('CAST_TEAM');
    return teamName;
};

const ProjectTeamsPicker = ({ isSmall, teams, onTeamChange, selectedTeam, setSelectedTeam }) => {
    const teamIndex = teams.findIndex(({ uuid }) => uuid === selectedTeam);

    const options = teams.map(({ uuid, name }) => ({
        label: getTeamName(name),
        value: uuid
    }));

    return (
        <StyledFormField
            className={cn(
                'c-project-teams-picker',
                `selected-team-${teamIndex > -1 ? teamIndex : 0}`,
                teams[teamIndex]?.name === TEAM_CAST && 'is-cast',
                { 'is-small': isSmall }
            )}
            id="project-tasks-header-team-selector"
            name="team"
            theme={Theme.Dark}
            type={FormFieldTypes.Select}
            value={selectedTeam || teams[0].uuid}
            onChange={(e) => {
                setSelectedTeam(e.target.value);
                if (typeof onTeamChange === 'function') onTeamChange(e);
            }}
            options={options}
        />
    );
};

export default ProjectTeamsPicker;
