import SidePanelNotes from './SidePanelNotes';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import {
    fileTypeSelector,
    fileUuidSelector,
    folderUuidSelector,
    pageAndPageNumberSelector
} from '$redux/content/file/fileSelectors';
import combineSelectors from '../../../helpers/combineSelectors';
import { routeParamsSelector } from '$redux/route/routeSelectors';
import { folderRoleSelector } from '$redux/content/folder/folderSelectors';
import { currentProjectUuidSelector } from '$redux/content/projects/projectsSelector';
import { castNotesSelector, notesSelector } from '$redux/content/note/noteSelectors';
import { preferencesSelector } from '$redux/user/userSelectors';

const mapStateToProps = combineSelectors(
    notesSelector,
    pageAndPageNumberSelector,
    currentProjectUuidSelector,
    folderUuidSelector,
    routeParamsSelector,
    fileUuidSelector,
    folderRoleSelector,
    castNotesSelector,
    preferencesSelector,
    fileTypeSelector
);

export default compose(
    connect(mapStateToProps),
    withProps(({ notes }) => ({
        sorters: notes.find((n) => n.metadata?.tcIn) ? ['timecode', 'date'] : []
    }))
)(SidePanelNotes);
