import React from 'react';
import { StyledFormField } from '@he-novation/design-system/components/form/StyledFormField/StyledFormField';
import { ExtraFormFieldTypes } from '@he-novation/design-system/enums';

const FiltersDate = ({ title, label, formId, filterName, onChange, filters, locale }) => (
    <div className="c-filters-date">
        {title && <h3>{title}</h3>}
        <StyledFormField
            id={`filter-date-${filterName}`}
            name={filterName}
            type={ExtraFormFieldTypes.DatePicker}
            formId={formId}
            value={filters[filterName] ? new Date(filters[filterName]) : undefined}
            label={label}
            direction="TOP"
            onSelect={(e, date) => onChange(filterName, date)}
            onChange={(e, date, a) => {
                if (date instanceof Date) {
                    onChange(filterName, date);
                }
            }}
        />
    </div>
);

export default FiltersDate;
