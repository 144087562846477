import './SidePanelFolderSettings.scss';
import React, { useEffect, useRef, useState } from 'react';
import type { FormStore } from 'react-modular-forms/dist/declarations/FormStore';
import { useSelector } from 'react-redux';
import { FolderLiveNotify, FolderRole, FullFolder } from '@he-novation/config/types/folder.types';
import {
    FolderNotificationBody,
    FolderUpdateBody
} from '@he-novation/config/types/payloads/folder.payload';
import { acl, ROLE_MANAGER, ROLE_OWNER } from '@he-novation/config/utils/acl';
import {
    AvatarSize,
    AvatarUser
} from '@he-novation/design-system/components/avatars/Avatar/AvatarUser';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { CopyField } from '@he-novation/design-system/components/form/CopyField/CopyField';
import {
    FormField,
    FormFields,
    ModularForm
} from '@he-novation/design-system/components/form/FormField/FormField';
import { SidePanelHeader } from '@he-novation/design-system/components/sidePanels/SidePanel/SidePanelHeader/SidePanelHeader';
import { Theme } from '@he-novation/design-system/enums';
import { __ } from '@he-novation/design-system/utils/i18n';
import {
    asyncFolderFetchNotificationSettings,
    asyncFolderUpdate,
    asyncFolderUpdateNotificationSettings,
    fetchFolderSize
} from '@he-novation/front-shared/async/folder.async';
import Icon from '@he-novation/icons';
import { folderPublicLink } from '@he-novation/paths/herawFrontUris';
import { admittanceLink } from '@he-novation/paths/herawMarketingPaths';
import { bytesToSize } from '@he-novation/utils/bytes';
import cn from 'classnames';
import union from 'lodash/union';
import { PubSubEvent, useSubscribe } from '../../../hooks/useSubscribe';
import { SidePanelFolderSettingsCheckbox } from './SidePanelFolderSettingsCheckbox/SidePanelFolderSettingsCheckbox';

import { getLocalizedDate } from '$helpers/datetime';
import { localeSelector } from '$redux/config/configSelectors';
import { folderPluginsSelector } from '$redux/content/folder/folderSelectors';
import { preferencesSelector } from '$redux/user/userSelectors';

const dataToNotify = (data) => {
    if (!data['folder-notify-email'] && !data['folder-notify-desktop']) {
        return false;
    }
    if (data['folder-notify-email'] && data['folder-notify-desktop']) {
        return 'all';
    }
    if (data['folder-notify-email']) return 'email';
    return 'desktop';
};

const dataToLivenotify = (data, role: FolderRole): FolderNotificationBody => {
    const notify = dataToNotify(data);
    return data.livenotify
        ? {
              access_grant: [ROLE_MANAGER, ROLE_OWNER].includes(role)
                  ? data['folder-notify-invite'] && notify
                  : false,
              comment_add: data['folder-notify-comment'] && notify,
              file_download: data['folder-notify-download'] && notify,
              file_export: data['folder-notify-export'] && notify,
              file_new: data['folder-notify-new'] && notify,
              file_set_final: data['folder-notify-final'] && notify
          }
        : {};
};

const dataToSettings = (data, triggers, tags, labels): FolderUpdateBody => {
    const _triggers = triggers.filter((trigger) => !/metadata|bundles/.test(trigger));
    if (data['trigger-metadata']) _triggers.push('metadata');
    if (data['trigger-bundles']) _triggers.push('bundles');

    const payload: FolderUpdateBody = {
        admittance: data.admittance,
        default_presets: data.bypass,
        export_mode: data.export ? data.export_mode : 'never',
        labels: labels.filter((l) => l),
        encrypted: data.encrypted,
        public: data.public,
        public_download: data['folder-option-download-public'],
        public_password: data['folder-settings-form-public-password'] || '',
        tags: tags.filter((t) => t),
        triggers: _triggers,
        watermark: data.watermark
    };

    payload.metadata = {
        admittance: data.admittance && {
            role: data['admittance-role'],
            download: data['admittance-download'],
            export: data['admittance-export']
        },
        notes: data.description
    };

    return payload;
};

type SidePanelFolderSettingsProps = {
    openRecursiveSettingsModal: Function;
    updatePreferences: (preferences: Record<string, unknown>) => void;
    folder: FullFolder;
};
export const SidePanelFolderSettings = ({
    openRecursiveSettingsModal,
    updatePreferences,
    folder
}: SidePanelFolderSettingsProps) => {
    const { folderPlugins } = useSelector(folderPluginsSelector);
    const [initialized, setInitialized] = useState(false);
    const [tab, setTab] = useState('info');
    const [size, setSize] = useState<number | string>('-');
    const [tags, setTags] = useState(folder.tags);
    const [labels, setLabels] = useState(folder.labels);
    const { locale } = useSelector(localeSelector);
    const [livenotify, setLivenotify] = useState<Partial<FolderLiveNotify>>();

    const shared = folder.role !== ROLE_OWNER;
    const [liveNotifyDisplay, setLiveNotifyDisplay] = useState(false);
    const form = useRef<FormStore>(null);
    const readOnly = !acl.folders.modify(folder.role);
    const { preferences } = useSelector(preferencesSelector);
    const labelRef = useRef<HTMLDivElement>(null);
    const tagRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (initialized) submit();
    }, [initialized, tags, labels]);

    useEffect(() => {
        Promise.all([
            asyncFolderFetchNotificationSettings(folder.uuid),
            fetchFolderSize(folder.uuid)
        ]).then(([livenotify, { size }]) => {
            setLivenotify(livenotify || {});
            setSize(size);
            if (livenotify) setLiveNotifyDisplay(true);
            setTimeout(() => setInitialized(true), 500);
        });
    }, [folder.uuid]);

    useSubscribe(
        PubSubEvent.FileUpload,
        () => {
            fetchFolderSize(folder.uuid).then(({ size }) => {
                setSize(size);
            });
        },
        [folder.uuid]
    );

    const submit = () => {
        const l = folder.labels;
        const t = folder.tags;

        const labelsToAddToPreferences = labels.filter((la) => !l.find((pT) => pT === la));
        const tagsToAddToPreferences = tags.filter((ta) => !t.find((pT) => pT === ta));

        if (labelsToAddToPreferences.length || tagsToAddToPreferences.length) {
            updatePreferences({
                ...preferences,
                encrypted: folder.isEncrypted,
                metadata: folder.metadata,
                public: folder.isPublic,
                public_download: folder.publicDownload,
                public_password: folder.publicPassword,
                watermark: folder.watermark,
                export_mode: folder.exportMode,
                default_presets: folder.defaultPresets,
                admittance: folder.admittance,
                export: folder.canExport,
                triggers: folder.triggers,
                download: folder.canDownload,
                tags: union(preferences.tags, t, tagsToAddToPreferences).filter((v) => v),
                labels: union(preferences.labels, l, labelsToAddToPreferences).filter((v) => v)
            });
        }

        const data = form.current?.getValues();
        if (acl.folders.modify(folder.role)) {
            asyncFolderUpdate(folder.uuid, dataToSettings(data, folder.triggers, tags, labels));
        }
        asyncFolderUpdateNotificationSettings(folder.uuid, dataToLivenotify(data, folder.role));
    };

    const folderAdmittance = folder.metadata?.admittance ? folder.metadata.admittance : null;

    return (
        <div id="c-side-panel-folder-settings">
            <SidePanelHeader title={__('PROPERTIES')} />
            <div className="tab-buttons">
                <Button
                    tone={tab === 'info' ? ButtonTone.Light : ButtonTone.Medium}
                    onClick={() => setTab('info')}
                >
                    {__('INFORMATION')}
                </Button>
                <Button
                    tone={tab === 'settings' ? ButtonTone.Light : ButtonTone.Medium}
                    onClick={() => setTab('settings')}
                >
                    {__('SETTINGS')}
                </Button>
            </div>
            <ModularForm id="folder-settings-form" ref={form} outputEmptyFields>
                <div className={cn('tab', 'info', tab === 'info' && 'is-active')}>
                    <section className="folder-owner">
                        <AvatarUser {...folder.user} size={AvatarSize.Large} />
                        <div className="folder-owner-text">
                            <p>
                                {__('FOLDER_INFORMATIONS_CREATED')}{' '}
                                {getLocalizedDate(new Date(folder.created), locale)}
                            </p>
                            <p>
                                {__('FOLDER_INFORMATIONS_MODIFIED')}{' '}
                                {getLocalizedDate(
                                    new Date(folder.updated || folder.created),
                                    locale
                                )}
                            </p>
                        </div>
                    </section>

                    <section className="folder-size">
                        <Icon icon="folder-full" />{' '}
                        {typeof size === 'number' ? bytesToSize(size) : size}
                    </section>

                    <FormFields
                        formId="folder-settings-form"
                        theme={Theme.Dark}
                        fields={[
                            {
                                type: 'react-select',
                                label: __('LABELS'),
                                name: 'labels',
                                isMulti: true,
                                isSearchable: true,
                                creatable: true,
                                after: <div ref={labelRef} className={'react-select-tags-list'} />,
                                multiContainerRef: labelRef,
                                onCreateOption: (value: string) => {
                                    const newLabels = [...labels, value];
                                    setLabels(newLabels);
                                },
                                onChange: (e, l) => {
                                    setLabels(l);
                                },
                                value: labels,
                                options:
                                    preferences.labels?.map((label) => ({
                                        value: label,
                                        label: label
                                    })) || []
                            },
                            {
                                id: 'folder-settings-form-description',
                                label: __('DESCRIPTION'),
                                type: 'textarea',
                                value: folder.metadata?.notes,
                                name: 'description',
                                readOnly,
                                onBlur: () => submit()
                            },
                            {
                                type: 'react-select',
                                label: __('FILE_INFO_TAGS'),
                                name: 'tags',
                                isMulti: true,
                                isSearchable: true,
                                creatable: true,
                                after: <div ref={tagRef} className={'react-select-tags-list'} />,
                                multiContainerRef: tagRef,
                                onCreateOption: (value: string) => {
                                    const newTags = [...tags, value];
                                    setTags(newTags);
                                },
                                onChange: (e, t) => {
                                    setTags(t);
                                },
                                value: tags,
                                options:
                                    preferences.tags?.map((tag) => ({
                                        value: tag,
                                        label: tag
                                    })) || []
                            }
                        ]}
                    />
                </div>

                <div className={cn('tab', tab === 'settings' && 'is-active')}>
                    <SidePanelFolderSettingsCheckbox
                        formId="folder-settings-form"
                        name="livenotify"
                        id="folder-settings-form-livenotify"
                        label={__('LIVENOTIFY_FOLDER_CHECKBOX')}
                        checked={liveNotifyDisplay}
                        onChange={() => {
                            setLiveNotifyDisplay(!liveNotifyDisplay);
                            submit();
                        }}
                    >
                        <div className="icon-checkboxes">
                            <FormField
                                formId="folder-settings-form"
                                name="folder-notify-desktop"
                                id="folder-notify-desktop"
                                className="icon-checkbox"
                                type={'checkbox'}
                                onChange={() => submit()}
                                checked={
                                    livenotify
                                        ? Object.keys(livenotify).reduce(
                                              (acc, key) =>
                                                  acc ||
                                                  (typeof livenotify![key] === 'string' &&
                                                      /desktop|all/.test(livenotify![key])),
                                              false
                                          )
                                        : false
                                }
                                label={
                                    <>
                                        <Icon icon="screen" /> {__('DESKTOP')}
                                    </>
                                }
                            />
                            <FormField
                                formId="folder-settings-form"
                                name="folder-notify-email"
                                id="folder-notify-email"
                                className="icon-checkbox"
                                type={'checkbox'}
                                onChange={() => submit()}
                                checked={
                                    livenotify
                                        ? Object.keys(livenotify).reduce(
                                              (acc, key) =>
                                                  acc ||
                                                  (typeof livenotify[key] === 'string' &&
                                                      /email|all/.test(livenotify[key])),
                                              false
                                          )
                                        : false
                                }
                                label={
                                    <>
                                        <Icon icon="email" /> {__('EMAIL')}
                                    </>
                                }
                            />
                        </div>
                        <FormFields
                            formId="folder-settings-form"
                            theme={Theme.Dark}
                            fields={[
                                [ROLE_MANAGER, ROLE_OWNER].includes(folder.role) && {
                                    name: 'folder-notify-invite',
                                    id: 'folder-notify-invite',
                                    type: 'checkbox',
                                    onChange: () => submit(),
                                    checked: livenotify?.access_grant,
                                    label: __('LOGS_TAGS_ACCESS_GRANT')
                                },
                                {
                                    name: 'folder-notify-comment',
                                    id: 'folder-notify-comment',
                                    type: 'checkbox',
                                    onChange: () => submit(),
                                    checked: livenotify?.comment_add,
                                    label: __('LOGS_TAGS_COMMENT_ADD')
                                },
                                {
                                    name: 'folder-notify-download',
                                    id: 'folder-notify-download',
                                    type: 'checkbox',
                                    onChange: () => submit(),
                                    checked: livenotify?.file_download,
                                    label: __('LOGS_TAGS_FILE_DOWNLOAD')
                                },
                                {
                                    name: 'folder-notify-export',
                                    id: 'folder-notify-export',
                                    type: 'checkbox',
                                    onChange: () => submit(),
                                    checked: livenotify?.file_export,
                                    label: __('LOGS_TAGS_FILE_EXPORT')
                                },
                                {
                                    name: 'folder-notify-new',
                                    id: 'folder-notify-new',
                                    type: 'checkbox',
                                    onChange: () => submit(),
                                    checked: livenotify?.file_new,
                                    label: __('LOGS_TAGS_FILE_NEW')
                                },
                                {
                                    name: 'folder-notify-final',
                                    id: 'folder-notify-final',
                                    type: 'checkbox',
                                    onChange: () => submit(),
                                    checked: livenotify?.file_set_final,
                                    label: __('LOGS_TAGS_FILE_SET_FINAL')
                                }
                            ]}
                        />
                    </SidePanelFolderSettingsCheckbox>

                    {!readOnly && (
                        <div>
                            {folderPlugins.includes('public') && (
                                <SidePanelFolderSettingsCheckbox
                                    formId="folder-settings-form"
                                    name="public"
                                    id="folder-settings-form-public"
                                    onChange={() => submit()}
                                    label={__('CHECKBOX_PRIVATE_FOLDER')}
                                    checked={!!folder.isPublic}
                                >
                                    <CopyField
                                        id="folder-settings-form-public-link"
                                        value={
                                            process.env.APP_URL +
                                            folderPublicLink(folder.uuid, folder.user.uuid)
                                        }
                                        theme={Theme.Dark}
                                    />
                                    <CopyField
                                        formId="folder-settings-form"
                                        name="folder-settings-form-public-password"
                                        id="folder-settings-form-public-password"
                                        placeholder={__('PASSWORD')}
                                        readOnly={false}
                                        onChange={() => submit()}
                                        value={folder.publicPassword}
                                        theme={Theme.Dark}
                                    />

                                    <FormField
                                        formId="folder-settings-form"
                                        name="folder-option-download-public"
                                        id="folder-option-download-public"
                                        label={__('RIGHTS_DOWNLOAD_PUBLIC')}
                                        onChange={() => submit()}
                                        type="checkbox"
                                        theme={Theme.Dark}
                                        checked={!!folder.publicDownload}
                                    />
                                </SidePanelFolderSettingsCheckbox>
                            )}

                            {folderPlugins.includes('admittance') && (
                                <SidePanelFolderSettingsCheckbox
                                    formId="folder-settings-form"
                                    name="admittance"
                                    id="folder-settings-form-admittance"
                                    label={__('CHECKBOX_ADMITTANCE_FOLDER')}
                                    onChange={() => submit()}
                                    checked={!!folder.admittance}
                                >
                                    <CopyField
                                        id="folder-settings-form-admittance-link"
                                        theme={Theme.Dark}
                                        value={
                                            process.env.MARKETING_URL +
                                            admittanceLink(locale, folder.client.name, folder.uuid)
                                        }
                                    />

                                    <FormFields
                                        formId="folder-settings-form"
                                        theme={Theme.Dark}
                                        fields={[
                                            {
                                                name: 'admittance-role',
                                                id: 'folder-admittance-role',
                                                type: 'select',
                                                onChange: () => submit(),
                                                value: folderAdmittance?.role,
                                                children: acl.content.showRoles().map((key) => (
                                                    <option value={key} key={key}>
                                                        {__(key)}
                                                    </option>
                                                ))
                                            },
                                            {
                                                name: 'admittance-download',
                                                id: 'folder-admittance-download',
                                                type: 'checkbox',
                                                onChange: () => submit(),
                                                checked: folderAdmittance?.download,
                                                label: __('RIGHTS_DOWNLOAD')
                                            },
                                            {
                                                name: 'admittance-export',
                                                id: 'folder-admittance-export',
                                                onChange: () => submit(),
                                                checked: folderAdmittance?.export,
                                                type: 'checkbox',
                                                label: __('RIGHTS_EXPORT')
                                            }
                                        ]}
                                    />
                                </SidePanelFolderSettingsCheckbox>
                            )}

                            <SidePanelFolderSettingsCheckbox
                                formId="folder-settings-form"
                                id="folder-settings-form-export"
                                name="export"
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        (
                                            document.getElementById(
                                                'folder-option-export'
                                            ) as HTMLInputElement
                                        ).value = 'always';
                                    }
                                    submit();
                                }}
                                label={__('FOLDER_OPTION_EXPORT')}
                                checked={folder.exportMode !== 'never'}
                            >
                                <FormField
                                    formId="folder-settings-form"
                                    name="export_mode"
                                    id="folder-option-export"
                                    type="select"
                                    value={folder.exportMode}
                                    onChange={() => submit()}
                                    theme={Theme.Dark}
                                >
                                    <option value="always">
                                        {__('FOLDER_OPTION_EXPORT_ALWAYS')}
                                    </option>
                                    <option value="final">
                                        {__('FOLDER_OPTION_EXPORT_FINAL')}
                                    </option>
                                </FormField>
                            </SidePanelFolderSettingsCheckbox>

                            {folderPlugins.includes('watermark') && (
                                <SidePanelFolderSettingsCheckbox
                                    formId="folder-settings-form"
                                    onChange={() => submit()}
                                    label={__('CHECKBOX_WATERMARK_FOLDER')}
                                    id="folder-plugin-watermark"
                                    name="watermark"
                                    checked={!!folder.watermark}
                                />
                            )}

                            {folderPlugins.includes('bypass') && (
                                <SidePanelFolderSettingsCheckbox
                                    onChange={() => submit()}
                                    formId="folder-settings-form"
                                    label={__('CHECKBOX_DEFAULT_PRESETS_FOLDER')}
                                    id="folder-plugin-bypass"
                                    name="bypass"
                                    checked={!!folder.defaultPresets}
                                />
                            )}

                            {folderPlugins.includes('encrypted') && (
                                <SidePanelFolderSettingsCheckbox
                                    onChange={() => submit()}
                                    formId="folder-settings-form"
                                    label={__('CHECKBOX_ENCRYPTED_FOLDER')}
                                    id="folder-encrypted"
                                    name="encrypted"
                                    checked={!!folder.isEncrypted}
                                />
                            )}

                            {folderPlugins.includes('bundles') && (
                                <SidePanelFolderSettingsCheckbox
                                    onChange={() => submit()}
                                    formId="folder-settings-form"
                                    label={__('CHECKBOX_TRIGGER_BUNDLES_FOLDER')}
                                    id="folder-plugin-bundles"
                                    name="trigger-bundles"
                                    checked={folder.triggers.includes('bundles')}
                                />
                            )}

                            {folderPlugins.includes('metadata') && (
                                <SidePanelFolderSettingsCheckbox
                                    onChange={() => submit()}
                                    formId="folder-settings-form"
                                    label={__('CHECKBOX_TRIGGER_METADATA_FOLDER')}
                                    id="folder-plugin-metadata"
                                    name="trigger-metadata"
                                    checked={folder.triggers.includes('metadata')}
                                />
                            )}
                        </div>
                    )}
                </div>
            </ModularForm>

            <button
                className="recursive-button"
                onClick={() =>
                    openRecursiveSettingsModal(
                        folder.uuid,
                        dataToSettings(form.current?.getValues(), folder.triggers, tags, labels),
                        shared,
                        folder.client.name
                    )
                }
            >
                {__('RECURSIVE_ASK_BUTTON')} <Icon icon="list" />
            </button>
        </div>
    );
};
