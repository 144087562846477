import { modalFormFactory } from '@he-novation/design-system/components/modals/ModalForm/ModalForm';
import { FormClient } from '../../form/FormClient';
import { connect } from 'react-redux';
import { closeModal, openFeedbackModal } from '$redux/route/routeActions';
import { __ } from '@he-novation/design-system/utils/i18n';
import { pluginsSelector } from '$redux/user/userSelectors';
import { asyncClientCreate, asyncClientUpdate } from '@he-novation/front-shared/async/client.async';
import update from 'immutability-helper';

export const ModalFormClient = connect(
    pluginsSelector,
    (dispatch, { payload: { data, setClients } }) => ({
        submit: async (e, payload) => {
            e.preventDefault();
            try {
                if (data?.uuid) {
                    const client = await asyncClientUpdate(data.uuid, payload);

                    setClients((clients) =>
                        update(clients, {
                            [clients.findIndex((i) => i.uuid === data.uuid)]: {
                                $set: client
                            }
                        })
                    );
                } else {
                    const client = await asyncClientCreate(payload);
                    setClients((clients) => [...clients, client]);
                }
                dispatch(closeModal());
            } catch (e) {
                if (e.message) dispatch(openFeedbackModal(__(e.message)));
            }
        }
    })
)(
    modalFormFactory(FormClient, (payload) =>
        __(payload.data?.uuid ? 'CLIENT_UPDATE' : 'CLIENT_CREATE')
    )
);
