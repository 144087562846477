import { CONFIRM } from '@he-novation/paths/modals.constants';
import { ClientGuests } from '$views/client/ClientGuests/ClientGuests';
import { __ } from '@he-novation/design-system/utils/i18n';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { clientGuestsFetch, clientUserRemove } from '../../../redux/client/clientActions';
import { clientGuestsSelector } from '$redux/client/clientSelectors';
import { closeModal, openModal } from '../../../redux/route/routeActions';

export default compose(
    connect(clientGuestsSelector, (dispatch) => ({
        fetchClientGuests: () => dispatch(clientGuestsFetch()),
        clientLicenseCancel: ({ licenseUuid, userUuid }) =>
            dispatch(
                openModal(CONFIRM, {
                    message: __('CLIENT_LICENSE_CANCEL_QUESTION_TXT'),
                    alert: true,
                    onSubmit: () => {
                        dispatch(
                            clientUserRemove({ licenseUuid, userUuid }, () =>
                                dispatch(closeModal())
                            )
                        );
                    }
                })
            )
    }))
)(ClientGuests);
