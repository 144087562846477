import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Note } from '@he-novation/config/types/note.types';
import { applyNoteFilters } from '@he-novation/config/utils/noteUtils';
import NotesFilters from './NotesFilters/NotesFiltersContainer';
import NotesList from './NotesList/NotesListContainer';

import { fileHighlightedVersionSelector } from '$redux/content/file/fileSelectors';
import { displayAllVersionsSelector } from '$redux/content/note/noteSelectors';

type NotesWithFiltersProps = {
    displayFilters: boolean;
    filters: any;
    setFilters: (filters: any) => void;
    notes: Note[];
    isPublicFile?: boolean;
    displayCastNotes?: boolean;
    sorters: string[];
    sorter: string;
    setSorter: (sorter: string) => void;
    password?: string;
};
const NotesWithFilters = ({
    displayFilters,
    filters,
    setFilters,
    notes,
    isPublicFile,
    displayCastNotes,
    sorters,
    sorter,
    setSorter,
    password
}: NotesWithFiltersProps) => {
    const { fileHighlightedVersion } = useSelector(fileHighlightedVersionSelector);
    const { displayAllVersions } = useSelector(displayAllVersionsSelector);

    const filteredNotes = useMemo(() => {
        const sortedNotes = notes.sort(
            (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
        );

        const filteredNotes = displayAllVersions
            ? sortedNotes
            : sortedNotes.filter(({ file }) => file.version === fileHighlightedVersion);
        return applyNoteFilters(filters, filteredNotes, displayCastNotes);
    }, [notes, fileHighlightedVersion, displayAllVersions, displayCastNotes, filters]);

    return (
        <div className="c-notes-with-filters">
            {displayFilters && (
                <NotesFilters
                    notes={notes}
                    onFilter={setFilters}
                    isPublicFile={isPublicFile}
                    displayCastNotes={displayCastNotes}
                    sorters={sorters}
                    sorter={sorter}
                    setSorter={setSorter}
                />
            )}
            <NotesList
                notes={filteredNotes}
                isPublicFile={isPublicFile}
                sorter={sorter}
                password={password}
            />
        </div>
    );
};

export default NotesWithFilters;
