import lookup, { Socket as ClientSocket } from 'socket.io-client';
import { SocketAction, SOCKETS_FOLDERS } from './sockets.constants';
import {
    SOCKETS_ACTIVITY,
    SOCKETS_ARCHIVES,
    SOCKETS_COMPANIES,
    SOCKETS_EVENTS,
    SOCKETS_FOLDER,
    SOCKETS_ITEMS,
    SOCKETS_MISC,
    SOCKETS_NOTE,
    SOCKETS_PROJECTS,
    SOCKETS_SUBTITLES,
    SOCKETS_USER
} from './sockets.constants';

type RegisteredSocket = { room: string; properties: string[]; listeners: number };
type RegisteredAction = { actionName: string; actionFunction: (data: unknown) => void };
export class ClientSocketHandler {
    id: string;
    channel: string;
    actions: SocketAction[];
    sio: ClientSocket;

    registeredSockets: RegisteredSocket[] = [];

    constructor(options: { id: string; channel: string; actions: SocketAction[] }) {
        this.id = options.id;
        this.channel = options.channel;
        this.actions = options.actions;
    }
    clientInit(sioLib: typeof lookup, host: string) {
        this.sio = sioLib(host + this.id, {
            withCredentials: true,
            transports: ['websocket']
        });
    }
    private clientSetEventHandlers(room: string, view: any) {
        const properties = Object.keys(view);
        let registeredSocket = this.registeredSockets.find((socket) => {
            return socket.room === room && socket.properties.every((p) => properties.includes(p));
        });
        if (registeredSocket) {
            registeredSocket.listeners++;
        } else {
            registeredSocket =
                this.registeredSockets[
                    this.registeredSockets.push({ room, properties, listeners: 1 }) - 1
                ];

            this.sio.on('reconnect', () => {
                this.sio.emit('join', room);
            });
            this.sio.emit('join', room);
        }

        const registeredActions: RegisteredAction[] = [];

        this.actions.forEach((action) => {
            const actionFunction = function (data: { room: string; [key: string]: unknown }) {
                if (
                    typeof view[action.client] !== 'function' ||
                    (data.room && data.room !== room)
                ) {
                    return false;
                }
                view[action.client](data);
            };

            registeredActions.push({
                actionName: action.name,
                actionFunction: actionFunction
            });

            this.sio.on(action.name, actionFunction);
        });

        return () => {
            if (!registeredSocket) return false;
            for (const { actionName, actionFunction } of registeredActions) {
                this.sio.off(actionName, actionFunction);
            }
            registeredSocket.listeners--;
            if (registeredSocket.listeners > 0) return;
            this.registeredSockets.splice(this.registeredSockets.indexOf(registeredSocket), 1);
            this.sio.emit('leave', room);
        };
    }

    clientActionsInit(room: string, view: any) {
        return this.clientSetEventHandlers(room, view);
    }

    emit(roomUuid: string, event: string, data: any) {
        this.sio.emit(event, roomUuid, data);
    }
}

export const events = new ClientSocketHandler(SOCKETS_EVENTS);

export const folder = new ClientSocketHandler(SOCKETS_FOLDER);
export const folders = new ClientSocketHandler(SOCKETS_FOLDERS);

export const activity = new ClientSocketHandler(SOCKETS_ACTIVITY);

export const archives = new ClientSocketHandler(SOCKETS_ARCHIVES);

export const note = new ClientSocketHandler(SOCKETS_NOTE);

export const misc = new ClientSocketHandler(SOCKETS_MISC);

export const projects = new ClientSocketHandler(SOCKETS_PROJECTS);

export const companies = new ClientSocketHandler(SOCKETS_COMPANIES);

export const items = new ClientSocketHandler(SOCKETS_ITEMS);

export const subtitles = new ClientSocketHandler(SOCKETS_SUBTITLES);

export const user = new ClientSocketHandler(SOCKETS_USER);
