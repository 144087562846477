export const TO_DO = 'TO_DO';
export const IN_PROGRESS = 'IN_PROGRESS';
export const TO_VALIDATE = 'TO_VALIDATE';
export const DONE = 'DONE';
export const DELETE = 'DELETE';

export const TASK_STATUS = [TO_DO, IN_PROGRESS, TO_VALIDATE, DONE, DELETE];

const columnsManager = {
    columns: {
        [TO_DO]: {
            id: TO_DO,
            color: 'todo',
            title: 'TO_DO'
        },
        [IN_PROGRESS]: {
            id: IN_PROGRESS,
            color: 'inProgress',
            title: 'IN_PROGRESS'
        },
        [TO_VALIDATE]: {
            id: TO_VALIDATE,
            color: 'toValidate',
            title: 'TO_VALIDATE'
        },
        [DONE]: {
            id: DONE,
            color: 'done',
            title: 'COLUMN_DONE'
        }
    },
    columnOrder: [TO_DO, IN_PROGRESS, TO_VALIDATE, DONE]
};

export default columnsManager;
