import './NoteButtons.scss';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Asset } from '@he-novation/config/types/asset.types';
import { Theme } from '@he-novation/design-system/enums';
import { __ } from '@he-novation/design-system/utils/i18n';
import Icon from '@he-novation/icons';
import { ATTACHMENT_NOTE, KEY_WORD_NOTE } from '@he-novation/paths/modals.constants';
import cn from 'classnames';

import { openModal } from '$redux/route/routeActions';

type NoteButtonsProps = {
    attachments?: Asset[];
    theme?: Theme;
    keywords?: string[];
    isDraft?: boolean;
};
export const NoteButtons = ({
    attachments = [],
    theme,
    keywords = [],
    isDraft
}: NoteButtonsProps) => {
    const dispatch = useDispatch();
    if (!attachments.length && !keywords.length && !isDraft) return null;
    return (
        <div className={cn('c-note-buttons', theme)}>
            {attachments.length > 0 && (
                <button
                    type="button"
                    onClick={(e) => {
                        e.stopPropagation();
                        dispatch(openModal(ATTACHMENT_NOTE, { attachments }));
                    }}
                >
                    <Icon icon="attachment" />
                    <span className="number">{attachments.length}</span>
                </button>
            )}
            {keywords.length > 0 && (
                <button
                    type="button"
                    onClick={(e) => {
                        e.stopPropagation();
                        dispatch(openModal(KEY_WORD_NOTE, { keywords }));
                    }}
                >
                    <Icon icon="tag" />
                    <span className="number">{keywords.length}</span>
                </button>
            )}
            {isDraft && <span className="draft">{__('REACT_DRAFT')}</span>}
        </div>
    );
};

export default NoteButtons;
