import asyncAction from '$helpers/asyncAction';
import { openFeedbackModal } from '$redux/route/routeActions';
import { __ } from '@he-novation/design-system/utils/i18n';
import { ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { Theme } from '@he-novation/design-system/enums';

export const CONFIG = 'CONFIG';
export const SET_SIZE = `${CONFIG}/SET_SIZE`;
export const SET_USER_AGENT = `${CONFIG}/SET_USER_AGENT`;
export const SET_FULLSCREEN = `${CONFIG}/SET_FULLSCREEN`;
export const SET_IMMERSIVE = `${CONFIG}/SET_IMMERSIVE`;

export const SET_PREFERENCES = `${CONFIG}/SET_PREFERENCES`;
export const API_PREFERENCES = 'api/file';

export const setSize = (width, height) => ({
    type: SET_SIZE,
    width,
    height
});

export const setUserAgent = (agent) => ({
    type: SET_USER_AGENT,
    agent
});

export const setFullscreen = (isFullscreen) => ({
    type: SET_FULLSCREEN,
    isFullscreen
});

export const setImmersive = (isImmersive) => ({
    type: SET_IMMERSIVE,
    isImmersive
});

export const setPreferences = (preferences, success) =>
    asyncAction(SET_PREFERENCES, {
        method: 'POST',
        url: API_PREFERENCES,
        success
    })(preferences);

const openNewVersionModal = () =>
    openFeedbackModal(__('NEW_VERSION_AVAILABLE'), 0, {
        extraButtons: [
            {
                children: __('PLUGIN_POPIN_BUTTON_UPDATE'),
                onClick: () => window.location.reload(),
                tone: ButtonTone.Outlined,
                theme: Theme.Light
            }
        ]
    });

export const socketActions = {
    sioMiscReload: openNewVersionModal,
    sioMiscNewVersion: openNewVersionModal
};
