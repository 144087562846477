import React from 'react';
import { BottomButtons } from '@he-novation/design-system/components/buttons/BottomButtons/BottomButtons';
import { ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import {
    FormFieldTypes,
    GenericForm
} from '@he-novation/design-system/components/form/StyledFormField/StyledFormField';
import { StyledFormFields } from '@he-novation/design-system/components/form/StyledFormFields/StyledFormFields';
import { Theme } from '@he-novation/design-system/enums';
import { __ } from '@he-novation/design-system/utils/i18n';

type FormCastFolderProps = {
    cancel: () => void;
    submit: (e: any, data: any) => void;
    theme: Theme;
    data?: any;
};

export const FormCastFolder = ({ cancel, submit, theme, data }: FormCastFolderProps) => {
    return (
        <GenericForm id="form-cast-folder" onSubmit={submit}>
            <StyledFormFields
                theme={theme}
                formId="form-cast-folder"
                fields={[
                    {
                        type: FormFieldTypes.Text,
                        name: 'name',
                        id: 'form-cast-folder-name',
                        label: __('NAME'),
                        value: data?.name,
                        autoFocus: true
                    }
                ]}
            />
            <BottomButtons
                buttons={[
                    {
                        children: __('CANCEL'),
                        onClick: cancel,
                        theme: theme,
                        tone: ButtonTone.Outlined
                    },
                    {
                        children: __('SUBMIT'),
                        tone: ButtonTone.Primary
                    }
                ]}
            />
        </GenericForm>
    );
};
