import React, { ReactNode } from 'react';
import { MultiActionsList } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButtonList';

import { SelectionHandler, SelectionProps } from '$components/Selectable/SelectionHandler';

export type DataLayoutSelectionProps<T> = SelectionProps<{ item: T }>;

export enum DataLayoutDisplayMode {
    List = 'list',
    Grid = 'grid'
}

export type DataLayoutGroup<T> = {
    key: string;
    header: ReactNode | ((key: string, items: T[]) => ReactNode);
    items: T[];
};

export type DataLayoutColumn<T, U, V> = {
    key: string;
    unfilterable?: boolean;
    maxWidth?: number;
    grow?: boolean;
    width: number;
    header?: ReactNode;
    sort?: (a: T, b: T) => number;
    render?: (
        item: T,
        selection: U extends true ? undefined : DataLayoutSelectionProps<T>,
        extra?: V
    ) => ReactNode;
    icon?: string;
};

export type ItemComponent<T, U, V> = React.ComponentType<{
    item: T;
    selection: U extends true ? undefined : DataLayoutSelectionProps<T>;
    extra?: V;
}>;

export type DataLayoutGroupBy<T> = (items: T[]) => DataLayoutGroup<T>[];

export type ActionMenu<T, U, V> = (
    item: T,
    selection: U extends true ? undefined : DataLayoutSelectionProps<T>,
    extra: V
) => MultiActionsList;

export type BackgroundActionMenu<T> = (selection?: SelectionHandler<T>) => MultiActionsList;
