import './Playlist.scss';
import React, { MouseEvent, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FrontFolderContentFile } from '@he-novation/front-shared/types/file.front-types';
import { playerLink } from '@he-novation/paths/herawFrontUris';
import PlaylistFilters from '../Playlist/PlaylistFilters/PlaylistFiltersContainer';
import ItemPlaylist from './ItemPlaylist/ItemPlaylist';

import sortAlphabetically from '$helpers/sortAlphabetically';
import { fileUuidSelector, playlistFiltersSelector } from '$redux/content/file/fileSelectors';
import {
    folderContentSelector,
    folderMembersSelector
} from '$redux/content/folder/folderSelectors';
import { set } from '$redux/route/routeActions';
import { closePanel } from '$redux/sideMenu/sideMenuActions';
import { currentUserUuidSelector } from '$redux/user/userSelectors';

export function Playlist({ isFilterOpen }: { isFilterOpen: boolean }) {
    const { folderContent } = useSelector(folderContentSelector);
    const { playlistFilters } = useSelector(playlistFiltersSelector);
    const { fileUuid } = useSelector(fileUuidSelector);
    const { folderMembers } = useSelector(folderMembersSelector);
    const { currentUserUuid } = useSelector(currentUserUuidSelector);
    const dispatch = useDispatch();

    const fileList = useMemo(
        () => sortAlphabetically(folderContent, 'name').filter(({ type }) => type === 'file'),
        [folderContent]
    );
    const filtered = useMemo(() => {
        let _filtered: FrontFolderContentFile[] = [...fileList];
        if (playlistFilters.types.length) {
            playlistFilters.types.forEach((type: string) => {
                //@ts-expect-error @todo fix final
                if (type === 'is_final') _filtered = _filtered.filter(({ final }) => final);
            });
        }
        return _filtered;
    }, [fileList, playlistFilters]);

    return (
        <div className={`c-playlist ${isFilterOpen ? 'filters-open' : ''}`} tabIndex={0}>
            {isFilterOpen && <PlaylistFilters selectedFilters={playlistFilters} files={fileList} />}

            <div className="c-playlist-items">
                {filtered.map((file, i) => (
                    <ItemPlaylist
                        folderMembers={folderMembers}
                        currentUserUuid={currentUserUuid}
                        file={file}
                        key={i}
                        onClick={(_e: MouseEvent, selectedUuid: string) => {
                            const file = fileList.find(({ uuid }) => uuid === selectedUuid);
                            dispatch(set(playerLink(file.uuid, file.version)));
                            dispatch(closePanel());
                        }}
                        fileUuid={fileUuid}
                    />
                ))}
            </div>
        </div>
    );
}
