import __ from './i18n';

export const STORAGE_AVAILABLE_MULTIPLIER = 1073741824;
export const STORAGE_GB = 1024 * 1024 * 1024;
export const STORAGE_MB = 1024 * 1024;
export const bytesToGigaBytes = (bytes?: number) => (bytes ? bytes / STORAGE_GB : 0);
export const bytesToGigaBytesDisplay = (bytes: number, fractionalDigits?: number) => {
    if (!bytes) return '0 ' + __('UNIT_BYTES_GB');
    if (typeof fractionalDigits === 'undefined' || !Number.isInteger(fractionalDigits))
        fractionalDigits = 1;
    return (bytes / STORAGE_GB).toFixed(fractionalDigits) + ` ${__('UNIT_BYTES_GB')}`;
};
export const bytesToMegaBytes = (bytes: number) => bytes / STORAGE_MB;
export const bytesToMegaBytesDisplay = (bytes: number, fractionalDigits?: number) => {
    if (!bytes) return '0 ' + __('UNIT_BYTES_MB');
    if (typeof fractionalDigits === 'undefined' || !Number.isInteger(fractionalDigits))
        fractionalDigits = 1;
    return (bytes / STORAGE_MB).toFixed(fractionalDigits) + ` ${__('UNIT_BYTES_MB')}`;
};
export const gigaBytesToBytes = (gbytes: number) => gbytes * STORAGE_GB;
export const bytesToSize = (bytes: number, fractionalDigits?: number) => {
    if (!bytes) return '0 ' + __('UNIT_BYTES_MB');

    if (!fractionalDigits) fractionalDigits = 1;

    const sizes = [
        __('UNIT_BYTES_BYTES'),
        __('UNIT_BYTES_KB'),
        __('UNIT_BYTES_MB'),
        __('UNIT_BYTES_GB'),
        __('UNIT_BYTES_TB'),
        __('UNIT_BYTES_PB'),
        __('UNIT_BYTES_EB'),
        __('UNIT_BYTES_ZB'),
        __('UNIT_BYTES_YB')
    ];

    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    if (i === 0) {
        return bytes / Math.pow(1024, i) + ' ' + sizes[i];
    }

    return (bytes / Math.pow(1024, i)).toFixed(fractionalDigits) + ' ' + sizes[i];
};

export function bitsToSize(bits: number, fractionalDigits?: number): string {
    if (!bits) return '0 b';

    if (!fractionalDigits) fractionalDigits = 1;

    const tenPower = Math.floor(Math.log10(bits));
    const nearestThousandPower = tenPower - (tenPower % 3);
    const thousandPower = nearestThousandPower / 3;

    const prefixes = ['', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];

    return (
        (bits / Math.pow(10, nearestThousandPower)).toFixed(fractionalDigits) +
        ' ' +
        prefixes[thousandPower] +
        'b'
    );
}
