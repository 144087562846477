import './SidePanelProjectMembers.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ROLES, TEAM_ADMIN } from '@he-novation/config/constants/projects.constants';
import { FolderMember } from '@he-novation/config/types/folder.types';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { SidePanelHeader } from '@he-novation/design-system/components/sidePanels/SidePanel/SidePanelHeader/SidePanelHeader';
import { __ } from '@he-novation/design-system/utils/i18n';
import { asyncFolderMembersFetch } from '@he-novation/front-shared/async/folder.async';
import {
    DELETE_TEAM,
    FOLDER_TEAMS,
    PROJECT_TEAMS,
    RENAME_TEAM,
    VIEWS
} from '@he-novation/paths/modals.constants';
import MemberList from '../../lists/MemberList/MemberList';

import { folderUuidSelector, parentFolderSelector } from '$redux/content/folder/folderSelectors';
import {
    deleteMember,
    fetchProjectTeams,
    updateMember
} from '$redux/content/projects/projectsActions';
import {
    currentProjectSelector,
    isProjectManagerSelector
} from '$redux/content/projects/projectsSelector';
import { openModal } from '$redux/route/routeActions';
import { currentUserUuidSelector } from '$redux/user/userSelectors';

const SidePanelProjectMembers = () => {
    const { isProjectManager } = useSelector(isProjectManagerSelector);
    const { currentProject } = useSelector(currentProjectSelector());
    const { currentUserUuid } = useSelector(currentUserUuidSelector);
    const { folderUuid } = useSelector(folderUuidSelector);
    const { parentFolder } = useSelector(parentFolderSelector);
    const dispatch = useDispatch();
    const [folderMembers, setFolderMembers] = useState<FolderMember[]>([]);

    useEffect(() => {
        if (currentProject?.uuid) dispatch(fetchProjectTeams(currentProject.uuid));
    }, [currentProject?.uuid]);

    useEffect(() => {
        if (currentProject && (folderUuid || currentProject?.folderUuid))
            asyncFolderMembersFetch(folderUuid || currentProject.folderUuid, true).then(
                setFolderMembers
            );
    }, [folderUuid]);

    const teams =
        folderUuid && folderUuid !== currentProject?.folderUuid
            ? currentProject?.teams.filter(
                  ({ members }) =>
                      members.filter(
                          (m) =>
                              currentUserUuid === m.uuid ||
                              folderMembers.find((u) => u.uuid === m.uuid)
                      ).length
              )
            : currentProject?.teams;

    if (!currentProject) return null;
    return (
        <div id="c-side-panel-project-members">
            <SidePanelHeader
                title={__('PROJECT_MEMBERS')}
                buttons={
                    isProjectManager
                        ? [
                              {
                                  icon: 'eye',
                                  tone: ButtonTone.Hoverable,
                                  onClick: () =>
                                      dispatch(
                                          openModal(VIEWS, {
                                              folderUuid: folderUuid || currentProject.folderUuid
                                          })
                                      )
                              },
                              parentFolder?.uuid === currentProject.folderUuid && {
                                  icon: 'key',
                                  onClick: () =>
                                      dispatch(
                                          openModal(FOLDER_TEAMS, {
                                              folderUuid,
                                              projectUuid: currentProject.uuid
                                          })
                                      )
                              },
                              {
                                  icon: 'users-add',
                                  onClick: () => dispatch(openModal(PROJECT_TEAMS, null))
                              }
                          ]
                        : []
                }
            />
            <ul className="c-sp-project-members-list">
                {teams?.map(({ name, members, uuid, castTeamAccess, color }, i) => {
                    let roles = Object.keys(ROLES).map((role) => ({
                        label: __(ROLES[role]),
                        value: ROLES[role]
                    }));
                    if (name !== TEAM_ADMIN)
                        roles = roles.filter(({ value }) => value !== ROLES.MANAGER);
                    const _name = name === TEAM_ADMIN ? __('ADMINISTRATORS') : name;
                    return (
                        <li key={i}>
                            <h2>
                                <span
                                    className="team-bullet"
                                    style={{ background: color || undefined }}
                                />
                                {_name}
                                <div className="buttons-header">
                                    {isProjectManager && name !== TEAM_ADMIN && (
                                        <span className="edit">
                                            <Button
                                                tone={ButtonTone.Hoverable}
                                                icon="pencil"
                                                onClick={() =>
                                                    dispatch(
                                                        openModal(
                                                            RENAME_TEAM,
                                                            { castTeamAccess },
                                                            {
                                                                team_uuid: uuid,
                                                                name,
                                                                projectUuid: currentProject.uuid
                                                            }
                                                        )
                                                    )
                                                }
                                            />
                                        </span>
                                    )}
                                    {isProjectManager && name !== TEAM_ADMIN && (
                                        <span className="delete">
                                            <Button
                                                tone={ButtonTone.Hoverable}
                                                icon="trash-can"
                                                onClick={() =>
                                                    dispatch(
                                                        openModal(DELETE_TEAM, null, {
                                                            team_uuid: uuid,
                                                            projectUuid: currentProject.uuid
                                                        })
                                                    )
                                                }
                                            />
                                        </span>
                                    )}
                                    {isProjectManager && (
                                        <Button
                                            tone={ButtonTone.Hoverable}
                                            icon="user-add"
                                            onClick={() =>
                                                dispatch(
                                                    openModal(PROJECT_TEAMS, null, {
                                                        team: encodeURIComponent(_name)
                                                    })
                                                )
                                            }
                                        />
                                    )}
                                </div>
                            </h2>
                            <MemberList
                                roles={roles}
                                ownerUuid={currentProject.user.uuid}
                                lockAdmins
                                editable={isProjectManager}
                                canDeleteAdmins={isProjectManager}
                                members={members}
                                displayEmail={false}
                                onDelete={(member) =>
                                    dispatch(deleteMember(currentProject.uuid, member.uuid))
                                }
                                onDownloadChange={(e, member) =>
                                    dispatch(
                                        updateMember(currentProject.uuid, member.uuid, {
                                            download: e.currentTarget.checked
                                        })
                                    )
                                }
                                onExportChange={(e, member) =>
                                    dispatch(
                                        updateMember(currentProject.uuid, member.uuid, {
                                            export: e.currentTarget.checked
                                        })
                                    )
                                }
                                onRoleChange={(e, member) =>
                                    dispatch(
                                        updateMember(currentProject.uuid, member.uuid, {
                                            role: e.currentTarget.value
                                        })
                                    )
                                }
                            />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default SidePanelProjectMembers;
