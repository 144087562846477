import {
    ClientLabel,
    ClientLabelPayload,
    ClientLabelType,
    ClientLabelUpdatePayload
} from '@he-novation/config/types/clientLabel.types';
import { apiFetch } from '@he-novation/front-shared/async/apiFetch';
import paths, { clientCastLabelPaths, pathWithParams } from '@he-novation/paths/herawApiPaths';

const clientLabelTypeToPaths = (type: ClientLabelType) => {
    switch (type) {
        case ClientLabelType.Item:
            return paths.clientItemLabels;
        case ClientLabelType.Contact:
            return paths.clientContactLabels;
        case ClientLabelType.Cast:
            return clientCastLabelPaths;
        default:
            return paths.clientEventLabels;
    }
};
export function asyncClientLabelsFetch<T extends ClientLabelType>(
    type: T
): Promise<ClientLabel<T>[]> {
    return apiFetch(clientLabelTypeToPaths(type).multiple);
}

export const asyncClientLabelCreate = <T extends ClientLabelType>(
    type: T,
    body: ClientLabelPayload<T>
): Promise<ClientLabel<T>> =>
    apiFetch(clientLabelTypeToPaths(type).single, {
        method: 'POST',
        body
    });

export const asyncClientLabelUpdate = <T extends ClientLabelType>(
    type: T,
    uid: string,
    body: ClientLabelUpdatePayload<T>
) =>
    apiFetch(pathWithParams(clientLabelTypeToPaths(type).specific, { uid }), {
        method: 'PUT',
        body
    });

export const asyncClientLabelDelete = (type: ClientLabelType, uid: string) =>
    apiFetch(pathWithParams(clientLabelTypeToPaths(type).specific, { uid }), {
        method: 'DELETE'
    });

export const asyncLabelAssociationInsert = (
    type: ClientLabelType,
    associationUuid: string,
    uid: string
) =>
    apiFetch(
        pathWithParams(clientLabelTypeToPaths(type).associationSingle, { uid, associationUuid }),
        {
            method: 'POST'
        }
    );

export const asyncLabelAssociationDelete = (
    type: ClientLabelType,
    associationUuid: string,
    uid: string
) =>
    apiFetch(
        pathWithParams(clientLabelTypeToPaths(type).associationSingle, { uid, associationUuid }),
        {
            method: 'DELETE'
        }
    );
