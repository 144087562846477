import { connect } from 'react-redux';
import { branch, compose, renderNothing, withProps } from 'recompose';
import combineSelectors from '../../../helpers/combineSelectors';
import { Note } from './Note';

import { localeSelector } from '$redux/config/configSelectors';
import {
    comparisonSelector,
    fileHighlightedVersionSelector,
    fileThumbnailAndNameSelector,
    fileUuidSelector,
    fileVersionSelector
} from '$redux/content/file/fileSelectors';
import { isSharedSelector } from '$redux/content/folder/folderSelectors';
import { currentProjectUuidSelector } from '$redux/content/projects/projectsSelector';
import { currentUserUuidSelector } from '$redux/user/userSelectors';

const mapStateToProps = combineSelectors(
    localeSelector,
    fileUuidSelector,
    fileVersionSelector,
    isSharedSelector,
    fileHighlightedVersionSelector,
    fileThumbnailAndNameSelector,
    comparisonSelector,
    currentUserUuidSelector,
    currentProjectUuidSelector
);

export default compose(
    connect(mapStateToProps),
    branch(({ note }) => !note.comments.length, renderNothing),
    withProps(({ fileVersion, fileHighlightedVersion, fileThumbnail, comparison }) => {
        return {
            fileThumbnail:
                fileVersion === fileHighlightedVersion
                    ? fileThumbnail
                    : (comparison && comparison.tumbnail) || fileThumbnail
        };
    })
)(Note);
