import { companiesFetch as _companiesFetch } from '@he-novation/front-shared/async/company.async';
import { asyncActionSuccess } from '$helpers/asyncAction';
import { WS_PREFIX_IN } from '$constants/webSocket.constants';
export const COMPANIES = 'COMPANIES';
export const COMPANIES_FETCH = `${COMPANIES}/FETCH`;

export const COMPANY_SET_PICTURE_ASSET = `${COMPANIES}/SET_PICTURE_ASSET`;
export const WS_COMPANIES_CREATE = `${WS_PREFIX_IN}${COMPANIES}/CREATE`;
export const WS_COMPANIES_UPDATE = `${WS_PREFIX_IN}${COMPANIES}/UPDATE`;
export const WS_COMPANIES_DELETE = `${WS_PREFIX_IN}${COMPANIES}/DELETE`;

export const API_COMPANIES = 'hpi/companies';

export const companiesFetch = () => async (dispatch) => {
    dispatch({ type: COMPANIES_FETCH });
    const companies = await _companiesFetch();
    dispatch({ type: asyncActionSuccess(COMPANIES_FETCH), companies });
};

export const companySetPictureAsset = ({ companyUuid, url, key }) => ({
    type: COMPANY_SET_PICTURE_ASSET,
    companyUuid,
    url,
    key
});

export const companiesSocketActions = {
    sioCompanyCreate: (data) => ({ type: WS_COMPANIES_CREATE, data }),
    sioCompanyUpdate: (data) => ({ type: WS_COMPANIES_UPDATE, data }),
    sioCompanyDelete: (data) => ({ type: WS_COMPANIES_DELETE, data })
};
