import './SearchField.scss';
import React, { RefObject } from 'react';
import { connect } from 'react-redux';
import {
    FormFieldTypes,
    StyledFormField
} from '@he-novation/design-system/components/form/StyledFormField/StyledFormField';
import type { Theme } from '@he-novation/design-system/enums';
import { __ } from '@he-novation/design-system/utils/i18n';
import Icon from '@he-novation/icons';
import cn from 'classnames';

import { toggleKeyboardListeners } from '$redux/ui/uiActions';

type SearchFieldProps = {
    value?: string;
    onInput?: Function;
    onSubmit?: Function;
    onReset?: Function;
    isSmallScreen?: boolean;
    isActive?: boolean;
    theme?: Theme;
    className?: string;
    label?: string;
    autoComplete?: string;
    toggleKeyboardListeners: (active: boolean) => void;
};

type SearchFieldState = {
    mobileActive: boolean;
    value: string;
};

class SearchField extends React.Component<SearchFieldProps, SearchFieldState> {
    ref: RefObject<any>;
    constructor(props) {
        super(props);
        this.state = {
            mobileActive: false,
            value: props.value || ''
        };
        this.ref = React.createRef();
        this.toggleMobileSearch = this.toggleMobileSearch.bind(this);
        this.closeMobileSearch = this.closeMobileSearch.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) this.setState({ value: this.props.value || '' });
    }

    render() {
        const { onInput, isSmallScreen, isActive } = this.props;

        return (
            <form
                className={cn('c-search-field', this.props.className, {
                    'is-mobile-active': isSmallScreen
                        ? this.state.mobileActive || !!this.props.value
                        : false
                })}
                onSubmit={this.onSubmit}
            >
                <StyledFormField
                    icon="magnifier"
                    ref={this.ref}
                    theme={this.props.theme || null}
                    onInput={onInput}
                    label={this.props.label}
                    className={isActive ? 'is-active' : null}
                    placeholder={__('SEARCH')}
                    type={FormFieldTypes.Text}
                    name="search"
                    value={this.state.value}
                    onChange={(e) => this.setState({ value: e.currentTarget.value })}
                    autoComplete={this.props.autoComplete}
                    onFocus={() => {
                        this.props.toggleKeyboardListeners(false);
                    }}
                    onBlur={() => {
                        this.props.toggleKeyboardListeners(true);
                    }}
                />
                <button className="c-search-field-magnifier" onClick={this.toggleMobileSearch}>
                    <Icon icon="magnifier" />
                </button>
                {this.props.value && (
                    <button className="c-search-field-reset" onClick={this.onReset}>
                        <Icon icon="cross2" />
                    </button>
                )}
                <input type="submit" />
            </form>
        );
    }

    toggleMobileSearch() {
        this.setState(
            {
                mobileActive: !this.state.mobileActive
            },
            () => {
                if (this.state.mobileActive) this.ref.current?.el.current?.focus();
            }
        );
    }

    onReset = () => {
        this.props.onReset?.(this.ref.current.el.current);
        this.closeMobileSearch();
    };

    onSubmit = (e) => {
        if (typeof this.props.onSubmit === 'function') this.props.onSubmit(e, this.state.value);
        else e.preventDefault();
    };

    closeMobileSearch() {
        this.setState({
            mobileActive: false
        });
    }
}

export default connect(undefined, (dispatch) => ({
    toggleKeyboardListeners: (active: boolean) => dispatch(toggleKeyboardListeners(active))
}))(SearchField);
