import { __ } from '@he-novation/design-system/utils/i18n';
const parseEJSCondition = (activityKey, str, values) => {
    if (!str.includes('<% if')) {
        if (activityKey.startsWith('EVENT_')) {
            activityKey = activityKey.replace(/_(SELF|INFO)/, '');
            return __(`REACT_LOGS_${activityKey}`, values);
        }

        return str === activityKey ? __(`REACT_${activityKey}`, values) : str;
    }
    const matches =
        /<% if\s?\(([a-zA-Z0-9\s-_!]*)\)\s?{\s?%>([^<%]*)<%\s?}\s?(?:else\s?{\s)?%>([^<%]*)<%\s?}\s?%>/gm.exec(
            str
        );
    if (!matches) return str;
    const _var = matches[1].replace(/^!/, '');
    if (values[_var]) str = str.replace(matches[0], matches[2]);
    else str = str.replace(matches[0], matches[3]);
    return str;
};

export default parseEJSCondition;
