import { Locale } from '@he-novation/config/types/enums';
import { __ } from './i18n';
import { doubleDigit } from './doubleDigit';

const immutableDate = (date?: Date | number) =>
    new Date(date instanceof Date ? date.getTime() : date || Date.now());

export const offsetMonths = (date: Date | number, months: number) => {
    const result = immutableDate(date);
    result.setMonth(result.getMonth() + months);
    return result;
};

export const offsetWeeks = (date: Date | number, weeks: number) => {
    const result = immutableDate(date);
    result.setDate(result.getDate() + weeks * 7);
    return result;
};

export const offsetDays = (date: Date | number, days: number) => {
    const result = immutableDate(date);
    result.setDate(result.getDate() + days);
    return result;
};

export const offsetHours = (date: Date | number, hours: number) => {
    const result = immutableDate(date);
    result.setHours(result.getHours() + hours);
    return result;
};

export const offsetMinutes = (date: Date | number, minutes: number) => {
    const result = immutableDate(date);
    result.setMinutes(result.getMinutes() + minutes);
    return result;
};

export const getDateFromString = (created: Date | number) => {
    const dateObject = immutableDate(created);
    return `${dateObject.getDate()}-${dateObject.getMonth() + 1}-${dateObject.getFullYear()}`;
};

export const getFormattedDateAndHour = (d: Date = new Date()) =>
    [d.getDate(), d.getMonth() + 1, d.getFullYear()]
        .map((n) => (n < 10 ? `0${n}` : `${n}`))
        .join('/') + ` - ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;

export const getMysqlStringFromDate = (date: Date) =>
    `${date.getFullYear()}-${
        date.getMonth() + 1
    }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

export const getFullHourFromString = (created: Date | number) => {
    const dateObject = immutableDate(created);
    return `${dateObject.getHours()}${dateObject.getMinutes()}${dateObject.getSeconds()}`;
};

export const getTimeFromString = (created: Date | number) => immutableDate(created).getTime();

export const getLocalizedTime = (locale: Locale, created: Date | number, type: 'date' | 'hour') => {
    const now = new Date();
    const timeStamp = typeof created === 'object' ? created : immutableDate(created);

    let opts: any;

    if (type === 'date') {
        opts = {
            month: 'long',
            day: 'numeric'
        };

        if (now.getFullYear() !== timeStamp.getFullYear()) {
            opts.year = 'numeric';
        }
    } else if (type === 'hour') {
        opts = {
            hour: 'numeric',
            minute: 'numeric'
        };
    }

    return new Intl.DateTimeFormat(locale, opts).format(timeStamp);
};

export const isEqualDate = (date1: Date | number, date2: Date | number) =>
    immutableDate(date1).getFullYear() === immutableDate(date2).getFullYear() &&
    immutableDate(date1).getMonth() === immutableDate(date2).getMonth() &&
    immutableDate(date1).getDate() === immutableDate(date2).getDate();

export const toRoundDate = (date: Date | number) => {
    date = immutableDate(date);
    date.setHours(0, 0, 0, 0);
    return date;
};

export const dateToDateShortMonth = (date: Date, locale: Locale) => ({
    date: date.getDate(),
    shortMonth: new Intl.DateTimeFormat(locale, { month: 'short' }).format(date).replace('.', '')
});

export const localeToDateLocale = (locale: Locale) => {
    switch (locale) {
        case 'fr':
            return 'fr-FR';
        case 'de':
            return 'de-DE';
        default:
            return 'en-GB';
    }
};

export const getLocalizedDate = (
    date: Date,
    // @ts-ignore
    locale: Locale = window?.LOCALE || navigator.language.substring(0, 2),
    intlDateFormatOptions = {}
) => date.toLocaleString(localeToDateLocale(locale), intlDateFormatOptions);

export const dayInterval = (d1: Date | number, d2: Date | number) => {
    d1 = toRoundDate(d1);
    d2 = toRoundDate(d2);
    return Math.round((d1.getTime() - d2.getTime()) / 1000 / 60 / 60 / 24);
};

export const monthInterval = (d1: Date, d2: Date) => {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
};

export const weekInterval = (d1: Date | number, d2: Date | number) =>
    Math.ceil(dayInterval(d1, d2) / 7);

export const datesIntervalToString = (d1: Date | number, d2: Date | number) => {
    const interval = dayInterval(d1, d2);
    if (interval < -1) return __('DATE_IN', { days: Math.floor(Math.abs(interval)).toString() });
    if (interval === -1) return __('DATE_TOMOROW');
    if (interval === 0) return __('DATE_TODAY');
    if (interval === 1) return __('DATE_YESTERDAY');
    return __('DATE_AGO', { days: Math.floor(Math.abs(interval)).toString() });
};

export const datesIntervalToTimeBefore = (d1: Date | number, d2: Date | number) => {
    const minutes = getDiffDays(d1, d2, MS_PER_MINUTE);
    return minutes > 59
        ? __('BEFORE_HOURS', { hours: Math.ceil(minutes / 60) })
        : __('BEFORE_MINUTES', { minutes: minutes });
};

export const getCroppedMonth = (month: number, year: number) => {
    if (month > 11) {
        month = 0;
        year++;
    }
    if (month < 0) {
        month = 11;
        year--;
    }
    return {
        month,
        year
    };
};

export const MS_PER_SECOND = 1000;
export const MS_PER_MINUTE = MS_PER_SECOND * 60;
export const MS_PER_HOUR = MS_PER_MINUTE * 60;
export const MS_PER_DAY = MS_PER_HOUR * 24;

export const getDiffDays = (date1: Date | number, date2: Date | number, divider = MS_PER_DAY) => {
    const _date1 = immutableDate(date1);
    const _date2 = immutableDate(date2);
    const diffTime = _date2.getTime() - _date1.getTime();

    return Math.ceil(diffTime / divider);
};

export const isMonday = (date: Date) => date.getDay() === 1;

export const getNextMonday = (date: Date | number) => {
    const nextMonday = immutableDate(date);
    if (isMonday(nextMonday)) {
        nextMonday.setDate(nextMonday.getDate() + 7);
        return nextMonday;
    }
    nextMonday.setDate(nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7));

    if (isEqualDate(nextMonday, immutableDate(date))) nextMonday.setDate(nextMonday.getDate() + 7);

    return nextMonday;
};

export const isSunday = (date: Date) => date.getDay() === 0;

export const getNextSunday = (date: Date | number) => {
    const nextSunday = immutableDate(date);

    if (isSunday(nextSunday)) {
        nextSunday.setDate(nextSunday.getDate() + 7);
    } else {
        const nextMonday = getNextMonday(date);
        nextSunday.setDate(nextMonday.getDate() - 1);
    }
    return nextSunday;
};

export const getPrevMonday = (date: Date | number) => {
    const d = immutableDate(date);

    while (d.getDay() !== 1) {
        d.setDate(d.getDate() - 1);
    }

    return d;
};

export const setMidnight = (_date?: Date | number) => {
    const date = immutableDate(_date);
    date.setHours(0, 0, 0, 0);
    return date;
};

export const setLatestHour = (_date?: Date | number) => {
    const date = immutableDate(_date);
    date.setHours(23, 59, 59, 999);
    return date;
};

export const FORMAT_DDMMYYHHMMSS = 'DD:MM:YY - HH:MM:SS';
export const FORMAT_FULLDATETRANSLATED = 'DAY:DATE:MONTH:YEAR • H:MM';

export const daysTranslation = {
    fr: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    de: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
};

export const monthsTranslation = {
    fr: ['Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
    en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    de: ['Jan', 'Feb', 'März', 'Apr', 'Mai', 'Juni', 'Juli', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
};

export const formatDate = (
    date = new Date(),
    format = FORMAT_DDMMYYHHMMSS,
    lang: Locale = Locale.FR
) => {
    switch (format) {
        case FORMAT_DDMMYYHHMMSS:
            return getFormattedDateAndHour(date);
        case FORMAT_FULLDATETRANSLATED: {
            return `${daysTranslation[lang][date.getDay()]} ${date.getDate()} ${
                monthsTranslation[lang][date.getMonth()]
            } ${date.getFullYear()} • ${date.getHours()}h${
                date.getMinutes() > 0 ? doubleDigit(date.getMinutes()) : ''
            }`;
        }
        default:
            return date.toISOString();
    }
};

export const weekNumber = (d: Date | number) => {
    const date = immutableDate(d);
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return (
        1 +
        Math.round(
            ((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7
        )
    );
};

export const datesOverlap = (d1Start: Date, d1End: Date, d2Start: Date, d2End: Date) =>
    Math.max(d1Start.getTime(), d2Start.getTime()) < Math.min(d1End.getTime(), d2End.getTime());

export const roundToMinutes = (
    date: Date | number,
    minutes: number = 15,
    method: 'round' | 'ceil' | 'floor' = 'round'
) => {
    date = immutableDate(date);
    date.setMinutes((Math[method](date.getMinutes() / minutes) * minutes) % 60);
    date.setSeconds(0);
    return date;
};
