import React from 'react';
import type { Comment } from '@he-novation/config/types/note.types';
import { AvatarUser } from '@he-novation/design-system/components/avatars/Avatar/AvatarUser';
import { Theme } from '@he-novation/design-system/enums';
import { getDisplayName } from '@he-novation/utils/user';
import cn from 'classnames';
import NoteComment from '../../Notes/NoteComment/NoteCommentContainer';

import { getLocalizedDate, getLocalizedTime } from '$helpers/datetime';

type NoteCommentListProps = {
    comments: (Omit<Comment, 'note' | 'file'> & { isSameDateAsPrevious?: boolean })[];
    locale: string;
    currentUserUuid: string;
};
export default function NoteCommentList({
    comments,
    locale,
    currentUserUuid
}: NoteCommentListProps) {
    return (
        <div className="c-note-comment-list">
            {comments
                .sort((a, b) => b.created.getTime() - a.created.getTime())
                .map((comment) => {
                    const isAuthor = comment.user?.uuid === currentUserUuid;
                    const user = comment.user || { email: comment.postedAs || '-' };
                    const date = comment.created || new Date();
                    return (
                        <div
                            className={cn('wrapper-comment', {
                                'own-comment': isAuthor
                            })}
                            key={comment.uuid}
                        >
                            {!comment.isSameDateAsPrevious && (
                                <span className="date-comment">
                                    {getLocalizedDate(date, locale)}
                                </span>
                            )}
                            <NoteComment
                                theme={isAuthor ? Theme.Dark : Theme.Light}
                                comment={comment}
                            />

                            <div className="c-note-creator">
                                <AvatarUser {...user} />
                                <span>
                                    {getDisplayName(user)} •{' '}
                                    {getLocalizedTime(locale, date, 'hour')}
                                </span>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
}
