import React from 'react';
import { GenericFormField } from 'generic-form';
// @ts-ignore
import TextMask from 'react-text-mask';
import { DatePicker } from '../components/form/DatePicker/DatePicker';
import { setDatePickerConfiguration } from './setDatePickerConfiguration';
import { ExtraFormFieldTypes } from '../enums';
import { AutoComplete } from '../components/form/AutoComplete/AutoComplete';
import { EditorWrapper } from '../components/form/Editor/Editor';

setDatePickerConfiguration();

GenericFormField.registerExtraType(ExtraFormFieldTypes.AutoComplete, {
    render(sharedProps, ownProps, state, validateOnChange) {
        const { ref, ...props } = sharedProps;
        return (
            <AutoComplete
                {...ownProps}
                {...props}
                inputRef={ref}
                onChange={(...args: any[]) => {
                    setTimeout(() => {
                        validateOnChange(...args);
                        if (typeof ownProps.onChange === 'function') ownProps.onChange(...args);
                    });
                }}
            />
        );
    },
    getValue() {
        return this.el.current.value;
    }
});
GenericFormField.registerExtraType(ExtraFormFieldTypes.DatePicker, {
    render(
        { ref, valueAsDate, ...sharedProps },
        { onChange, onSelect, value, isDisabledDay, year, month }
    ) {
        return (
            <DatePicker
                ref={ref}
                year={year}
                month={month}
                isDisabledDay={isDisabledDay}
                sharedProps={sharedProps}
                onChange={onChange}
                onSelect={onSelect}
                value={value}
                valueAsDate={valueAsDate}
            />
        );
    },
    getValue() {
        if (this.el.current.props.valueAsDate) return this.el.current.state.date;
        return this.el.current.datePicker.inputRef.current.value;
    }
});

GenericFormField.registerExtraType(ExtraFormFieldTypes.Mask, {
    render(sharedProps, { mask, fieldType = 'text', guide = false }) {
        return <TextMask {...sharedProps} mask={mask} guide={guide} type={fieldType} />;
    },
    getValue() {
        return this.el.current.inputElement.value;
    }
});

GenericFormField.registerExtraType(ExtraFormFieldTypes.Editor, {
    render({ readOnly, ...sharedProps }, { quillOptions, theme }) {
        return (
            <EditorWrapper
                {...sharedProps}
                options={{ readOnly, ...(quillOptions || {}) }}
                theme={theme}
            />
        );
    },
    getValue() {
        return this.el.current.quill && this.el.current.quill.getText().trim()
            ? this.el.current.quill.root.innerHTML
            : null;
    }
});
