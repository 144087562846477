const _parentHasClass = (
    element: HTMLElement,
    classname: string,
    wrappingElement?: HTMLElement
) => {
    if (
        element.className &&
        element.className.split &&
        element.className.split(' ').indexOf(classname) >= 0
    )
        return element;
    if (wrappingElement === element) return false;
    return (
        !!element.parentNode &&
        _parentHasClass(element.parentNode as HTMLElement, classname, wrappingElement)
    );
};

export const parentHasClass = (
    element: HTMLElement,
    classNames: string | string[],
    wrappingElement?: HTMLElement
) => {
    if (!Array.isArray(classNames)) classNames = [classNames];
    for (let i = 0, iLength = classNames.length; i < iLength; i++) {
        if (_parentHasClass(element, classNames[i], wrappingElement)) return true;
    }
    return false;
};

export const getParentWithClass = (
    element: HTMLElement,
    classNames: string | string[],
    wrappingElement?: HTMLElement
) => {
    if (!Array.isArray(classNames)) classNames = [classNames];
    for (let i = 0, iLength = classNames.length; i < iLength; i++) {
        const parentElement = _parentHasClass(element, classNames[i], wrappingElement);
        if (parentElement) return parentElement;
    }
    return null;
};
